import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from "moment";

import nasmApi from '../../../api/endpoints';
import { selectClient } from '../../../reducers/selectedClientReducer';
import { useFetchAllGoals, useFetchActivityLevels, useFetchAllMilestones } from '../../../hooks/users/GoalsHooks';
import { SAVE_STATES } from '../../../constants';

import GoalsDialog from '../../Dialogs/GoalsDialog';

function ManageGoals (props) {
  const { goalsOpen, onClose, client } = props;
  const { goals } = useFetchAllGoals();
  const { activityLevels } = useFetchActivityLevels();
  const {
    milestones,
    loading,
    getAllMilestones,
  } = useFetchAllMilestones(client.id);

  const dispatch = useDispatch();

  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [largestOrdinal, setLargestOrdinal] = useState(0);
  const [tempMilestone, setTempMilestone] = useState(null);
  const [localMilestones, setLocalMilestones] = useState([]);
  const [isAdding, setAdding] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isFtu, setFtu] = useState(false);

  // Set up initial state when milestones are loaded
  useEffect(() => {
    const createFtuExperience = () => {
      const newMilestone = { name: '' };
      setFtu(true);
      setAdding(true);
      if (!milestones.length) {
        setTempMilestone(newMilestone);
      }
    };

    if (!loading) {
      if (!milestones.length) {
        createFtuExperience();
      } else {
        setFtu(false);
        setAdding(false);
        setTempMilestone(null);
      }
      setLocalMilestones(milestones);
    }
  }, [loading, milestones]);

  // Recalculate largest ordinal when milestones change
  useEffect(() => {
    const calculateLargestOrdinal = () => {
      const incomplete = milestones.filter(m => !m.status);
      let maxOrdinal = 0;
      incomplete.forEach(milestone => {
        if (milestone.ordinal) {
          maxOrdinal = Math.max(maxOrdinal, milestone.ordinal);
        }
      });
      setLargestOrdinal(maxOrdinal);
    };

    if (milestones.length) {
      calculateLargestOrdinal();
    }
  }, [milestones]);

  const resetSaveState = () => {
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const createNewMilestone = () => {
    if (isAdding) {
      return; // Prevent adding another milestone while one is being added
    }
    const newMilestone = { name: '' };
    setAdding(true);
    setTempMilestone(newMilestone);
  };

  const saveNewMilestone = async (milestone) => {
    if (milestone.name.length === 0) { return; }
    try {
      const pendingMilestone = { ...milestone };

      // Give the new milestone the largest ordinal + 1
      pendingMilestone.ordinal = largestOrdinal + 1;

      // remove prepended milestone and add it to the end
      setLocalMilestones(prev => [...prev, pendingMilestone]);
      setTempMilestone(null);
      await nasmApi.goals.createMilestone(client.id, pendingMilestone);
      setFtu(false);
      getAllMilestones(client.id);
      setAdding(false);
      setSaveState(SAVE_STATES.CLICKABLE);
    } catch (e) {
      window.alert('Unable to save milestone. Please try again.');
      setLocalMilestones(milestones);
      setTempMilestone(milestone);
    }
  };

  const saveEditedMilestone = async (milestone) => {
    try {
      await nasmApi.goals.updateMilestone(client.id, milestone);
      getAllMilestones(client.id);
      setSaveState(SAVE_STATES.CLICKABLE);
      setAdding(false);
    } catch (e) {
      window.alert('Unable to save milestone. Please try again.');
    }
  };

  const deleteMilestone = async (milestone) => {
    setDeleting(true);
    try {
      if (!milestone.id) {
        setTempMilestone(null);
      }
      setLocalMilestones(prev => prev.filter(m => m.id !== milestone.id));
      if (milestone.id && milestone.name) {
        await nasmApi.goals.deleteMilestone(milestone.id);
        getAllMilestones(client.id);
      }
      setSaveState(SAVE_STATES.CLICKABLE);
      if (isAdding) {
        setAdding(false);
      }
    } catch (e) {
      window.alert('Unable to delete milestone. Please try again.');
      setLocalMilestones(milestones);
    } finally {
      setDeleting(false);
    }
  };

  const reorderMilestones = async (srcIndex, destIndex) => {
    try {
      let largestOrdinal = 0;
      const tempMilestones = localMilestones.filter(m => !m.status);

      // Swap milestone at source index with destination index
      const [draggedMilestone] = tempMilestones.splice(srcIndex, 1);
      tempMilestones.splice(destIndex, 0, draggedMilestone);

      // Reset ordinal for each milestone
      tempMilestones.forEach((milestone, index) => {
        tempMilestones[index].ordinal = index + 1;
        largestOrdinal = tempMilestones[index].ordinal;
      });

      // Update local state
      const newLocalMilestones = localMilestones
        .map((ms) => {
          const updatedMilestone = tempMilestones.find(m => m.id === ms.id);
          if (updatedMilestone) {
            const msCopy = { ...ms };
            msCopy.ordinal = updatedMilestone.ordinal;
            return msCopy;
          }
          return ms;
        });

      setLocalMilestones(newLocalMilestones);
      setLargestOrdinal(largestOrdinal);
      await nasmApi.goals.updateMilestones(client.id, tempMilestones);
      getAllMilestones(client.id);
      setSaveState(SAVE_STATES.CLICKABLE);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      // If error, reset milestone order to previous order
      setLocalMilestones(milestones);
      window.alert('Unable to reorder milestones. Please try again.');
    }
  };

  const markMilestoneAsComplete = async (milestone) => {
    if (!milestone.id) { return; }
    setSaveState(SAVE_STATES.CLICKABLE);
    const newMilestone = { ...milestone };
    if (!newMilestone.status) {
      // Marking milestone as complete
      newMilestone.status = true;
      newMilestone.ordinal = largestOrdinal;
      const newLocalMilestones = localMilestones.map((ms) => {
        if (ms.id === milestone.id) {
          const msCopy = { ...ms };
          msCopy.status = true;
          msCopy.ordinal = largestOrdinal;
          return msCopy;
        }
        return ms;
      });
      setLocalMilestones(newLocalMilestones);
    } else {
      // Marking milestone as incomplete
      newMilestone.status = false;
      newMilestone.ordinal = largestOrdinal + 1;
      const newLocalMilestones = localMilestones.map((ms) => {
        if (ms.id === milestone.id) {
          const msCopy = { ...ms };
          msCopy.status = false;
          msCopy.ordinal = largestOrdinal + 1;
          return msCopy;
        }
        return ms;
      });
      setLocalMilestones(newLocalMilestones);
    }
    try {
      await nasmApi.goals.updateMilestone(client.id, newMilestone);
      getAllMilestones(client.id);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert('Unable to mark milestone as complete. Try again later.');
      setLocalMilestones(milestones);
    }
  };

  const onSaveGoals = async (goalId, activityLevelId) => {
    try {
      setSaveState(SAVE_STATES.LOADING);

      // Update Focus and Activity Level
      const updatedClient = await nasmApi.users.updateClientUser({
        goal_id: goalId,
        activity_level_id: activityLevelId,
      }, client.id);

      await dispatch(selectClient(updatedClient.result));
      getAllMilestones(client.id);
      setSaveState(SAVE_STATES.SAVED);
      setTempMilestone(null);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert('Unable to save goal information. Please try again.');
    }
  };

  const handleOnClose = () => {
    setAdding(false);
    setTempMilestone(null);
    onClose();
  };

  const incomplete = localMilestones.filter(m => !m.status).sort((a, b) => a.ordinal - b.ordinal);
  const incompleteMilestones = tempMilestone ? [tempMilestone, ...incomplete] : incomplete;
  const completedMilestones = localMilestones
    .filter(m => !!m.status)
    .sort((a, b) => {
      return moment(a.completed_at).isBefore(moment(b.completed_at));
    });

  return (
    <GoalsDialog
      open={goalsOpen}
      onClose={handleOnClose}
      saveState={saveState}
      focusOptions={goals}
      activityLevelOptions={activityLevels}
      onSaveGoals={onSaveGoals}
      resetSaveState={resetSaveState}
      client={client}
      loading={loading}
      incompleteMilestones={incompleteMilestones}
      completedMilestones={completedMilestones}
      saveNewMilestone={saveNewMilestone}
      saveEditedMilestone={saveEditedMilestone}
      deleteMilestone={deleteMilestone}
      reorderMilestones={reorderMilestones}
      createNewMilestone={createNewMilestone}
      isAdding={isAdding}
      isDeleting={isDeleting}
      isFtu={isFtu}
      markMilestoneAsComplete={markMilestoneAsComplete}
    />
  );
}

export default ManageGoals;
