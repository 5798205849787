import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { colors } from '../../styles';
import { ReactComponent as NotIncludedIcon } from '../../resources/icon-not-included.svg';
import { ReactComponent as IncludedIcon } from '../../resources/icon-checkmark-blue.svg';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    width: '350px',
    height: props => props.tabVisible ? '520px' : '500px',
    borderRadius: '17px',
    boxShadow: `0 3px 8px 0 ${colors.cloudy_blue_50}`,
    backgroundColor: colors.white,
    margin: '10px 15px',
  },
  tab: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props => props.currentPlan ? colors.steel : colors.lightest_blue,
    color: colors.white,
    width: '350px',
    height: '36px',
    borderTopLeftRadius: '17px',
    borderTopRightRadius: '17px',
    textAlign: 'center',
    fontSize: '13px',
    fontFamily: 'DMSans-Medium, sans-serif',
  },
  cardContent: {
    padding: '36px',
    marginTop: props => props.tabVisible ? '15px' : 0,
  },
  headerContainer: {
    marginBottom: '20px',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: 1.04,
    color: props => props.headerTextColor ? props.headerTextColor : colors.carbon_black,
    textAlign: 'center',
  },
  subHeader: {
    fontSize: '19px',
    color: colors.steel,
    lineHeight: 1.32,
    textAlign: 'center',
    marginTop: '6px',
  },
  price: {
    fontSize: '34px',
    lineHeight: 0.74,
    color: colors.carbon_black,
    textAlign: 'center',
    marginBottom: '8px',
  },
  pricingSubheader: {
    fontSize: '12px',
    color: colors.steel,
    textAlign: 'center',
    lineHeight: 1.5,
  },
  perksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '25px',
  },
  perkItem: {
    display: 'flex',
    width: '220px',
    marginBottom: '14px',
    alignItems: 'center',
  },
  includedPerkItem: {
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    color: colors.charcoal_grey,
    marginLeft: '14px',
  },
  notIncludedPerkItem: {
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    color: colors.steel,
    marginLeft: '14px',
  },
});

const allPerks = [
  'Unlimited Clients',
  'Client Management',
  'Workout Builder',
  'Performance Assessments',
  'Overhead Squat Assessments',
  'In App Chat',
  'Online Booking',
  'Payment Processing',
];

function TopTab ({ currentPlan, tabText }) {
  const classes = useStyles({ currentPlan });
  if (!tabText) {
    return null;
  }

  return <Box className={classes.tab}>{tabText}</Box>;
}

function PerkItem ({ perks, perk }) {
  const classes = useStyles();
  return (
    <Box className={classes.perkItem}>
      {perks.includes(perk) ? <IncludedIcon /> : <NotIncludedIcon />}
      <Typography className={perks.includes(perk) ? classes.includedPerkItem : classes.notIncludedPerkItem}>
        {perk}
      </Typography>
    </Box>
  );
}

function PlanCard (props) {
  const {
    currentPlan,
    tabText,
    headerText,
    headerTextColor,
    subHeaderText,
    price,
    perText,
    perSubHeader,
    perks,
  } = props;

  const classes = useStyles({ tabVisible: tabText, headerTextColor });

  return (
    <Box className={classes.card}>
     <TopTab
        currentPlan={currentPlan}
        tabText={tabText}
     />
      <Box className={classes.cardContent}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.header}>{headerText}</Typography>
          <Typography className={classes.subHeader}>{subHeaderText}</Typography>
        </Box>
        <Box>
          <Typography className={classes.price}>{`$${price}`}</Typography>
          <Typography className={classes.pricingSubheader}>{perText}</Typography>
          { perSubHeader ? <Typography className={classes.pricingSubheader}>{perSubHeader}</Typography> : ''}
        </Box>
        <Box className={classes.perksContainer}>
          {
            allPerks.map((perk, index) => {
              return (
                <PerkItem
                  key={index}
                  perk={perk}
                  perks={perks}
                />
              );
            })
          }
        </Box>
      </Box>

    </Box>
  );
}

export default PlanCard;

PlanCard.propTypes = {
  currentPlan: PropTypes.bool.isRequired,
  tabText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  headerTextColor: PropTypes.string,
  subHeaderText: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  perText: PropTypes.string.isRequired,
  perSubHeader: PropTypes.string,
  perks: PropTypes.array.isRequired,
};
