import React from 'react';
import { Dialog, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import AddNewGroupForm from './AddNewGroupForm';

const useStyles = makeStyles({
  dialogBox: {
    width: '578px',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
});

function AddNewGroup (props) {
  const { open, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogBox }}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <Box className={classes.dialogBox}>
        <AddNewGroupForm
          visible={open}
          onClose={onClose}
        />
      </Box>
    </Dialog>
  );
}

export default AddNewGroup;
