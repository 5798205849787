import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import noResultsIcon from '../../resources/icon-bar-bells.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10%',
  },
  header: {
    fontSize: '19px',
    fontWeight: 'bold',
    lineHeight: '39px',
    marginTop: '14px',
  },
  subHeader: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    lineHeight: '33px',
  },
});

function NoSearchResults () {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img src={noResultsIcon} alt='no results' />
      <Typography className={classes.header}>No Results Found</Typography>
      <Typography className={classes.subHeader}>Try resetting all filters</Typography>
    </Box>
  );
}

export default NoSearchResults;
