import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import querystring from 'query-string';

import WorkoutVideoPanel from '../Panels/WorkoutVideoPanel';

import {SAVE_STATES, UPLOAD_STATES, UPLOADED_MEDIA_STATUS_TYPES} from '../../constants';

import {
  editScheduledWorkoutName,
  editWorkoutDescription,
  selectWorkout,
  saveWorkout,
} from '../../reducers/selectedWorkoutReducer';

import {programContexts} from '../../reducers/programContextReducer';

function ScheduleWorkoutVideoPanelWrapper (props) {
  const {
    visible,
    isScheduleDialogOpen,
    headerText,
    showSaveButton,
    showDeleteButton,
    showScheduleButton,

    setScheduleDialogOpen,
    resetWorkoutIndex,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = history.location;
  const query = querystring.parse(location.search || {});

  const programContext = useSelector(state => state.programContext.context);
  const { workout } = useSelector(state => state.selectedWorkout);

  const [streamingUrl, setStreamingUrl] = useState('');
  const [uploadState, setUploadState] = useState(UPLOAD_STATES.Empty);
  const [saveState, setSaveState] = useState(SAVE_STATES.SAVED);

  const onClickSchedule = () => {
    setScheduleDialogOpen(true);
  };

  const onChangeWorkoutTitle = (title) => {
    if (programContext === programContexts.RESCHEDULING) {
      setSaveState(SAVE_STATES.CLICKABLE);
      dispatch(editScheduledWorkoutName(title));
    }
  };

  const onChangeWorkoutDescription = (description) => {
    if (programContext === programContexts.RESCHEDULING) {
      setSaveState(SAVE_STATES.CLICKABLE);
      dispatch(editWorkoutDescription(description));
    }
  };

  const onSaveWorkout = async () => {
    if (programContext === programContexts.RESCHEDULING)  {
      try {
        setSaveState(SAVE_STATES.LOADING);
        await dispatch(saveWorkout());
        setSaveState(SAVE_STATES.SAVED);
      } catch (err) {
        setSaveState(SAVE_STATES.ERROR);
        window.alert('An error occurred when trying to save the scheduled workout. Please try again.');
        setSaveState(SAVE_STATES.CLICKABLE);
        throw err;
      }

    }
  };

  const onCloseVideoWorkout = () => {
    if (programContext === programContexts.SCHEDULING) {
      resetWorkoutIndex();
      history.replace({
        pathname: history.location.pathname,
        search: '?view=workoutList',
      });
    } else if (programContext === programContexts.RESCHEDULING) {
      history.goBack();
    }
  };

  useEffect(() => {
    if(visible && query.workoutId) {
      dispatch(selectWorkout({id: query.workoutId}));
    }
  }, [query.workoutId, visible, dispatch]);

  useEffect(() => {
    if (visible) {
      if (workout.id && workout.uploaded_media) {
        const uploadStatus = workout.uploaded_media?.status ?? 'DEFAULT';
        const streamUrl = workout.uploaded_media.stream_url;

        switch(uploadStatus) {
          case UPLOADED_MEDIA_STATUS_TYPES.FINISHED:
            setUploadState(UPLOAD_STATES.Media_Available);
            setStreamingUrl(streamUrl);
            break;
          case UPLOADED_MEDIA_STATUS_TYPES.PENDING:
            setUploadState(UPLOAD_STATES.Processing);
            break;
          case UPLOADED_MEDIA_STATUS_TYPES.ERROR:
            window.alert('There was an error uploading this video workout. Please try again later');
            break;
          default:
            break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout.id, visible]);

  return (
    <WorkoutVideoPanel
      visible={visible}
      programContext={programContext}
      workout={workout}
      headerText={headerText}

      showSaveButton={showSaveButton}
      showDeleteButton={showDeleteButton}
      showScheduleButton={showScheduleButton}
      isScheduleDialogOpen={isScheduleDialogOpen}

      saveState={saveState}
      uploadState={uploadState}
      streamingUrl={streamingUrl}

      onSaveWorkout={onSaveWorkout}
      onClickSchedule={onClickSchedule}
      onClose={onCloseVideoWorkout}
      onChangeTitle={onChangeWorkoutTitle}
      onChangeDescription={onChangeWorkoutDescription}
    />
  );
}

ScheduleWorkoutVideoPanelWrapper.propTypes = {
  visible: PropTypes.bool,
  isScheduleDialogOpen: PropTypes.bool,
  showSaveButton: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  showScheduleButton: PropTypes.bool.isRequired,
  headerText: PropTypes.string,

  setScheduleDialogOpen: PropTypes.func,
  resetWorkoutIndex: PropTypes.func,
};

export default ScheduleWorkoutVideoPanelWrapper;