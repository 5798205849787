/* eslint-disable camelcase */
import { FEATURE_FLAGS } from '../../constants';
import request from '../nasm_sdk_v2/request';


export default function activity(apiVer) {

  function getAllActivityFeed(
    userId = '',
    page = 1,
    size = 20,
    searchStr = '',
    filters,
    club_id = '',
    location_id = '',
  ) {
    let queries = `?page=${page}&size=${size}&search=${searchStr}`;
    if (userId) {
      queries += `&client_user_id=${userId}`;
    }
    if (club_id) {
      queries += `&club_id=${club_id}`;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && location_id) {
        queries += `&location_id=${location_id}`;
      }
    }
    if (filters) {
      const { workout_activity, daily_readiness_assessment, activity_types } = filters;
      if (workout_activity?.length) {
        queries += `&workout_activity=${workout_activity.join(',')}`;
      }
      if (daily_readiness_assessment?.length) {
        queries += `&daily_readiness_assessment=${daily_readiness_assessment.join(',')}`;
      }
      if (activity_types?.length) {
        queries += `&activity_types=${activity_types.join(',')}`;
      }
    }
    return request.get(`${apiVer}/api/activity-feed${queries}`);
  }


  return {
    getAllActivityFeed,
  };
}