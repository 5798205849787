import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0px 20px 0px 20px',
    width: '100%',
    height: '58px',
    backgroundColor: 'rgba(240, 241, 243, 0.41)',
    borderTop: '1px solid rgba(182, 189, 195, 0.5)',
    borderBottom: '1px solid rgba(182, 189, 195, 0.5)',
  },
  section: {
    fontSize: '14px',
    fontWeight: 500,
  },
  time: {
    fontSize: '11px',
  },
});

function SectionDivider (props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.section}>
        {props.name}
      </Typography>
      <Typography className={classes.time}>
        {props.totalTime}
      </Typography>
    </Box>
  );
}

SectionDivider.propTypes = {
  name: PropTypes.string.isRequired,
  totalTime: PropTypes.string.isRequired,
};

export default SectionDivider;
