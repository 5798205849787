import React, { useEffect, useCallback, useState } from 'react';
import { Dialog, CircularProgress, Box } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import { colors } from '../../../styles';
import { useExternalScript } from '../../../hooks/ExternalScript';
import { useFetchActivityLevels } from '../../../hooks/users/GoalsHooks';
import nasmApi from '../../../api/endpoints';
import CalculatorFTUE from '../CalculatorFTUE';
import FTUImg from '../../../resources/image-nutrition-ftu.svg';
import { ROLES } from '../../../constants';

const useStyles = makeStyles({
    profilePaper: {
        display: 'flex',
        alignItems: 'center',
        width: '575px',
        height: '855px',
        borderRadius: '17px',
        boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
    },
    scrollPaper: {
        alignItems: 'flex-start',
    },
});

const LoadingView = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
    height: '100%',
});

function BWPCalculatorContainer(props) {
    const { currentUser, isOpen, onClose } = props;
    const { allActivityLevels = [], activityLevels = {} } = useFetchActivityLevels();
    const classes = useStyles();

    const hasSelectedClient = currentUser?.role === ROLES.CLIENT;

    const ftueShown = localStorage.getItem('bwp-shown');
    const [bwpShown, setBwpShown] = useState(ftueShown === 'true');

    // Prepare BWP Scripts for mounting
    const widgetUrl = process.env.REACT_APP_BWP_WIDGET_URL || '/dist/bwp/index.js';
    const scriptState = useExternalScript({ url: widgetUrl, canStartLoading: isOpen });
    const canMount = isOpen && scriptState === 'ready';

    const getGender = (userGender, genderType) => {
        if (userGender) {
            return userGender === 'OTHER' ? 'MALE' : userGender;
        }

        if (genderType) {
            return genderType === 2 ? 'FEMALE' : 'MALE';
        }

        return 'MALE';
    };

    const clientInfo = {
        client: {
            firstName: currentUser?.first_name || '',
            lastName: currentUser?.last_name || '',
            birthDate: currentUser?.birth_date || '',
            gender: getGender(currentUser?.gender, currentUser?.gender_type),
            unitHeight: currentUser?.unit_height || 'in',
            unitWeight: currentUser?.unit_weight || 'lb',
            weight: currentUser?.client_user?.weight || 0,
            height: currentUser?.client_user?.height || 0,
        },
        activityLevelId: currentUser?.client_user?.activity_level_id || null,
    };

    // Find activity level if client selected one
    if (clientInfo.activityLevelId && activityLevels[clientInfo.activityLevelId]) {
        clientInfo.activityLevel = {
            title: activityLevels[clientInfo.activityLevelId].label,
            description: activityLevels[clientInfo.activityLevelId].description,
        };
    } else {
        // Default activity level
        clientInfo.activityLevel = {
            title: 'Sedentary',
            description: 'Little or no exercise and a desk job',
        };
    }

    const saveBWPRecord = useCallback (async (bwpData) => {
        if (!hasSelectedClient) {
            return;
        }
        // if we have client user ID for this screen in redux, save their BWP results
        try {
            await nasmApi.bwp.saveResults(currentUser?.id, bwpData);
        } catch (error) {
            showAlert(error);
        } finally {
            const SaveRequestComplete = new CustomEvent("saveRequestComplete");
            window.dispatchEvent(SaveRequestComplete);
            /**
             * Dispatch another event to update nutrition card
            */
            const UpdateNutritionCard = new CustomEvent("updateNutritionCard");
            window.dispatchEvent(UpdateNutritionCard);
        }
    }, [currentUser.id, hasSelectedClient]);

    useEffect(() => {
        if (allActivityLevels.length) {
            window.addEventListener('onSaveRequest', (event) => {
                const { bwpData } = event.detail;
                if (bwpData) {
                    const requestData = {
                        ...bwpData,
                        activity_level_id: 
                            allActivityLevels.find((level) => level.coefficient === bwpData.currentActivityLevel)?.id,
                    };
                    saveBWPRecord(requestData);
                }
            });
            return () => {
                window.removeEventListener('onSaveRequest');
            };
        }
    }, [allActivityLevels, saveBWPRecord]);

    useEffect(() => {
        window.addEventListener('fetchCurrentBWPRecord', getCurrentBWP);
        window.addEventListener('fetchPaginatedHistoryList', getBWPHistory);
        return () => {
            window.removeEventListener('fetchCurrentBWPRecord', getCurrentBWP);
            window.removeEventListener('fetchPaginatedHistoryList', getBWPHistory);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCurrentBWP = async () => {
        if (!hasSelectedClient) {
            return;
        }
        // if we have client user ID for this screen, get their current body weight plan
        try {
            const response = await nasmApi.bwp.getCurrentPlan(currentUser?.id);
            const receiveCurrentBWPEvent = new CustomEvent("onReceiveCurrentBWPRecord", {
                cancelable: true,
                detail: {
                    currentBWPRecord: response?.result,
                },
            });
            window.dispatchEvent(receiveCurrentBWPEvent);
        } catch (error) {
            showAlert(error);
        }
    };

    const getBWPHistory = async (event) => {
        if (!hasSelectedClient) {
            return;
        }
        // if we have client user ID for this screen, get their current body weight plan
        try {
            const response = await nasmApi.bwp.getHistory(currentUser?.id, { page: event?.detail?.page });
            const receiveBWPHistoryEvent = new CustomEvent("onReceivePaginatedHistoryList", {
                cancelable: true,
                detail: {
                    BWPHistoryRecord: response?.result,
                },
            });
            window.dispatchEvent(receiveBWPHistoryEvent);
        } catch (error) {
            showAlert(error);
        }
    };

    const closeBWP = useCallback ((_, reason) => {
            onClose();
    }, [onClose]);

    useEffect(() => {
        // Pass onClose function to custom widget element:
        const bwpCalculator = document.querySelector('nasm-bwp-calculator');
        if (canMount && bwpCalculator) {
            bwpCalculator.closewidget = closeBWP;
        }
    }, [canMount, closeBWP, bwpShown]);

    const startBWP = () => {
        setBwpShown(true);
        localStorage.setItem('bwp-shown', 'true');
    };

    const showAlert = (error) => {
        alert(error?.message || 'Something went wrong! Please try again later.');
    };

    const renderLoader = () => (
        <LoadingView>
            <CircularProgress size={40} style={{ color: colors.green }} />
        </LoadingView>
    );

    const renderCalculator = () => {
        return (
            <>
                {!bwpShown
                    ? <CalculatorFTUE
                        headerText={`${clientInfo?.client?.firstName ?? 'Client'}'s Nutrition`}
                        headerColor={colors.green}
                        heroText='Set Up Nutrition with the Body Weight Planner'
                        heroImg={FTUImg}
                        subHeadingText={
                            'Calculate basic macronutrients and create advanced meal plans based on specific needs.'
                        }
                        startButtonText='Body Weight Planner'
                        onStart={startBWP}
                        onClose={onClose}
                    />
                    : <>
                        {/* Add loading spinner as the BWP widget may load into view async */}
                        {!canMount && renderLoader()}
                        {canMount
                            && <nasm-bwp-calculator
                                data-first-name={clientInfo.client.firstName}
                                data-last-name={clientInfo.client.lastName}
                                data-birth-date={clientInfo.client.birthDate}
                                data-gender={clientInfo.client.gender}
                                data-unit-height={clientInfo.client.unitHeight}
                                data-unit-weight={clientInfo.client.unitWeight}
                                data-weight={clientInfo.client.weight}
                                data-height={clientInfo.client.height}
                                data-activity-level-id={clientInfo.activityLevelId}
                                data-edge-web='true'
                                selected-client={hasSelectedClient ? 'true' : 'false'}
                            />
                        }
                    </>
                }
            </>
        );
    };

    return (
        <Dialog
            open={isOpen}
            onClose={closeBWP}
            classes={{ paper: classes.profilePaper, scrollPaper: classes.scrollPaper }}
            disableEscapeKeyDown
            disableBackdropClick
            BackdropProps={{
                style: {
                    backgroundColor: colors.backdrop_tint,
                    opacity: 0.35,
                },
            }}
        >
            {renderCalculator()}
        </Dialog>
    );
}

export default BWPCalculatorContainer;
