import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import { YellowSwitch } from '../Buttons';
import { StatelessDropdown } from '../Inputs';

import { EXERCISE_SIDES } from '../../constants';

const SubheaderText = withStyles({
  root: {
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: 1.33,
  },
})(Typography);

const useStyles = makeStyles({
  unilateralCombobox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 27,
  },
  unilateralCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
});

function UnilateralCombobox (props) {
  const {
    checked,
    exerciseSides,
    onChangeUnilateral,
    onChangeExerciseSides,
  } = props;
  const classes = useStyles();

  const ExerciseSidesDropdown = () => {
    if (checked) {
      return (
        <Box style={{ marginBottom: 10 }}>
          <StatelessDropdown
            label='Exercise Side'
            name='exercise_sides'
            options={EXERCISE_SIDES}
            currentValue={exerciseSides}
            onOptionChanged={onChangeExerciseSides}
            width='100%'
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box>
      <Box className={classes.unilateralCombobox}>
        <SubheaderText>
          Default Values (Optional)
        </SubheaderText>
        <Box className={classes.unilateralCheckbox}>
          <SubheaderText>
            Unilateral
          </SubheaderText>
          <YellowSwitch
            checked={checked}
            onChange={onChangeUnilateral}
          />
        </Box>
      </Box>
      <ExerciseSidesDropdown />
    </Box>
  );
}

UnilateralCombobox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChangeUnilateral: PropTypes.func.isRequired,
  exerciseSides: PropTypes.string.isRequired,
  onChangeExerciseSides: PropTypes.func.isRequired,
};

export default UnilateralCombobox;
