import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Dialog, Typography, Divider } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { OvalButton, SaveButton } from '../Buttons';
import { StatelessDropdown } from '../Inputs';
import DayOfWeekSelector from './DayOfWeekSelector';
import ScheduleDatePicker from './ScheduleDatePicker';

import { colors } from '../../styles';
import { SAVE_STATES } from '../../constants';

import { ReactComponent as IconCalendar } from '../../resources/icon-calendar.svg';

const SCHEDULE_TYPES = Object.freeze({
  SCHEDULE_ONCE: 'Schedule Once',
  SCHEDULE_REPEAT: 'Schedule Repeat',
});

const SpaceDivider = withStyles({
  root: {
    minHeight: props => props.spacing || 30,
  },
})(Box);

const useStyles = makeStyles({
  dialogContainer: {
    minWidth: 600,
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    borderRadius: 17,
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
    overflowY: 'hidden',
  },
  // Used to keep dialog box vertically aligned starting from the top
  // rather than the center
  scrollPaper: {
    alignItems: 'flex-start',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 22,
  },
  closeButtonMargins: {
    marginLeft: 21,
    marginRight: 21,
  },
  scheduleContainer: {
    marginLeft: 33,
    marginTop: 5,
  },
  scheduleDropdownContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 16,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.63,
  },
  subtitle: {
    fontSize: 22,
    lineHeight: 1.36,
  },
  datePickersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
    marginBottom: 24,
    marginTop: 24,
  },
  dayOfWeekContainer: {
    overflowY: 'auto',
    height: '100%',
    marginLeft: 33,
    marginTop: 15,
  },
  daysOfWeekError: {
    marginLeft: 33,
    marginBottom: 10,
    color: 'red',
  },
  scheduleRepeatBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    borderRadius: '6px',
    width: '142px',
    height: '38px',
  },
});

function ScheduleDialog (props) {
  const {
    open,
    backdropFilterVisible,
    workouts = [],
    onClose,
    onScheduleOnce = async () => {},
    onScheduleRepeat = async () => {},
    onPrepareWorkouts = () => {},
    onlyRepeat = false,
    selectedDate,
  } = props;

  const [currentOption, setCurrentOption] = useState(SCHEDULE_TYPES.SCHEDULE_ONCE);
  const [dayOfWeekSelectorVisible, setDayOfWeekSelectorVisible] = useState(false);
  const [mainSubtitle, setMainSubtitle] = useState('What day');
  const [isDatePickerValuesValid, setDatePickerValuesValid] = useState(true);
  const [daysOfWeekError, setDaysOfWeekError] = useState('');
  const [scheduleRequestPending, setScheduleRequestPending] = useState(false);
  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);

  const scheduledProgram = useSelector(state => state.selectedProgram.program ?? {});
  const classes = useStyles();
  const now = moment().startOf('day');

  /* eslint-disable camelcase */
  const [startDate, setStartDate] = useState(getStartDate(scheduledProgram.start_date, selectedDate, now));
  const [endDate, setEndDate] = useState(scheduledProgram.end_date ? moment(scheduledProgram.end_date) : now);

  const setScheduleRepeat = useCallback(() => {
    setCurrentOption(SCHEDULE_TYPES.SCHEDULE_REPEAT);
    setDayOfWeekSelectorVisible(true);
    setMainSubtitle('How Long');

    if (endDate.isSameOrBefore(startDate, 'day')) {
      setEndDate(startDate.clone().add(1, 'month').endOf('day'));
      setDatePickerValuesValid(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // Reset to Default Option when dialog closes;
    if (!open) {
      setCurrentOption(SCHEDULE_TYPES.SCHEDULE_ONCE);
      setDayOfWeekSelectorVisible(false);
      setDaysOfWeekError('');
      setScheduleRequestPending(false);
    } else {
      onPrepareWorkouts();
      if (onlyRepeat) {
        setScheduleRepeat();
      }
    }
  }, [open, onPrepareWorkouts, setScheduleRepeat, onlyRepeat]);

  useEffect(() => {
    // Update program start and end dates when selecting an existing scheduled event
    if(!!scheduledProgram.start_date) {
      setStartDate(moment(scheduledProgram.start_date));
    }
    if(!!scheduledProgram.end_date) {
      setEndDate(moment(scheduledProgram.end_date));
    }

    // Determine if schedule type from scheduled program is set one time or repeating
    if(!!scheduledProgram.start_date && !!scheduledProgram.end_date) {
      const scheduledProgramStartDate = moment(scheduledProgram.start_date).startOf('day');
      const scheduledProgramEndDate = moment(scheduledProgram.end_date).startOf('day');
      const diff = scheduledProgramEndDate.diff(scheduledProgramStartDate, 'day');
      if(diff >= 1) {
        setCurrentOption(SCHEDULE_TYPES.SCHEDULE_REPEAT);
        setDayOfWeekSelectorVisible(true);
        setMainSubtitle('How Long');
      }
    }
  }, [scheduledProgram.start_date, scheduledProgram.end_date]);

  const handleOptionChanged = (event) => {
    const newOption = event.target.value;
    // Prevent changing options when in the middle of making a schedule request
    if (scheduleRequestPending) {
      return;
    }

    setCurrentOption(newOption);
    if (newOption === SCHEDULE_TYPES.SCHEDULE_REPEAT) {
      setScheduleRepeat();
    } else {
      setDayOfWeekSelectorVisible(false);
      setMainSubtitle('What Day');
      setDatePickerValuesValid(true);
    }
  };

  const handleDatePickerError = () => {
    setDatePickerValuesValid(false);
  };

  const handleStartDate = (date) => {
    // Prevent changing date when in the middle of making a schedule request
    if (scheduleRequestPending) {
      return;
    }

    const newDate = moment(date).startOf('day');
    setStartDate(newDate);
    setDatePickerValuesValid(newDate.isSameOrAfter(now, 'day'));
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const handleEndDate = (date) => {
    // Prevent changing date when in the middle of making a schedule request
    if (scheduleRequestPending) {
      return;
    }

    const newDate = moment(date).endOf('day');
    setEndDate(newDate);
    setDatePickerValuesValid(newDate.isAfter(startDate, 'day'));
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const handleDayOfWeekSelector = () => {
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const resetCurrentOptions = () => {
    setCurrentOption(SCHEDULE_TYPES.SCHEDULE_ONCE);
    setDayOfWeekSelectorVisible(false);
    setSaveState(SAVE_STATES.CLICKABLE);
    onClose();
  };

  const handleScheduleSubmit = () => {
    if (currentOption === SCHEDULE_TYPES.SCHEDULE_ONCE) {
      setScheduleRequestPending(true);
      setSaveState(SAVE_STATES.LOADING);
      onScheduleOnce(startDate.format()).then(() => {
        setSaveState(SAVE_STATES.SAVED);
      }).finally(() => setScheduleRequestPending(false));
    } else {
      const { name, valid: areDaysOfWeekSelected } = verifyRepeatingWorkoutsHaveDayOfWeekSelected(workouts);
      if (areDaysOfWeekSelected) {
        setScheduleRequestPending(true);
        setSaveState(SAVE_STATES.LOADING);
        setDaysOfWeekError('');
        onScheduleRepeat(startDate.format(), endDate.format()).then(() => {
          setSaveState(SAVE_STATES.SAVED);
        }).finally(() => setScheduleRequestPending(false));
      } else {
        setSaveState(SAVE_STATES.ERROR);
        setDaysOfWeekError(`Must select at least 1 day for the workout: ${name}`);
      }
    }
  };

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
        // turns off the backdrop tint
        invisible: !backdropFilterVisible,
      }}
      classes={{ paper: classes.dialogContainer, scrollPaper: classes.scrollPaper }}
    >
      <Box className={classes.buttonsContainer}>
        {isDatePickerValuesValid &&
          <SaveButton
            saveState={saveState}
            onClick={handleScheduleSubmit}
          />
         }
        <Box className={classes.closeButtonMargins}>
          <OvalButton disabled={scheduleRequestPending} onClick={resetCurrentOptions}>
            Close
          </OvalButton>
        </Box>
      </Box>
      <Box className={classes.scheduleContainer}>
        <Typography className={classes.dialogTitle}>
          Schedule
        </Typography>
        <Box className={classes.scheduleDropdownContainer}>
          <IconCalendar />
          <Box style={{ marginLeft: 13 }}>
            {
              onlyRepeat
                ? (
                  <Box className={classes.scheduleRepeatBox}>
                    <Typography>Schedule Repeat</Typography>
                  </Box>
                )
                : (
                  <StatelessDropdown
                    currentValue={currentOption}
                    sideMargin={0}
                    dropdownSpacing={4}
                    width={168}
                    options={{
                      [SCHEDULE_TYPES.SCHEDULE_ONCE]: 'Schedule Once',
                      [SCHEDULE_TYPES.SCHEDULE_REPEAT]: 'Schedule Repeat',
                    }}
                    onOptionChanged={handleOptionChanged}
                  />
                )
            }
          </Box>
        </Box>
        <Typography className={classes.subtitle}>
          {mainSubtitle}
        </Typography>
        {/* Should error validation messages be displayed to scheduled events in the past when viewing? */}
        {/* Maybe show an indicator in the dialog that highlights that this a schedule from the past */}
        <Box className={classes.datePickersContainer}>
          <ScheduleDatePicker
            onChange={handleStartDate}
            onDatePickerError={handleDatePickerError}
            value={startDate}
            /* Date coming from API could be in the past */
            minDate={getMinDate(now, startDate)}
            minDateMessage='Start Date should not be set in the past'
            label='Start'
          />
          {(dayOfWeekSelectorVisible || onlyRepeat) &&
            <ScheduleDatePicker
              onChange={handleEndDate}
              onDatePickerError={handleDatePickerError}
              value={endDate}
              minDate={startDate.clone().add(1, 'day')}
              minDateMessage='End Date should not be set before or at Start Date'
              label='End'
            />}
        </Box>
      </Box>
      {(dayOfWeekSelectorVisible || onlyRepeat) &&
        <>
          <Box style={{ marginLeft: 33, marginBottom: 20 }}>
            <Typography className={classes.subtitle}>
              What Day
            </Typography>
          </Box>
          {!!daysOfWeekError &&
            <Typography className={classes.daysOfWeekError}>
              {daysOfWeekError}
            </Typography>}
          <Divider style={{ color: colors.colors_fill_light_2 }} />
          <Box className={classes.dayOfWeekContainer}>
            {workouts.map(w => {
              return (
                <React.Fragment key={w.key}>
                  <DayOfWeekSelector
                    workout={w}
                    handleDayOfWeekSelector={handleDayOfWeekSelector}
                  />
                  <SpaceDivider />
                </React.Fragment>
              );
            })}
          </Box>
        </>}
    </Dialog>
  );
}

ScheduleDialog.propTypes = {
  open: PropTypes.bool,
  backdropFilterVisible: PropTypes.bool,
  workouts: PropTypes.array,
  onClose: PropTypes.func,
  onScheduleOnce: PropTypes.func,
  onScheduleRepeat: PropTypes.func,
  onPrepareWorkouts: PropTypes.func,
  onlyRepeat: PropTypes.bool,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

export default ScheduleDialog;

// HELPER FUNCTION(S) //
const getMinDate = (date1, date2) => date1.isBefore(date2, 'day') ? date1 : date2;

function verifyRepeatingWorkoutsHaveDayOfWeekSelected (workouts = []) {
  for (const workout of workouts) {
    if (workout.days_of_week == null || workout.days_of_week.length === 0) {
      return { valid: false, name: workout.name };
    }
  }

  return { valid: true, name: '' };
}

function getStartDate(scheduledProgramStartDate, selectedDateFromCalendar, now) {
  if(!!scheduledProgramStartDate) {
    return moment(scheduledProgramStartDate);
  }

  if(!!selectedDateFromCalendar) {
    if(selectedDateFromCalendar.isAfter(now, 'day')) {
      return selectedDateFromCalendar;
    }
  }

  return now;
}
