import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { MacaroniButton } from '../Buttons';
import { colors } from '../../styles';

import imgFeedback from '../../resources/image-feedback@3x.png';

const Title = withStyles({
  root: {
    fontWeight: 'bold',
    fontFamily: 'DMSans',
    fontSize: 40,
    lineHeight: 0.98,
  },
})(Typography);

const Text = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontSize: 17,
    lineHeight: 1.76,
    color: colors.confirmDialogBlack,
    margin: '20px 60px 30px 20px',
    textAlign: 'center',
},
})(Typography);

const useStyles = makeStyles({
  upgradePlanContainer: {
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    borderRadius: 17,
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 554,
    height: 485,
  },
});

function UpgradePlanDialog (props) {
  const classes = useStyles();
  const { open, onClose, onViewUpgradeOptions } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: colors.mediumBlue, opacity: 0.35 } }}
      classes={{ paper: classes.upgradePlanContainer }}
    >
      <img src={imgFeedback} width={297} height={215}  alt='upgrade-plan'/>
      <Title>Upgrade Plan</Title>
      <Text>
        Get more from EDGE, upgrade your plan to add unlimited clients, Pro features and more.
      </Text>
      <MacaroniButton width={193} height={38} onClick={onViewUpgradeOptions}>
        See Upgrade Options
      </MacaroniButton>
    </Dialog>
  );
}

UpgradePlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onViewUpgradeOptions: PropTypes.func.isRequired,
};

export default UpgradePlanDialog;
