import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  termsAndConditions: {
    color: '#2592ec',
    '&:hover': {
      borderBottom: '1px solid #2592ec',
    },
  },
});

export default function TermsAndConditionsLink () {
  const classes = useStyles();
  return (
    <span>
      <a
        href='https://www.nasm.org/policies/terms-and-conditions/nasm-app?mobile=1'
        target='_blank'
        rel='noopener noreferrer'
        className={classes.termsAndConditions}
      >
        Terms & Conditions
      </a>
    </span>
  );
}