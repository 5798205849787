import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import { FilterButton } from './index';
import { SquareCheckbox } from '../Inputs';
import { colors } from '../../styles';

const useFilterMenuStyles = makeStyles({
  filterPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '265px',
    height: '108px',
  },
  filterPopover: {
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    borderRadius: '6px',
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.22)',
  },
  menuContainer: {
    marginTop: '16px',
    marginLeft: '36px',
  },
  menuList: {
    padding: '0 8px',
  },
});

const useFilterItemStyles = makeStyles({
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    width: '260px',
    '&:first-child': {
      marginBottom: '8px',
    },
  },
  filterText: {
    marginLeft: '4px',
    fontWeight: 'bold',
    fontSize: '17px',
  },
});

const FilterItem = React.forwardRef((props, ref) => {
  const { id, isSelected, label, onSelectFilter } = props;
  const classes = useFilterItemStyles();
  return (
    <Box className={classes.filterItem} onClick={() => onSelectFilter(id)}>
      <SquareCheckbox isChecked={isSelected} />
      <Typography className={classes.filterText}>{label}</Typography>
    </Box>
  );
});

function WorkoutFilterButton (props) {
  const classes = useFilterMenuStyles();
  const { visible, onCloseFilter, selectedFilters, onSelectFilter, workoutFilterOptions } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const onOpenFilter = (e) => {
    setFilterOpen(true);
    setAnchorEl(e.target);
  };

  const onClose = () => {
    onCloseFilter();
    setFilterOpen(false);
    setAnchorEl(null);
  };

  if (!visible) { return null; }

  return (
    <Box>
      <FilterButton
        margin='0 0 0 20px'
        width={74}
        isPressed={filterOpen}
        onOpenFilterMenu={onOpenFilter}
        filterCount={selectedFilters.length}
      />
      <Menu
        className={classes.menuContainer}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        classes={{ paper: classes.filterPaper }}
        PopoverClasses={{ paper: classes.filterPopover }}
        MenuListProps={{ className: classes.menuList }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          workoutFilterOptions.map((filter, index) => {
            // We don't want to filter by nasm workouts
            if (filter.filter_value  === 'nasm') { return null; }

            return (
              <FilterItem
                key={index}
                id={filter.filter_value}
                isSelected={selectedFilters.includes(filter.filter_value)}
                label={filter.display_value}
                onSelectFilter={onSelectFilter}
              />
            );
          })
        }
      </Menu>
    </Box>
  );
}

export default WorkoutFilterButton;

WorkoutFilterButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseFilter: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  onSelectFilter: PropTypes.func,
  workoutFilterOptions: PropTypes.arrayOf(PropTypes.shape({
    display_value: PropTypes.string,
    filter_value: PropTypes.string,
  })),
};
