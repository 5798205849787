import { useEffect, useState } from 'react';
import axios from 'axios';
import nasmApi from '../../api/endpoints';

export function useFetchProgramsList ({
  pageNumber,
  searchText,
  sizePerPage,
  programSources = '',
  categoryIds = '',
  sortField = '',
  sortOrder = '',
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [programData, setProgramData] = useState([]);
  const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false);

  // Clear out program data if new searchText is entered
  useEffect(() => {
    setProgramData([]);
  }, [searchText, programSources, categoryIds, sortField, sortOrder]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    const searchParams = {
      page: pageNumber,
      size: sizePerPage,
      programSources: programSources,
    };

    if (searchText && searchText.length > 0) {
      searchParams.search = searchText;
    }

    if(sortField && sortField.length > 0) {
      searchParams.sortField = sortField;
    }

    if(sortOrder && sortOrder.length > 0) {
      searchParams.sortOrder = sortOrder;
    }

    if(categoryIds && categoryIds.length > 0) {
      searchParams.categoryIds = categoryIds;
    }

    const cancel = axios.CancelToken.source();
    nasmApi.nasmPrograms
      .findPrograms(searchParams, cancel.token)
      .then(data => {
        setLoading(false);
        if (!data.isRequestCancelled) {
          setHasMoreDataToLoad(data.result.nextPage && data.result.nextPage.length > 0);
          setProgramData(prevProgramData => {
            return [
              ...prevProgramData,
              ...data.result.programs,
            ];
          });
        } else {
          setHasMoreDataToLoad(false);
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(err => {
        setError(true);
        setLoading(false);
      });

    // Acts as a clean up function that gets called before executing the next effect
    // and runs before the component is removed from the UI
    return () => cancel.cancel('Program Search Request Cancelled');
  }, [searchText, pageNumber, sizePerPage, programSources, categoryIds, sortField, sortOrder]);

  return { loading, error, programData, hasMoreDataToLoad };
}
