import * as React from 'react';
import {
  Button,
  Dialog,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import { colors } from '../../styles';

const useStyle = makeStyles({
  accountPaper: {
    borderRadius: '6px',
    width: '580px',
  },
  scrollPaper: {
    alignItems: 'flex-start',
    marginTop: '55px',
  },
  content: {
    padding: '20px 37px',
  },
  header: {
    fontFamily: 'DMSans-Bold, sans-serif',
    fontSize: '40px',
    color: colors.black,
    textAlign: 'center',
  },
  description: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    lineHeight: '30px',
    color: colors.steel,
    textAlign: 'center',
    padding: '15px 20px 0px',
  },
  buttonLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    color: colors.steel,
    textAlign: 'center',
    textTransform: 'capitalize',
    padding: '10px 0px',
    borderTop: `1px solid ${colors.colors_fill_light_gray_2}`,
    borderRadius: '0px',
  },
  cancelButton: {
    fontFamily: 'Roboto-Bold, sans-serif',
    marginBottom: '20px',
    borderBottom: `1px solid ${colors.colors_fill_light_gray_2}`,
  },
});

export default function RemoveGroup(props) {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      classes={{ paper: classes.accountPaper, scrollPaper: classes.scrollPaper }}
      open={props.open}
      onClose={props.onClose}
      BackdropProps={{ style: { backgroundColor: colors.mediumBlue, opacity: 0.35 } }}
    >
      <Box className={classes.content}>
        <Typography className={classes.header}>Remove Group</Typography>
        <Typography className={classes.description}>
          Are you sure you want to remove this group? Programs, workouts, 
          and exercises associated with the group will be removed from all clients within the group.
        </Typography>
      </Box>
      <Button className={classes.buttonLabel} onClick={props.onDeleteGroup}>Yes, Remove Group</Button>
      <Button className={[classes.buttonLabel, classes.cancelButton]} onClick={props.onClose}>Cancel</Button>
    </Dialog>
  );
}
