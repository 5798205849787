import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputAdornment, InputBase } from '@material-ui/core';
import { colors } from '../../styles';

import SearchIcon from '../../resources/search.svg';

const useStyles = makeStyles({
  search: {
    fontSize: '17px',
    borderRadius: '19px',
    backgroundColor: '#f3f4f5',
    width: '250px',
    height: '38px',
  },
  positionStart: {
    marginLeft: '9px',
    marginRight: '-9px',
  },
  inputStyle: {
    padding: '12px',
    color: colors.steel,
  },
});

export default function VanillaSearchBar (props) {
  const classes = useStyles(props);
  return (
    <FormControl>
      <InputBase
        className={classes.search}
        placeholder='Search'
        inputProps={{
          className: classes.inputStyle,
        }}
        startAdornment={
          <InputAdornment
            position='start'
            classes={{
              positionStart: classes.positionStart,
            }}
          >
            <img alt='search icon' src={SearchIcon} />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
