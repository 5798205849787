import axios from 'axios';
import request from '../nasm_sdk_v2/request';

export default function nasmExercises(apiVer) {
  /**
   *
   * @param searchParams {Object}
   * @param searchParams.page {Number} page number
   * @param searchParams.size {Number} max number of exercise records to show per page
   * @param searchParams.searchText {String}
   * @param searchParams.exerciseFilters {String} comma separated string containing filter params
   * such as My Exercises and NASM Exercises
   * @param searchParams.muscleGroupIds {Array<String>}
   * @param searchParams.fitnessComponentIds {Array<String>}
   * @param cancelToken - Axios cancelToken used to cancel http requests
   * @param apiVersion {String} Backend API Version to make the http request under
   * @returns {Promise<* | undefined>}
   */
  function findExercises(searchParams, cancelToken, apiVersion = apiVer) {
    return request
      .get(`${apiVersion}/api/nasm-exercises`, {
        params: searchParams,
        cancelToken: cancelToken,
      })
      .catch(e => {
        if(axios.isCancel(e)) {
          // swallow cancel request error
          return Promise.resolve({
            isRequestCancelled: true,
          });
        } else {
          return Promise.reject(e);
        }
      });
  }

  function getNasmExercise(exerciseId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/nasm-exercises/${exerciseId}`);
  }

  function getExerciseFilters(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/nasm-exercises/filters`);
  }

  function favoriteExercise(exerciseId, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/nasm-exercises/favorite/${exerciseId}`);
  }

  return {
    findExercises,
    getNasmExercise,
    getExerciseFilters,
    favoriteExercise,
  };
}