import React from 'react';
import { ViewWorkouts } from './ViewWorkouts';
import ViewVideoWorkout from './ViewVideoWorkout';

const WorkoutRowRef = React.forwardRef((props, ref) => {
  return <WorkoutRowType lastElementRef={ref} { ...props } />;
});

// Determine if workout is a video workout or
// standard workout
function WorkoutRowType (props) {
  const { workout } = props;
  if (workout.uploaded_media) {
    return <ViewVideoWorkout { ...props } />;
  }
  return <ViewWorkouts { ...props } />;
}

function WorkoutRows (props) {
  const {
    workouts,
    selectedWorkoutIndex,
    setSelectedWorkoutIndex,
    lastWorkoutElementRef,
    getWorkoutUrlParams,
    copyWorkoutLink,
    addingWorkouts,
    updateButtonVisibilityFlags,
    selectedProgramWorkout,
    showDropdownOnWorkoutRecords,
    onDeleteWorkout,
    onChangeWorkoutPending,
  } = props;

  const workoutListItems = [];

  workouts.forEach((workout, index) => {
    if (index + 1 === workouts.length) {
      // Add ref to workout row to trigger pagination observer
      workoutListItems.push(
        <WorkoutRowRef
          key={index}
          index={index}
          ref={lastWorkoutElementRef}
          workout={workout}
          isSelected={selectedWorkoutIndex === index}
          setSelectedWorkoutIndex={setSelectedWorkoutIndex}
          getWorkoutUrlParams={getWorkoutUrlParams}
          copyWorkoutLink={copyWorkoutLink}
          addingWorkouts={addingWorkouts}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          selectedProgramWorkout={selectedProgramWorkout}
          showDropdownOnWorkoutRecords={showDropdownOnWorkoutRecords}
          onDeleteWorkout={onDeleteWorkout}
          onChangeWorkoutPending={onChangeWorkoutPending}
        />,
      );
    } else {
      workoutListItems.push(
        <WorkoutRowType
          key={index}
          index={index}
          workout={workout}
          isSelected={selectedWorkoutIndex === index}
          setSelectedWorkoutIndex={setSelectedWorkoutIndex}
          getWorkoutUrlParams={getWorkoutUrlParams}
          copyWorkoutLink={copyWorkoutLink}
          addingWorkouts={addingWorkouts}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          selectedProgramWorkout={selectedProgramWorkout}
          showDropdownOnWorkoutRecords={showDropdownOnWorkoutRecords}
          onDeleteWorkout={onDeleteWorkout}
          onChangeWorkoutPending={onChangeWorkoutPending}
        />,
      );
    }
  });
  return (
    <>
      { workoutListItems }
    </>
  );
}

export default WorkoutRows;
