import React from 'react';
import { styled, makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import { MacaroniButton, OvalButton } from '../../components/Buttons';
import { colors } from '../../styles';
import ChooseExerciseList from './ChooseExerciseList';
import { ExercisesProvider } from '../../contexts/ExercisesContext';

const useStyles = makeStyles({
  dialogPaper: {
    width: '554px',
    height: '700px',
    borderRadius: '5px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: '20px',
  },
  buttonsBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogHeading: {
    fontFamily: 'DMSans, sans-serif',
    fontWeight: 900,
    fontSize: 38,
    textAlign: 'center',
  },
  headingContainer: {
    padding: '20px',
    paddingBottom: '10px',
  },
  root: {
    padding: '0px 20px 20px 20px',
  },
});

const DialogContainer = styled('div')({
  position: 'relative',
});

export default function ChooseExerciseDialog (props) {
  const { isOpen, onClose, onDone, setSelectedExercise,setSelectedCircuitsExercise ,isCircuits } = props;
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={isOpen}
        classes={{ paper: classes.dialogPaper, scrollPaper: classes.scrollPaper }}
        BackdropProps={{
          style: {
            backgroundColor: colors.backdrop_tint,
            opacity: 0.35,
          },
        }}
      >
        <Box className={classes.headingContainer}>
          <Box className={classes.buttonContainer}>
            <Box className={classes.buttonsBox}>
              <Box style={{ paddingRight: 5 }}>
                <MacaroniButton onClick={onDone}>Done</MacaroniButton>
              </Box>
              <Box>
                <OvalButton onClick={onClose}>Close</OvalButton>
              </Box>
            </Box>
            </Box>
            <Typography className={classes.dialogHeading}>
              Choose exercises
            </Typography>
          </Box>
        <DialogContainer>
          <ExercisesProvider>
            <ChooseExerciseList 
              setSelectedExercise={setSelectedExercise}
              isCircuits={isCircuits}
              setSelectedCircuitsExercise={setSelectedCircuitsExercise}
            />
          </ExercisesProvider>
        </DialogContainer>
      </Dialog>
    </>
  );
}
