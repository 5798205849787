import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import CardContentLoading from '../CardContentLoading';
import CardDefaultContent from '../CardDefaultContent';

import { colors } from '../../../styles';

const Focus = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '60px',
  padding: '0 20px',
});

const FocusText = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '14px',
});

const MilestoneContainer = styled('div')({
  display: 'flex',
  height: '60px',
  padding: '0 20px',
  borderTop: `1px solid ${colors.divider_line_light_blue}`,
  alignItems: 'center',
});

const MilestoneText = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '17px',
  fontWeight: 500,
  color: colors.carbon_black,
});

function Milestones ({ milestones }) {
  return (
    milestones.map((milestone, index) => {
      return (
        <MilestoneContainer key={index}>
          <MilestoneText>{milestone.name}</MilestoneText>
        </MilestoneContainer>
      );
    })
  );
}

function GoalsController ({ loading, milestones, focus }) {
  if (loading) {
    return (
      <CardContentLoading
        width='100%'
        height={210}
      />
    );
  }

  return (
    <Box>
      <Focus>
        <FocusText>Focus: {focus ?? 'No focus set'}</FocusText>
      </Focus>
      {
        milestones.length
          ? <Milestones milestones={milestones} />
          :  <CardDefaultContent
            label='No milestones recorded. Click on this card to record.'
            height={100}
          />
      }
    </Box>
  );
}

export default GoalsController;
