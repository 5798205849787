import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import nasmApi from '../../api/endpoints';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { colors } from '../../styles';
import { SAVE_STATES, UPLOAD_STATES } from '../../constants';

import CurrentExerciseMediaView from './CurrentExerciseMediaView';

const useStyles = makeStyles({
  exerciseMediaUploader: {
    width: 526,
    height: 235,
    backgroundColor: colors.black,
  },
});

function ExerciseMediaUploader (props) {
  const {
    imageUrl,
    videoUrl,
    setImageUrl,
    setVideoUrl,
    exerciseId,
    cancelToken,
    setSaveState,
    onCancelMediaUploadRequest,
  } = props;

  const classes = useStyles();
  const [canCalculateProgress, setCanCalculateProgress] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [uploadState, setUploadState] = useState(!imageUrl && !videoUrl
    ? UPLOAD_STATES.Empty : UPLOAD_STATES.Media_Available);

  const [fileType, setFileType] = useState('');
  const [pendingFileType, setPendingFileType] = useState('');

  useEffect(() => {
    if (exerciseId !== 'new' && exerciseId) {
      if (videoUrl) {
        setFileType('video');
      } else {
        setFileType('image');
      }

      if (imageUrl || videoUrl) {
        setUploadState(UPLOAD_STATES.Media_Available);
      } else {
        setUploadState(UPLOAD_STATES.Empty);
      }
    }
  }, [exerciseId, videoUrl, imageUrl]);

  function updateProgressBar ({ canProgressBeCalculated, progress }) {
    setCanCalculateProgress(canProgressBeCalculated);
    setProgressValue(progress);

    if (progress === 100) {
      setUploadState(UPLOAD_STATES.Processing);
    }
  }

  function onUploadBegin (e) {
    // skip uploading if no file was selected
    if (e.target.files.length === 0) {
      return;
    }

    const prevUploadState = uploadState;
    setProgressValue(0);
    setUploadState(UPLOAD_STATES.Uploading);

    const currentFile = e.target.files[0];
    setPendingFileType(currentFile.type);

    if (currentFile.type.includes('image')) {
      nasmApi.imageUpload.upload(currentFile, cancelToken.token, updateProgressBar)
        .then(response => {
          if (!response.cancelMessage) {
            setUploadState(UPLOAD_STATES.Media_Available);
            setImageUrl(response.result.image_url);
            setFileType(currentFile.type);
          } else {
            setUploadState(prevUploadState);
          }
          setSaveState(SAVE_STATES.CLICKABLE);
        })
        .catch(err => {
          setUploadState(prevUploadState);
          const errorMessage = err?.data?.message || err?.message || err;
          window.alert(`An error has occurred:\n${errorMessage}`);
        })
        .finally(() => setProgressValue(0));
    } else if (currentFile.type.includes('video')) {
      nasmApi.mediaUpload.upload(currentFile, cancelToken.token, updateProgressBar)
        .then(response => {
          if (!response.cancelMessage) {
            setUploadState(UPLOAD_STATES.Media_Available);
            setVideoUrl(response.result.video_url);
            setImageUrl(response.result.thumbnail_url);
            setFileType(currentFile.type);
          } else {
            setUploadState(prevUploadState);
          }
          setSaveState(SAVE_STATES.CLICKABLE);
        })
        .catch(err => {
          setUploadState(prevUploadState);
          const errorMessage = err?.data?.message || err?.message || err;
          window.alert(`An error has occurred:\n${errorMessage}`);
        })
        .finally(() => setProgressValue(0));
    }
  }

  return (
    <Box className={classes.exerciseMediaUploader}>
      <CurrentExerciseMediaView
        uploadState={uploadState}
        onUploadBegin={onUploadBegin}
        onCancelMediaUploadRequest={onCancelMediaUploadRequest}
        canCalculateProgress={canCalculateProgress}
        progressValue={progressValue}
        pendingFileType={pendingFileType}
        fileType={fileType}
        imageUrl={imageUrl}
        videoUrl={videoUrl}
      />
    </Box>
  );
}

ExerciseMediaUploader.propTypes = {
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  setImageUrl: PropTypes.func.isRequired,
  setVideoUrl: PropTypes.func.isRequired,
  exerciseId: PropTypes.string,
  // Axios cancel token
  cancelToken: PropTypes.object.isRequired,
  onCancelMediaUploadRequest: PropTypes.func,
};

// Cache the component so that the video doesn't re-render while the video is playing
// and while the trainer is editing the exercise
const ExerciseMediaUploaderExt = React.memo(ExerciseMediaUploader);
export default ExerciseMediaUploaderExt;
