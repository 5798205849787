import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { VictoryLine, VictoryScatter, VictoryLegend, VictoryAxis } from 'victory';

import { validateAssessmentType } from './propValidator';
import {ASSESSMENT_TYPES} from '../../../constants';

const LegendData = {
  Cardio: [
    {
      name: `Step`,
      symbol: { fill: '#2592ec', type: "circle"},
    },
    {
      name: `JJ`,
      symbol: { fill: '#163963', type: "square"},
    },
    {
      name: `Mile`,
      symbol: { fill: '#dc8c00', type: "triangleUp"},
    },
    {
      name: `Row`,
      symbol: { fill: '#8eb013', type: "diamond" },
    },
  ],
  Endurance: [
    {
      name: `Pushups`,
      symbol: { fill: '#2592ec', type: "circle" },
    },
    {
      name: `Squats`,
      symbol: { fill: '#163963', type: "square" },
    },
  ],
  Strength: [
    {
      name: `Squat`,
      symbol: { fill: '#dc8c00', type: "triangleUp" },
    },
    {
      name: `Bench`,
      symbol: { fill: '#163963', type: "square" },
    },
    {
      name: `Row`,
      symbol: { fill: '#2592ec', type: "circle" },
    },
  ],
};

function PlotCurve (props) {
  const {
    data,
    color,
    symbol,
  } = props;

  return (
    <>
      <VictoryLine
        standalone={false}
        style={{ data: { stroke: color, strokeWidth: 2}}}
        data={data}
        interpolation={'catmullRom'}
        domain={{x: [0, 6], y: [0, 1.1] }}
      />
      <VictoryScatter
        standalone={false}
        style={{ data: { fill: color } }}
        size={4}
        symbol={symbol}
        data={data}
        domain={{x: [0, 6], y: [0, 1.1] }}
      />
    </>
  );
}

const GraphContainer = styled(Box)({
  // Relative position set here as the component nested as a child needs an absolute position to reference from
  position: 'relative',
  width: '100%',
  height: 190,
});

function PerformanceAssessmentGraph (props) {
  const {
    assessmentType,
    cardioAssessments,
    enduranceAssessments,
    strengthAssessments,
  } = props;

  return (
    <GraphContainer>
      <GraphController
        assessmentType={assessmentType}
        cardioAssessments={cardioAssessments}
        enduranceAssessments={enduranceAssessments}
        strengthAssessments={strengthAssessments}
      />
      <LegendController assessmentType={assessmentType} />
    </GraphContainer>
  );
}

function GraphController (props) {
  const { assessmentType, cardioAssessments, enduranceAssessments, strengthAssessments } = props;

  if(assessmentType === ASSESSMENT_TYPES.CARDIO) {
    return <AssessmentGraph assessmentType={assessmentType} assessments={cardioAssessments} />;
  }

  if(assessmentType === ASSESSMENT_TYPES.ENDURANCE) {
    return <AssessmentGraph assessmentType={assessmentType} assessments={enduranceAssessments} />;
  }

  return <AssessmentGraph assessmentType={assessmentType} assessments={strengthAssessments} />;
}

function AssessmentGraph (props) {
  const { assessmentType, assessments = [] } = props;

  return (
    <svg width={'100%'} height={300} viewBox="25 90 415 200" style={{ position: 'absolute', top: 0 }}>
      {assessments.map(
        (assessment, i) => {
          let currentSymbol = LegendData[assessmentType]?.find(c => c.name === assessment.name);

          if(!currentSymbol) {
            currentSymbol = {
              symbol: {
                fill: 'black',
                type: 'square',
              },
            };
          }

          return (
            <PlotCurve
              key={i}
              data={assessment.data}
              color={currentSymbol?.symbol.fill}
              symbol={currentSymbol?.symbol.type}
            />
          );

        })}
      <VictoryAxis
        standalone={false}
        key={0}
        style={{
          axis: {stroke: ''},
          tickLabels: { fill: 'rgba(0,0,0,0)'},
          grid: {stroke: 'silver'},
        }}
        tickValues={[0, 1, 2, 3, 4, 5, 6]}
      />
    </svg>
  );
}

function LegendController (props) {
  const { assessmentType } = props;

  if(assessmentType === ASSESSMENT_TYPES.CARDIO) {
    return <CardioLegend />;
  } else if(assessmentType === ASSESSMENT_TYPES.ENDURANCE) {
    return <EnduranceLegend />;
  }

  return <StrengthLegend />;
}

function CardioLegend () {
  return (
    <svg width={'100%'} height={'35'} viewBox={'5 0 400 35'} style={{ position: 'absolute', top: 220 }}>
      <VictoryLegend
        standalone={false}
        gutter={50}
        borderPadding={{left: 40, right: 40 }}
        orientation={'horizontal'}
        data={LegendData[ASSESSMENT_TYPES.CARDIO]}
      />
    </svg>
  );
}

function EnduranceLegend () {
  return (
    <svg width={'100%'} height={'35'} viewBox={'-60 0 400 35'} style={{ position: 'absolute', top: 220 }}>
      <VictoryLegend
        standalone={false}
        gutter={50}
        borderPadding={{left: 40, right: 40 }}
        orientation={'horizontal'}
        data={LegendData[ASSESSMENT_TYPES.ENDURANCE]}
      />
    </svg>
  );
}

function StrengthLegend () {
  return (
    <svg width={'100%'} height={'35'} viewBox={'-15 0 400 35'} style={{ position: 'absolute', top: 220 }}>
      <VictoryLegend
        standalone={false}
        gutter={50}
        borderPadding={{left: 40, right: 40 }}
        orientation={'horizontal'}
        data={LegendData[ASSESSMENT_TYPES.STRENGTH]}
      />
    </svg>
  );
}

const assessmentDataShape = PropTypes.shape({
    /***
     * Assessment Name
     */
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      /**
       * Represents days of the week
       */
      x: PropTypes.number,
      /**
       * Normalized value ranging between 0 and 1
       */
      y: PropTypes.number,
      /***
       * Date format string YYYY-MM-DD
       */
      date: PropTypes.string,

    })),
});

PerformanceAssessmentGraph.propTypes = {
  assessmentType: validateAssessmentType,
  cardioAssessments: PropTypes.arrayOf(assessmentDataShape),
  enduranceAssessments: PropTypes.arrayOf(assessmentDataShape),
  strengthAssessments: PropTypes.arrayOf(assessmentDataShape),
};

export default PerformanceAssessmentGraph;