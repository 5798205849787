import React, {useState} from 'react';
import moment from 'moment';
import {styled} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';
import {colors} from '../../../styles';
import AssessmentTypePicker from '../PerformanceAssessment/AssessmentTypePicker';
import {ASSESSMENT_TYPES} from '../../../constants';
import PerformanceAssessmentGraph from '../PerformanceAssessment/PerformanceAssessmentGraph';
import CardContentLoading from '../CardContentLoading';
import CardDefaultContent from '../CardDefaultContent';

const ASSESSMENT_NAMES = Object.freeze({
  // CARDIO
  FIVE_HUNDRED_METER_ROW: 'five_hundred_meter_row',
  ONE_MILE_RUN: 'one_mile_run',
  ONE_MINUTE_JUMPING_JACKS: 'one_minute_jumping_jacks',
  THREE_MINUTE_STEP: 'three_minute_step',

  // ENDURANCE
  ONE_MINUTE_SQUATS: 'one_minute_squats',
  ONE_MINUTE_PUSHUPS: 'one_minute_pushups',

  // STRENGTH
  SQUAT: 'squat',
  BENCH: 'bench',
  SEATED_ROW: 'seated_row',
});

const HeaderText = styled(Typography)({
  color: colors.white,
  fontWeight: 500,
  fontSize: 20,
  lineHeight: 1.95,
});

const CenterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: 'inherit',
  marginLeft: 20,
});

function transformData (performanceAssessments = []) {
  const assessmentsByCardio = [];
  const assessmentsByEndurance = [];
  const assessmentsByStrength = [];

  // Loop through every single date
  performanceAssessments.forEach(perf => {
    const cardioAssessmentsByDate = perf.items.filter(item =>
      item.performance_assessment.category === ASSESSMENT_TYPES.CARDIO) || [];
    const enduranceAssessmentsByDate = perf.items.filter(item =>
      item.performance_assessment.category === ASSESSMENT_TYPES.ENDURANCE) || [];
    const strengthAssessmentsByDate = perf.items.filter(item =>
      item.performance_assessment.category === ASSESSMENT_TYPES.STRENGTH) || [];

    assessmentsByCardio.push(...cardioAssessmentsByDate);
    assessmentsByEndurance.push(...enduranceAssessmentsByDate);
    assessmentsByStrength.push(...strengthAssessmentsByDate);
  });

  return {
    assessmentsByCardio,
    assessmentsByEndurance,
    assessmentsByStrength,
  };
}

function mapAssessmentData(legendName, assessmentData) {
  return {
    name: legendName,
    date: assessmentData.date,
    value: assessmentData.value,
  };
}

function normalizeData (data = [], maxValue = 0) {
  const maxNum = Math.max(...data.map(d => d.y), maxValue);

  // safeguard against division by 0 error
  if(maxNum === 0) {
    return Array(data.length).fill(0);
  }

  return data.map(d => ({ ...d, y: d.y / maxNum }));
}

function normalizeAssessmentData (assessmentsByCardio, assessmentsByEndurance, assessmentsByStrength) {
  // CARDIO //
  const fiveHundredMeterRows = assessmentsByCardio
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.FIVE_HUNDRED_METER_ROW)
    .map(as => mapAssessmentData('Row', as));
  const oneMileRuns = assessmentsByCardio
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.ONE_MILE_RUN)
    .map(as => mapAssessmentData('Mile', as));
  const oneMinJumpJacks = assessmentsByCardio
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.ONE_MINUTE_JUMPING_JACKS)
    .map(as => mapAssessmentData('JJ', as));
  const threeMinSteps = assessmentsByCardio
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.THREE_MINUTE_STEP)
    .map(as => mapAssessmentData('Step', as));

  // ENDURANCE //
  const oneMinSquats = assessmentsByEndurance
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.ONE_MINUTE_SQUATS)
    .map(as => mapAssessmentData('Squats', as));
  const oneMinPushups = assessmentsByEndurance
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.ONE_MINUTE_PUSHUPS)
    .map(as => mapAssessmentData('Pushups', as));

  // STRENGTH //
  const squats = assessmentsByStrength
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.SQUAT)
    .map(as => mapAssessmentData('Squat', as));
  const benches = assessmentsByStrength
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.BENCH)
    .map(as => mapAssessmentData('Bench', as));
  const seatedRows = assessmentsByStrength
    .filter(as => as.performance_assessment.name === ASSESSMENT_NAMES.SEATED_ROW)
    .map(as => mapAssessmentData('Row', as));

  // sort from earliest date to latest date
  const comparerFn = (date1, date2) => moment(date1).valueOf() - moment(date2).valueOf;
  fiveHundredMeterRows.sort(comparerFn);
  oneMileRuns.sort(comparerFn);
  oneMinJumpJacks.sort(comparerFn);
  threeMinSteps.sort(comparerFn);
  oneMinSquats.sort(comparerFn);
  oneMinPushups.sort(comparerFn);
  squats.sort(comparerFn);
  benches.sort(comparerFn);
  seatedRows.sort(comparerFn);

  return {
    cardioAssessments: [
      {
        name: 'Row',
        data: normalizeData(fiveHundredMeterRows.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
      {
        name: 'Mile',
        data: normalizeData(oneMileRuns.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
      {
        name: 'JJ',
        data: normalizeData(oneMinJumpJacks.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
      {
        name: 'Step',
        data: normalizeData(threeMinSteps.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
    ],
    enduranceAssessments: [
      {
        name: 'Squats',
        data: normalizeData(oneMinSquats.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
      {
        name: 'Pushups',
        data: normalizeData(oneMinPushups.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
    ],
    strengthAssessments: [
      {
        name: 'Squat',
        data: normalizeData(squats.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
      {
        name: 'Bench',
        data: normalizeData(benches.map((f,i) => ({x: i, y: f.value, date: f.date}))),
      },
      {
        name: 'Row',
        data: normalizeData(seatedRows.map((f, i) => ({x: i, y: f.value, date: f.date}))),
      },
    ],
  };
}

function PerformanceAssessmentCard (props) {
  const { loading, performanceAssessments = [] } = props;
  return (
    <CardContainer
      width={420}
      height={360}
    >
      <CardHeader height={55} backgroundColor={colors.perfAssessHeader}>
        <CenterContainer>
          <HeaderText>
            Performance Assessments
          </HeaderText>
        </CenterContainer>
      </CardHeader>
      <PerformanceAssessmentController
        loading={loading}
        performanceAssessments={performanceAssessments}
      />
    </CardContainer>
  );
}

function PerformanceAssessmentController (props) {
  const { loading, performanceAssessments = [] } = props;
  const [assessmentType, setAssessmentType] = useState(ASSESSMENT_TYPES.CARDIO);

  const {
    assessmentsByCardio,
    assessmentsByEndurance,
    assessmentsByStrength }
    = transformData(performanceAssessments);

  const {
    cardioAssessments,
    enduranceAssessments,
    strengthAssessments,
  } = normalizeAssessmentData(assessmentsByCardio, assessmentsByEndurance, assessmentsByStrength);

  if(loading) {
    return <CardContentLoading width={'auto'} height={305} />;
  }

  if(!loading && performanceAssessments.length === 0) {
    return <CardDefaultContent
      height={100}
      label={'No Performance Assessments recorded. Please go to the NASM EDGE mobile app to record.'}
    />;
  }

  return  (
    <>
      <PerformanceAssessmentGraph
        assessmentType={assessmentType}
        cardioAssessments={cardioAssessments}
        enduranceAssessments={enduranceAssessments}
        strengthAssessments={strengthAssessments}
      />
      <AssessmentTypePicker
        width={380}
        assessmentType={assessmentType}
        setAssessmentType={setAssessmentType}
      />
    </>
  );
}

export default PerformanceAssessmentCard;