import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Typography, Box} from '@material-ui/core';
import { styled, makeStyles }  from '@material-ui/core/styles';
import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';

import { colors } from '../../../styles';
import CalendarWeekStrip from '../CalendarWeekStrip/CalendarWeekStrip';
import CalendarDivider from '../CalendarWeekStrip/CalendarDivider';
import SchedulePreviewController from '../Schedule/SchedulePreviewController';


const HeaderTitle = styled(Typography)({
  color: colors.white,
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 2.5,
  marginLeft: 20,
});

const useStyles = makeStyles({
 weekStripContainer: {
   margin: '20px 10px',
 },
});

function ScheduleCard (props) {
  const {
    todaysDate,
    selectedDate,
    setSelectedDate,
    scheduledWorkoutsByDate,
    selectedScheduledWorkouts,
    isDashboardDataLoading,
    onNavigateToScheduling,
    onSelectSchedule,
  } = props;
  const classes = useStyles();

  return (
    <CardContainer width={416} height={650}>
      <CardHeader
        height={55}
        backgroundColor={colors.graphite}
        onClick={onNavigateToScheduling}
        enableCursorPointer
      >
        <HeaderTitle>Schedule</HeaderTitle>
      </CardHeader>
      <Box className={classes.weekStripContainer}>
        <CalendarWeekStrip
          todaysDate={todaysDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          scheduledWorkoutsByDate={scheduledWorkoutsByDate}
        />
      </Box>
      <CalendarDivider onClick={onNavigateToScheduling}/>
      <Typography style={{margin: '27px 0px 0px 20px', fontSize: 14, lineHeight: 1.43}}>
        {displayDateLabel(selectedDate, todaysDate)}
      </Typography>
      <SchedulePreviewController
        isPastSchedule={moment(selectedDate).isBefore(moment(todaysDate), 'day')}
        selectedScheduledWorkouts={selectedScheduledWorkouts}
        isDashboardDataLoading={isDashboardDataLoading}
        onNavigateToScheduling={onNavigateToScheduling}
        onSelectSchedule={onSelectSchedule}
      />
    </CardContainer>
  );
}

function displayDateLabel (selectedDate, todaysDate) {
  const dateFormat = moment(selectedDate).format('MMMM DD');
  if(moment(todaysDate).isSame(selectedDate, 'day')) {
    return `Today ${dateFormat}`;
  }

  return dateFormat;
}

ScheduleCard.propTypes = {
  onNavigateToScheduling: PropTypes.func,
  todaysDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  scheduleWorkoutsByDate: PropTypes.object,
};

export default ScheduleCard;