import {makeStyles} from '@material-ui/core/styles';
import {colors} from '../../styles';
import {Box, Divider, Typography} from '@material-ui/core';
import React from 'react';

// import { OvalButton } from '../Buttons';


const useStyles = makeStyles({
  sectionDivider: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 48px 0 22px',
    minHeight: 70,
  },
  sectionTitle: props => ({
    color: props.fontColor || colors.steel,
    fontSize: 17,
    fontWeight: 500,
  }),
});

function SectionDivider (props) {
  const { name, fontColor } = props;
  const classes = useStyles({ fontColor });

  return (
    <>
      <Box className={classes.sectionDivider}>
        <Typography className={classes.sectionTitle}>{name}</Typography>
        {/* Hiding Complete Button until implemented:  */}
        {/* <OvalButton */}
        {/*  width={121} */}
        {/*  height={38} */}
        {/* > */}
        {/*  Complete */}
        {/* </OvalButton> */}
      </Box>
      <Divider style={{ backgroundColor: colors.cloudy_blue_50, minHeight: 1 }} />
    </>
  );
}

export default SectionDivider;