// Documented Cases: (Only covers Trainer sign-in flow)
// https://medlenterprises.atlassian.net/wiki/spaces/NASM/pages/294158337/UA+Login+Migration+-+v2.7.0
const LOGIN_TYPES = Object.freeze({
  CLIENT: 'client',
  FRESH_LOGIN: 'fresh_login',
  // New Trainer Account - Case 1
  NEW_ACCOUNT: 'new_account',
  // Case 2
  LEGACY_EDGE_ACCOUNT: 'legacy_edge_account',
  // Case 3
  UA_ACCOUNT_ONLY: 'ua_account_only',
  // Case 4 ~ UA email matches EDGE email but accounts are unlinked
  UNLINKED_ACCOUNTS: 'unlinked_accounts',
  // Case 5
  LINKED_ACCOUNTS: 'linked_accounts',
  // Case 6 ~ UA email differs from EDGE email but both share the same ua_guid
  MISMATCHED_ACCOUNT_LINKS: 'mismatched_account_links',
  UNEXPECTED_ERROR: 'unexpected_error',
});

const LOGIN_VIEWS = Object.freeze({
  LOGIN_FORM: 'login_form',
  LINK_ACCOUNTS: 'link_accounts',
  MIGRATE_ACCOUNT: 'migrate_account',
});

const CREATE_ACCOUNT_ENABLED = true;

export {
  LOGIN_TYPES,
  LOGIN_VIEWS,
  CREATE_ACCOUNT_ENABLED,
};