import React from 'react';
import OvalButton from './OvalButton';
import { colors } from '../../styles';

export default function MacaroniButton (props) {
  return (
    <OvalButton
      border='none'
      backgroundcolor={props.active ? colors.navy : colors.yellow_button_style}
      color={props.disabled ? colors.grey_text_color : colors.white}
      width={props.width}
      height={props.height}
      margin={props.margin}
      onClick={props.onClick}
      disabled={props.disabled}
      fontWeight={props.fontWeight ?? 'bold'}
      type={props.type}
      component={props.component}
    >
      {props.children}
    </OvalButton>
  );
}
