import FormData from 'form-data';
import axios from 'axios';

import request from '../nasm_sdk_v2/request';
import { uploadProgressHandler } from './utils';

// Upload Exercise Image
export default function imageUpload(apiVer) {
  function upload(
    file,
    cancelToken,
    updateProgressBar = ({
      canProgressBeCalculated,
      progress,
    }) => {},
    apiVersion = apiVer,
  ) {

    const data = new FormData();
    data.append('image', file);

    return request.post(`${apiVersion}/api/image-upload`, data, {
      onUploadProgress: uploadProgressHandler(updateProgressBar),
      cancelToken: cancelToken,
    }).catch(e => {
      if(axios.isCancel(e)) {
        // swallow cancel request error
        return Promise.resolve({
          isRequestCancelled: true,
        });
      } else {
        return Promise.reject(e);
      }
    });
  }

  return {
    upload: upload,
  };
}