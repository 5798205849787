import React from 'react';
import { Breadcrumbs, Typography, Link as MatUiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';

import { colors } from '../../styles';

const useStyles = makeStyles({
  root: props => ({
    paddingTop: props?.paddingTop ?? 0,
    paddingLeft: props?.paddingLeft ?? 0,
    backgroundColor: colors.white,
  }),
  lastTrail: {
    fontSize: 14,
    letterSpacing: 0,
    color: colors.black,
  },
  trail: {
    fontSize: 14,
    letterSpacing: 0,
    color: colors.steel,
  },
});

const LinkRouter = (props) => <MatUiLink {...props} component={Link} />;

/**
 *
 * @param props
 * @param {object} props.pathMap - key pathname to screen | value breadcrumb name
 * @param {Array<string>} props.pathNamesExclusionList - list of keywords to exclude rendering inside
 * of BreadCrumbs component
 * @param {string} props.ariaLabel - required label used to describe BreadCrumb
 * as per Material UI docs: https://material-ui.com/components/breadcrumbs/#breadcrumbs
 * @returns {*}
 */
export default function ClientBreadcrumbs (props) {
  const {
    pathMap = {},
    pathNamesExclusionList = [],
    ariaLabel,
    queryMap = {},
    view,
  } = props;

  const classes = useStyles(props);
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(p => p.length > 0);
  const trailingComponents = [];

  for (let i = 0; i < pathnames.length; ++i) {
    // Don't render excluded pathname in BreadCrumbs Component
    if (pathNamesExclusionList.includes(pathnames[i])) {
      continue;
    }

    const isLastIndex = i === pathnames.length - 1;
    const pathLink = `/${pathnames.slice(0, i + 1).join('/')}`;
    const trailName = pathMap[pathLink];

    if (trailName) {
      if (isLastIndex && !view) {
        trailingComponents.push(
          <Typography className={classes.lastTrail} key={pathLink}>{trailName}</Typography>,
        );
      } else {
        trailingComponents.push(
          <LinkRouter key={pathLink} to={pathLink}>
            <Typography className={classes.trail}>
              {trailName}
            </Typography>
          </LinkRouter>,
        );
      }
    }
  }

  // Handling breadcrumbs for queries not included in the pathname
  if (view) {
    queryMap[view].forEach((crumb, index) => {
      const queryItem = queryMap[view][index];
      if (index === queryMap[view].length - 1) {
        trailingComponents.push(
          <Typography className={classes.lastTrail} key={view}>{queryItem.label}</Typography>,
        );
      } else {
        trailingComponents.push(
          <LinkRouter key={view} to={queryMap[view][index].link(queryItem.data)}>
            <Typography className={classes.trail}>
              {queryItem.label}
            </Typography>
          </LinkRouter>,
        );
      }
    });
  }

  return (
    <Breadcrumbs className={classes.root} aria-label={ariaLabel}>
      {trailingComponents}
    </Breadcrumbs>
  );
}
