import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, ListItem, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import { usePaginationObserver } from '../../util/utilFunctions';
import { colors } from '../../styles';
import { useFetchWorkoutsList } from '../../workoutsHooks';
import OvalButton from '../Buttons/OvalButton';
import { HighlightSearchBar } from '../Inputs';
import { selectWorkout } from '../../reducers/selectedWorkoutReducer';
import { programContexts } from '../../reducers/programContextReducer';

// icons
import on from '../../resources/interaction-toggle-checkmark-multi-select-on.svg';
import off from '../../resources/interaction-toggle-checkmark-multi-select-off.svg';

const { RESCHEDULING } = programContexts;

const useWorkoutRowStyles = makeStyles({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '89px',
    backgroundColor: colors.white,
    padding: '10px 25px',
    borderTop: props => props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderLeft: props => props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderRight: props => props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderBottom: props => props.isDragging
      ? `2px solid ${colors.yellow_button_style}`
      : `1px solid ${colors.light_grey}`,
    '&:hover': {
      backgroundColor: `${colors.baby_blue}`,
    },
    '&:active': {
      backgroundColor: colors.white,
      filter: 'brightness(88%)',
    },
  },
  leftSection: {
    width: '90%',
  },
  workoutName: {
    fontFamily: 'DMSans, sans-serif',
    fontWeight: 'bold',
    color: colors.black,
    fontSize: '17px',
    lineHeight: '39px',
  },
  exerciseInfo: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    color: colors.steel,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  duration: {
    fontSize: '12px',
    color: colors.black,
  },
});

const WorkoutRow = React.forwardRef((props, ref) => {
  const { workout, getWorkoutUrlParams, isDragging, updateButtonVisibilityFlags } = props;
  const classes = useWorkoutRowStyles({ isDragging });
  const { pathname, search } = getWorkoutUrlParams(workout.id);
  const dispatch = useDispatch();

  const context = useSelector(state => state.programContext.context);

  const {
    name,
    total_dur_seconds: totalDurSeconds = 0,
    exercise_count: exerciseCount,
  } = workout;

  const totalMins = Math.ceil(totalDurSeconds / 60);
  const exerciseCountStr = !!exerciseCount && (exerciseCount > 1 || exerciseCount === 0)
    ? `${exerciseCount} Exercises` : `${exerciseCount} Exercise`;

  return (
    <>
      <ListItem
        ref={ref}
        className={classes.rowContainer}
      >
        <Link
          onClick={() => {
            if(context === RESCHEDULING && updateButtonVisibilityFlags) {
              // WorkoutSectionPanel buttons
              updateButtonVisibilityFlags({
                isCopyButtonVisible: false,
                isSaveButtonVisible: false,
                isAddExercisesButtonVisible: false,
                isAddButtonVisible: true,
              });
            }
            dispatch(selectWorkout(workout));
          }}
          to={{
            pathname,
            search,
            state: { addingWorkouts: true },
          }}
          className={classes.leftSection}
        >
          <Typography className={classes.workoutName}>{name || 'Placeholder Workout Name'}</Typography>
          <Typography className={classes.exerciseInfo}>{exerciseCountStr}</Typography>
        </Link>
        <Box className={classes.rightSection}>
          <Typography className={classes.duration}>{totalMins} min</Typography>
        </Box>
      </ListItem>
    </>
  );
});

const DraggableWorkoutRow = React.forwardRef((props, ref) => {
  const { workout, getWorkoutUrlParams, index, updateButtonVisibilityFlags } = props;

  return (
    <Draggable
      index={index}
      draggableId={workout.id}
    >
      {(provided, snapshot) => {
        return (
          <>
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <WorkoutRow
                getWorkoutUrlParams={getWorkoutUrlParams}
                workout={workout}
                draggableId={workout.id}
                isDragging={snapshot.isDragging}
                updateButtonVisibilityFlags={updateButtonVisibilityFlags}
              />
            </Box>
            {
              snapshot.isDragging &&
                <WorkoutRow
                  workout={workout}
                  getWorkoutUrlParams={getWorkoutUrlParams}
                />
            }
          </>
        );
      }}
    </Draggable>
  );
});

const useWorkoutListStyles = makeStyles({
  workoutRowList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '88%',
  },
  searchPadding: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '360px',
    justifyContent: 'space-between',
  },
  searchFilterCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    width: '360px',
  },
  workoutList: {
    overflowY: 'auto',
    height: '100%',
  },
  closeBtnCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '20px 20px 0 0',
  },
  filterCont: {
    width: '320px',
    marginTop: '5px',
    padding: 0,
  },
  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    margin: 0,
    backgroundColor: 'rgba(240, 241, 243, 0.41)',
    height: '58px',
  },
  menuHeaderText: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    width: '260px',
  },
  filterText: {
    marginLeft: '15px',
    fontWeight: 'bold',
    fontSize: '17px',
  },
});

const WorkoutRowList = React.forwardRef((props, ref) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState([]);

  const { getWorkoutUrlParams, updateButtonVisibilityFlags } = props;
  const currentUser = useSelector(state => state.currentUser);

  const classes = useWorkoutListStyles();

  const {
    isLoading,
    workoutData,
    hasMoreDataToLoad,
  } = useFetchWorkoutsList({
    pageNumber: pageNumber,
    searchText: searchText,
    sizePerPage: 10,
    getPublic: filters.includes('NASM Workouts'),
    getPrivate: filters.includes('My Workouts'),
  });

  useEffect(() => {
    setPageNumber(1);
  }, [filters]);

  const workoutRows = [];

  const rootElement = useRef();
  const lastWorkoutElementRef = usePaginationObserver({
    setPageNumber: setPageNumber,
    isLoading: isLoading,
    hasMoreDataToLoad: hasMoreDataToLoad,
    rootElement: rootElement.current,
  });

  function handleSearch (e) {
    setSearchText(e.target.value);
    setPageNumber(1);
  }

  const openFilterMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const onSelectFilter = (selection) => {
    if (!filters.includes(selection)) {
      setFilters(prev =>
        [...prev.slice(0, filters.indexOf(selection)), selection, ...prev.slice(filters.indexOf(selection) + 1)],
      );
    } else {
      setFilters(prev => [...prev.filter((filter) => filter !== selection)]);
    }
  };

  workoutData.forEach((workout, index) => {
    if(currentUser?.id === workout?.owner_id){
      if (index === pageNumber) {
        workoutRows.push(
          <DraggableWorkoutRow
            key={index}
            index={index}
            ref={lastWorkoutElementRef}
            workout={workout}
            getWorkoutUrlParams={getWorkoutUrlParams}
            updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          />,
        );
      } else {
        workoutRows.push(
          <DraggableWorkoutRow
            key={index}
            index={index}
            workout={workout}
            getWorkoutUrlParams={getWorkoutUrlParams}
            updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          />,
        );
      }
    }    
  });

  return (
    <Box className={classes.workoutRowList}>
      <Box className={classes.searchFilterCont}>
        <HighlightSearchBar onChange={handleSearch} value={searchText} />
        <OvalButton
          onClick={openFilterMenu}
          backgroundcolor={filters.length > 0 ? colors.navy : colors.white}
          color={filters.length > 0 ? colors.white : colors.steel}
        >
          {filters.length > 0 ? filters.length : 'Filter'}
        </OvalButton>
        <Menu
          className={classes.filterCont}
          id='workout-filter'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeFilterMenu}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className={classes.menuHeader}>
            <Typography className={classes.menuHeaderText}>Workout Author</Typography>
          </Box>
          <MenuItem onClick={() => onSelectFilter('NASM Workouts')} className={classes.filterItem}>
            <img src={filters.includes('NASM Workouts') ? on : off} alt='check' />
            <Typography className={classes.filterText}>NASM Workouts</Typography>
          </MenuItem>
          <MenuItem onClick={() => onSelectFilter('My Workouts')} className={classes.filterItem}>
            <img src={filters.includes('My Workouts') ? on : off} alt='check' />
            <Typography className={classes.filterText}>My Workouts</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box ref={rootElement} style={{ height: 'inherit' }}>
      <Droppable droppableId='Workout-List' isDropDisabled>
        {provided => {
          return (
            <Box
              ref={provided.innerRef}
              className={classes.workoutList}
              {...provided.droppableProps}
            >
              {workoutRows}
              {provided.placeholder}
            </Box>
          );
        }}
      </Droppable>
      </Box>
    </Box>
  );
});

const useMainPanelStyles = makeStyles({
  addWorkoutsPanel: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  addWorkoutsHeader: {
    fontWeight: 'bold',
    fontSize: '24px',
    padding: '0 20px',
  },
  closeBtnCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '20px 20px 0 0',
  },
});

export default function AddWorkoutsPanel (props) {
  const {
    visible,
    getWorkoutUrl,
    addWorkoutKey,
    programDetailsKey,
    getWorkoutUrlParams,
    programDetailsLink,
    updateButtonVisibilityFlags,
  } = props;

  const classes = useMainPanelStyles();

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={800}>
        <FadeTransition visible={visible}>
          <Box className={classes.addWorkoutsPanel}>
            <Box className={classes.closeBtnCont}>
              <Link to={() => programDetailsLink()}>
                <OvalButton>Close</OvalButton>
              </Link>
            </Box>
            <Typography className={classes.addWorkoutsHeader}>
              Add Workouts
            </Typography>
            <WorkoutRowList
              getWorkoutUrl={getWorkoutUrl}
              addWorkoutKey={addWorkoutKey}
              programDetailsKey={programDetailsKey}
              getWorkoutUrlParams={getWorkoutUrlParams}
              updateButtonVisibilityFlags={updateButtonVisibilityFlags}
            />
          </Box>
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}
