import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

import { USER_ROLES } from '../../constants';
import AccountSettingBox from './AccountSettingBox';
import TermsConditionsDialog from '../Dialogs/TermsConditionsDialog';
import MeasurementUnits from '../Dialogs/MeasurementUnits';
import ClientDashboardSettingsWrapper from '../Dialogs/ClientDashboardSettingsWrapper';
import { StatelessDropdown as Dropdown } from '../Inputs';

import BWPCalculatorContainer from '../ClientDashboard/Nutrition/BWPCalculatorContainer';
import OneRmCalculatorContainer from '../ClientDashboard/OneRmCalculatorContainer';
import BMICalculatorContainer from '../ClientDashboard/BMICalculatorContainer';
import { CALC_VISIBILITY_STATES } from '../../constants';
import MHRCalculatorContainer from '../ClientDashboard/MHRCalculatorContainer';
import BodyFatCalculatorContainer from '../ClientDashboard/BodyFatCalculatorContainer';

const dialogTextOptions = {
  TERMS: 'terms-conditions',
  PRIVACY: 'privacy-policy',
};

const calculatorDropdownOptions = {
  'BWP': 'BWP',
  '1RM': '1RM',
  'BMI': 'BMI',
  'MHR': 'MHR',
  'BodyFat': 'BodyFat',
};

const calculatorDropdownLabel = {
  'BWP': 'Body Weight Planner (BWP)',
  '1RM': 'One Rep-Max (1RM)',
  'BMI': 'Body Mass Index (BMI)',
  'MHR': 'Max Heart Rate (MHR)',
  'BodyFat': 'Body Fat',
};

const Header = styled(Typography)({
  fontSize: '22px',
});

function TrainerAccountSettings ({ user, clubId = null }) {
  const [tcOpen, setTcOpen] = useState(false);
  const [tcTextType, setTcTextType] = useState('');
  const [selectedCalculator, setSelectedCalculator] = useState('');

  const [showMeasurements, setShowMeasurements] = useState(false);
  const [calcVisibilityState, setCalcVisibilityState] = useState(CALC_VISIBILITY_STATES.NONE);

  const userUnits = { unitHeight: user.unit_height, unitWeight: user.unit_weight };

  let calculatorOptions = calculatorDropdownLabel;
  if (clubId){
    delete calculatorOptions.BWP;
  }

  const onClickTerms = () => {
    setTcTextType(dialogTextOptions.TERMS);
    setTcOpen(true);
  };

  const onClickPrivacy = () => {
    setTcTextType(dialogTextOptions.PRIVACY);
    setTcOpen(true);
  };

  const onClickMeasurementUnits = () => {
    setShowMeasurements(true);
  };

  const onChangeCalculator = (e) => {
    const calculator = e.target.value;
    switch (calculator) {
      case calculatorDropdownOptions.BWP:
        setSelectedCalculator(calculatorDropdownOptions.BWP);
        setCalcVisibilityState(CALC_VISIBILITY_STATES.BWP);
        break;
      case calculatorDropdownOptions['1RM']:
        setSelectedCalculator(calculatorDropdownOptions['1RM']);
        setCalcVisibilityState(CALC_VISIBILITY_STATES.ONE_RM);
        break;
      case calculatorDropdownOptions.BMI:
        setSelectedCalculator(calculatorDropdownOptions.BMI);
        setCalcVisibilityState(CALC_VISIBILITY_STATES.BMI);
        break;
      case calculatorDropdownOptions.MHR:
        setSelectedCalculator(calculatorDropdownOptions.MHR);
        setCalcVisibilityState(CALC_VISIBILITY_STATES.MHR);
        break;
      case calculatorDropdownOptions.BodyFat:
        setSelectedCalculator(calculatorDropdownOptions.BodyFat);
        setCalcVisibilityState(CALC_VISIBILITY_STATES.BodyFat);
        break;
      default:
        break;
    }
  };

  const onCloseWidget = () => {
    setSelectedCalculator('');
    setCalcVisibilityState(CALC_VISIBILITY_STATES.NONE);
  };

  return (
    <>
      <div>
        <AccountSettingBox
          settingText='Measurement Units'
          onClickSetting={onClickMeasurementUnits}
        />
        <Dropdown
          label='Calculators'
          options={calculatorOptions}
          onOptionChanged={onChangeCalculator}
          width='100%'
          fontFamily='Roboto, sans-serif'
          fontSize={14}
          currentValue={selectedCalculator}
        />
        <AccountSettingBox
          settingText='Terms of Use'
          onClickSetting={onClickTerms}
        />
        <AccountSettingBox
          settingText='Privacy Policy'
          onClickSetting={onClickPrivacy}
        />
      </div>
      <TermsConditionsDialog
        isOpen={tcOpen}
        textType={tcTextType}
        onClose={() => setTcOpen(false)}
      />
      <MeasurementUnits
        isOpen={showMeasurements}
        userUnits={userUnits}
        onClose={() => setShowMeasurements(false)}
      />
      <BWPCalculatorContainer
        currentUser={user}
        isOpen={calcVisibilityState === CALC_VISIBILITY_STATES.BWP}
        onClose={onCloseWidget}
      />
      <BMICalculatorContainer
        currentUser={user}
        isOpen={calcVisibilityState === CALC_VISIBILITY_STATES.BMI}
        onClose={onCloseWidget}
      />
      <OneRmCalculatorContainer
        currentUser={user}
        isOpen={calcVisibilityState === CALC_VISIBILITY_STATES.ONE_RM}
        onClose={onCloseWidget}
      />
      <MHRCalculatorContainer
        currentUser={user}
        isOpen={calcVisibilityState === CALC_VISIBILITY_STATES.MHR}
        onClose={onCloseWidget}
      />
      <BodyFatCalculatorContainer
        currentUser={user}
        isOpen={calcVisibilityState === CALC_VISIBILITY_STATES.BodyFat}
        onClose={onCloseWidget}
      />
    </>
  );
}

function ClientAccountSettings (props) {
  const { user, clubId = null } = props;
  const [isDashboardSettingsOpen, setDashboardSettingsOpen] = useState(false);
  return (
    <>
      <Box>
        <AccountSettingBox
          settingText='Dashboard Settings'
          onClickSetting={() => setDashboardSettingsOpen(true)}
        />
      </Box>
      <ClientDashboardSettingsWrapper
        open={isDashboardSettingsOpen}
        onClose={() => setDashboardSettingsOpen(false)}
        client={user}
        clubId={clubId}
      />
    </>
  );
}

function ManageAccountController ({ user, clubId = null }) {
  if (user.nasm_role === USER_ROLES.TRAINER) {
    return <TrainerAccountSettings user={user} clubId={clubId} />;
  }
  return <ClientAccountSettings user={user} clubId={clubId} />;
}

function ManageAccount (props) {
  const { user, clubId = null } = props;
  return (
    <div>
      <Header>Manage Account</Header>
      <ManageAccountController user={user} clubId={clubId} />
    </div>
  );
}

export default ManageAccount;

ManageAccount.propTypes = {
  user: PropTypes.shape({
    unit_height: PropTypes.string,
    unit_weight: PropTypes.string,
    trainer_user: PropTypes.shape({
      certificates: PropTypes.array,
    }),
  }),
};
