import OvalButton from './OvalButton';
import {colors} from '../../styles';
import React from 'react';

export default function UpgradeButton (props) {
  return (
    <OvalButton
      fontWeight={'bold'}
      color={colors.white}
      backgroundcolor={colors.mediumBlue}
      width={109}
      height={27}
      onClick={props.onClick}
      style={{ borderWidth: 2 }}
    >
      Upgrade
    </OvalButton>
  );
}