import React from 'react';

import { LoginContainer, LoginFormController } from '../components/Login';
import AppStoreLinks from '../components/Login/AppStoreLinks';

import {styled} from '@material-ui/core/styles';
import {colors} from '../styles';

const TryItFreeText = styled('div')({
  fontSize: 40,
  fontWeight: 'bold',
  color: colors.white,
  textAlign: 'center',
  marginTop: 54,
  lineHeight: '39px',
});

export default function LoginPage (props) {

  return (
    <LoginContainer>
     <LoginFormController />
      <TryItFreeText>
        Try it FREE
      </TryItFreeText>
     <AppStoreLinks/>
    </LoginContainer>
  );
}