import request from '../nasm_sdk_v2/request';

const BMI_BASE_ENDPOINT = (apiVersion, userId) => `${apiVersion}/api/users/${userId}/bmi`;

export default function bmi(apiVer) {
  function saveBMIResults(userId, jsonData, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error("userId is required to save BMI results."),
      );
    }
    return request.post(`${BMI_BASE_ENDPOINT(apiVersion, userId)}`, jsonData);
  }

  function getCurrentBMI(userId, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch current BMI.'),
      );
    }
    return request.get(`${BMI_BASE_ENDPOINT(apiVersion, userId)}/current`);
  }

  function getBMIHistory(userId, searchParams, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch BMI history.'),
      );
    }
    return request.get(`${BMI_BASE_ENDPOINT(apiVersion, userId)}/history`, {
      params: {
        ...searchParams,
        size: 20,
      },
    });
  }

  function getBMIGraphHistory(userId, searchParams, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch BMI Graph history.'),
      );
    }
    return request.get(`${BMI_BASE_ENDPOINT(apiVersion, userId)}/graph-history`, {
      params: searchParams,
    });
  }

  return {
    saveBMIResults,
    getCurrentBMI,
    getBMIHistory,
    getBMIGraphHistory,
  };
}
