import React, { useRef } from 'react';
import { Box, CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../styles';
import { SquareCheckbox } from '../Inputs';
import { usePaginationObserver } from '../../util/utilFunctions';
import NoSearchResults from '../EmptyStates/NoSearchResults';
import exerciseImagePlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import ROUTINE_TYPES from '../../util/RoutineTypes';
import ProgRegIconSet from "../ProgressionRegressionIconSet";

const useListStyle = makeStyles({
  list: {
    // hides scrollbar if mouse isn't hovering over the list
    overflowY: 'hidden',
    height: '90%',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  exerciseListHeading: {
    borderTop: `1px solid ${colors.light_grey}`,
    borderBottom: `1px solid ${colors.light_grey}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 25px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 14,
  },
});

export default function ExerciseScheduleList (props) {
  const {
    searchText,
    loading,
    hasMore,
    exerciseData,
    error,
    exerciseCheckFlags,
    onToggleCheckmark,
    setPageNumber,
    activeFilterCount,
    setSupersetPageNumber,
    hasMoreSuperset,
    isSuperSetLoading,
    compoundRoutines,
  } = props;

  const rootElement = useRef();
  const lastItemRef = usePaginationObserver({
    isLoading: loading,
    hasMoreDataToLoad: hasMore,
    setPageNumber: setPageNumber,
    rootElement: rootElement.current,
  });

  const lastSuperSetItemRef = usePaginationObserver({
    setPageNumber: setSupersetPageNumber,
    isLoading: isSuperSetLoading,
    hasMoreDataToLoad: hasMoreSuperset,
    rootElement: rootElement.current,
  });

  const allCircuits = compoundRoutines?.filter(
    (compoundRoutine) => compoundRoutine.routine_type === ROUTINE_TYPES.CIRCUIT,
  );

  const allSuperSets = compoundRoutines?.filter(
    (compoundRoutine) => compoundRoutine.routine_type === ROUTINE_TYPES.SUPER_SET,
  );

  const myExercises = exerciseData?.filter((exercise) => exercise.exercise_source === 'trainer');
  const nasmExercises = exerciseData?.filter((exercise) => exercise.exercise_source === 'nasm');

  const classes = useListStyle();

  if ((activeFilterCount > 0 || searchText !== '') &&
      exerciseData.length === 0 && compoundRoutines.length === 0 && !loading
    ) {
    return <NoSearchResults />;
  }

  return (
    <List ref={rootElement} className={classes.list}>
      {!activeFilterCount && allCircuits?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            Circuits
          </Typography>
        </Box>) : null
      }

      {!activeFilterCount && allCircuits?.map((exercise, index) => {
        // last compound Routine in the paginated list
        if (allCircuits.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastSuperSetItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
              isCompoundRoutines={true}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
            isCompoundRoutines={true}
          />
        );
      })}

      {!activeFilterCount && allSuperSets?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            Super Sets
          </Typography>
        </Box>) : null
      }

      {!activeFilterCount && allSuperSets?.map((exercise, index) => {
        // last compound Routine in the paginated list
        if (allSuperSets.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastSuperSetItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
              isCompoundRoutines={true}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
            isCompoundRoutines={true}
          />
        );
      })}

      {myExercises?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            My Exercises
          </Typography>
        </Box>) : null
      }

      {myExercises?.map((exercise, index) => {
        // last item in the paginated list
        if (myExercises.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
          />
        );
      })}

      {nasmExercises?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            NASM Exercises
          </Typography>
        </Box>) : null
      }

      {nasmExercises?.map((exercise, index) => {
        // last item in the paginated list
        if (nasmExercises.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
          />
        );
      })}

      {loading && !error &&
        <ListItem key='loading spinner' style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={20} />
        </ListItem>}
    </List>
  );
}

const useStyles = makeStyles({
  listItem: props => ({
    borderBottom: props.isChecked ? '1px solid rgba(255, 255, 255, 0.5)' : `1px solid ${colors.cloudy_blue_50}`,
    borderCollapse: 'collapse',
    backgroundColor: props.isChecked ? colors.baby_blue : colors.white,
    // Hack ~ https://stackoverflow.com/questions/8114657/how-to-style-the-parent-element-when-hovering-a-child-element
    // Turns off pointer events for parent but child elements can still accept them
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
  }),
  boxLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: props => ({
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 2.29,
    padding: props.isCompoundRoutines ? 5 : 0,
  }),
  checkboxMargins: {
    marginLeft: 0,
    marginRight: 10,
    // Can influence how parent element (listItem) can change color when
    // this element is being hovered over
    pointerEvents: 'auto',
  },
  thumbnail: {
    borderRadius: 6,
  },
  imageContainer: {
    paddingLeft: 12,
    display: 'flex',
  },
});

const ExerciseScheduleItem = React.forwardRef((props, ref) => {
  const { exercise = {}, isChecked, onToggleCheckmark } = props;
  const { name, image_url: imageUrl } = exercise;
  const classes = useStyles(props);

  return (
    <ListItem ref={ref} className={classes.listItem}>
      <Box className={classes.boxLayout}>
        <Box className={classes.checkboxMargins}>
          <SquareCheckbox
            isChecked={isChecked}
            onToggle={(checkFlag) => onToggleCheckmark({ checkFlag, exercise })}
          />
        </Box>
        <div className={classes.imageContainer}>
          {!props.isCompoundRoutines && <img
            className={classes.thumbnail}
            width={75} height={48}
            alt='exercise-thumbnail'
            src={imageUrl || exerciseImagePlaceholder}
            onError={e => { e.target.src = exerciseImagePlaceholder; }}
          />}
          <ProgRegIconSet exercise={exercise} />
        </div>
        <Typography className={classes.text}>
          {props.isCompoundRoutines ? exercise.title : name}
        </Typography>
      </Box>
    </ListItem>
  );
});
