import { colors } from '../../styles';

export const EVENT_CONTENT_COLORS = {
  TODAY: {
    bg: '#16161d',
    bar: '#16161d',
    text: colors.white,
  },
  COMPLETE: {
    bg: 'rgba(70, 154, 30, 0.15)',
    bar: '#8eb013',
    text: '#86a70d',
  },
  INCOMPLETE: {
    bg: 'rgba(246, 170, 44, 0.15)',
    bar: colors.yellow_button_style,
    text: '#e4981a',
  },
  UPCOMING: {
    bg: 'rgba(232, 234, 236, 0.66)',
    bar: colors.steel,
    text: colors.steel,
  },
};

export const EVENT_DATE_COLORS = {
  COMPLETE: {
    text: colors.steel,
    bg: 'rgba(70, 154, 30, 0.25)',
  },
  INCOMPLETE: {
    text: colors.steel,
    bg: 'rgba(246, 170, 44, 0.25)',
  },
  NORMAL: {
    text: colors.steel,
    bg: '#e8eaec',
  },
  SELECTED: {
    text: colors.white,
    bg: '#16161d',
  },
};
