import React from 'react';
import PropTypes from 'prop-types';

function FileUploadInput (props) {
  const {
    id,
    inputFileRef,
    onUploadBegin,
    supportedFileTypes = [
      'image/png',
      'image/jpeg',
      'video/quicktime', // MOV file
      'video/mp4',
    ],
  } = props;
  return (
    <input
      id={id}
      ref={inputFileRef}
      type='file'
      accept={supportedFileTypes.join(',')}
      onChange={onUploadBegin}
      style={{ display: 'none' }}
    />
  );
}

FileUploadInput.propTypes = {
  id: PropTypes.string.isRequired,
  // ref passed into input component
  inputFileRef: PropTypes.object.isRequired,
  onUploadBegin: PropTypes.func.isRequired,
  /***
   * Comma separated strings tht specify the supported file types
   * that can be uploaded to the server. E.g. image/png
   */
  supportedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default FileUploadInput;
