import React from 'react';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import ProgramList from '../Programs/ProgramList';

export default function ProgramListPanel (props) {
  const {
    visible,
    getProgramUrl,
    resetProgramIndex,
    selectedProgramIndex,
    setSelectedProgramIndex,
    copyProgramLink,
    onCreateProgram,
    ftuTo = '',
    ftuBtnHidden,
    showDuplicateDeleteButtons = true,
  } = props;
  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={800}>
        <FadeTransition visible={visible}>
          <ProgramList
            getProgramUrl={getProgramUrl}
            resetProgramIndex={resetProgramIndex}
            selectedProgramIndex={selectedProgramIndex}
            setSelectedProgramIndex={setSelectedProgramIndex}
            copyProgramLink={copyProgramLink}
            onCreateProgram={onCreateProgram}
            ftuTo={ftuTo}
            ftuBtnHidden={ftuBtnHidden}
            showDuplicateDeleteButtons={showDuplicateDeleteButtons}
          />
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}
