import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel, Radio } from '@material-ui/core';

import { colors } from '../../styles';

const formControlClasses = makeStyles({
  formControl: {
    '&.MuiFormControlLabel-label': {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '13px',
    },
  },
});

const StyledRadio = withStyles({
  root: {
    color: colors.black,
    '&$checked': {
      color: colors.radio_blue,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


function RadioButton (props) {
  const { label, value, disabled } = props;
  const classes = formControlClasses();
  return (
    <Box>
      <FormControlLabel
        classes={{
          label: classes.formControl,
        }}
        control={<StyledRadio />}
        label={label}
        value={value}
        disabled={disabled}
      />
    </Box>
  );
}

export default RadioButton;
