import {useEffect, useState} from 'react';

export const SCRIPT_STATES = {
  LOADING: 'loading',
  IDLE: 'idle',
  READY: 'ready',
  ERROR: 'error',
};

export function useExternalScript (props) {
  const { LOADING, IDLE, READY, ERROR } = SCRIPT_STATES;
  const { url, canStartLoading = true, type = 'text/javascript' } = props;
  const [state, setState] = useState(url ? LOADING : IDLE);

  const handleScript = e => {
    setState(e.type === 'load' ? READY : ERROR);
    if (e.type !== 'load') {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!url || !canStartLoading) {
      setState(IDLE);
      return;
    }

    const script = document.createElement('script');
    script.type = type;
    script.async = true;
    script.src = url;
    document.body.appendChild(script);
    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
      document.body.removeChild(script);
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [url, canStartLoading]);

  return state;
}