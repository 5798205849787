import React from 'react';
import querystring from 'query-string';
import { useHistory } from 'react-router-dom';

// Local
import PanelContainer from '../../components/Panels/PanelContainer';
import Panel from '../../components/Panels/Panel';
import AddingToWorkoutListContainer from './AddingToWorkoutListContainer';
import ExerciseDetailsPanel from '../../components/Panels/ExerciseDetailsPanel';
import SlidingFlexTransition from '../../components/Transitions/SlidingFlexTransition';
import { updateQuery } from '../../util/utilFunctions';
import SwapExercisePanel from '../Panels/SwapExercisePanel';

const views = {
  addingToWorkoutExList: {
    key: 'addingToWorkoutExList',
    addingToWorkoutExList: true,
  },
  exerciseDetails: {
    key: 'exerciseDetails',
    addingToWorkoutExList: true,
    exerciseDetails: true,
  },
  swapPage: {
    key: 'swapPage',
    exerciseDetails: true,
    swapPage: true,
  },
};

export default function AddingToWorkoutPanelContainer (props) {
  const { isScheduleDialogOpen, onOpenScheduleDialog } = props;
  const history = useHistory();
  const location = history.location;
  const query = querystring.parse(location.search);
  const view = query.view || views.addingToWorkoutExList.key;

  const viewAddingToWorkoutExList = () => {
    return updateQuery(location.search, { view: views.addingToWorkoutExList.key });
  };

  const viewExerciseDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, {
      view: views.exerciseDetails.key,
      exerciseId,
      exerciseKey,
      showProgressionsRegressions,
    });
  };

  const viewSwapPageLink = (exerciseId) => {
    return updateQuery(location.search, {
      view: views.swapPage.key,
      exerciseId,
    });
  };

  return (
    <PanelContainer>
      <SlidingFlexTransition visible={!!views[view].addingToWorkoutExList}>
        <Panel height={800}>
          <AddingToWorkoutListContainer
            isScheduleDialogOpen={isScheduleDialogOpen}
            onOpenScheduleDialog={onOpenScheduleDialog}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
          />
        </Panel>
      </SlidingFlexTransition>
      <ExerciseDetailsPanel
        visible={!!views[view].exerciseDetails}
        resetSelection={() => {}}
        backLink={viewAddingToWorkoutExList}
        viewExerciseDetailsLink={viewExerciseDetailsLink}
        viewSwapPageLink={viewSwapPageLink}
        isScheduling
      />
      <SwapExercisePanel
        visible={!!views[view].swapPage}
        backLink={viewExerciseDetailsLink}
      />
    </PanelContainer>
  );
}
