import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import PlayPauseButtonSrc from '../../../../resources/button-video-play.svg';

const useStyles = makeStyles({
  playPauseButton: {
    width: 'inherit',
    height: 'inherit',
    backgroundImage: `url(${PlayPauseButtonSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  playPauseButtonOff: {
    opacity: 0,
    transform: 'scale(2)',
    transition: 'opacity .3s, transform .3s',
  },
  playPauseButtonOn: {
    opacity: 1,
    transform: 'scale(1)',
    transition: 'opacity .3s, transform .3s',
  },
});

/**
 * @return {null | JSXElement}
 */
function PlayPauseOverlay (props) {
  const { componentType, isActive } = props;
  const classes = useStyles();

  if (componentType === 'img') {
    return null;
  }

  return (
    <Box
      id='play-pause-button'
      className={`${classes.playPauseButton} 
          ${isActive ? classes.playPauseButtonOff : classes.playPauseButtonOn}`}
    />
  );
}

PlayPauseOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
  // Must either be 'video' or 'img'
  componentType: PropTypes.string.isRequired,
};

export default PlayPauseOverlay;
