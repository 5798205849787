import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ClientDashboardSettings from './ClientDashboardSettings';
import nasmApi from '../../api/endpoints';
import { selectClient } from '../../reducers/selectedClientReducer';
import {SAVE_STATES} from '../../constants';

function ClientDashboardSettingsWrapper (props) {
  const { open, onClose, client, clubId = null } = props;
  const dispatch = useDispatch();

  const visibilityFlags = {
    visibleGoals: client.client_user.visible_goals || false,
    visibleNutrition: client.client_user.visible_nutrition || false,
    visibleMeasures: client.client_user.visible_measures || false,
    visibleOhsa: client.client_user.visible_ohsa || false,
    visiblePerfAssess: client.client_user.visible_perf_assess || false,
  };

  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [isGoalsVisible, setGoalsVisible] = useState(visibilityFlags.visibleGoals);
  const [isNutritionVisible, setNutritionVisible] = useState(visibilityFlags.visibleNutrition);
  const [isMeasurementsVisible, setMeasurementsVisible] = useState(visibilityFlags.visibleMeasures);
  const [isPerformanceAssessmentsVisible, setPerformanceAssessmentsVisible] =
    useState(visibilityFlags.visiblePerfAssess);
  const [isOverheadSquatVisibleToClient, setOverheadSquatVisibleToClient] = useState(visibilityFlags.visibleOhsa);

  const onToggleGoals = () => {
    setGoalsVisible(prevState => !prevState);
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const onToggleNutrition = () => {
    setNutritionVisible(prevState => !prevState);
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const onToggleMeasurements = () => {
    setMeasurementsVisible(prevState => !prevState);
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const onTogglePerformanceAssessments = () => {
    setPerformanceAssessmentsVisible(prevState => !prevState);
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const onChangeOverHeadSquatVisibility = () => {
    setOverheadSquatVisibleToClient(prevState => !prevState);
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const onSavePreferences = async () => {
    try {
      setSaveState(SAVE_STATES.LOADING);
      const requestBody = {
        visible_goals: isGoalsVisible,
        visible_nutrition: isNutritionVisible,
        visible_measures: isMeasurementsVisible,
        visible_perf_assess: isPerformanceAssessmentsVisible,
        visible_ohsa: isOverheadSquatVisibleToClient,
      };

      const response = await nasmApi.users.updateClientPreferences(client.id, requestBody);
      dispatch(selectClient(response.result));
      setSaveState(SAVE_STATES.SAVED);
    } catch (e) {
      window.alert('Error saving Client Preferences.');
      setSaveState(SAVE_STATES.ERROR);
    }
  };

  // Reset visibility flags if changes aren't saved
  useEffect(() => {
    if(open === false && saveState === SAVE_STATES.CLICKABLE) {
      setGoalsVisible(visibilityFlags.visibleGoals);
      setNutritionVisible(visibilityFlags.visibleNutrition);
      setMeasurementsVisible(visibilityFlags.visibleMeasures);
      setPerformanceAssessmentsVisible(visibilityFlags.visiblePerfAssess);
      setOverheadSquatVisibleToClient(visibilityFlags.visibleOhsa);
    }

  }, [
    open,
    saveState,
    visibilityFlags.visibleGoals,
    visibilityFlags.visibleNutrition,
    visibilityFlags.visibleMeasures,
    visibilityFlags.visiblePerfAssess,
    visibilityFlags.visibleOhsa,
  ]);


  return (
    <ClientDashboardSettings
      open={open}
      saveState={saveState}
      isGoalsVisible={isGoalsVisible}
      isNutritionVisible={isNutritionVisible}
      isMeasurementsVisible={isMeasurementsVisible}
      isPerformanceAssessmentsVisible={isPerformanceAssessmentsVisible}
      isOverheadSquatVisibleToClient={isOverheadSquatVisibleToClient}

      // used to hide nutrition card visibility option
      clubId={clubId}

      onToggleGoals={onToggleGoals}
      onToggleNutrition={onToggleNutrition}
      onToggleMeasurements={onToggleMeasurements}
      onTogglePerformanceAssessments={onTogglePerformanceAssessments}
      onChangeOverHeadSquatVisibility={onChangeOverHeadSquatVisibility}

      onSave={onSavePreferences}
      onClose={onClose}
    />
  );
}

ClientDashboardSettingsWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClientDashboardSettingsWrapper;