import React from 'react';
import OvalButton from './OvalButton';

export default function CircleButton (props) {
  return (
    <OvalButton
      width={props.width || 35}
      height={props.height || 35}
      backgroundcolor={props.backgroundcolor}
      margin={props.margin}
      borderradius={50}
      onClick={props.onClick}
      opacity={props.opacity}
    >
      {props.children}
    </OvalButton>
  );
}
