import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, OutlinedInput } from '@material-ui/core';
import SearchIcon from '../../resources/search.svg';
import ClearIcon from '../../resources/icons-global-x-gray-clear-search.svg';

const useStyles = makeStyles({
  search: {
    fontSize: props => props.fontSize || 17,
    borderRadius: '19px',
    backgroundColor: '#f3f4f5',
    height: '38px',
  },
});

const useClearButtonStyles = makeStyles({
  clearSearch: {
    cursor: 'pointer',
  },
});

/**
 * @return {JSX|null}
 */
function ClearButton (props) {
  const { isClearable, onClickClear } = props;
  const classes = useClearButtonStyles();

  if (isClearable) {
    return (
      <InputAdornment
        position='end'
        className={classes.clearSearch}
        onClick={onClickClear}
      >
        <img src={ClearIcon} alt='clear' />
      </InputAdornment>
    );
  }

  return null;
}

function HighlightSearchBar (props) {
  const classes = useStyles(props);
  const { value, onClickClear, isClearable, onChange, fullWidth } = props;

  return (
    <OutlinedInput
      className={classes.search}
      placeholder='Search'
      startAdornment={
        <InputAdornment position='start'>
          <img alt='search icon' src={SearchIcon} />
        </InputAdornment>
      }
      endAdornment={<ClearButton isClearable={isClearable} onClickClear={onClickClear}/>}
      value={value}
      onChange={onChange}
      fullWidth={fullWidth || false}
    />
  );
}

HighlightSearchBar.propTypes = {
  onClickClear: PropTypes.func,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  value: PropTypes.string,
};

export default HighlightSearchBar;
