import React from 'react';
import AccountSettings from '../Dialogs/AccountSettings';
import ManageGoals from './Goals/ManageGoals';
import OneRmCalculatorContainer from './OneRmCalculatorContainer';
import BWPCalculatorContainer from './Nutrition/BWPCalculatorContainer';
import BMICalculatorContainer from './BMICalculatorContainer';
import MHRCalculatorContainer from './MHRCalculatorContainer';
import BodyFatCalculatorContainer from './BodyFatCalculatorContainer';

function ClientDashboardDetails (props) {
  const {
    selectedClient,
    showClientSettings,
    showGoals,
    showOneRmWidget,
    showBWPWidget,
    showBMIWidget,
    showMHRWidget,
    showBodyFatWidget,
    onClickAccountSettings,
    onCloseAccountSettings,
    onCloseGoals,
    onCloseWidget,
    clubId,
  } = props;

  return (
    <>
      <AccountSettings
        isOpen={showClientSettings}
        onOpen={onClickAccountSettings}
        onClose={onCloseAccountSettings}
        currentUser={selectedClient}
        clubId={clubId}
      />
      <ManageGoals
        goalsOpen={showGoals}
        onClose={onCloseGoals}
        client={selectedClient}
      />
      <BWPCalculatorContainer
        currentUser={selectedClient}
        isOpen={showBWPWidget}
        onClose={onCloseWidget}
      />
      <BMICalculatorContainer
        currentUser={selectedClient}
        isOpen={showBMIWidget}
        onClose={onCloseWidget}
      />
      <OneRmCalculatorContainer
        currentUser={selectedClient}
        isOpen={showOneRmWidget}
        onClose={onCloseWidget}
      />
      <MHRCalculatorContainer
        currentUser={selectedClient}
        isOpen={showMHRWidget}
        onClose={onCloseWidget}
      />
      <BodyFatCalculatorContainer
        currentUser={selectedClient}
        isOpen={showBodyFatWidget}
        onClose={onCloseWidget}
      />
    </>
  );
}

export default ClientDashboardDetails;
