import { useEffect, useState } from 'react';
import nasmApi from '../../api/endpoints';

export default function useFetchMeasurementUnits () {
    const [loading, setLoading] = useState(false);
    const [measurementUnits, setMeasurementUnits] = useState({});

   useEffect(() => {
     setLoading(true);
     nasmApi.users.getMeasurementSystem().then((result) => {
       setMeasurementUnits(() => result.result);
     }).finally(() => setLoading(false));
   }, []);

  return {
    loading,
    measurementUnits,
  };
}
