import { createSlice } from '@reduxjs/toolkit';

const compoundRoutinesSlice = createSlice({
  name: 'compoundRoutines',
  // Store a list of exercises retrieved from the backend API
  initialState: {
    byId: {},
  },
  reducers: {
    saveCompoundRoutines: (state, action) => {
      const compoundRoutines = action.payload;
      if (compoundRoutines && compoundRoutines.length > 0) {
        for (const routine of compoundRoutines) {
          state.byId[routine.id] = routine;
        }
      }
    },
    clearCompoundRoutines: (state, action) => {
      state.byId = {};
    },
  },
});

const { actions, reducer } = compoundRoutinesSlice;

export const { saveCompoundRoutines, clearCompoundRoutines } = actions;

export default reducer;
