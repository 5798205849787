import { CircleButton } from '../Buttons';
import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

/**
 * @return {null | JSX}
 */

const useOverlay = makeStyles({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    width: 38,
    height: 38,
    borderRadius: 18,
    backgroundColor: colors.white,
    cursor: 'pointer',
    zIndex: 1,
    opacity: 0,
    '&:hover': {
      opacity: 0.06,
    },
  },
});

export default function DialogCircleButton (props) {
  const { isHidden = false, onClick, margin } = props;
  const classes = useOverlay();

  if (isHidden) {
    return null;
  }

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.overlay} />
      <CircleButton
        width={38}
        height={38}
        backgroundcolor='transparent'
        margin={margin ?? '0 20px 0 0'}
      >
        {props.children}
      </CircleButton>
    </Box>
  );
}
