import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import DeleteDuplicateButton from '../Workouts/DeleteDuplicateButton';
import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import processingThumbnail from '../../resources/image-video-processing.svg';
import { colors } from '../../styles';

const RowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 25px',
  backgroundColor: props => props.selected ? colors.baby_blue : colors.white,
  borderBottom: `1px solid ${colors.light_grey}`,
  height: '89px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colors.baby_blue,
  },
  '&:active': {
    backgroundColor: colors.white,
    filter: 'brightness(88%)',
  },
});

const LeftContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '90%',
});

const RightContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
});

const Thumbnail = styled('img')({
  width: '75px',
  height: '48px',
  borderRadius: '6px',
  objectFit: 'cover',
});

const WorkoutTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '29px',
  color: colors.carbon_black,
  marginLeft: '24px',
});

const VideoProcessing = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '12px',
  fontWeight: 500,
  color: colors.yellow_button_style,
  marginRight: '39px',
});

const TotalMinutes = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '12px',
  color: colors.carbon_black,
  marginRight: '28px',
  width: '50px',
  textAlign: 'right',
});

function VideoWorkoutRow (props) {
  const {
    workout,
    showDeleteButton,
    showDuplicateButton,
    isVideoProcessing,
    onClickWorkout,
    isSelected,
    lastElementRef,
    onDeletePressed,
    showDropdownOnWorkoutRecords,
  } = props;
  // Todo: Replace with actual video duration
  const totalMins = Math.ceil(workout.total_dur_seconds / 60);

  return(
    <RowContainer
      selected={isSelected}
      ref={lastElementRef}
    >
      <LeftContainer onClick={onClickWorkout}>
        <Thumbnail
          src={isVideoProcessing ? processingThumbnail : workout.uploaded_media.thumbnail_url}
          alt='video-thumbnail'
          onError={e => e.target.src = thumbnailPlaceholder}
        />
        <WorkoutTitle>{workout.name}</WorkoutTitle>
      </LeftContainer>
      <RightContainer>
        { isVideoProcessing ? <VideoProcessing>video processing...</VideoProcessing> : null }
        <TotalMinutes>{`${totalMins} min`}</TotalMinutes>
        { showDropdownOnWorkoutRecords &&
          <DeleteDuplicateButton
            onDeletePressed={onDeletePressed}
            onDuplicatePressed={() => {}}
            showDeleteButton={showDeleteButton}
            showDuplicateButton={showDuplicateButton}
          />
        }
      </RightContainer>
    </RowContainer>
  );
}

export default VideoWorkoutRow;

VideoWorkoutRow.propTypes = {
  workout: PropTypes.object,
  showDeleteButton: PropTypes.bool,
  isVideoProcessing: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClickWorkout: PropTypes.func,
  onDeleteWorkout: PropTypes.func,
};

