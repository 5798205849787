import React from 'react';
import PropTypes from 'prop-types';
import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import CreateCircuitDialog from '../../pages/circuits/CreateCircuitDialog';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: {
    borderRadius: '6px',
    backgroundColor: colors.white,
    transition: 'height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overfolwY: 'auto', 
    overflowX: 'hidden',
    height: 800,
  },
});

function AddCircuitsPanel (props) {
  const {
    visible,
    backLink,
    resetSelection = () => {},
    isAutoSaveEnabled,
    isScheduling,
    isRescheduling,
    circuitData,
    setCircuitsData,
    viewCircuitExDetailsLink,
    isDeletable,
  } = props;

  const classes = useStyles();

  const containerHeight = classes.root.height;

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={containerHeight}>
        <FadeTransition visible={visible}>
          <Container className={classes.root}>
            <CreateCircuitDialog
              isAutoSaveEnabled={isAutoSaveEnabled}
              isScheduling={isScheduling}
              isRescheduling={isRescheduling}
              backLink={backLink}
              resetSelection={resetSelection}
              circuitData={circuitData}
              setCircuitsData={setCircuitsData}
              viewCircuitExDetailsLink={viewCircuitExDetailsLink}
              isDeletable={isDeletable}
            />
          </Container>
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

AddCircuitsPanel.propTypes = {
  visible: PropTypes.bool,
  backLink: PropTypes.func,
  resetSelection: PropTypes.func,
  isAutoSaveEnabled: PropTypes.bool,
  isScheduling: PropTypes.bool,
  isRescheduling: PropTypes.bool,
};

export default AddCircuitsPanel;

