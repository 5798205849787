import React, { useState, useEffect } from 'react';
import { colors } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
  Box,
  Typography,
  Divider,
  CardMedia,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  ButtonBase,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ROLES, SAVE_STATES } from '../../constants';

import Panel from './Panel';

import { VolumeCombobox, IntensityCombobox } from '../Comboboxes';
import { CircleButton, OvalButton, SaveButton } from '../Buttons';

import Trash from '../../resources/trash.svg';
import { ReactComponent as DownArrowIcon } from '../../resources/down-arrow-small.svg';

import {
  LabelTextInput,
  StatelessDropdown as Dropdown,
} from '../Inputs';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

import {
  editExercise,
  editExerciseNoAutoSave,
  removeExerciseAndSaveWorkout,
  removeExercise,
} from '../../reducers/selectedWorkoutReducer';

import { exerciseFromWorkoutValidationSchema } from '../../util/exerciseValidationSchema';

import {
  convertFromMinutesAndSecondsToSeconds,
  updateExercisePayload,
  findPrimaryChoice, formatMinutesAndSecondsDisplay,
} from '../../util/utilFunctions';

import {
  getExerciseVariables,
  getRestTempoStringForExercise,
} from '../../util/programUtils';

import {
  REST_TEMPO,
  VOLUME_KEYS,
  INTENSITY_KEYS,
  EXERCISE_SIDES,
} from '../../constants';

import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import ProgressionsRegressionsList from '../ProgressionsRegressionsList';
import { programContexts } from '../../reducers/programContextReducer';

const useTabButtonStyles = makeStyles({
  root: props => (props.isSelected ? {
    color: colors.yellow_button_style,
    borderBottom: `5px solid ${colors.yellow_button_style}`,
    paddingBottom: 5,
    width: 178,
    height: 48,
  } : {
    color: colors.steel,
    borderBottom: 'none',
    paddingBottom: 5,
    width: 178,
    height: 48,
  }
  ),
  tabTextStyle: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

function TabButton (props) {
  const classes = useTabButtonStyles(props);
  const { label, onClick } = props;
  return (
    <ButtonBase
      disableRipple
      className={classes.root}
      onClick={onClick}
    >
      <Typography className={classes.tabTextStyle}>
        {label}
      </Typography>
    </ButtonBase>
  );
}

const useDescDropdownStyles = makeStyles({
  removeTopBorder: {
    '&:before': {
      height: 0,
    },
  },
  accordionStyle: {
    boxShadow: 'none',
    width: '100%',
    paddingBottom: '20px',
  },
  accordionActionsStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
  },
  accordionSummaryStyle: {
    padding: 0,
  },
  accordionSummaryContentStyle: {
    margin: 0,
  },
  accordionDetailsStyle: {
    paddingTop: 0,
  },
  description: props => ({
    color: props.toggleMediaVisible ? colors.steel : colors.baby_blue,
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 1.94,
  }),
});

function DescriptionDropdown (props) {
  const classes = useDescDropdownStyles(props);
  const { descriptionText, onChange } = props;

  return (
    <Accordion
      onChange={onChange}
      className={classes.accordionStyle}
      classes={{ root: classes.removeTopBorder }}
    >
      <AccordionActions
        disableSpacing
        className={classes.accordionActionsStyle}
      >
        <AccordionSummary
          expandIcon={<DownArrowIcon />}
          className={classes.accordionSummaryStyle}
          classes={{ content: classes.accordionSummaryContentStyle }}
        >
          <Typography
            className={classes.description}
          >Description
          </Typography>
        </AccordionSummary>
      </AccordionActions>
      <AccordionDetails
        className={classes.accordionDetailsStyle}
      >
        <Typography>
          {descriptionText}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

const useExerciseFormStyles = makeStyles({
  root: props => ({
    margin: 20,
    opacity: props.isVisible ? 1 : 0,
    height: props.isVisible ? 'auto' : 0,
  }),
  section1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 2,
  },
  section2: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 10,
  },
  bottomSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  fontStyle: {
    color: colors.steel,
    fontSize: 14,
  },
  progregCont: {
    paddingTop: 20,
    marginRight: -20,
    marginLeft: -20,
  },
});

function ExerciseForm (props) {
  const { isVisible = true, setSaveState} = props;
  const classes = useExerciseFormStyles({ isVisible });
  const { currentUser } = useSelector(state => state);
  const isTrainer = currentUser.role === ROLES.TRAINER;

  const {
    volumeChoice,
    onChangeVolumeChoice,
    intensityChoice = '',
    onChangeIntensityChoice,
    // used to determine if exercise sides should be visible and editable to the trainer
    allowUnilateral,
    formikProps,
    exercise,
    exerciseKey,
    programContext,
    selectedWorkout,
    viewExerciseDetailsLink,
  } = props;

  const isScheduling = programContext.context === programContexts.SCHEDULING;
  const isRescheduling = programContext.context === programContexts.RESCHEDULING;

  let exerciseData = exercise;
  let exeOrCompoundExe = {};

  if (isScheduling || isRescheduling) {
    exeOrCompoundExe = Object.values(selectedWorkout?.entities?.exercises)?.find(
      (ex) => ex.key === exerciseKey,
    );
    if (!exeOrCompoundExe) {
      exeOrCompoundExe = exercise;
    }
    if (exeOrCompoundExe?.compound_routine_exercises?.length) {
      exerciseData = exeOrCompoundExe.compound_routine_exercises?.find(
        (ex) => {
          if (ex.id === exercise?.id) {
            return ex;
          }
          return null;
        },
      );
      if (!exerciseData) {
        exerciseData = exeOrCompoundExe.compound_routine_exercises.find(
          (ex) => {
            if (
              ex.swapped_original_exercise?.compound_routine_exercises?.some(
                (ex1) => ex1.id === exercise?.id,
              )
            ) {
              return ex;
            }
            return null;
          },
        );
      }
    } else {
      exerciseData = exeOrCompoundExe;
    }
  }

  function onChangeReps (e) {
    formikProps.setFieldValue('reps', e.target.value, true);
    formikProps.setFieldTouched('reps', true, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeDuration (e) {
    const timeDisplay = formatMinutesAndSecondsDisplay(e.target.value);
    formikProps.setFieldValue('duration', timeDisplay, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeDistance (e) {
    formikProps.setFieldValue('distance', e.target.value, true);
    formikProps.setFieldTouched('distance', true, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeDistanceType (e) {
    formikProps.setFieldValue('distance_units', e.target.value, true);
  }

  function onChangeTempo (e) {
    formikProps.setFieldValue('tempo', e.target.value, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangePaceUnits (e) {
    formikProps.setFieldValue('pace_units', e.target.value, true);
  }

  function onChangePaceValue (e) {
    formikProps.setFieldValue('pace', e.target.value, true);
    formikProps.setFieldTouched('pace', true, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function setRestTempo (event) {
    let newRestTempo = 0;
    switch (event.target.value) {
      case 'rest':
        newRestTempo = 1;
        break;
      case 'slow':
        newRestTempo = 2;
        break;
      default:
        newRestTempo = 0;
        break;
    }

    formikProps.setFieldValue('rest_tempo', newRestTempo, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeRest (e) {
    const timeDisplay = formatMinutesAndSecondsDisplay(e.target.value);
    formikProps.setFieldValue('rest', timeDisplay, true);
  }

  function resetVolumeFieldErrors () {
    formikProps.setFieldError('reps', undefined);
    formikProps.setFieldError('dur_seconds', undefined);
    formikProps.setFieldError('distance', undefined);
  }

  function resetVolumeValues () {
    formikProps.setFieldValue('reps', '', false);
    formikProps.setFieldValue('duration', '', false);
    formikProps.setFieldValue('distance', '', false);
  }

  function resetIntensityValues () {
    formikProps.setFieldValue('tempo', '', false);
    formikProps.setFieldValue('pace', '', false);
    formikProps.setFieldValue('pace_units', '', false);
  }

  function resetIntensityFieldErrors () {
    formikProps.setFieldError(intensityChoice, undefined);
  }

  const onBlurField = (e) => {
    setSaveState(SAVE_STATES.CLICKABLE);
    formikProps.handleBlur(e);
  };

  /* eslint-disable camelcase */
  return (
    <Box className={classes.root}>
      <Box className={classes.section1}>
        <LabelTextInput
          label='Sets'
          name='sets'
          inputType='number'
          sideMargin={10}
          flex={1}
          placeholder='Amount'
          value={formikProps.values.sets}
          onHandleChange={formikProps.handleChange}
          onHandleBlur={onBlurField}
          errorText={formikProps.errors.sets}
          showError={!!formikProps.touched.sets}
        />
        <LabelTextInput
          label='Weight'
          name='weight'
          inputType='number'
          sideMargin={10}
          flex={1.25}
          placeholder='Amount'
          value={formikProps.values.weight}
          onHandleChange={formikProps.handleChange}
          onHandleBlur={onBlurField}
          errorText={formikProps.errors.weight}
          showError={!!formikProps.touched.weight}
        />
      </Box>

      {allowUnilateral &&
        <Box className={classes.section2}>
          <Dropdown
            label='Exercise Side'
            name='exercise_sides'
            options={EXERCISE_SIDES}
            currentValue={formikProps.values.exercise_sides}
            onOptionChanged={formikProps.handleChange}
          />
        </Box>}

      <Box style={{ margin: '10px' }}>
        <Typography className={classes.fontStyle}>
          Volume
        </Typography>
      </Box>
      <VolumeCombobox
        volumeChoice={volumeChoice === 'n/a' ? '' : volumeChoice}
        onChangeVolumeChoice={onChangeVolumeChoice}
        reps={formikProps.values.reps}
        duration={formikProps.values.duration}
        distance={formikProps.values.distance}
        onChangeReps={onChangeReps}
        onChangeDuration={onChangeDuration}
        onChangeDistance={onChangeDistance}
        distanceType={formikProps.values.distance_units}
        onChangeDistanceType={onChangeDistanceType}
        resetFieldErrors={resetVolumeFieldErrors}
        fieldErrors={formikProps.errors}
        touchedFields={formikProps.touched}
        resetVolumeValues={resetVolumeValues}
        marginLeft={10}
        marginRight={10}
      />
      <Box style={{ margin: '20px 10px 10px 10px' }}>
        <Typography className={classes.fontStyle}>
          Intensity
        </Typography>
      </Box>
      <IntensityCombobox
        intensityChoice={intensityChoice}
        onChangeIntensityChoice={onChangeIntensityChoice}
        tempo={formikProps.values.tempo}
        onChangeTempo={onChangeTempo}
        paceValue={formikProps.values.pace}
        onChangePaceValue={onChangePaceValue}
        paceUnits={formikProps.values.pace_units}
        onChangePaceUnits={onChangePaceUnits}
        onResetValues={resetIntensityValues}
        resetFieldErrors={resetIntensityFieldErrors}
        fieldErrors={formikProps.errors}
        touchedFields={formikProps.touched}
        marginLeft={10}
        marginRight={10}
      />
      <Box
        className={classes.bottomSection}
        style={{ margin: '35px 10px 15px 10px' }}
      >
        <LabelTextInput
          label='Rest'
          name='rest'
          value={formikProps.values.rest}
          placeholder='00:00'
          onHandleChange={onChangeRest}
          onHandleBlur={onBlurField}
          errorText={formikProps.errors.rest}
        />
      </Box>
      <Box
        className={classes.bottomSection}
        style={{ margin: '10px 10px 25px 10px' }}
      >
        <Dropdown
          label='Rest Tempo'
          options={REST_TEMPO}
          currentValue={getRestTempoStringForExercise(formikProps.values).toLowerCase()}
          onOptionChanged={setRestTempo}
        />
      </Box>
      <Box className={classes.progregCont}>
        {(isTrainer) ? 
          <ProgressionsRegressionsList
            exerciseDetails={exerciseData}
            exerciseKey={exerciseKey}
            exeOrCompoundExe={exeOrCompoundExe}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
          /> : null}
      </Box>
    </Box>
  );
  /* eslint-enable camelcase */
}

const useNotesStyles = makeStyles({
  root: props => ({
    margin: props.isVisible ? 20 : 0,
    minHeight: props.isVisible ? 400 : 0,
    opacity: props.isVisible ? 1 : 0,
    height: props.isVisible ? 'auto' : 0,
  }),
  inputStyle: {
    fontSize: 17,
  },
  width: {
    width: '100%',
  },
});

function NotesForm (props) {
  const { isVisible = false } = props;
  const classes = useNotesStyles({ isVisible });
  const { notes, formikProps } = props;

  return (
    <Box className={classes.root}>
      <Input
        multiline
        disableUnderline
        inputProps={{ className: classes.inputStyle }}
        className={classes.width}
        placeholder='Write something here...'
        value={notes}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        name='notes'
      />
    </Box>
  );
}

const useStyles = makeStyles({
  parentContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 720,
  },
  root: {
    margin: 20,
  },
  topButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 20,
  },
  rightButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  exerciseNameStyle: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.63,
    color: colors.black,
    marginTop: 25,
  },
  descriptionCont: {
    display: 'flex',
    alignItems: 'center',
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

function EditExerciseContainer (props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    backLink,
    isAutoSaveEnabled,
    resetSelectedExercise = () => {},
    viewExerciseDetailsLink = () => {},
  } = props;

  const [toggleMediaVisible, setToggleMediaVisible] = useState(true);
  const [isDetailsTabSelected, setIsDetailsTabSelected] = useState(true);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);

  const exerciseKey = location?.state?.exerciseKey ?? 'bad-key';
  const sectionId = location?.state?.sectionId ?? null;
  const exercise = location?.state?.exercise ?? {};
  const workout = location?.state?.workout ?? {};

  const [volumeChoice, setVolumeChoice] = useState(findPrimaryChoice(exercise, VOLUME_KEYS));
  const [intensityChoice, setIntensityChoice] = useState(findPrimaryChoice(exercise, INTENSITY_KEYS));

  /* eslint-disable camelcase */
  const [exerciseState, setExerciseState] = useState(
    updateExercisePayload(volumeChoice, intensityChoice, exercise),
  );

  const { selectedWorkout, programContext } = useSelector(state => state);

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: exerciseState,
    validationSchema: exerciseFromWorkoutValidationSchema,
    onSubmit: async (modifiedExercise) => {
      let localExericse = exercise;
      if (programContext.context === programContexts.RESCHEDULING && selectedWorkout) {
        localExericse = Object.values(selectedWorkout?.entities?.exercises)?.find((ex) => ex.id === exercise.id);
      }
      const updatedExercise = {
        ...localExericse,
        ...modifiedExercise,
      };
      await onPressSave(updatedExercise);
    },
  });

  function onChangeVolumeChoice (e) {
    formikProps.setFieldValue('volumeChoice', e.target.value, true);
    setVolumeChoice(e.target.value);
  }

  function onChangeIntensityChoice (e) {
    // set intensity choice here to empty string to trigger conditional error validation
    // when volume choice is set to distance
    formikProps.setFieldValue('intensityChoice',
      e.target.value === 'n/a' ? '' : e.target.value, true);
    setIntensityChoice(e.target.value);
  }

  // update exercise fields when new exercise is selected for editing
  useEffect(() => {
    const variables = getExerciseVariables(exercise, true);
    const newVolumeChoice = findPrimaryChoice(variables, VOLUME_KEYS);
    const newIntensityChoice = findPrimaryChoice(variables, INTENSITY_KEYS);

    setVolumeChoice(newVolumeChoice);
    setIntensityChoice(newIntensityChoice);

    const newExercise = {
      ...variables,
      name: exercise.name,
      id: exercise.id,
      notes: exercise.notes,
    };

    setExerciseState(
      updateExercisePayload(newVolumeChoice, newIntensityChoice, newExercise));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseKey]);

  async function onPressSave (modifiedExercise) {
    const exerciseCopy = {
      ...modifiedExercise,
      key: exerciseKey,
      image_url: exercise.image_url,
      video_url: exercise.video_url,
    };

    exerciseCopy.weight_units = 'lb';
    exerciseCopy.rest = convertFromMinutesAndSecondsToSeconds(exerciseCopy.rest);
    if (exerciseCopy.duration) {
      exerciseCopy.dur_seconds = convertFromMinutesAndSecondsToSeconds(exerciseCopy.duration);
      // Remove duration as its not part of the request payload
      delete exerciseCopy.duration;
    } else {
      exerciseCopy.dur_seconds = '';
    }

    if (isAutoSaveEnabled) {
      try {
        setSaveState(SAVE_STATES.LOADING);
        await dispatch(editExercise(exerciseCopy));
        setSaveState(SAVE_STATES.SAVED);
      } catch (e) {
        setSaveState(SAVE_STATES.ERROR);
        window.alert(`Error saving changes to exercise: ${e.message}`);
      }
    } else {
      dispatch(editExerciseNoAutoSave(exerciseCopy));
      setSaveState(SAVE_STATES.SAVED);
    }
  }

  function onOpenConfirmationDialog () {
    setShowConfirmDeleteDialog(true);
  }

  function onCloseConfirmationDialog () {
    setShowConfirmDeleteDialog(false);
  }

  function onPressDelete () {
    if (sectionId == null) {
      window.alert(`Failed to delete exercise. Could not find ${exercise?.name ?? 'this exercise'} in any of the` +
        ` workout sections in ${workout?.name ?? 'this workout'}`);
    } else {
      // When a workout is duplicated, the trainer must manually save the workout
      if (isAutoSaveEnabled) {
        dispatch(removeExerciseAndSaveWorkout({ exercise, sectionId }))
          .then(() => {
            setShowConfirmDeleteDialog(false);
            resetSelectedExercise();
            history.goBack();
          })
          .catch((e) => {
            window.alert(`Failed to delete the exercise: ${exercise.name} from ${workout?.name ?? 'this workout'}`);
            console.error(`Failed to delete exercise error:\n${JSON.stringify(e, null, 2)}`);
          });
      } else {
        dispatch(removeExercise({ exercise, sectionId }));
        history.goBack();
      }
    }
  }

  return (
    <>
      <form autoComplete='off' onSubmit={formikProps.handleSubmit}>
        <Box className={classes.topButtons}>
          <CircleButton onClick={onOpenConfirmationDialog}>
            <img alt='trash icon' src={Trash} />
          </CircleButton>
          <Box className={classes.rightButtons}>
            <Box style={{ marginRight: '15px' }}>
              <SaveButton
                saveState={saveState}
                type='submit'
              />
            </Box>
            <Link
              to={{ search: backLink() }}
              onClick={() => resetSelectedExercise()}
            >
              <OvalButton
                width={72}
                height={38}
              >
                Close
              </OvalButton>
            </Link>
          </Box>
        </Box>
        <Box className={classes.parentContainer}>
          <Box className={classes.root}>
            <Typography className={classes.exerciseNameStyle}>
              {exercise?.name || 'Exercise Placeholder Name'}
            </Typography>
            <Box className={classes.descriptionCont}>
              <DescriptionDropdown
                toggleMediaVisible={toggleMediaVisible}
                onChange={() => setToggleMediaVisible(prevState => !prevState)}
                descriptionText={exercise?.description || 'Description Placeholder'}
              />
            </Box>
            {/* eslint-disable camelcase */}
            {toggleMediaVisible &&
              <CardMedia
                controls
                component={exercise.video_url ? 'video' : 'img'}
                src={exercise.video_url || exercise.image_url || thumbnailPlaceholder}
                disablePictureInPicture
                controlsList='nodownload'
                onError={e => { e.target.src = thumbnailPlaceholder; }}
              />}
            {/* eslint-enable camelcase */}
          </Box>
          <Box className={classes.tabsContainer}>
            <TabButton
              label='Details'
              isSelected={isDetailsTabSelected}
              onClick={() => setIsDetailsTabSelected(true)}
            />
            <TabButton
              label='Notes'
              isSelected={!isDetailsTabSelected}
              onClick={() => setIsDetailsTabSelected(false)}
            />
          </Box>
          <Divider />
          {/*
            Both Forms need to be rendered on the DOM so that Formik can validate all fields that will be submitted
          */}
          <ExerciseForm
            volumeChoice={volumeChoice}
            onChangeVolumeChoice={onChangeVolumeChoice}
            intensityChoice={intensityChoice}
            onChangeIntensityChoice={onChangeIntensityChoice}
            allowUnilateral={!!exercise?.allow_unilateral}
            exercise={exercise}
            exerciseKey={exerciseKey}
            selectedWorkout={selectedWorkout}
            programContext={programContext}
            isVisible={isDetailsTabSelected}
            formikProps={formikProps}
            setSaveState={setSaveState}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
          />
          <NotesForm
            notes={formikProps.values.notes}
            isVisible={!isDetailsTabSelected}
            formikProps={formikProps}
          />
        </Box>
      </form>
      <ConfirmDialog
        title='Remove Exercise'
        actionButtonTitle='Delete'
        description={`Are you sure you would like to remove "${exercise?.name ?? 'this exercise'}"?`}
        open={showConfirmDeleteDialog}
        onClose={onCloseConfirmationDialog}
        handleConfirmAction={onPressDelete}
      />
    </>
  );
}

export default function EditExercisePanel (props) {
  const { visible, backLink, isAutoSaveEnabled, resetSelectedExercise, viewExerciseDetailsLink } = props;

  return (
    <SlidingFlexTransition
      visible={visible}
    >
      <Panel>
        <FadeTransition visible={visible}>
          <EditExerciseContainer
            isAutoSaveEnabled={isAutoSaveEnabled}
            backLink={backLink}
            resetSelectedExercise={resetSelectedExercise}
            viewExerciseDetailsLink={viewExerciseDetailsLink}
          />
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}
