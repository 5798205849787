import React, {useEffect, useRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import videojs from  'video.js';
import 'video.js/dist/video-js.min.css';
import './workout-media-preview.css';

import {Box, Menu, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {EditUploadButton, FullScreenButton} from './MediaControls';
import FileUploadInput from '../FileUploadInput';
import {colors} from '../../../styles';

const useStyles = makeStyles({
  mediaTopRightControls: {
    position: 'absolute',
    top: 10,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '20%',
    zIndex: 2, // to allow buttons to be clickable
  },
});

function WorkoutMediaPreview (props) {
  const { videoSrc, type, onUploadBegin, isVideoEditable, workoutId } = props;

  const [videoWidth, setVideoWidth] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const inputFileRef = useRef();
  const playerRef = useRef();
  const classes = useStyles();

  function onOpenEditMenu (e) {
    setAnchorEl(e.target);
    setEditButtonClicked(true);
    e.stopPropagation();
  }

  function onCloseEditMenu (e) {
    setAnchorEl(null);
    setEditButtonClicked(false);
    e.stopPropagation();
  }

  function onClickUploadButton (e) {
    inputFileRef.current.click();
    setAnchorEl(null);
    setEditButtonClicked(false);
    e.stopPropagation();
  }

  function onToggleFullScreen (e) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullScreen#browser_compatibility
    playerRef.current.requestFullscreen().catch(e => {
      console.error('Error turning on full screen mode: ', e);
    });
  }

  const onVideoPlay = useCallback((e) => {
    setIsPlaying(true);
  }, []);

  const onVideoPause = useCallback((e) => {
    setIsPlaying(false);
  }, []);

  const onVideoEnd = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const player = videojs(playerRef.current, {
      sources: [{
        src: videoSrc,
        type: type,
      }],
    }, function onPlayerReady() {
        this.on('play', onVideoPlay);
        this.on('pause', onVideoPause);
        this.on('ended', onVideoEnd);
    });

    player.addClass('workout-player');
    setVideoWidth(playerRef.current.clientWidth);

    return () => {
      player.off('play', onVideoPlay);
      player.off('pause', onVideoPause);
      player.off('ended', onVideoEnd);

      // See Removing Players dispose() section
      // https://docs.videojs.com/tutorial-player-workflows.html
      player.dispose();
    };

  }, [videoSrc, type, onVideoPlay, onVideoPause, onVideoEnd]);

  return (
    // https://github.com/videojs/video.js/issues/4935
    <div data-vjs-player={true} style={{ position: 'relative' }}>
      <video-js
        ref={playerRef}
        className={'video-js'}
        controls={true}
        disablePictureInPicture={true}
        preload={'auto'}
        width={526}
        height={235}
        key={workoutId}
      />
      {/* Needs to be set to the width of its sibling container  - video-js */}
      <Box className={classes.mediaTopRightControls} style={{
        width: videoWidth,
        visibility: isPlaying ? 'hidden' : 'visible',
      }}>
        <UploadButton
          isVideoEditable={isVideoEditable}
          editButtonClicked={editButtonClicked}
          anchorEl={anchorEl}
          inputFileRef={inputFileRef}

          onOpenEditMenu={onOpenEditMenu}
          onCloseEditMenu={onCloseEditMenu}
          onClickUploadButton={onClickUploadButton}
          onUploadBegin={onUploadBegin}
        />
        <FullScreenButton onClick={onToggleFullScreen}/>
      </Box>
    </div>
  );
}

const useUploadButtonStyles = makeStyles({
  editMenu: {
    width: 114,
    paddingTop: 0,
    paddingBottom: 0,
  },
  editMenuItem: {
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
    '&.Mui-focusVisible': {
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: colors.selected_highlight_copy_6,
      },
    },
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 2.6,
    display: 'flex',
    justifyContent: 'center',
  },
  popoverPaper: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.22)',
    borderRadius: '6px',
    border: `solid 1px ${colors.selected_highlight_copy_6}`,
  },
  marginSpacing: {
    marginTop: 10,
  },
});

/**
 * @return {JSX|null}
 */
function UploadButton (props) {
  const {
    isVideoEditable,
    editButtonClicked,
    anchorEl,
    inputFileRef,

    onOpenEditMenu,
    onCloseEditMenu,
    onClickUploadButton,
    onUploadBegin,
  } = props;

  const classes = useUploadButtonStyles();

  if (!isVideoEditable) {
    return null;
  }

  return (
    <EditUploadButton
      onClick={onOpenEditMenu}
      isClicked={editButtonClicked}
    >
      <Menu
        className={classes.marginSpacing}
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onCloseEditMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{ className: classes.editMenu }}
        PopoverClasses={{ paper: classes.popoverPaper }}
      >
        <MenuItem className={classes.editMenuItem} onClick={onClickUploadButton}>
          <FileUploadInput
            id='media-edit-upload'
            inputFileRef={inputFileRef}
            onUploadBegin={onUploadBegin}
            supportedFileTypes={[
              'video/quicktime', // MOV file
              'video/mp4',
            ]}
          />
          Replace
        </MenuItem>
      </Menu>
    </EditUploadButton>
  );
}

WorkoutMediaPreview.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isVideoEditable: PropTypes.bool.isRequired,
  onUploadBegin: PropTypes.func.isRequired,
  workoutId: PropTypes.string,
};

export default WorkoutMediaPreview;