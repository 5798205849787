import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@material-ui/core/styles';

import SubscriptionToggleButton from '../components/Buttons/SubscriptionToggleButton';
import PlanCard from '../components/View Subscriptions/PlanCard';
import { colors } from '../styles';
import BackgroundImg from '../resources/image-background-subcriptions@2x.png';

import { UA_PERMISSION_TYPES } from '../constants';
import {
  basePlan,
  plusPlan,
  proPlan,
  subscriptionFrequencies,
} from '../components/View Subscriptions/SubscriptionPlanConstants';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  height: 'auto',
  minHeight: '100%',
  width: '100%',
  backgroundImage: `url(${BackgroundImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionX: 'center',
});

const HeaderContainer = styled('div')({
  marginBottom: '25px',
});

const MainHeader = styled('div')({
  fontSize: '40px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: colors.carbon_black,
  lineHeight: '49px',
  letterSpacing: '-0.4px',
});

const SecondHeader = styled('div')({
  fontSize: '40px',
  color: colors.radio_blue,
  textAlign: 'center',
  lineHeight: '49px',
  letterSpacing: '-0.4px',
});

const ThirdHeader = styled('div')({
  fontSize: '24px',
  color: colors.steel,
  textAlign: 'center',
  lineHeight: '49px',
  letterSpacing: '-0.24px',
});

const PlanCardContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-evenly',
  margin: '6% 0',
  width: '100%',
  flexWrap: 'wrap',
});


function SubscriptionPlans () {
  const [subscriptionFrequency, setSubscriptionFrequency] = useState(subscriptionFrequencies.MONTHLY);

  const currentSubscription = useSelector(state => state.currentUser.subscription);

  const onChangeSubscriptionFrequency = (frequency) => {
    setSubscriptionFrequency(frequency);
  };

  return (
    <Root>
      <HeaderContainer>
        <MainHeader>Get more from EDGE</MainHeader>
        <SecondHeader>Choose the plan that’s best for you</SecondHeader>
        <ThirdHeader>Open the EDGE mobile app to manage your subscription.</ThirdHeader>
      </HeaderContainer>
      <SubscriptionToggleButton
        subscriptionFrequency={subscriptionFrequency}
        onChangeSubscriptionFrequency={onChangeSubscriptionFrequency}
      />
      <PlanCardContainer>
        <PlanCard
          currentPlan={!currentSubscription || currentSubscription === UA_PERMISSION_TYPES.EDGE_TRAINER_ACCESS}
          tabText={!currentSubscription || currentSubscription === UA_PERMISSION_TYPES.EDGE_TRAINER_ACCESS
            ? 'Current Plan'
            : null
          }
          headerText="Base"
          subHeaderText="Essential Features"
          price={subscriptionFrequency === subscriptionFrequencies.MONTHLY ? basePlan.monthly : basePlan.yearly}
          perText='/ Manage 1 Client Free'
          perks={basePlan.perks}
        />
        <PlanCard
          currentPlan={currentSubscription === UA_PERMISSION_TYPES.EDGE_TRAINER_PLUS}
          tabText={currentSubscription === UA_PERMISSION_TYPES.EDGE_TRAINER_PLUS
            ? 'Current Plan'
            : null
          }
          headerText="Plus"
          headerTextColor={colors.radio_blue}
          subHeaderText="Essential Features Plus"
          price={subscriptionFrequency === subscriptionFrequencies.MONTHLY ? plusPlan.monthly : plusPlan.yearly}
          perText={`/ per ${subscriptionFrequency === subscriptionFrequencies.MONTHLY ? 'month' : 'year'}`}
          perSubHeader={`billed every 
          ${subscriptionFrequency === subscriptionFrequencies.MONTHLY 
            ? 'month' 
            : '12 months'}`
          }
          perks={plusPlan.perks}
        />
        <PlanCard
          currentPlan={currentSubscription === UA_PERMISSION_TYPES.EDGE_TRAINER_PRO}
          tabText={currentSubscription === UA_PERMISSION_TYPES.EDGE_TRAINER_PRO
            ? 'Current Plan'
            : 'Recommended by NASM'
          }
          headerText="Pro"
          headerTextColor={colors.radio_blue}
          subHeaderText="Advanced Features"
          price={subscriptionFrequency === subscriptionFrequencies.MONTHLY ? proPlan.monthly : proPlan.yearly}
          perText={`/ per ${subscriptionFrequency === subscriptionFrequencies.MONTHLY ? 'month' : 'year'}`}
          perSubHeader={`billed every 
          ${subscriptionFrequency === subscriptionFrequencies.MONTHLY 
            ? 'month' 
            : '12 months'}`
          }
          perks={proPlan.perks}
        />
      </PlanCardContainer>
    </Root>
  );
}

export default SubscriptionPlans;
