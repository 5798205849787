import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

// Local
import { colors } from '../styles';
import { formatNavTitle } from '../util/utilFunctions';

const useStyles = makeStyles({
  container: props => ({
    display: 'flex',
    alignItems: 'center',
    background: props?.selectedProfile?.ClubId ? colors.charcoal_grey : props?.color ?? colors.lightBlue,
    height: props?.height ?? '80px',
    paddingLeft: 133,
  }),
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 0.98,
    letterSpacing: -0.4,
    fontFamily: 'DMSans, sans-serif',
    color: colors.white,
  },
  subtitle: {
    fontSize: 40,
    fontFamily: 'DMSans, sans-serif',
    fontWeight: 'normal',
    opacity: 0.75,
    color: colors.white,
    letterSpacing: -0.4,
    paddingLeft: '17px',
  },
});

export default function PageHeader ({ title, subtitle, ...props }) {
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);
  const classes = useStyles({...props, selectedProfile});
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {formatNavTitle(title)}
        {!!subtitle && <span className={classes.subtitle}>{`${subtitle}`}</span>}
        {selectedProfile?.ClubId && <span className={classes.subtitle}>{`${selectedProfile?.ClubName}`}</span>}
      </div>
      {props.children}
    </div>
  );
}
