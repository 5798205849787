import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import GroupScheduleNavigator from './GroupScheduleNavigator';

export default function Groups (props) {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/my-groups`} />
        </Route>
        <Route path={`${path}/my-groups`}>
          <GroupScheduleNavigator />
        </Route>
        {/* Redirect to my groups if path subsection is invalid */}
        <Route path={`${path}/*`}>
          <Redirect to={`${path}/my-groups`} />
        </Route>
      </Switch>
    </>
  );
}
