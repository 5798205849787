import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { colors } from '../../styles';
import { ReactComponent as RightArrow } from '../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg';

const ContainerBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 10px',
  margin: '30px 0',
  width: '100%',
  height: '40px',
  border: `1px solid ${colors.selected_highlight_copy_6}`,
  borderRadius: '3px',
  cursor: 'pointer',
  '&:last-child': {
    marginBottom: '60px',
  },
});

const Setting = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '14px',
});

function AccountSettingBox (props) {
  const { settingText, onClickSetting } = props;
  return (
    <ContainerBox onClick={onClickSetting}>
      <Setting>{settingText}</Setting>
      <RightArrow />
    </ContainerBox>
  );
}

export default AccountSettingBox;

AccountSettingBox.propTypes = {
  settingText: PropTypes.string,
  onClickSetting: PropTypes.func,
};
