import beginnersImg from '../../../resources/schedulePreviewImages/image-beginners@2x.png';
import generalFitnessImg from '../../../resources/schedulePreviewImages/image-general-fitness@2x.png';
import intervalTrainingImg from '../../../resources/schedulePreviewImages/image-interval-training@2x.png';
import lifestyleImg from '../../../resources/schedulePreviewImages/image-lifestyle@2x.png';
import maximalStrengthImg from '../../../resources/schedulePreviewImages/image-maximal-strength@2x.png';
import muscleGainImg from '../../../resources/schedulePreviewImages/image-muscle-gain@2x.png';
import powerImg from '../../../resources/schedulePreviewImages/image-power@2x.png';
import stabilizationEnduranceImg from '../../../resources/schedulePreviewImages/image-stabilization-endurance@2x.png';
import wakeupWorkoutImg from '../../../resources/schedulePreviewImages/image-wakeup-workout@2x.png';

export const SchedulePreviewImages = Object.freeze({
  Beginners: beginnersImg,
  'General Fitness': generalFitnessImg,
  'Interval Training': intervalTrainingImg,
  Lifestyle: lifestyleImg,
  'Maximal Strength': maximalStrengthImg,
  'Muscle Gain': muscleGainImg,
  Power: powerImg,
  'Stabilization Endurance': stabilizationEnduranceImg,
  'Wakeup Workout': wakeupWorkoutImg,
});