import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  cardDefaultContent: props => ({
    width: props.width || 'auto',
    height: props.height || 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
  }),
});


// Used when no content is available in Measurements, Performance Assessments, or Overhead Squat cards
function CardDefaultContent (props) {
  const {label} = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.cardDefaultContent}>
      <Typography>
        {label}
      </Typography>
    </Box>
  );
}

CardDefaultContent.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CardDefaultContent;