import request from '../nasm_sdk_v2/request';
import axios from 'axios';

export default function nasmWorkouts(apiVer) {
  function findWorkouts(searchParams, cancelToken, apiVersion = apiVer) {
    return request
      .get(`${apiVersion}/api/nasm-workouts`, {
        params: searchParams,
        cancelToken: cancelToken,
      })
      .catch(e => {
        if(axios.isCancel(e)) {
          // swallow cancel request error
          return Promise.resolve({
            isRequestCancelled: true,
          });
        } else {
          return Promise.reject(e);
        }
      });
  }

  function getWorkout(workoutId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/nasm-workouts/${workoutId}`);
  }

  function createWorkout(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/nasm-workouts`, data);
  }

  function updateWorkout(workoutId, data, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/nasm-workouts/${workoutId}`, data);
  }

  function deleteWorkout(workoutId, apiVersion = apiVer) {
    return request.delete(`${apiVersion}/api/nasm-workouts/${workoutId}`);
  }

  function getWorkoutFilters(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/nasm-workouts/filters`);
  }

  return {
    findWorkouts,
    getWorkout,
    createWorkout,
    updateWorkout,
    deleteWorkout,
    getWorkoutFilters,
  };
}