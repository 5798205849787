import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { colors } from '../../styles';
import placeholderIcon from '../../resources/user-dashboard-client-profile-with-no-photo@2x.png';

const useStyles = makeStyles({
  root: props => ({
    margin: props.margin ? props.margin : '0 40px 40px 0',
    opacity: props.isActive ? 1 : 0.25,
  }),
  outerCircle: props => ({
    width: '88px',
    height: '88px',
    borderRadius: '44px',
    border: `2px solid ${colors.yellow_button_style}`,
    boxShadow: '0 3px 8px 0 rgba(31, 33, 36, 0.2)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      filter: props.isActive ? 'brightness(110%)' : 'brightness(100%)',
    },
  }),
  profileImg: {
    width: '76px',
    height: '76px',
    objectFit: 'cover',
    borderRadius: '38px',
  },
  profileName: {
    textAlign: 'center',
    fontWeight: 500,
    marginTop: 4,
  },
});

function ClientIcon (props) {
  const {
    client,
    isActive = false,
    onClick,
    showName = true,
    margin,
  } = props;
  const { first_name: firstName, avatar_url: imgUrl } = client;

  const classes = useStyles({ isActive, margin });

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.outerCircle}>
        <img
          className={classes.profileImg}
          src={imgUrl || placeholderIcon}
          onError={e => { e.target.src = placeholderIcon; }}
          alt='profile'
        />
      </Box>
      {
        showName
          ? <Typography className={classes.profileName}>{firstName}</Typography>
          : null
      }
    </Box>
  );
}

ClientIcon.propTypes = {
  client: PropTypes.shape({
    first_name: PropTypes.string,
    avatar_url: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  showName: PropTypes.bool,
  margin: PropTypes.string,
};

export default ClientIcon;
