import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { StatelessDropdown, TextInput } from '../Inputs';
import { INTENSITY_OPTIONS, TEMPO_MAPPINGS, PACE_OPTIONS } from '../../constants';

const useComboBoxStyle = makeStyles({
  root: props => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: props.marginTop || 0,
    marginBottom: props.marginBottom || 0,
    marginLeft: props.marginLeft || 0,
    marginRight: props.marginRight || 0,
  }),
});

// Intensity is mandatory when volume type is distance!
// otherwise, it can be set to n/a for all other volume types
function IntensityCombobox (props) {
  const {
    intensityChoice,
    onChangeIntensityChoice,
    tempo,
    onChangeTempo,
    paceValue,
    onChangePaceValue,
    paceUnits,
    onChangePaceUnits,
    onResetValues,
    resetFieldErrors,
    fieldErrors = {},
    touchedFields = {},
  } = props;

  const classes = useComboBoxStyle(props);

  // whenever the intensity type gets changed
  function onChangePrimaryOption (e) {
    onResetValues();
    resetFieldErrors();

    const newOption = e.target.value;
    if (newOption === 'tempo') {
      const slowValue = { target: { value: 1 } };
      onChangeTempo(slowValue);
    } else if (newOption === 'pace') {
      const mphValue = { target: { value: 'mph' } };
      onChangePaceUnits(mphValue);
    }

    onChangeIntensityChoice(e);
  }

  const TempoDropdown = () => {
    if (intensityChoice === 'tempo') {
      return (
        <StatelessDropdown
          currentValue={tempo}
          options={TEMPO_MAPPINGS}
          onOptionChanged={onChangeTempo}
          width='100%'
          dropdownSpacing={10}
        />
      );
    }

    return null;
  };

  // Memoize the component here so that when typing
  // input here doesn't cause a re-render to lose focus as the
  // user is typing in additional digits
  const PaceUnitsTextInput = useMemo(() => {
    if (intensityChoice === 'pace') {
      return (
        <Box style={{ marginRight: 20 }}>
          <TextInput
            flex={1.25}
            placeholder='Amount'
            inputType='number'
            currentValue={paceValue}
            onChange={onChangePaceValue}
            onBlur={onChangePaceValue}
            errorText={fieldErrors.pace}
            showError={touchedFields.pace}
          />
        </Box>
      );
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intensityChoice, paceValue, fieldErrors.pace, touchedFields.pace]);

  const PaceUnitsDropdown = () => {
    if (intensityChoice === 'pace') {
      return (
        <StatelessDropdown
          currentValue={paceUnits}
          options={PACE_OPTIONS}
          onOptionChanged={onChangePaceUnits}
          width='100%'
          dropdownSpacing={10}
        />
      );
    }

    return null;
  };

  return (
    <Box className={classes.root}>
      <Box style={{ marginRight: 20 }}>
        <StatelessDropdown
          onOptionChanged={onChangePrimaryOption}
          currentValue={intensityChoice}
          options={INTENSITY_OPTIONS}
          width='100%'
          dropdownSpacing={10}
          errorText={fieldErrors.intensityChoice}
          showError
        />
      </Box>
      <TempoDropdown />
      {PaceUnitsTextInput}
      <PaceUnitsDropdown />
    </Box>
  );
}

IntensityCombobox.propTypes = {
  intensityChoice: PropTypes.string.isRequired,
  onChangeIntensityChoice: PropTypes.func.isRequired,
  tempo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeTempo: PropTypes.func,
  paceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangePaceValue: PropTypes.func,
  paceUnits: PropTypes.string,
  onChangePaceUnits: PropTypes.func,
  onResetValues: PropTypes.func,
  resetFieldErrors: PropTypes.func,
  fieldErrors: PropTypes.object,
  touchedFields: PropTypes.object,
};

export default IntensityCombobox;
