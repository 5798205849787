import React from 'react';
import PropTypes from 'prop-types';
import OvalButton from './OvalButton';
import {colors} from '../../styles';

function SortButton(props) {
  const { text, margin, onClick, isActive } = props;
  return (
    <OvalButton
      width={122}
      margin={margin}
      onClick={onClick}
      backgroundcolor={isActive ? colors.navy : colors.white}
      color={isActive ? colors.white : colors.steel}
    >
      {text}
    </OvalButton>
  );
}

SortButton.propTypes = {
  text: PropTypes.string.isRequired,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default SortButton;