import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import CardHeader from '../CardHeader';
import CardContainer from '../CardContainer';
import UpdatedAtText from '../UpdatedAtText';
import GoalsController from '../Goals/GoalsController';

import { colors } from '../../../styles';

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 22px',
});

const HeaderText = styled(Typography)({
  fontSize: '20px',
  fontWeight: 500,
  color: colors.white,
  lineHeight: 2.5,
});

function GoalsCard (props) {
  const { loading, focus, milestones, lastUpdated, onClick } = props;
  return (
    <CardContainer
      width={416}
      height={295}
    >
      <CardHeader
        backgroundColor={colors.purple}
        height={55}
        onClick={onClick}
        enableCursorPointer
      >
        <HeaderContainer>
          <HeaderText>Goals</HeaderText>
          <UpdatedAtText date={lastUpdated} margin={'0 0 0 85px'}/>
        </HeaderContainer>
      </CardHeader>
      <GoalsController
        loading={loading}
        focus={focus}
        milestones={milestones}
      />
    </CardContainer>
  );
}

export default GoalsCard;

GoalsCard.propTypes = {
  milestones: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  focus: PropTypes.string,
  loading: PropTypes.bool,
  lastUpdated: PropTypes.string,
};
