import React from 'react';
import PropTypes from 'prop-types';

import {
  EmptyUploadView,
  ExerciseMediaPreview,
  UploadInProgressView,
  UploadProcessingView,
} from './Views';

import {UPLOAD_STATES} from '../../constants';
import validateUploadState from './validateUploadState';

/**
 * @return {JSX|null}
 */
function CurrentExerciseMediaView (props) {
  const {
    uploadState,
    onUploadBegin,
    onCancelMediaUploadRequest,
    canCalculateProgress,
    progressValue,
    pendingFileType,
    fileType,
    imageUrl,
    videoUrl,
  } = props;

  if (uploadState === UPLOAD_STATES.Empty) {
    return (
      <EmptyUploadView
        headerLabel='Upload an Image/Video'
        onUploadBegin={onUploadBegin}
      />
    );
  }

  if (uploadState === UPLOAD_STATES.Uploading) {
    return (
      <UploadInProgressView
        canCalculateProgress={canCalculateProgress}
        progressValue={progressValue}
        onCancelMediaUploadRequest={onCancelMediaUploadRequest}
      />
    );
  }

  if (uploadState === UPLOAD_STATES.Processing) {
    return (
      <UploadProcessingView
        fileType={pendingFileType.split('/')[0]}
      />
    );
  }

  if (uploadState === UPLOAD_STATES.Media_Available) {
    let srcUrl = '';
    if (fileType.includes('image')) {
      if (imageUrl) {
        srcUrl = imageUrl;
      }
    } else if (fileType.includes('video')) {
      if (videoUrl) {
        srcUrl = videoUrl;
      }
    }

    return (
      <ExerciseMediaPreview
        src={srcUrl}
        fileType={fileType}
        onUploadBegin={onUploadBegin}
      />
    );
  }

  return null;
}

CurrentExerciseMediaView.propTypes = {
  uploadState: validateUploadState,
  onUploadBegin: PropTypes.func,
  onCancelMediaUploadRequest: PropTypes.func,
  canCalculateProgress: PropTypes.bool,
  progressValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  pendingFileType: PropTypes.string,
  fileType: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default CurrentExerciseMediaView;