import React, { useState, useCallback, useContext, createContext } from 'react';
import nasmApi from '../api/endpoints';
import axios from 'axios';

const defaultState = {
  loading: false,
  error: null,
  hasMore: false,
  workouts: [],
  pageNumber: 1,
  searchText: '',
  sizePerPage: 10,
  getPublic: false,
  getPrivate: false,
  sortField: '',
  sortOrder: '',
  workoutFilters: '',
  refreshing: false,
};

const WorkoutsContext = createContext(null);

function WorkoutsProvider ({ children }) {
  const [state, dispatch] = useState({ ...defaultState });

  return (
    <WorkoutsContext.Provider value={[state, dispatch]}>
      { children }
    </WorkoutsContext.Provider>
  );
}

function useWorkoutsContext () {
  const [state, dispatch] = useContext(WorkoutsContext);

  const onReceiveWorkouts = useCallback((newWorkouts, refreshing) => {
    dispatch(prev => {
      return {
        ...prev,
        workouts: refreshing ? newWorkouts : [ ...prev.workouts, ...newWorkouts ],
      };
    });
  }, [dispatch]);

  const onError = useCallback((err) => {
    dispatch(prev => {
      return {
        ...prev,
        error: err,
      };
    });
  }, [dispatch]);

  const clearWorkouts = useCallback(() => {
    dispatch(prev => {
      return {
        ...prev,
        workouts: [],
        pageNumber: 1,
      };
    });
  }, [dispatch]);

  const getWorkoutData = useCallback((searchParams, refreshing) => {
    const cancel = axios.CancelToken.source();
    nasmApi.nasmWorkouts
      .findWorkouts(searchParams, cancel.token)
      .then(data => {
        dispatch(prev => {
          return {
            ...prev,
            loading: false,
          };
        });

        if (!data.isRequestCancelled) {
          dispatch(prev => {
            return {
              ...prev,
              refreshing,
              hasMore: data.result.nextPage && data.result.nextPage.length > 0,
            };
          });
          let workouts;
          if (searchParams.getPublic) {
            workouts = data.result.workouts.filter((workout) => workout.is_public && !workout.owner_id);
          } else {
            workouts = data.result.workouts.filter((workout) => !workout.is_public && workout.owner_id);
          }
          onReceiveWorkouts(workouts, refreshing);
        } else {
          dispatch(prev => {
            return {
              ...prev,
              refreshing: false,
              hasMore: false,
            };
          });
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(err => onError(err));
  }, [dispatch, onError, onReceiveWorkouts]);

  const fetchWorkouts = useCallback((filterParams) => {
    dispatch(prev => {
      return {
        ...prev,
        ...filterParams,
        loading: true,
      };
    });

    const { pageNumber } = filterParams;
    const searchParams = buildWorkoutParams(filterParams);
    return getWorkoutData(searchParams, pageNumber === 1);
  }, [dispatch, getWorkoutData]);

  const removeWorkout = useCallback((id) => {
    dispatch(prev => {
      return {
        ...prev,
        workouts: [...prev.workouts.filter((w) => w.id !== id)],
      };
    });
  }, [dispatch]);

  const pendingWorkoutRefresh = useCallback(() => {
    dispatch(prev => {
      return {
        ...prev,
        refreshing: true,
      };
    });
  }, [dispatch]);

  const refreshWorkouts = () => {
    pendingWorkoutRefresh();
    const {
      pageNumber,
      sizePerPage,
      getPublic,
      getPrivate,
      workoutFilters,
      searchText,
      sortField,
      sortOrder,
    } = state;

    const searchParams = buildWorkoutParams({
      pageNumber,
      sizePerPage,
      getPublic,
      getPrivate,
      workoutFilters,
      searchText,
      sortField,
      sortOrder,
    });

    getWorkoutData(searchParams, true);
  };

  return {
    ...state,
    fetchWorkouts,
    clearWorkouts,
    removeWorkout,
    refreshWorkouts,
  };
}

const buildWorkoutParams = (filterParams) => {
  const {
    pageNumber,
    sizePerPage,
    getPublic,
    getPrivate,
    workoutFilters,
    searchText,
    sortField,
    sortOrder,
  } = filterParams;

  const searchParams = {
    page: pageNumber,
    size: sizePerPage,
    getPublic: getPublic,
    getPrivate: getPrivate,
    workoutFilter: workoutFilters,
  };

  if (searchText && searchText.length > 0) {
    searchParams.search = searchText;
  }

  if(sortField && sortOrder && sortField.length > 0 && sortOrder.length > 0) {
    searchParams.sortField = sortField;
    searchParams.sortOrder = sortOrder;
  }

  return searchParams;
};

export {
  useWorkoutsContext,
  WorkoutsProvider,
};