import React from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import progressionIcon from "../resources/progressionIcon.png";
import regressionIcon from "../resources/regressionIcon.png";
import {EXERCISE_TYPE} from "../constants";

const progRegIconStyles = makeStyles({
  progressionRegressionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 76,
    height: 48,
    justifyContent: 'flex-end',
  },
  progressionRegressionIcon: {
    width: 17,
    height: 17,
    marginBottom: 3,
    marginRight: 3,
    marginLeft: 2,
  },
});

const ProgRegIconSet = ({ exercise }) => {
  const classes = progRegIconStyles();

  const hasProgression = exercise?.progressions_regressions?.some(
    (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
  );
  const hasRegression = exercise?.progressions_regressions?.some(
    (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
  );

  if (!exercise?.progressions_regressions?.length) {
    return null;
  }

  return (
    <Box className={classes.progressionRegressionView}>
      {hasProgression ? (
        <img
          src={progressionIcon}
          className={classes.progressionRegressionIcon}
          alt={'Prog'}
          onError={(e) => {
            e.target.src = progressionIcon;
          }}
        />
      ) : null}
      {hasRegression ? (
        <img
          src={regressionIcon}
          className={classes.progressionRegressionIcon}
          alt={'Reg'}
          onError={(e) => {
            e.target.src = regressionIcon;
          }}
        />
      ) : null}
    </Box>
  );
};

export default ProgRegIconSet;
