import { useState, useEffect, useCallback } from 'react';
import nasmApi from '../../api/endpoints';
import { useSelector } from 'react-redux';

export function useFetchAllGoals () {
  const [error, setError] = useState(false);
  const [goals, setGoals] = useState({});

  // The API returns a list of goals, but they need to be
  // in an object to be accepted by the dropdown component

  const createGoalsObj = (goals) => {
    let goalsObj = {};
    goals.forEach(goal => {
      goalsObj[goal.id] = goal.name;
    });
    setGoals(goalsObj);
  };

  useEffect(() => {
    nasmApi.goals.getAllGoals().then((result) => {
      createGoalsObj(result.result);
    }).catch(() => setError(true));
  }, []);

  return {
    goals,
    error,
  };
}

export function useFetchActivityLevels () {
  const [error, setError] = useState(false);
  const [activityLevels, setActivityLevels] = useState({});
  const [allActivityLevels, setAllActivityLevels] = useState([]);

  const createActivityLevelsObj = (levels) => {
    let levelObj = {};
    levels.forEach(level => {
      levelObj[level.id] = {
        label: level.title,
        description: level.description,
      };
    });
    setAllActivityLevels(levels);
    setActivityLevels(levelObj);
  };

  useEffect(() => {
    nasmApi.goals.getActivityLevels().then((result) => {
      createActivityLevelsObj(result.result);
    }).catch(() => setError(true));
  }, []);

  return {
    allActivityLevels,
    activityLevels,
    error,
  };
}

export function useFetchDashboardMilestones (userId) {
  const [milestones, setMilestones] = useState([]);
  const [focus, setFocus] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // For updating the dashboard if the client focus gets updated
  const currentFocus = useSelector(state => state.selectedClient.client?.client_user?.goals[0]);

  useEffect(() => {
    setLoading(true);
    nasmApi.dashboard.getDashboardMilestones(userId).then((result) => {
      setMilestones(result.result.milestones);
      setFocus(result.result.goal.name);
      setLastUpdated(result.result.latest_activity_date);
    }).catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [userId, currentFocus]);

  return {
    milestones,
    focus,
    lastUpdated,
    loading,
    error,
  };
}

export function useFetchAllMilestones (userId) {
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAllMilestones = useCallback((userId) => {
    setLoading(true);
    nasmApi.goals.getAllMilestones(userId).then((result) => {
      setMilestones(result.result);
    }).catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getAllMilestones(userId);
  }, [userId, getAllMilestones]);

  return {
    milestones,
    loading,
    error,
    getAllMilestones,
  };
}

export default {
  useFetchAllGoals,
  useFetchDashboardMilestones,
};