const DEFAULT_SUBJECT = 'User Feedback - Web';

const NASM_ROLES_TO_FEEDBACK_FORM = {
  TRAINER: 'Trainer',
  CLIENT: 'Client',
};

// Environment variables come in as strings and this field needs to be a Number
// to work properly in the Widget.
const GROUP_ID = parseInt(process.env.REACT_APP_FRESH_DESK_GROUP_ID);

export function resetWidget() {
  if (window.FreshworksWidget) {
    window.FreshworksWidget('destroy');
    window.FreshworksWidget('boot');
  }
}

export function defaultHideContactFormFields() {
  if (!window.FreshworksWidget) { return; }
  window.FreshworksWidget('hide', 'ticketForm', [
    'subject',
    'group_id',
    'custom_fields.cf_ua_guid',
    'custom_fields.cf_ua_id',
    'custom_fields.cf_edge_user_id',
    'custom_fields.cf_user_role',
    'custom_fields.cf_subscription_expiration',
    'custom_fields.cf_ua_permissions',
  ]);
}

export function hideIdentityContactFormFields() {
  if (!window.FreshworksWidget) { return; }
  window.FreshworksWidget('hide', 'ticketForm', ['email', 'name']);
}

export function identifyContactForm(email, name) {
  if (!window.FreshworksWidget) { return; }
  window.FreshworksWidget('identify', 'ticketForm', {
    email,
    name,
  });
}

export function prefillDefaultFormFields(env, isLoggedIn) {
  if (!window.FreshworksWidget) { return; }
  const subject = buildSubject(env, isLoggedIn);
  window.FreshworksWidget('prefill', 'ticketForm', {
    subject,
    group_id: GROUP_ID,
  });
}

export function prefillFormFields(env, isLoggedIn, user) {
  if (!window.FreshworksWidget) { return; }
  const subject = buildSubject(env, isLoggedIn);
  const uaPerms = user.permissions?.map(perm => perm.name).join('\n');
  window.FreshworksWidget('prefill', 'ticketForm', {
    subject,
    custom_fields: {
      cf_edge_user_id: user.id,
      cf_ua_guid: user.ua_guid,
      cf_ua_id: user.ua_id,
      cf_user_role: NASM_ROLES_TO_FEEDBACK_FORM[user.nasm_role],
      cf_subscription_expiration: user.subscription_expiration_date,
      cf_ua_permissions: uaPerms,
    },
  });
}

export function toggleWidgetVisibility(show) {
  if (!window.FreshworksWidget) { return; }
  const visibility = show ? 'show' : 'hide';
  window.FreshworksWidget(visibility);
}

function buildSubject(env, isLoggedIn) {
  let environment = '';
  let unauthenticated = isLoggedIn ? '' : ' - Unauthenticated';

  if (!env) {
    environment = '[DEV] ';
  } else if (env === 'PROD') {
    environment = '';
  } else {
    environment = `[${env}] `;
  }

  return `${environment}${DEFAULT_SUBJECT}${unauthenticated}`;
}
