import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, styled} from '@material-ui/core/styles';
import {colors} from '../../../styles';
import {Box, Typography} from '@material-ui/core';



const useOhsaContentStyles = makeStyles({
  section: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 20px',
  },
  bulletList: {
    margin: '0 0 15px 0',
    paddingInlineStart: 5,
    color: colors.dark_grey,
  },
  spacing: {
    marginLeft: 10,
  },
});

const LabelText = styled(Typography)({
  fontSize: 14,
  color: colors.carbon_black,
});

function OhsaContent (props) {
  const { icon: Icon, content = [], label } = props;
  const classes = useOhsaContentStyles();

  return (
    <Box className={classes.section}>
      <Icon />
      <Box className={classes.spacing}>
        <LabelText>{label}</LabelText>
        <ul className={classes.bulletList}>
          {content.map(c => <li key={c.id}>{c.name}</li>)}
        </ul>
      </Box>
    </Box>
  );
}

OhsaContent.propTypes = {
  /***
   * SVG icon component
   */
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_overactive: PropTypes.number,
  })),
};

export default OhsaContent;