import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ErrorText from './ErrorText';
import { colors } from '../../styles';

const useStyles = makeStyles({
  textRoot: props => ({
    display: 'flex',
    flexDirection: 'column',
    flex: props.flex ? props.flex : 1,
  }),
  inputStyle: props => ({
    flex: props.flex ? props.flex : 1,
    width: props.width ? props.width : 232,
    border: props.showError && !!props.errorText ? `solid 1px ${colors.yellow_button_style}` : 'solid 1px #d2d7db',
    boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.18)',
    borderRadius: 3,
    height: 40,
    padding: '0 10px 0 10px',
    marginLeft: props.sideMargin ? props.sideMargin : 0,
    marginRight: props.sideMargin ? props.sideMargin : 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'Roboto, sans-serif',
    // Turns off number spinners ~ may not work on all browsers
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      marginRight: 20,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      marginRight: 20,
    },
  }),
});

function TextInput (props) {
  const classes = useStyles(props);
  const {
    placeholder = '',
    currentValue,
    onChange,
    onBlur,
    errorText,
    showError,
    inputType = 'text',
    width,
  } = props;

  return (
    <Box className={classes.textRoot}>
      <Box>
        <FormControl style={{ width: width ?? '100%' }}>
          <InputBase
            placeholder={placeholder}
            value={currentValue}
            inputProps={{
              className: classes.inputStyle,
              type: inputType,
            }}
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormControl>
      </Box>
      <ErrorText
        textColor={colors.yellow_button_style}
        errorText={errorText}
        showError={showError}
      />
    </Box>
  );
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
  inputType: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextInput;
