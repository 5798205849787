/*  eslint-disable global-require */
import moment from 'moment';
const pJson = require('../package.json');

export const VERSION_NUMBER = pJson.version;

export const ROLES = {
  TRAINER: 'PRACTITIONER',
  CLIENT: 'ATHLETE',
};

export const CERT_CATEGORIES = {
  CERT: 'CERT',
  MASTER: 'MASTER',
  SPECIAL: 'SPECIAL',
};

export const EXERCISE_CATEGORIES = {
  INHIBIT: 'INHIBIT',
  LENGTHEN: 'LENGTHEN',
  ACTIVATE: 'ACTIVATE',
  INTEGRATE: 'INTEGRATE',
};

export const EXERCISE_FILTER_CATEGORIES = {
  BODY_REGION: 'bodyRegion',
  FITNESS_COMPONENTS: 'fitnessComponents',
};

export const SAVE_STATES = {
  CLICKABLE: 'clickable',
  LOADING: 'loading',
  SAVED: 'saved',
  ERROR: 'error',
};

// export const CERT_ICONS = [
//   {
//     name: 'CNC',
//     icon: require('./resources/credentialsCnc.png'),
//     lockedIcon: require('./resources/credentialsCncOff.png'),
//   },
//   {
//     name: 'CPT',
//     icon: require('./resources/credentialsNasmCpt.png'),
//     lockedIcon: require('./resources/credentialsNasmCptOff.png'),
//   },
//   {
//     name: 'CES',
//     icon: require('./resources/credentialsCes.png'),
//     lockedIcon: require('./resources/credentialsCesOff.png'),
//   },
//   {
//     name: 'PES',
//     icon: require('./resources/credentialsPes.png'),
//     lockedIcon: require('./resources/credentialsPesOff.png'),
//   },
//   {
//     name: 'NASM MASTER TRAINER',
//     icon: require('./resources/masterTrainerOn.png'),
//     lockedIcon: require('./resources/masterTrainerOff.png'),
//   },
//   {
//     name: 'NASM MASTER INSTRUCTOR',
//     icon: require('./resources/masterInstructorOn.png'),
//     lockedIcon: require('./resources/masterInstructorOff.png'),
//   },
//   {
//     name: 'FNS',
//     icon: require('./resources/credentialsFns.png'),
//     lockedIcon: require('./resources/credentialsFnsOff.png'),
//   },
//   {
//     name: 'BCS',
//     icon: require('./resources/credentialsBcs.png'),
//     lockedIcon: require('./resources/credentialsBcsOff.png'),
//   },
//   {
//     name: 'WLS',
//     icon: require('./resources/credentialsWls.png'),
//     lockedIcon: require('./resources/credentialsWlsOff.png'),
//   },
//   {
//     name: 'GPT',
//     icon: require('./resources/credentialsGpt.png'),
//     lockedIcon: require('./resources/credentialsGptOff.png'),
//   },
//   {
//     name: 'WFS',
//     icon: require('./resources/credentialsWfs.png'),
//     lockedIcon: require('./resources/credentialsWfsOff.png'),
//   },
//   {
//     name: 'YES',
//     icon: require('./resources/credentialsYes.png'),
//     lockedIcon: require('./resources/credentialsYesOff.png'),
//   },
//   {
//     name: 'SFS',
//     icon: require('./resources/credentialsSfs.png'),
//     lockedIcon: require('./resources/credentialsSfsOff.png'),
//   },
//   {
//     name: 'GFS',
//     icon: require('./resources/credentialsGfs.png'),
//     lockedIcon: require('./resources/credentialsGfsOff.png'),
//   },
//   {
//     name: 'MMACS',
//     icon: require('./resources/credentialsMma.png'),
//     lockedIcon: require('./resources/credentialsMmaOff.png'),
//   },
// ];

export const CERT_URLS = [
  {
    name: 'CNC',
    url: 'https://www.nasm.org/products/CNC301K',
  },
  {
    name: 'CPT',
    url: 'https://www.nasm.org/how-to-become-a-personal-trainer',
  },
  {
    name: 'CES',
    url: 'https://www.nasm.org/injury-prevention/why-corrective-exercise',
  },
  {
    name: 'PES',
    url: 'https://www.nasm.org/performance-training/sports-performance-training',
  },
  {
    name: 'NASM MASTER TRAINER',
    url: 'https://www.nasm.org/continuing-education/master-trainer',
  },
  {
    name: 'NASM MASTER INSTRUCTOR',
    url: 'https://www.nasm.org/continuing-education/master-instructors',
  },
  {
    name: 'FNS',
    url: 'https://www.nasm.org/products/FNS105K',
  },
  {
    name: 'BCS',
    url: 'https://www.nasm.org/products/CEU151K',
  },
  {
    name: 'WLS',
    url: 'https://www.nasm.org/products/CEU161K',
  },
  {
    name: 'GPT',
    url: 'https://www.nasm.org/products/CEU152K',
  },
  {
    name: 'WFS',
    url: 'https://www.nasm.org/products/CEU162K',
  },
  {
    name: 'YES',
    url: 'https://www.nasm.org/products/CEU142K',
  },
  {
    name: 'SFS',
    url: 'https://www.nasm.org/products/CEU140K',
  },
  {
    name: 'GFS',
    url: 'https://www.nasm.org/products/CEU139K',
  },
  {
    name: 'MMACS',
    url: 'https://www.nasm.org/products/CEU143K',
  },
];

export const CREATE_USER_FLOW = {
  CREATE: 'CREATE',
  INVITE: 'INVITE',
  ERROR: 'ERROR',
};

export const ProgramStatuses = {
  ACTIVE: 1,
  COMPLETED: 2,
};

export function capitalizeString (string) {
  if (!string) {
    return;
  }
  return string.replace(/\b[\w']+\b/g, function (text) {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });
}

export function isDateInThePast (date) {
  if (!date) {
    return false;
  }
  return moment().isAfter(moment(date));
}

export function sortCorrectiveExercises (correctiveExercises) {
  if (!correctiveExercises || correctiveExercises.length === 0) {
    return [];
  }

  const inhibit = [];
  const lengthen = [];
  const activate = [];
  const integrate = [];

  correctiveExercises.forEach(section => {
    Object.values(section).forEach(exercises => {
      exercises.forEach(exercise => {
        switch (exercise.exercise_category) {
          case EXERCISE_CATEGORIES.INHIBIT:
            inhibit.push(exercise);
            break;

          case EXERCISE_CATEGORIES.LENGTHEN:
            lengthen.push(exercise);
            break;

          case EXERCISE_CATEGORIES.ACTIVATE:
            activate.push(exercise);
            break;

          case EXERCISE_CATEGORIES.INTEGRATE:
            integrate.push(exercise);
            break;

          default:
            break;
        }
      });
    });
  });

  return inhibit.concat(lengthen).concat(activate).concat(integrate);
}

export function calculateCalories (protein = 0, carbs = 0, fat = 0) {
  return (protein * 4) + (carbs * 4) + (fat * 9);
}

export function debounce (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this; var args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function passwordRecoveryUAURL () {
  let envUrl = '';
  switch (process.env.REACT_APP_DEPLOY_ENV) {
    case 'DEV':
      envUrl = 'https://dev2-auth.nasm.org/reset_password?service=http://www.nasm.org';
      break;
    case 'STAGE':
      envUrl = 'https://dev2-auth.nasm.org/reset_password?service=http://www.nasm.org';
      break;
    case 'PROD':
      envUrl = 'https://auth.nasm.org/reset_password?service=http://www.nasm.org';
      break;
    default:
      envUrl = 'https://auth.nasm.org/reset_password?service=http://www.nasm.org';
      break;
  }
  return envUrl;
}

export const VARIABLE_DESCRIPTIONS = {
  tempo: {
    slow: 'Slow tempo reps can be performed for 6-7 seconds. For example, they can be performed 3/2/1 or 4/2/1.',
    medium: 'Medium tempo reps are to be performed from 4-6 seconds i.e. 2/0/2.',
    fast: 'Fast tempo reps are to be performed from 2-4 seconds. Fast reps should also be performed under control.',
    explosive: `Explosive reps are performed as fast as possible or AFAP. 
    Explosive reps should also be performed under control.`,
  },
  rest_tempo: {
    slow: `This is an active recovery in which the exerciser is still in motion. 
    This may be used during the rest interval of any interval training program.`,
    rest: `This is a “complete” recovery period in which the 
    exerciser is not required to move in between sets or exercises.`,
    'n/a': 'N/A',
  },
  exercise_sides: {
    right_only: 'The exerciser should perform reps only on the right side in a set.',
    left_only: 'The exerciser should perform reps only on the left side in a set.',
    alternate: `The exerciser should alternate reps on each side for the duration of a set i.e. in a Step-Up exercise, 
    one would step up with the right foot first and on the following rep step up with the left.`,
    each_side: `The exerciser should perform all the reps of an exercise on one side and 
    then immediately perform the same amount of reps on the opposite side.`,
  },
};

export const TEMPO_MAPPINGS = {
  1: 'Slow',
  2: 'Medium',
  3: 'Fast',
  4: 'Explosive',
};

export const REST_TEMPO = {
  rest: 'Rest',
  slow: 'Slow',
  'n/a': 'N/A',
};

export const VOLUME_OPTIONS = {
  reps: 'Reps',
  duration: 'Duration',
  distance: 'Distance',
};

export const VOLUME_KEYS = Object.keys(VOLUME_OPTIONS);

export const DISTANCE_UNIT_OPTIONS = {
  miles: 'Miles',
  yards: 'Yards',
  meters: 'Meters',
};

export const INTENSITY_OPTIONS = {
  pace: 'Pace',
  tempo: 'Tempo',
  // need to test edit exercise panel and see how this reacts there
  'n/a': 'N/A',
};

export const INTENSITY_KEYS = Object.keys(INTENSITY_OPTIONS);

export const PACE_OPTIONS = {
  mph: 'mph',
  rpm: 'rpm',
  watts: 'watts',
};

export const EXERCISE_SIDES = {
  right_only: 'Right Only',
  left_only: 'Left Only',
  alternate: 'Alternate',
  each_side: 'Each Side',
};

// Relates to trainer subscription types
// Possible Improvement: NASM UA should provide an endpoint that retrieves all possible
// permission types
export const UA_PERMISSION_TYPES = {
  EDGE_USER: 'edge_user',
  EDGE_EXAM_PREP_PACKAGE_CPT: 'edge_exam_prep_package_cpt',
  EDGE_TRAINER_ACCESS: 'edge_trainer_access',
  EDGE_TRAINER_PLUS: 'edge_trainer_plus',
  EDGE_TRAINER_PRO: 'edge_trainer_pro',
  EDGE_TRAINER_FOUNDATION: 'edge_trainer_foundation',
};

export const USER_ROLES = {
  TRAINER: 'TRAINER',
  CLIENT: 'CLIENT',
};

export const ASSESSMENT_TYPES = Object.freeze({
  CARDIO: 'Cardio',
  ENDURANCE: 'Endurance',
  STRENGTH: 'Strength',
});

export const UPLOAD_STATES = Object.freeze({
  Empty: 'empty',
  Uploading: 'uploading',
  Processing: 'processing',
  Media_Available: 'media_available',
  Error: 'error',
});

export const termsPrivacyDialogTextOptions = {
  TERMS: 'terms-conditions',
  PRIVACY: 'privacy-policy',
};

export const UPLOADED_MEDIA_STATUS_TYPES = Object.freeze({
  FINISHED: 'FINISHED',//1,
  PENDING: 'PENDING', //2,
  ERROR: 'ERROR', //3,
});

export const CALC_VISIBILITY_STATES = {
  NONE: 'NONE',
  BWP: 'BWP',
  BMI: 'BMI',
  ONE_RM: 'ONE_RM',
  MHR: 'MHR',
  BodyFat: 'BodyFat',
};

export const FEATURE_FLAGS  = {
  CLUB_CONNECT_MULTI_LOCATION_ENABLED: false,
};

export const mobileRoutes = [
  '/deleteinstruction',
  '/delete-account',
  '/terms-and-conditions',
  '/privacy-policy',
];

export const EXERCISE_TYPE = {
  PROGRESSION: 'PROGRESSION',
  REGRESSION: 'REGRESSION',
};