import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import { makeStyles } from '@material-ui/core';
import { Dialog, Box } from '@material-ui/core';

import nasmApi from '../../api/endpoints';
import { termsPrivacyDialogTextOptions } from '../../constants';

import ActionButton from '../Buttons/ActionButton';
import OvalButton from '../Buttons/OvalButton';
import { colors } from '../../styles';

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'flex-start',
  },
  root: {
    height: '830px',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
    padding: '22px 22px 0 22px',
  },
  buttonHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '16px',
  },
  buttonContainer: {
    marginRight: '16px',
  },
  header: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '38px',
  },
  termsContainer: {
    fontSize: '17px',
    fontFamily: 'Roboto, sans-serif',
    width: '100%',
    overflowY: 'auto',
    padding: '0 46px',
  },
  privacyHeader: {
    fontSize: '34px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'bold',
  },
});

function TermsConditionsDialog (props) {
  const { isOpen, onClose, closeBtnText, textType, onAcceptAndContinue, loading } = props;
  const classes = useStyles();

  const [terms, setTerms] = useState(null);

  const fetchTerms = () => {
    nasmApi.terms.termsConditions().then((termsText) => {
      setTerms(termsText.data);
    }).catch(() => {
      setTerms('Could not load terms and conditions');
    });
  };

  const fetchPrivacy = () => {
    nasmApi.terms.privacyPolicy().then((privacyText) => {
      setTerms(privacyText.data);
    }).catch(() => {
      setTerms('Could not load privacy policy');
    });
  };

  // Fetch Terms and Conditions or Privacy Policy text
  // from the API on first mount
  useEffect(() => {
    if (textType === termsPrivacyDialogTextOptions.TERMS) {
      fetchTerms();
    } else {
      fetchPrivacy();
    }
  }, [textType]);

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.root,
        scrollPaper: classes.scrollPaper,
      }}
      maxWidth='md'
      BackdropProps={{ style: { backgroundColor: colors.backdrop_tint, opacity: 0.35 } }}
    >
      <Box className={classes.buttonHeader}>
        {
          onAcceptAndContinue
          ? <Box className={classes.buttonContainer}>
              <ActionButton
                loading={loading}
                label='Accept and Continue'
                width={225}
                onClick={onAcceptAndContinue}
              />
            </Box>
          : null
        }
        <OvalButton onClick={onClose}>{closeBtnText || 'Close'}</OvalButton>
      </Box>
      <Box className={classes.termsContainer}>
        <ReactMarkdown children={terms} />
      </Box>
    </Dialog>
  );
}

export default TermsConditionsDialog;

TermsConditionsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeBtnText: PropTypes.string,
  textType: PropTypes.string.isRequired,
  onAcceptAndContinue: PropTypes.func,
  loading: PropTypes.bool,
};
