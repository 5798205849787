import { createSlice } from '@reduxjs/toolkit';
import nasmApi from '../api/endpoints';
import querystring from 'querystring';

export const workoutsSlice = createSlice({
  name: 'workouts',
  initialState: {
    loading: false,
    error: false,
    nextPage: null,
    isRefreshing: false,
    byId: {},
    defaultSections: [],
  },
  reducers: {
    // -----
    // GET ALL WORKOUTS
    workoutsRequested: (state, action) => {
      const { reload, query } = action.payload;
      state.loading = true;
      state.query = query;
      if (reload) {
        state.byId = {};
      }
    },
    workoutsReceived: (state, action) => {
      const { error, result } = action.payload;
      state.loading = false;
      state.error = error;

      if (error) {
        state.byId = {};
        return;
      }

      const { workouts = [], nextPage = '' } = result;
      state.byId = {
        ...state.byId,
        ...normalizeWorkouts(workouts),
      };
      state.nextPage = parseNextPage(nextPage);
      state.nextPageQuery = nextPage;
    },
    // -----
    // CREATE WORKOUT
    createWorkoutRequested: (state) => {
      state.loading = true;
    },
    createWorkoutReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response.error;
        return;
      }
      state.byId[response.id] = response;
    },
    // -----
    // UPDATE WORKOUT
    updateWorkoutRequested: (state) => {
      state.loading = true;
    },
    updateWorkoutReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response.error;
        return;
      }
      state.byId[response.id] = response;
    },
    // EDIT WORKOUT
    editWorkoutRequested: (state, action) => {
      const workout = action.payload;
      state.byId[workout.id].loading = true;
    },
    editWorkoutReceived: (state, action) => {
      const { id, response } = action.payload;
      if (response.error) {
        state.error = response.error;
        return;
      }
      state.byId[id] = response.workout;
    },
    // -----
    // DELETE WORKOUT
    deleteWorkoutRequested: (state, action) => {
      const id = action.payload;
      state.byId[id].loading = true;
    },
    deleteWorkoutReceived: (state, action) => {
      const { id, response } = action.payload;
      if (response.error) {
        state.error = response.error;
        return;
      }
      delete state.byId[id];
    },
    // -----
    // GET DEFAULT SECTIONS
    workoutSectionsRequested: () => {
      // TODO: should put some kind of loading state here
    },
    workoutSectionsReceived: (state, action) => {
      const { error } = action.payload;
      if (error) {
        state.error = true;
        return;
      }
      state.defaultSections = action.payload;
    },
  },
});

// Extract the action creators object and the reducer from the slice
const { reducer } = workoutsSlice;
export const { actions } = workoutsSlice;
export default reducer;

// Extract and export each action creator by name
export const {
  workoutsRequested,
  workoutsReceived,
  // workoutDetailsRequested,
  // workoutDetailsReceived,
  editWorkoutRequested,
  editWorkoutReceived,
  deleteWorkoutRequested,
  deleteWorkoutReceived,
} = actions;

// *****
// ACTION CREATORS
// *****

// -----
// GET ALL WORKOUTS
const defaultOptions = {
  page: 1,
  size: 100,
  search: '',
  sortField: 'name',
  sortOrder: 'asc',
  getPublic: true,
  getPrivate: true,
  reload: false,
};

export const fetchWorkouts = (options) => async (dispatch, getState) => {
  const { reload, cancelToken, ...query } = { ...defaultOptions, ...options };

  const state = getState();
  if (state.workouts.loading && !reload) return;

  // API request
  dispatch(workoutsRequested({ reload, query }));

  // API response
  const response = await nasmApi.nasmWorkouts.findWorkouts(query, cancelToken)
    .catch(error => ({ error: error.message }));

  // Dispatch workouts received action if request to retrieve workouts IS NOT cancelled
  if (!response.cancelMessage) {
    dispatch(workoutsReceived(response));
  }

  if (response.error) {
    return Promise.reject(response.error);
  } else {
    return Promise.resolve(response);
  }
};

// -----
// DELETE WORKOUT
export const deleteWorkout = (id) => async (dispatch, getState) => {
  const state = getState();
  const workout = state.workouts.byId[id];
  if (!workout || workout?.loading) return;

  dispatch(deleteWorkoutRequested(id));
  const response = await nasmApi.nasmWorkouts.deleteWorkout(id)
    .catch(error => ({ error: error.message }));
  dispatch(deleteWorkoutReceived({ id, response }));
  if (response.error) {
    return Promise.reject(response.error);
  } else {
    return Promise.resolve(response);
  }
};

// -----
// GET DEFAULT SECTIONS
export const getWorkoutSections = () => async (dispatch) => {
  dispatch(actions.workoutSectionsRequested());
  const response = await nasmApi.workoutSections.getAllSections()
    .catch(error => ({ error: error.message }));
  dispatch(actions.workoutSectionsReceived(response.result));
  if (response.error) {
    return Promise.reject(response.error);
  } else {
    return Promise.resolve(response);
  }
};

// *****
// HELPER FUNCTIONS
// *****
const normalizeWorkouts = workouts => workouts.reduce((acc, val) => {
  acc[val.id] = val;
  return acc;
}, {});

const parseNextPage = (q) => {
  if (!q) return null;
  const string = q.replace(/\?/, '');
  const params = querystring.parse(string);
  return params.page;
};
