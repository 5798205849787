import {
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { colors } from "../../styles";

export const RookActivity = (props) => {
  const { activity } = props;

  const classes = useStyles();

  return (
    <ListItem className={classes.activityItem}>
      <ListItemText
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
        primary={
          <Typography
            component="span"
            variant="body2"
            color="textPrimary"
            className={classes.activityTitle}
          >
            {activity?.user?.full_name}
          </Typography>
        }
        secondary={
          <Typography variant="body2" className={classes.activityDate}>
            {moment(activity?.created_at).format("M/D/YY")}
          </Typography>
        }
      />
      <Typography
        component="span"
        variant="body2"
        color="textPrimary"
        className={classes.activityDescription}
      >
        {activity?.name}
      </Typography>
    </ListItem>
  );
};

const useStyles = makeStyles({
  activityItem: {
    display: "block",
    padding: "15px 25px",
  },
  activityAvatar: {
    width: "24px",
    height: "24px",
  },
  activityTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.black,
  },
  activityDescription: {
    fontFamily: "Avenir-Medium",
    fontSize: "15px",
    lineHeight: 1.2,
    color: colors.black,
  },
  activityDate: {
    fontFamily: "Roboto-Medium",
    fontSize: "12px",
    lineHeight: 1,
    color: colors.dark_grey,
  },
});
