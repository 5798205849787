import React from 'react';
import PropTypes from 'prop-types';

import {styled} from '@material-ui/core/styles';
import {colors} from '../../styles';
import {IconButton, InputAdornment, Typography, CircularProgress} from '@material-ui/core';
import OvalTextInput from '../Inputs/OvalTextInput';

import iconShowPass from '../../resources/icon-eye-show-password.svg';
import iconHidePass from '../../resources/icon-eye-hide-password.svg';
import {OvalButton} from '../Buttons';

import { CREATE_ACCOUNT_ENABLED } from '../../util/loginConstants';

const LoginForm = styled('form')({
  marginBottom: `${CREATE_ACCOUNT_ENABLED ? 0 : '30px'}`,
});

const LoginFormCard = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  height: props => props.height || 'auto',
  borderRadius: 19,
  backgroundColor: 'rgba(22, 57, 99, 0.75)',
  padding: '0 90px',
});

const LogoImgContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 48,
  padding: '30px 0',
});

const InputGroup= styled(({ hide, ...otherProps }) =>
  <div {...otherProps} />)({
  display: props => !!props.hide ? 'none' : 'flex',
  flexDirection: 'column',
  marginBottom: 14,
  transition: 'ease-in-out all 0.15s',
});

const InputLabel = styled('div')({
  textAlign: 'right',
  margin: '0 10px 10px 10px',
  color: colors.white,
  opacity: 0.7,
  fontSize: 14,
});

const ChangeLinkContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0 10px 10px 10px',
});

const ChangeLink = styled(Typography)({
  color: colors.baby_blue,
  fontWeight: 500,
  fontSize: 14,
  cursor: 'pointer',
  '&:hover': {
    color: colors.white,
  },
});

const InputDescription = styled('div')({
  textAlign: 'right',
  margin: '10px 10px 0 10px',
  color: colors.yellow_button_style,
  fontSize: 14,
});

const ServerError = styled('div')({
  textAlign: 'center',
  margin: '10px 10px 0 10px',
  color: colors.red,
  fontSize: 20,
});

function TogglePassword (props) {
  const { showPass, onClick } = props;
  return (
    <IconButton
      aria-label='toggle password visibility'
      onClick={onClick}
    >
      <img src={showPass ? iconHidePass : iconShowPass} alt='toggle hide or show password' />
    </IconButton>
  );
}

TogglePassword.propTypes = {
  showPass: PropTypes.bool,
  onClick: PropTypes.func,
};

function PasswordTextField (props) {
  const { showPass, placeholder, password, onChange, onBlur, onTogglePasswordVisibility, showError } = props;
  return (
    <OvalTextInput
      placeholder={placeholder || 'Password'}
      type={showPass ? 'text' : 'password'}
      value={password}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      endAdornment={
        <InputAdornment position='end'>
          <TogglePassword showPass={showPass} onClick={onTogglePasswordVisibility}/>
        </InputAdornment>
      }
    />
  );
}

PasswordTextField.propTypes = {
  showPass: PropTypes.bool,
  password: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onTogglePasswordVisibility: PropTypes.func,
  showError: PropTypes.bool,
};

function ContinueButton (props) {
  const { isLoading, actionText } = props;

  return (
    <OvalButton
      backgroundcolor={colors.lightBlue}
      color={colors.white}
      width='100%'
      height={55}
      borderradius={28}
      opacity={1}
      fontSize={19}
      fontWeight='bold'
      type='submit'
      border='none'
      margin='20px 0 0 0'
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress size={21} color='inherit' /> : (actionText || 'Continue')}
    </OvalButton>
  );
}

ContinueButton.propTypes = {
  isLoading: PropTypes.bool,
  actionText: PropTypes.string,
};

export {
  LoginForm,
  LoginFormCard,
  LogoImgContainer,
  InputGroup,
  InputLabel,
  ChangeLink,
  ChangeLinkContainer,
  InputDescription,
  ServerError,
  TogglePassword,
  PasswordTextField,
  ContinueButton,
};