import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Box, Typography, CircularProgress } from '@material-ui/core';

import OvalButton from './OvalButton';
import { ReactComponent as Checkmark } from '../../resources/checkmark.svg';
import { colors } from '../../styles';
import { SAVE_STATES } from '../../constants';

const useLabelStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '90px',
  },
  loadingSpinner: {
    color: colors.white,
  },
  textStyle: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: colors.white,
  },
  checkmark: {
    filter: 'brightness(130%)',
  },
});

function SaveButtonLabel (props) {
  const { saveState } = props;
  const classes = useLabelStyles();

  if (saveState === SAVE_STATES.LOADING) {
    return (
      <Box className={classes.container}>
        <CircularProgress className={classes.loadingSpinner} size={20} thickness={8} />
        <Typography className={classes.textStyle}>Saving...</Typography>
      </Box>
    );
  }
  if (saveState === SAVE_STATES.SAVED) {
    return (
      <Box className={classes.container}>
        <Checkmark className={classes.checkmark} />
        <Typography className={classes.textStyle}>Saved</Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography className={classes.textStyle}>Save</Typography>
    </Box>
  );
}

const useContainerStyles = makeStyles({
  root: ({ saveState }) => ({
    transition: 'all 0.5s',
    '&:hover': {
      filter: saveState === SAVE_STATES.LOADING || saveState === SAVE_STATES.SAVED
        ? 'brightness(100%)'
        : 'brightness(94%)',
    },
  }),
});

function SaveButton (props) {
  const { onClick, margin, type, saveState } = props;

  const classes = useContainerStyles({ saveState });

  const onSave = (e) => {
    if (saveState === SAVE_STATES.LOADING) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <OvalButton
      className={classes.root}
      backgroundcolor={saveState === SAVE_STATES.SAVED ? colors.navy : colors.yellow_button_style}
      border='none'
      width={108}
      height={38}
      margin={margin}
      disabled={saveState === SAVE_STATES.SAVED}
      type={type}
      onClick={onSave}
    >
      <SaveButtonLabel
        saveState={saveState}
      />
    </OvalButton>
  );
}

export default SaveButton;

SaveButton.propTypes = {
  saveState: PropTypes.string.isRequired,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  onClick: PropTypes.func,
};
