import request from '../nasm_sdk_v2/request';

const MHR_BASE_ENDPOINT = (apiVersion, userId) => `${apiVersion}/api/users/${userId}/mhr`;

export default function mhr(apiVer) {
  function saveMHRResults(userId, jsonData, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error("userId is required to save MHR results."),
      );
    }
    return request.post(`${MHR_BASE_ENDPOINT(apiVersion, userId)}`, jsonData);
  }

  function getCurrentMHR(userId, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch current MHR.'),
      );
    }
    return request.get(`${MHR_BASE_ENDPOINT(apiVersion, userId)}/current`);
  }

  function getMHRHistory(userId, searchParams, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch MHR history.'),
      );
    }
    return request.get(`${MHR_BASE_ENDPOINT(apiVersion, userId)}/history`, {
      params: {
        ...searchParams,
        size: 10,
      },
    });
  }

  return {
    saveMHRResults,
    getCurrentMHR,
    getMHRHistory,
  };
}
