import moment from 'moment';

export const SUBSCRIPTION_STATUS = Object.freeze({
  EXPIRED: 'expired',
  SUBSCRIBED: 'subscribed',
  NONE: 'none',
  PENDING: 'pending',
});

export function checkTrainerSubscriptionStatus(expirationDate) {
  let subscriptionStatus = SUBSCRIPTION_STATUS.PENDING;

  if(!!expirationDate) {
    const localExpirationDate = moment(expirationDate).local();
    if(localExpirationDate.isBefore(moment())) {
      subscriptionStatus =  SUBSCRIPTION_STATUS.EXPIRED;
    } else {
      subscriptionStatus = SUBSCRIPTION_STATUS.SUBSCRIBED;
    }
  } else {
    // if expirationDate returned from backend is null
    // then that means this trainer never made a subscription before
    if(expirationDate === null) {
      subscriptionStatus = SUBSCRIPTION_STATUS.NONE;
    }
  }

  return subscriptionStatus;
}