import React from 'react';
import PropTypes from 'prop-types';
import  {Box} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';

import {
  EmptyUploadView,
  UploadInProgressView,
  UploadProcessingView, WorkoutMediaPreview,
} from './Views';

import {UPLOAD_STATES} from '../../constants';
import validateUploadState from './validateUploadState';
import { colors } from '../../styles';

const Background = styled(Box)({
  width: 526,
  height: 235,
  backgroundColor: colors.black,
});

/**
 * @return {JSX|null}
 */
function CurrentWorkoutMediaView (props) {
  const {
    uploadState,
    onUploadBegin = () => {},
    onCancelMediaUploadRequest = () => {},
    canCalculateProgress,
    progressValue,
    videoSrc,
    isVideoEditable,
    workoutId,
  } = props;

  if (uploadState === UPLOAD_STATES.Empty) {
    return (
      <Background>
        <EmptyUploadView
          onUploadBegin={onUploadBegin}
          headerLabel={'Upload Video'}
          supportedFileTypes={[
            'video/quicktime', // MOV file
            'video/mp4',
          ]}
        />
      </Background>
    );
  }

  if (uploadState === UPLOAD_STATES.Uploading) {
    return (
      <Background>
        <UploadInProgressView
          canCalculateProgress={canCalculateProgress}
          progressValue={progressValue}
          onCancelMediaUploadRequest={onCancelMediaUploadRequest}
        />
      </Background>
    );
  }

  if (uploadState === UPLOAD_STATES.Processing) {
    return (
      <Background>
        <UploadProcessingView
          fileType={'video'}
        />
      </Background>
    );
  }

  if (uploadState === UPLOAD_STATES.Media_Available) {
    return (
      <Background>
        <WorkoutMediaPreview
          onUploadBegin={onUploadBegin}
          videoSrc={videoSrc}
          type={'application/x-mpegURL'}
          isVideoEditable={isVideoEditable}
          workoutId={workoutId}
        />
      </Background>
    );
  }

  return null;
}

CurrentWorkoutMediaView.propTypes ={
  uploadState: validateUploadState,
  canCalculateProgress: PropTypes.bool,
  progressValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  videoSrc: PropTypes.string,
  isVideoEditable: PropTypes.bool,
  workoutId: PropTypes.string,

  onUploadBegin: PropTypes.func,
  onCancelMediaUploadRequest: PropTypes.func,
};

export default CurrentWorkoutMediaView;