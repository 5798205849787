import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom';
import querystring from 'query-string';

import WorkoutVideoPanel from '../Panels/WorkoutVideoPanel';
import {
  saveVideoWorkout,
  editWorkoutDescription,
  editWorkoutName,
} from '../../reducers/selectedWorkoutReducer';

import {UPLOAD_STATES, UPLOADED_MEDIA_STATUS_TYPES, SAVE_STATES} from '../../constants';
import { useWorkoutsContext } from '../../contexts/WorkoutsContext';
import nasmApi from '../../api/endpoints';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import {selectWorkout} from '../../reducers/selectedWorkoutReducer';

// For handling Redux updates so Storybook
// won't require the Redux store
function WorkoutVideoPanelWrapper (props) {
  const {
    onDeleteWorkout,
    navigateToMyWorkoutList,

    cancelToken,
    cancelMediaUploadRequest,
    saveState,
    setSaveState,
    uploadState,
    setUploadState,
    visible,
    uploadedMediaId,
    setUploadedMediaId,
  } = props;

  const dispatch = useDispatch();
  const workout = useSelector(state => state.selectedWorkout.workout ?? {});
  const programContext = useSelector(state => state.programContext.context);
  const location = useLocation();

  const [canCalculateProgress, setCanCalculateProgress] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [streamingUrl, setStreamingUrl] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const query = querystring.parse(location.search || {}, { parseBooleans: true });
  const { refreshWorkouts } = useWorkoutsContext();

  const onChangeWorkoutTitle = (title) => {
    setSaveState(SAVE_STATES.CLICKABLE);
    dispatch(editWorkoutName(title));
  };

  const onChangeWorkoutDescription = (desc) => {
    setSaveState(SAVE_STATES.CLICKABLE);
    dispatch(editWorkoutDescription(desc));
  };

  const onSaveWorkout = async () => {
    // prevent saving a workout with no media id set
    if(uploadedMediaId.length === 0) {
      return;
    }

    try {
      setSaveState(SAVE_STATES.LOADING);
      const newWorkout  = await dispatch(saveVideoWorkout(uploadedMediaId));
      determineMediaUploadStatus(newWorkout.uploaded_media, uploadState);

      setSaveState(SAVE_STATES.SAVED);
      refreshWorkouts();
    } catch (err) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert('An error occurred when trying to save the workout. Please try again.');
      setSaveState(SAVE_STATES.CLICKABLE);
      throw err;
    }
  };

  const updateProgressBar = ({canProgressBeCalculated, progress}) => {
    setCanCalculateProgress(canProgressBeCalculated);
    setProgressValue(progress);
  };

  const determineMediaUploadStatus = useCallback((result, prevUploadState) => {
    // reset progress value
    setProgressValue(0);

    const uploadStatus = result?.status ?? 'DEFAULT';

    switch(uploadStatus) {
      case UPLOADED_MEDIA_STATUS_TYPES.FINISHED:
        setUploadState(UPLOAD_STATES.Media_Available);
        setStreamingUrl(result.stream_url);
        setUploadedMediaId(result.id);
        break;
      case UPLOADED_MEDIA_STATUS_TYPES.PENDING:
        setUploadState(UPLOAD_STATES.Processing);
        setUploadedMediaId(result.id);
        break;
      case UPLOADED_MEDIA_STATUS_TYPES.ERROR:
        setUploadState(prevUploadState);
        window.alert('There was an error uploading this video workout. Please try again later');
        break;
      default:
        setUploadState(prevUploadState);
        setUploadedMediaId('');
        break;
    }
  }, [setUploadState, setUploadedMediaId]);

  const onUploadBegin = useCallback(async (e) => {
    // skip uploading if no file was selected
    if (e.target.files.length === 0) {
      return;
    }

    const prevUploadState = uploadState;
    setUploadState(UPLOAD_STATES.Uploading);
    setSaveState(SAVE_STATES.CLICKABLE);
    setProgressValue(0);
    const currentFile = e.target.files[0];

    try {
      const response = await nasmApi.mediaUpload
        .uploadMediaToTranscode(currentFile, cancelToken.token, updateProgressBar);

      if(!response.cancelMessage) {
        const result = response.result;

        if(!result) {
          setUploadState(prevUploadState);
        } else {
          determineMediaUploadStatus(result, prevUploadState);
        }
      } else {
        setUploadState(prevUploadState);
      }

    } catch (err) {
      const errorMessage = err?.data?.message || err?.message;
      if(errorMessage) {
        window.alert(`An error has occurred:\n${errorMessage}`);
      } else {
        window.alert('An unexpected error occurred when uploading this video workout. Please try again.');
      }

      setUploadState(prevUploadState);
    }

  }, [cancelToken.token, uploadState, setUploadState, setSaveState, determineMediaUploadStatus]);

  const onOpenCreateVideoWorkout = () => {
    setUploadState(UPLOAD_STATES.Empty);
    setStreamingUrl('');
    setProgressValue(0);
  };

  const onCloseVideoWorkout = () => {
    navigateToMyWorkoutList();
  };

  const deleteWorkout = () => {
    onDeleteWorkout({ name: workout.name, id: workout.id });
    setShowDeleteDialog(false);
  };

  // Fetch workout details
  useEffect(() => {
    if(visible && query.workoutId && query.workoutId !== 'new') {
      dispatch(selectWorkout({id: query.workoutId}));
      // Reset save state
      setSaveState(SAVE_STATES.SAVED);
    }
  }, [dispatch, query.workoutId, visible, setSaveState]);

  useEffect(() => {
    if (visible) {
      if (workout.id) {
        determineMediaUploadStatus(workout.uploaded_media, uploadState);
      } else {
        onOpenCreateVideoWorkout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout.id, visible]);

  return (
    <>
      <WorkoutVideoPanel
        programContext={programContext}
        workout={workout}
        saveState={saveState}
        uploadState={uploadState}
        canCalculateProgress={canCalculateProgress}
        progressValue={progressValue}
        streamingUrl={streamingUrl}
        showSaveButton={[UPLOAD_STATES.Processing, UPLOAD_STATES.Media_Available].includes(uploadState)}
        showDeleteButton={uploadState === UPLOAD_STATES.Media_Available}
        headerText={'Create your own Workout Video'}

        onSaveWorkout={onSaveWorkout}
        onChangeTitle={onChangeWorkoutTitle}
        onChangeDescription={onChangeWorkoutDescription}
        onCancelMediaUploadRequest={cancelMediaUploadRequest}
        onUploadBegin={onUploadBegin}
        onClickDelete={() => setShowDeleteDialog(true)}
        onClose={onCloseVideoWorkout}
        { ...props }
      />
      <ConfirmDialog
        title='Delete Workout'
        description={`Deleting ${workout.name ? `the workout "${workout.name}"` : 'this workout'} will remove it ` +
        'from your library and all programs. Are you sure? '}
        actionButtonTitle='Delete'
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        handleConfirmAction={deleteWorkout}
      />
    </>
  );
}

WorkoutVideoPanelWrapper.propTypes = {
  visible: PropTypes.bool.isRequired,
  currentView: PropTypes.string,

  cancelToken: PropTypes.object,
  saveState: PropTypes.string,
  setSaveState: PropTypes.func,
  uploadState: PropTypes.string,
  setUploadState: PropTypes.func,

  onCancelMediaUploadRequest: PropTypes.func,
  onDeleteWorkout: PropTypes.func,
  resetWorkoutIndex: PropTypes.func,
  navigateToMyWorkoutList: PropTypes.func,

  uploadedMediaId: PropTypes.string,
  setUploadedMediaId: PropTypes.func,
};

export default WorkoutVideoPanelWrapper;