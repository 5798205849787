import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { useExternalScript } from '../../hooks/ExternalScript';
import { ROLES } from '../../constants';
import nasmApi from '../../api/endpoints';
import CalculatorFTUE from './CalculatorFTUE';
import FTUImg from '../../resources/bmi_ftu.svg';
import moment from 'moment';

const useStyles = makeStyles({
  profilePaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '660px',
    maxWidth: '760px',
    height: '760px',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
});

const BMICalculatorContainer = ({ currentUser, isOpen, onClose }) => {
  const ftueShown = localStorage.getItem('bmi-shown');
  const [bmiShown, setBmiShown] = useState(ftueShown === 'true');

  const widgetUrl = process.env.REACT_APP_BMI_WIDGET_URL || '/dist/bmi/index.js';
  const scriptState = useExternalScript({ url: widgetUrl, canStartLoading: isOpen });
  const canMount = isOpen && scriptState === 'ready';
  const unitWeight = currentUser?.unit_weight || 'lb';
  const hasSelectedClient = currentUser?.role === ROLES.CLIENT;

  const classes = useStyles();

  useEffect(() => {
    // Pass onClose function to custom widget element:
    const bmiCalculator = document.querySelector('bmi-calculator');
    if (canMount && bmiCalculator) {
      bmiCalculator.closewidget = onClose;
    }
  }, [canMount, onClose, bmiShown]);

  const saveBMIRecord = useCallback(async (bmiData) => {
    if (!hasSelectedClient) {
      return;
    }
    // if we have client user ID for this screen in redux, save their BMI results
    try {
      await nasmApi.bmi.saveBMIResults(currentUser?.id, bmiData);
    } catch (error) {
      showAlert(error);
    } finally {
      const SaveRequestComplete = new CustomEvent("saveRequestComplete");
      window.dispatchEvent(SaveRequestComplete);
    }
  }, [currentUser.id, hasSelectedClient]);

  useEffect(() => {
    window.addEventListener('onSaveRequest', (event) => {
      const { bmiData } = event.detail;
      if (bmiData) {
        saveBMIRecord(bmiData);
      }
    });
    return () => {
      window.removeEventListener('onSaveRequest');
    };
  }, [saveBMIRecord]);

  useEffect(() => {
    window.addEventListener('fetchCurrentBMIRecord', getCurrentBMI);
    window.addEventListener('fetchBMIPaginatedHistoryList', getBMIHistory);
    window.addEventListener('fetchGraphHistory', getBMIGraphHistory);
    return () => {
      window.removeEventListener('fetchCurrentBMIRecord', getCurrentBMI);
      window.removeEventListener('fetchBMIPaginatedHistoryList', getBMIHistory);
      window.removeEventListener('fetchGraphHistory', getBMIGraphHistory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentBMI = async () => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const response = await nasmApi.bmi.getCurrentBMI(currentUser?.id);
      const receiveCurrentBMIEvent = new CustomEvent("onReceiveCurrentBMIRecord", {
        cancelable: true,
        detail: {
          currentBMIRecord: response?.result,
        },
      });
      window.dispatchEvent(receiveCurrentBMIEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const getBMIHistory = async (event) => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const { page, currentDate, granularity } = event?.detail;
      const response = await nasmApi.bmi.getBMIHistory(currentUser?.id, {
        page,
        currentDate: moment(currentDate).format(),
        granularity,
      });
      const receiveBMIHistoryEvent = new CustomEvent("onReceiveBMIPaginatedHistoryList", {
        cancelable: true,
        detail: {
          BMIHistoryRecord: response?.result,
        },
      });
      window.dispatchEvent(receiveBMIHistoryEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const getBMIGraphHistory = async (event) => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const { currentDate, granularity } = event?.detail;
      const response = await nasmApi.bmi.getBMIGraphHistory(currentUser?.id, {
        currentDate: moment(currentDate).format(),
        granularity,
      });
      const receiveBMIGraphHistoryEvent = new CustomEvent("onReceiveGraphHistory", {
        cancelable: true,
        detail: {
          BMIGraphHistory: response?.result,
        },
      });
      window.dispatchEvent(receiveBMIGraphHistoryEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const showAlert = (error) => {
    alert(error?.message || 'Something went wrong! Please try again later.');
  };

  const closeBmi = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const startBMI = () => {
    setBmiShown(true);
    localStorage.setItem('bmi-shown', 'true');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeBmi}
      classes={{ paper: classes.profilePaper, scrollPaper: classes.scrollPaper }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      {!bmiShown
        ? <CalculatorFTUE
          headerText='BMI Calculator'
          heroText='Create your first BMI calculation'
          heroImg={FTUImg}
          subHeadingText="BMI is used to categorize bodyweight based on a person's height and weight."
          startButtonText='Calculate BMI'
          onStart={startBMI}
          onClose={onClose}
        />
        : <>
          {!canMount && <CircularProgress />}
          {canMount && (
            <bmi-calculator
              data-edge-web='true'
              data-unit-weight={unitWeight}
              selected-client={hasSelectedClient ? 'true' : 'false'}
              style={{ width: '100%' }}
            />
          )}
        </>
      }
    </Dialog>
  );
};

export default BMICalculatorContainer;
