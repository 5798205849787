import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { colors } from '../../styles';

import ClientBreadcrumbs from '../../components/ClientDashboard/ClientBreadcrumbs';
import { PageHeader } from '../../components';
import FloatingButtonDropdown from '../../components/ClientDashboard/FloatingButtonDropdown';
import ClientBigCalendar from '../../components/ClientCalendar/ClientBigCalendar';
import ViewScheduleDialog from '../../components/ViewSchedule/ViewScheduleDialog';

import useFetchWorkoutScheduleByMonth from '../../hooks/workoutSchedule/FetchWorkoutScheduleByMonth';
import {clearProgramContext} from '../../reducers/programContextReducer';
import {addPossessiveApostrophe} from '../../util/utilFunctions';
import { clearProgram } from '../../reducers/selectedProgramReducer';
import { clearWorkout } from '../../reducers/selectedWorkoutReducer';

export default function ClientSchedule (props) {
  const {
    pathMap = {},
    selectedClient = {},
    selectedDate,
    setSelectedDate,
    monthViewDate,
    setMonthViewDate,
  } = props;

  const dispatch = useDispatch();
  const [selectedEventDate, setSelectedEventDate] = useState(null);
  // key - date string | value - Event Object
  const [events, setEvents] = useState({});

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isViewScheduleDialogOpen, setViewScheduleDialogOpen] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState('');
  const [selectedWorkoutId, setSelectedWorkoutId] = useState('');
  const [canFetchData, setCanFetchData] = useState(true);

  const history = useHistory();

  const openViewScheduleDialog = (scheduleId, workoutId, startEventDate) => {
    setViewScheduleDialogOpen(true);
    setSelectedScheduleId(scheduleId);
    setSelectedWorkoutId(workoutId);
    setSelectedEventDate(moment(startEventDate));
  };

  const displayFormattedDate = () => {
    const currentDateSelected = moment(selectedDate);
    return isDateSelectedToday(currentDateSelected, moment())
      ? `Today, ${currentDateSelected.format('MMMM D')}`
      : currentDateSelected.format('dddd, MMMM D');
  };

  // Can be further optimized by pulling all upcoming schedules for the year instead of
  // pulling schedules 1 month at a time
  const {
    workoutSchedulesByMonth,
    isDataLoaded,
    error,
  } = useFetchWorkoutScheduleByMonth({
    userId: selectedClient.id,
    // In moment, months are 0-indexed and API expects January to start at 1
    month: monthViewDate.month() + 1,
    year: monthViewDate.year(),
    canFetchData: canFetchData,
  });

  useEffect(() => {
    if (isDataLoaded && !error) {
      const workoutSchedules = buildWorkoutSchedulesByMonth(workoutSchedulesByMonth);
      setEvents(workoutSchedules);
    }
  }, [isDataLoaded, error, workoutSchedulesByMonth]);

  useEffect(() => {
    dispatch(clearProgramContext());
    dispatch(clearProgram());
    dispatch(clearWorkout());
  }, [dispatch]);

  // eslint-disable-next-line camelcase
  const clientFirstName = selectedClient?.first_name ?? '';

  const scheduleExercisesLink = () => {
    let link;
    if (clientFirstName) {
      link = `/clients/my-clients/${clientFirstName.toLowerCase()}/dashboard/calendar/schedule-exercises-next`;
    } else {
      // Fallback link
      link = '/clients/my-clients';
    }

    return link;
  };

  const scheduleWorkoutsLink = () => {
    return `/clients/my-clients/${clientFirstName?.toLowerCase() ?? 'Client'}/dashboard/calendar/schedule-workouts`;
  };

  const scheduleProgramsLink = () => {
    return `/clients/my-clients/${clientFirstName?.toLowerCase() ?? 'Client'}/dashboard/calendar/schedule-programs`;
  };

  const navigateToLink = (link) => {
    history.push(link);
  };

  const navigationItems = [
    {
      label: 'Exercises',
      onClick: () => navigateToLink(scheduleExercisesLink()),
    },
    {
      label: 'Workouts',
      onClick: () => navigateToLink(scheduleWorkoutsLink()),
    },
    {
      label: 'Programs',
      onClick: () => navigateToLink(scheduleProgramsLink()),
    },
  ];

  return (
    <Box>
      <PageHeader
        title={`${addPossessiveApostrophe(clientFirstName)} Schedule`}
        subtitle={displayFormattedDate()}
        color={colors.graphite}
        height={120}
      />
      <FloatingButtonDropdown
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        dropdownHeader='Assign a workout from:'
        navigationItems={navigationItems}
      />
      <ClientBreadcrumbs
        paddingTop={21}
        paddingLeft={133}
        ariaLabel='client-schedule-breadcrumbs'
        pathMap={pathMap}
        pathNamesExclusionList={['clients', clientFirstName?.toLowerCase()]}
      />
      <ClientBigCalendar
        selectedDate={selectedDate.toDate()}
        setSelectedDate={setSelectedDate}
        monthViewDate={monthViewDate}
        setMonthViewDate={setMonthViewDate}
        events={events}
        openViewScheduleDialog={openViewScheduleDialog}
      />
      <ViewScheduleDialog
        open={isViewScheduleDialogOpen}
        setOpen={setViewScheduleDialogOpen}
        setCanFetchData={setCanFetchData}
        selectedScheduleId={selectedScheduleId}
        selectedWorkoutId={selectedWorkoutId}
        selectedClient={selectedClient}
        eventDate={selectedEventDate}
      />
    </Box>
  );
}

// HELPER FUNCTIONS //

/***
 *
 * @param {Object} schedule - user_schedule_workout object returned from the backend
 * @returns {{allDay: boolean, resource: *, start: Date, end: Date, title: *}}
 */
function createEventFromWorkoutSchedule (schedule) {
  /* returns
      Event {
        title: string,
        start: Date,
        end: Date,
        allDay?: boolean
        resource?: any,
      }
 */

  return {
    title: schedule.workout_name,
    start: schedule.localStartDate.toDate(),
    // React Big Calendar requires to have an end-date. Without one,
    // the scheduled workouts won't appear on the calendar as events
    end: schedule.localStartDate.clone().add(1, 'hour').toDate(),
    allDay: true,
    resource: {
      scheduleId: schedule.user_schedule_id,
      workoutId: schedule.workout_id,
    },
  };
}

function buildWorkoutSchedulesByMonth (schedules) {
  // key - date string | value - [Event] array of event objects
  const events = {};
  for (const schedule of schedules) {
    // Assuming server time is held in UTC, convert to local timezone
    const startDate = moment(schedule.workout_date).local().startOf('day');
    const startDateStr = startDate.toISOString();

    const event = createEventFromWorkoutSchedule({ ...schedule, localStartDate: startDate });
    if (events[startDateStr]) {
      events[startDateStr].push(event);
    } else {
      events[startDateStr] = [event];
    }
  }

  return events;
}

function isDateSelectedToday (selectedDate, todaysDate) {
  const startOfSelectedDate = selectedDate.startOf('day');
  const startOfToday = todaysDate.startOf('day');
  return startOfSelectedDate.date() === startOfToday.date() &&
    startOfSelectedDate.month() === startOfToday.month() &&
    startOfSelectedDate.year() === startOfToday.year();
}
