import { createSlice } from '@reduxjs/toolkit';

export const selectedClientSlice = createSlice({
  name: 'selectedClient',
  initialState: { client: null },
  reducers: {
    selectClient: (state, action) => {
      state.client = action.payload;
    },
    deselectClient: (state, action) => {
      state.client = null;
    },
  },
});

// Extract the action creators object and the reducer from the slice
export const { actions, reducer } = selectedClientSlice;
export default reducer;

// Extract and export each action creator by name
export const {
  selectClient,
  deselectClient,
} = actions;
