/* eslint-disable max-len */

import React from 'react';
import { styled } from '@material-ui/core/styles';
import { colors } from '../styles';

const Root = styled('div')({
  width: '100%',
  height: 'auto',
  minHeight: '100%',
  display: 'flex',
});

const Body = styled('div')({
  margin: '0px 100px 0px 100px',
  padding: '72px 0',
});

const Header = styled('div')({
  fontFamily: 'DMSans, sans-serif',
  fontWeight: 'bold',
  fontSize: '40px',
  color: colors.black,
  textAlign: 'center',
});

const Title = styled('p')({
  fontSize: 18,
  fontWeight: 'bold',
  color: colors.black,
  fontFamily: "Roboto, sans-serif",
});

const Description = styled('p')({
  fontSize: 16,
  color: colors.black,
  fontFamily: "Roboto, sans-serif",
});

const Redirect = styled('a')({
  fontSize: 16,
  color: colors.radio_blue,
  fontWeight: props => props.fontWeight,
  textDecoration: props => props.textDecoration,
  fontFamily: "Roboto, sans-serif",
});

const DeleteInstruction = ({
    params,
}) => (
  <Root>
    <Body>
      <Header>Account Deletion Guide</Header>
      <Description>Thank you for choosing NASM Edge. If you've decided to part ways with us, we respect your decision.
        Here is a step-by-step guide on how to delete your account and the associated data.
        Please note that once your account is deleted, certain data may be retained for a specific period as outlined
        in our privacy policy.
      </Description>
      <Description>Alternatively, if you prefer guidance or have any questions during the account deletion process,
        our dedicated customer service team is ready to assist you.
        Feel free to reach out to them for step-by-step assistance and prompt support using the phone number below.
        Your satisfaction is our priority, and we're here to ensure a seamless experience tailored to your preferences
      </Description>
      <Title>NASM Customer Support:
        <Redirect href="tel:833-707-2626" fontWeight="bold" target="_blank">833-707-2626</Redirect>
      </Title>
      <Title>Step 1: Open NASM Edge</Title>
      <Description>Launch the NASM Edge app on your mobile device or log in on your browser.
        Make sure you are using the latest version of the app to access the most up-to-date features and options.
      </Description>
      <Title>Step 2: Log In to Your Account</Title>
      <Description>Log in to your NASM Edge account using your credentials.
        If you've forgotten your password, follow the app's password recovery process.
      </Description>
      <Title>Step 3: Navigate to Account Settings</Title>
      <Description>Once logged in, locate and access your account settings.
        This is typically found in the app's main menu or within a dedicated "Profile" section.
      </Description>
      <Title>Step 4: Find the "Delete Account" Option</Title>
      <Description>Look for an option titled "Deactivate Account”.</Description>
      <Title>Step 5: Follow the Deletion Process</Title>
      <Description>Initiate the account deletion process by selecting the appropriate option.
        The app may request additional information or ask for confirmation before proceeding.
      </Description>
      <Title>Step 6: Confirm Deletion Request</Title>
      <Description>Confirm your decision to delete the account.
        Some apps may prompt you to enter your password again to ensure account security.
      </Description>
      <Title>Step 7: Check for Confirmation Email</Title>
      <Description>After confirming the deletion request, check your registered email for a confirmation 
        message from NASM. This email may contain additional instructions or serve as a final confirmation step.
      </Description>
      <Title>Step 8: Data Retention Information</Title>
      <Description>Be aware that certain types of data may be retained for a specified period,as outlined in
        {' '}
        <Redirect
          href="https://www.nasm.org/policies/terms-and-conditions/nasm-app"
          textDecoration='underline'
          target="_blank"
        >
          NASM's privacy policy.
        </Redirect>
        {' '}
        This could include aggregated, non-personal data for analytical purposes.
        Review the privacy policy for details on data retention.
      </Description>
      <Title>Step 9: Contact Support (Optional)</Title>
      <Description>If you have any concerns or encounter issues during the account deletion process,
        you can contact NASM customer support for assistance.
        Provide relevant details, such as your username or registered email, to expedite the resolution.
        By following these steps, you can smoothly delete your NASM account and ensure a seamless transition.
        We appreciate your feedback and thank you for being a part of our fitness community.
      </Description>
      </Body>
  </Root>
);

export default DeleteInstruction;
