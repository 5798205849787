import React from 'react';

import WorkoutList from '../Workouts/Workoutlist';
import Panel from './Panel';
import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';

const TAB_OPTIONS = Object.freeze({
  MY_WORKOUTS: 'my-workouts',
  NASM_WORKOUTS: 'nasm-workouts',
});

export default function WorkoutListPanel (props) {
  const {
    selectedWorkoutIndex,
    setSelectedWorkoutIndex,
    visible,
    // Determines if the 3 dots menu pop-up is visible for all workout records being rendered
    showDropdownOnWorkoutRecords = true,
    // Navigation
    pathName,
    updateButtonVisibilityFlags,
    getWorkoutUrlParams = (workoutId) => {},
    copyWorkoutLink = () => {},
    onCreateWorkout = () => {},
    ftuTo = '',
    ftuBtnHidden,
    onDeleteWorkout,
    onChangeWorkoutPending,
  } = props;

  let showPublicWorkouts = false;
  let showPrivateWorkouts = false;
  if (pathName.includes(TAB_OPTIONS.MY_WORKOUTS)) {
    showPrivateWorkouts = true;
    showPublicWorkouts = false;
  } else if (pathName.includes(TAB_OPTIONS.NASM_WORKOUTS)) {
    showPrivateWorkouts = false;
    showPublicWorkouts = true;
  }

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={800}>
        <FadeTransition visible={visible}>
          <WorkoutList
            getWorkoutUrlParams={getWorkoutUrlParams}
            copyWorkoutLink={copyWorkoutLink}
            selectedWorkoutIndex={selectedWorkoutIndex}
            setSelectedWorkoutIndex={setSelectedWorkoutIndex}
            getPublic={showPublicWorkouts}
            getPrivate={showPrivateWorkouts}
            updateButtonVisibilityFlags={updateButtonVisibilityFlags}
            showDropdownOnWorkoutRecords={showDropdownOnWorkoutRecords}
            createNewWorkout={onCreateWorkout}
            ftuTo={ftuTo}
            ftuBtnHidden={ftuBtnHidden}
            onDeleteWorkout={onDeleteWorkout}
            onChangeWorkoutPending={onChangeWorkoutPending}
          />
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}
