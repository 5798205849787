import React from 'react';
import { styled } from '@material-ui/core/styles';

import { colors } from '../styles';
import edgeLogo from '../resources/imgEdgeLogo.png';
import { OvalButton } from '../components/Buttons';

const LoginContainer = styled('div')({
  backgroundColor: colors.backdrop_tint,
  height: 'auto',
  minHeight: '100%',
  width: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
});

const LandingBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px 22px',
  maxWidth: 400,
  alignItems: 'center',
});

const AppStoreLinksContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 20,
  maxWidth: 320,
  width: '100%',
  '@media(max-width: 370px)': {
    flexDirection: 'column',
    height: 120,
  },
});

const LandingCopy = styled('div')({
  textAlign: 'center',
  fontWeight: 500,
  color: colors.white,
  marginTop: 56,
});

const MobileLanding = () => {
  return (
    <LoginContainer>
      <LandingBody>
        <div style={{ width: '100%' }}>
          <img src={edgeLogo} alt='NASM EDGE Logo' />
        </div>
        <LandingCopy>
          Try the app for a better experience.
        </LandingCopy>
        <AppStoreLinksContainer>
          <OvalButton
            border='none'
            backgroundcolor='#2592ec'
            color={colors.white}
            width='100%'
            height='55px'
            borderradius='26px'
            fontSize='19px'
            fontWeight='bold'
            href='https://nasm-edge.app.link/download'
          >
            Download EDGE
          </OvalButton>
        </AppStoreLinksContainer>
      </LandingBody>
    </LoginContainer>
  );
};

export default MobileLanding;
