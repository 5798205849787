import React from 'react';
import PropTypes from 'prop-types';
import OvalButton from './OvalButton';
import { colors } from '../../styles';

import { CircularProgress, Typography } from '@material-ui/core';

function ActionButton (props) {
  const Label = () => {
    if (props.loading) {
      return (
        <CircularProgress size={20} style={{ color: props.color || colors.white }} />
      );
    }

    return (
      <Typography style={{
        fontWeight: props.fontWeight || 'bold',
        fontSize: props.fontSize || 14,
      }}
      >
        {props.label}
      </Typography>
    );
  };

  return (
    <OvalButton
      border='none'
      backgroundcolor={props.backgroundcolor || colors.yellow_button_style}
      color={props.color || colors.white}
      width={props.width}
      height={props.height}
      margin={props.margin}
      onClick={props.onClick}
      disabled={props.loading}
      type={props.type}
    >
      <Label />
    </OvalButton>
  );
}

ActionButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fontSize: PropTypes.number,
};

export default ActionButton;
