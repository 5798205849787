import moment from 'moment';
import axios from 'axios';
import {
  NASM_OAUTH_CLIENT,
  NASM_OAUTH_GRANT_TYPES,
  STORAGE_KEYS,
  BASE_URL,
} from '../nasm_sdk_v2/constants';

const { AUTH_TOKEN } = STORAGE_KEYS;

const instance = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  // used to create a session that can be used between requests
  withCredentials: true,
  auth: {
    username: NASM_OAUTH_CLIENT.ID,
    password: NASM_OAUTH_CLIENT.SECRET,
  },
});

instance.interceptors.response.use(function(response) {
  if(response.config.responseType === 'json') {
    return response.data;
  }

  return response;
}, function(error) {
  if(error.response) {
    return Promise.reject({
      status: error.response.status,
      statusText: error.response.statusText,
      data: error.response.data,
    });
  }

  return Promise.reject(error);
});

async function requestTokensInternal(data, storage, apiVersion) {
  const tokenInfo = await instance.post(`${apiVersion}/oauth/token`, data);
  const { access_token, refresh_token, token_type, expires_in } = tokenInfo;

  const newTokenInfo = {
    access_token: access_token,
    refresh_token: refresh_token,
    token_type: token_type,
    expires_in: moment()
      .add(expires_in, 'seconds')
      .toISOString(),
  };

  storage.save(AUTH_TOKEN, newTokenInfo);

  return newTokenInfo;
}

export default function oauth(apiVer, storage) {
  function requestTokens(username, password, apiVersion = apiVer) {
    const data ={
      scope: 'read',
      grant_type: NASM_OAUTH_GRANT_TYPES.PASSWORD,
      username,
      password,
    };

    return requestTokensInternal(data, storage, apiVersion);
  }

  function requestTokensByRefreshToken(refreshToken, apiVersion = apiVer) {
    const data = {
      scope: 'read',
      grant_type: NASM_OAUTH_GRANT_TYPES.REFRESH_TOKEN,
      refresh_token: refreshToken,
    };

    return requestTokensInternal(data, storage, apiVersion);
  }

  return {
    requestTokens,
    requestTokensByRefreshToken,
  };
}