import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

import dragHandlePlaceholder from '../../resources/img-sort-gray-placeholder.svg';

const useSkeletonStyles = makeStyles({
  root: {
    padding: '10px 10px 0px 0px',
  },
  rounded: {
    borderRadius: '5.5px',
  },
});

function DescriptionPlaceholder () {
  const classes = useSkeletonStyles();
  return (
    <Box className={classes.root}>
      <Skeleton animation={false} className={classes.rounded} variant='rect' width={80} height={10} />
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    padding: '15px',
  },
  outer: {
    width: '100%',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  inner: {
    paddingLeft: '40px',
    paddingTop: '5px',
  },
  placeholderContents: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: '5%',
    width: '100%',
  },
  rounded: {
    borderRadius: '5.5px',
  },
});

export default function RowPlaceholder () {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.outer}>
        <img alt='drag handle disabled' src={dragHandlePlaceholder} />
        <Box className={classes.inner}>
          <Skeleton animation={false} className={classes.rounded} variant='rect' width={140} height={11} />
          <Box className={classes.placeholderContents}>
            <DescriptionPlaceholder />
            <DescriptionPlaceholder />
            <DescriptionPlaceholder />
            <DescriptionPlaceholder />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
