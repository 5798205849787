import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

import { termsPrivacyDialogTextOptions } from '../constants';
import TermsConditionsDialog from './Dialogs/TermsConditionsDialog';
import ConfirmDialog from './Dialogs/ConfirmDialog';

function VerifyTermsPrivacy (props) {
  const {
    visible,
    requiresTc,
    requiresPp,
    onClose,
    handleAcceptTerms,
    handleAcceptPrivacy,
    loading,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);

  const type = requiresTc ? 'Terms and Conditions' : 'Privacy Policy';

  const updateWarningText = `Our ${type} ${requiresTc ? 'have' : 'has'} been updated, ` +
    `you are required to view and accept them. ` +
    `If you don't do this now, you have to log out.`;

  useEffect(() => {
    setDialogOpen(requiresTc || requiresPp);
  }, [requiresTc, requiresPp]);

  const onViewTerms = () => {
    setDialogOpen(false);
    setTermsDialogOpen(true);
  };

  const onCloseTerms = () => {
    setTermsDialogOpen(false);
    setDialogOpen(requiresPp || requiresTc);
    onClose();
  };

  const onAcceptAndContinue = () => {
    setTermsDialogOpen(false);
    if (requiresTc) {
      handleAcceptTerms();
    } else {
      handleAcceptPrivacy();
    }
  };

  if (!visible) { return null; }

  return (
    <Dialog
      open={visible}
      fullScreen
    >
      <ConfirmDialog
        open={dialogOpen}
        onClose={onClose}
        handleConfirmAction={onViewTerms}
        title={`Updated ${type}`}
        description={updateWarningText}
        cancelButtonTitle='Logout'
        actionButtonTitle='View'
      />
      <TermsConditionsDialog
        isOpen={termsDialogOpen}
        onClose={onCloseTerms}
        closeBtnText='Logout'
        onAcceptAndContinue={onAcceptAndContinue}
        textType={requiresTc ? termsPrivacyDialogTextOptions.TERMS : termsPrivacyDialogTextOptions.PRIVACY}
        loading={loading}
      />
    </Dialog>
  );
}

export default VerifyTermsPrivacy;

VerifyTermsPrivacy.propTypes = {
  visible: PropTypes.bool.isRequired,
  requiresTc: PropTypes.bool.isRequired,
  requiresPp: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleAcceptTerms: PropTypes.func.isRequired,
  handleAcceptPrivacy: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
