import * as yup from 'yup';
import invariant from 'invariant';
import { without } from 'lodash';

addAtLeastOneRequiredExtensionToYup();

const baseExerciseSchema = {
  id: yup.string(),
  sets: yup.number().min(1).max(99).integer().required('Sets is a required field'),
  weight: yup.number().min(0).max(9999),
  weight_units: yup.string(),
  rest_tempo: yup.number(),
  rest: yup.string().matches(/^\d\d:([0-5]\d)$/, 'Must be in the format - MM:SS'),

  exercise_sides: yup.string(),
  notes: yup.string(),
  distance_units: yup.string(),
  pace_units: yup.string(),

  volumeChoice: yup.string(),
  intensityChoice: yup.string().when('volumeChoice', {
    is: volumeChoice => volumeChoice === 'distance',
    then: yup.string().required('Intensity is required when Distance is selected'),
  }),
};

const volumeSchema = {
  // Volume Type ~ only 1 of the 3 values are required to be filled out
  reps: yup.number().min(1).max(99999).integer(),
  duration: yup.string()
    .matches(/^\d\d:([0-5]\d)$/, 'Must be in the format - MM:SS'),
  distance: yup.number().min(1).max(99999),
};

const intensitySchema = {
  // Intensity Type ~
  // if volume type is set to distance then,
  // only 1 of the 2 values are required to be filled out
  // otherwise, intensity type is an optional field.
  tempo: yup.number().when(['volumeChoice', 'pace', 'intensityChoice'], {
    is: (volumeChoice, pace, intensityChoice) =>
      (volumeChoice === 'distance' && !pace) || intensityChoice === 'tempo',
    then: yup.number().required('Tempo is a required field'),
  }),
  pace: yup.number().min(1).max(99999).when(['volumeChoice', 'tempo', 'intensityChoice'], {
    is: (volumeChoice, tempo, intensityChoice) =>
      (volumeChoice === 'distance' && !tempo) || intensityChoice === 'pace',
    then: yup.number().required('Pace is a required field'),
  }),
};

export const exerciseFromWorkoutValidationSchema = yup
  .object(baseExerciseSchema)
  .shape(volumeSchema)
  .atLeastOneRequired(['reps', 'duration', 'distance'])
  .shape(intensitySchema, ['tempo', 'pace']);

// May need to camelCase all of these fields to match request body fields
export const customExerciseValidationSchema = yup
  .object(baseExerciseSchema)
  .shape({
    name: yup.string().required('Name is a required field'),
    description: yup.string().required('Description is a required field'),
    fitness_component_ids: yup.array().of(yup.string()),
    muscle_group_ids: yup.array().of(yup.string()).min(1, 'Must select at least 1 Muscle Group'),
    allow_unilateral: yup.boolean(),
  })
  .shape(volumeSchema)
  .atLeastOneRequired(['reps', 'duration', 'distance'])
  .shape(intensitySchema, ['tempo', 'pace']);

// source: https://runkit.com/anber/yup---at-least-one
function addAtLeastOneRequiredExtensionToYup () {
  yup.addMethod(yup.object, 'atLeastOneRequired', function atLeastOneRequired (list) {
    invariant(list.every(field => this.fields[field]),
      'All required fields should be defined before calling atLeastOneRequired');
    return this.shape(list.reduce((acc, field) => ({
      ...acc,
      [field]: this.fields[field].when(without(list, field), {
        is: (...values) => !values.some(item => item),
        then: this.fields[field].required(
          `${field[0].toUpperCase()}${field.slice(1, field.length)} is a required field`),
      }),
    }), {}), list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map(i => [item, i])], []));
  });
}
