/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import nasmApi from "../api/endpoints";

export default function useAllActivity() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Change to null to capture error messages
  const [activities, setActivities] = useState([]);
  const selectedProfile = useSelector(
    (state) => state?.clubConnect?.selectedProfile,
  );

  const fetchActivity = useCallback(
    (
      userId = "",
      page = 1,
      size = 20,
      searchStr = "",
      filters = {},
    ) => {
      setLoading(true);
      setError(null); // Reset error state before fetching
      nasmApi.activity
        .getAllActivityFeed(
          userId,
          page,
          size,
          searchStr,
          filters,
          selectedProfile?.ClubId, // Club ID
          selectedProfile?.Locations?.Id, // Location ID
        )
        .then((data) => {
          setActivities(data.result || []); // Ensure data.result is handled correctly
        })
        .catch((error) => {
          setError(error.message || "An error occurred"); // Capture error message
        })
        .finally(() => setLoading(false));
    },
    [selectedProfile],
  );

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  return { loading, error, activities, fetchActivity };
}
