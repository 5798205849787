import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FilterButton } from '../Buttons';

// CheckMark Icons
import on from '../../resources/interaction-toggle-checkmark-multi-select-on.svg';
import off from '../../resources/interaction-toggle-checkmark-multi-select-off.svg';

const useStyles = makeStyles({
  programFilterRoot: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginLeft: 20,
  },
  menu: {
    marginTop: '10px',
  },
  menuItemCont: {
    display: 'flex',
    alignItems: 'center',
    width: '265px',
  },
  menuItemText: {
    marginLeft: '14px',
    fontWeight: 'bold',
    fontSize: '17px',
  },
});

const SelectItem = React.forwardRef((props, ref) => {
  const { selected, category, onPressFilter, index } = props;

  const classes = useStyles();
  return (
    <MenuItem ref={ref} onClick={() => onPressFilter(index)}>
      <Box className={classes.menuItemCont}>
        <img src={selected ? on : off} alt='check' />
        <Typography className={classes.menuItemText}>{category.label}</Typography>
      </Box>
    </MenuItem>
  );
});

export default function ProgramFilter (props) {
  const { categories, onPressFilter, onCloseFilters } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const onOpenFilters = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    onCloseFilters();
    setAnchorEl(null);
  };

  const selectedCount = getSelectedCount(categories);

  return (
    <Box className={classes.programFilterRoot}>
      <FilterButton
        onOpenFilterMenu={onOpenFilters}
        filterCount={selectedCount}
        isPressed={!!anchorEl}
        width={88}
      />
      <Menu
        className={classes.menu}
        id='program-filter-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          categories.map((category, index) => {
            return (
              <SelectItem
                key={index}
                category={category}
                selected={category.selected || false}
                onPressFilter={onPressFilter}
                index={index}
              />
            );
          })
        }
      </Menu>
    </Box>
  );
}

function getSelectedCount (categories) {
  return categories.filter((category) => {
    return category.selected === true;
  }).length;
}
