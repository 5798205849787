import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  scheduleHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '39px',
    margin: '28px 0',
    // In workout sections panel, the header marginTop is being
    // overwritten by a css rule in Material-UI, adding this in
    // overrides the default setting
    '&:first-child': {
      marginTop: '28px',
    },
  },
});

export default function ScheduleHeader (props) {
  const { scheduleHeader } = useStyles();
  return (
    <Typography className={scheduleHeader}>
      {props.children}
    </Typography>
  );
}
