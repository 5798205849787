import moment from 'moment';

/**
 * Get an array of the acute variable names for a given exercise
 * @param {Object} exercise - Complete exercise data
 */
export function getExerciseVariables (exercise, includeZeroValues) {
  const {
    sets, reps,
    dur_seconds, rest,
    tempo, rest_tempo,
    distance, distance_units,
    pace, pace_units,
    weight, exercise_sides, allow_unilateral: allowUnilateral, name,
  } = exercise;
  const variables = {
    reps,
    sets,
    dur_seconds,
    weight,
    distance,
    distance_units,
    pace,
    pace_units,
    tempo,
    exercise_sides,
    rest,
    rest_tempo,
    name,
  };

  if (!includeZeroValues) {
    delete variables.distance_units;
    delete variables.pace_units;
    if (reps) {
      delete variables.dur_seconds;
    }
  }

  if (!allowUnilateral) {
    delete variables.exercise_sides;
  }

  return Object.keys(variables)
    .map((key) => {
      if (key === 'exercise_sides') {
        if (variables[key]) {
          return { [key]: variables[key] };
        }
        return { [key]: 'alternate' };
      }
      if (key === 'pace_units') {
        if (variables[key]) {
          return { [key]: variables[key] };
        }
        return { [key]: 'mph' };
      }
      if (key === 'distance_units') {
        if (variables[key]) {
          return { [key]: variables[key] };
        }
        return { [key]: 'miles' };
      }
      const number = parseInt(variables[key], 10);
      if (number > 0 || (includeZeroValues && number >= 0)) return { [key]: number };
      if (includeZeroValues && isNaN(number)) {
        return { [key]: 0 };
      }
      return false;
    })
    .filter(variable => !!variable)
    .reduce((acc, val) => Object.assign(acc, val), {});
}

export function getTempoStringForExercise (exercise) {
  switch (exercise.tempo) {
    case 1:
      return 'Slow';
    case 2:
      return 'Medium';
    case 3:
      return 'Fast';
    case 4:
      return 'Explosive';
    default:
      return 'n/a';
  }
}

export function getRestTempoStringForExercise (exercise) {
  switch (exercise.rest_tempo) {
    case 1:
      return 'Rest';
    case 2:
      return 'Slow';
    default:
      return 'n/a';
  }
}

export function getSideStringForExercise (exercise) {
  switch (exercise.exercise_sides) {
    case 'right_only':
      return 'Right';
    case 'left_only':
      return 'Left';
    case 'alternate':
      return 'Alternating';
    case 'each_side':
      return 'Each';
    default:
      return 'n/a';
  }
}

export function abbreviateDistanceUnit (unit) {
  switch (unit.toLowerCase()) {
    case 'miles':
      return 'mi';
    case 'yards':
      return 'yd';
    case 'meters':
      return 'm';
    default:
      return unit;
  }
}

// Copied from NASM EDGE Mobile app repo
export function getDurationFromSeconds (seconds, appendPlus) {
  if (!seconds) {
    return `0${appendPlus ? '+' : ''} min`;
  } else if (seconds > 0 && seconds < 30) {
    return `${seconds}${appendPlus ? '+' : ''} sec`;
  }
  const duration = moment.duration(seconds, 'seconds');
  let minutes = Math.floor(duration.asMinutes());
  const remainingSeconds = duration.seconds();
  if (remainingSeconds >= 30) {
    minutes += 1;
  }
  return `${minutes}${appendPlus ? '+' : ''} min`;
}

// Copied from NASM EDGE Mobile app repo
/* eslint-disable camelcase */
export function calculateSecondsForExercise (exercise) {
  let {
    dur_seconds = 0,
    reps = 1, sets = 1,
    tempo = 0,
    rest = 0,
    distance = 0,
    distance_units = 'miles',
    pace = 0,
    pace_units = 'mph',
    exercise_sides,
    allow_unilateral,
  } = exercise;
  let seconds = 0;

  if (reps === 0) {
    reps = 1;
  }
  if (sets === 0) {
    sets = 1;
  }

  let side = 1;
  if (allow_unilateral && exercise_sides === 'each_side') {
    side = 2;
  }

  if (dur_seconds !== 0 && dur_seconds !== null) {
    seconds = ((dur_seconds * side) + rest) * sets;
  } else if (distance !== 0 && distance != null) {
    if (pace) {
      switch (pace_units) {
        case 'mph':
          seconds = ((((distanceInMiles(distance, distance_units) / pace) * 3600) * side) + rest) * sets;
          break;
        case 'rpm':
          seconds = 0;
          // We are not calculating duration for RPM as a proper formula would require more variables than
          // we would like to keep track of
          // this would be the most accurate formula with our current variables:
          //
          // seconds = (((distanceInMiles(distance, distance_units) / ((distanceInMiles(27.5 * 3.14, 'inches') * pace)
          // / 60)) * side) + rest) * sets;
          // note: 27.5 inches is the average diameter of a road bike tire              ^^^
          //
          // this is still incomplete. This formula doesn't take into account the gear ratio which will vary depending
          // on the bike and the user
          // as the rpm measured is the rotation of the bike pedals, not the tire
          // the formula currently assumes a 1:1 gear ratio
          break;
        case 'watts':
          seconds = ((((Math.cbrt(2.8 / pace) * distanceInMeters(distance, distance_units))) * side) + rest) * sets;
          break;
        default:
          break;
      }
    }
  } else {
    switch (tempo) {
      case 1:
        seconds = 5;
        break;
      case 2:
        seconds = 3;
        break;
      case 3:
        seconds = 2;
        break;
      case 4:
        seconds = 1;
        break;
      default:
        seconds = 3;
        break;
    }
    seconds = (((reps * seconds) * side) + rest) * sets;
  }

  return seconds;
}
/* eslint-enable camelcase */

// Copied from NASM EDGE Mobile app repo
function distanceInMiles (distance, unit) {
  switch (unit) {
    case 'miles':
      return distance;
    case 'yards':
      return distance / 1760;
    case 'meters':
      return distance / 1609.344;
    case 'inches':
      return distance / 63360;
    default:
      return distance;
  }
}

// Copied from NASM EDGE Mobile app repo
function distanceInMeters (distance, unit) {
  switch (unit) {
    case 'miles':
      return distance * 1609.344;
    case 'yards':
      return distance / 1.094;
    case 'meters':
      return distance;
    default:
      return distance;
  }
}

export const formatSuperSets = (workouts = []) => {
  const getUpdatedWorkouts = workouts.map((workout) => {
    const getUpdatedSections = workout.sections.map((sec) => {
      const sectionExercises = combineExercisesAndSuperSets(sec.exercises);
      return {
        ...sec,
        exercises: sectionExercises,
      };
    });
    return {
      ...workout,
      sections: getUpdatedSections,
    };
  });
  return getUpdatedWorkouts;
};

export const combineExercisesAndSuperSets = (originalExercises = []) => {
  const isAlreadyFormatted = originalExercises.some(
    (ex) => 'compound_routine_exercises' in ex,
  );
  if (isAlreadyFormatted) {
    return originalExercises;
  }
  const exercises = originalExercises.reduce((acc, i) => {
    const { compound_routine_id } = i;
    return {
      ...acc,
      [compound_routine_id]: acc[compound_routine_id]
        ? [...acc[compound_routine_id], i].sort((a, b) => a.ordinal - b.ordinal)
        : [i],
    };
  }, {});

  let output = [];
  const keys = Object.keys(exercises);
  keys.forEach((i, index) => {
    const exercise = [...exercises[i]].map((j) => {
      const qq = { ...j };
      delete qq.compound_routine;
      return qq;
    });
    if (i === 'null') {
      output.push(...exercise);
    } else {
      const compoundRoutine = {
        ...exercises[i][0].compound_routine,
        compound_routine_id: exercises[i][0].compound_routine_id,
        ordinal: exercises[i][0].ordinal ?? index,
        compound_routine_exercises: [...exercise],
      };
      if (!compoundRoutine.title && exercises[i][0].compound_routine_title) {
        compoundRoutine.title = exercises[i][0].compound_routine_title;
      }
      if (!compoundRoutine.reps && exercises[i][0].compound_routine_reps) {
        compoundRoutine.reps = exercises[i][0].compound_routine_reps;
      }
      if (!compoundRoutine.rest && exercises[i][0].compound_routine_rest) {
        compoundRoutine.rest = exercises[i][0].compound_routine_rest;
      }
      if (
        !compoundRoutine.routine_type
        && exercises[i][0].compound_routine_type
      ) {
        compoundRoutine.routine_type = exercises[i][0].compound_routine_type;
      }
      output.push(compoundRoutine);
    }
  });
  output = output.sort((a, b) => a.ordinal - b.ordinal);
  return output;
};

export const unformatSuperSets = (workouts = []) => {
  const getUpdatedWorkouts = workouts.map((workout) => {
    const getUpdatedSections = workout.sections.map((sec) => {
      const sectionExercises = uncombineExercisesAndSuperSets(sec.exercises);
      return {
        ...sec,
        exercises: sectionExercises,
      };
    });
    return {
      ...workout,
      sections: getUpdatedSections,
    };
  });
  return getUpdatedWorkouts;
};

export const uncombineExercisesAndSuperSets = (originalExercises = []) => {
  const exercises = [];
  originalExercises.forEach((ex) => {
    if ('compound_routine_exercises' in ex) {
      ex.compound_routine_exercises.forEach((supersetEx) => {
        const cr = {
          ...supersetEx,
          compound_routine_id: ex.compound_routine_id || ex.id,
          compound_routine_title: ex.title,
          compound_routine_reps: ex.reps,
          compound_routine_rest: ex.rest,
          compound_routine_type: ex.routine_type,
        };
        exercises.push(cr);
      });
    } else if ('exercise' in ex) {
      const cr = {
        ...ex.exercise,
        compound_routine_id: ex.compound_routine_id || ex.id,
        compound_routine_title: ex.compound_routine_title || ex.title,
        compound_routine_reps: ex.compound_routine_reps || ex.reps,
        compound_routine_rest: ex.compound_routine_rest || ex.rest,
        compound_routine_type: ex.compound_routine_type || ex.routine_type,
      };
      exercises.push(cr);
    } else {
      exercises.push(ex);
    }
  });
  return exercises.sort((a, b) => a.ordinal - b.ordinal);
};

export function getDurationForSection({
  section,
  isSuperSetOrCircuit,
  exercises = [],
}) {
  let seconds = 0;
  let appendPlus = false;
  if (isSuperSetOrCircuit && section?.compound_routine_exercises?.length) {
    section.compound_routine_exercises.forEach((supersetEx) => {
      const exerciseSeconds = calculateSecondsForExercise(supersetEx);
      seconds += exerciseSeconds;
      if (exerciseSeconds === 0) {
        appendPlus = true;
      }
    });
  } else if (section.exercises) {
    section.exercises.forEach((exerciseId) => {
      const exercise = exercises[exerciseId];
      let exerciseSeconds = 0;
      if ('compound_routine_exercises' in exercise) {
        // eslint-disable-next-line no-unused-expressions
        exercise.compound_routine_exercises?.forEach((supersetEx) => {
          exerciseSeconds = calculateSecondsForExercise(supersetEx);
          seconds += exerciseSeconds;
        });
      } else {
        exerciseSeconds = calculateSecondsForExercise(exercise);
        seconds += exerciseSeconds;
      }
      if (exerciseSeconds === 0) {
        appendPlus = true;
      }
    });
  }

  return getDurationFromSeconds(seconds, appendPlus);
}

export function calculateSecondsForWorkout(workout) {
  if (!workout) return 0;
  let seconds = 0;
  const sections = workout.sections || [];
  sections.forEach((section) => {
    const exercises = section.exercises || [];
    exercises.forEach((exercise) => {
      seconds += calculateSecondsForExercise(exercise);
    });
  });
  return seconds;
}
