import { makeStyles } from '@material-ui/core/styles';
import EditButtonActiveSrc from '../../../../resources/button-edit-upload-active.svg';
import EditButtonSrc from '../../../../resources/button-edit-upload.svg';
import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useEditButtonStyles = makeStyles({
  editButton: props => ({
    width: 38,
    height: 38,
    margin: 10,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: props.isClicked ? `url(${EditButtonActiveSrc})` : `url(${EditButtonSrc})`,
    '&:hover': {
      backgroundImage: `url(${EditButtonActiveSrc})`,
    },
    cursor: 'pointer',
  }),
});

function EditUploadButton (props) {
  const { onClick, isClicked } = props;
  const classes = useEditButtonStyles({ isClicked });
  return (
    <Box onClick={onClick} className={classes.editButton}>
      {props.children}
    </Box>
  );
}

EditUploadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isClicked: PropTypes.bool.isRequired,
};

export default EditUploadButton;
