import {colors} from '../../../styles';

export const DAY_COLORS = Object.freeze({
  SELECTED: {
    backgroundColor: '#16161d',
    textColor: colors.white,
  },
  COMPLETE: {

    backgroundColor: 'rgba(44, 119, 92, 0.25)',
    textColor: colors.dark_grey,
  },
  INCOMPLETE: {
    backgroundColor: 'rgba(206, 130, 9, 0.25)',
    textColor: colors.dark_grey,
  },
  UPCOMING: {
    backgroundColor: 'rgba(232, 234, 246, 0.66)',
    textColor: colors.dark_grey,
  },
  NONE: {
    backgroundColor: 'transparent',
    textColor: colors.dark_grey,
  },
});

export const DAY_COLOR_STATES = Object.keys(DAY_COLORS);
