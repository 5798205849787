import React from 'react';
import { styled } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { colors } from '../../styles';

// Passing in props that is not a valid css property will cause React to throw a warning in the console.
// To prevent the warning from showing in the console, the showError prop is exposed here
// as part of a lambda function declaration. This prevents showError prop from being sent to the DOM but still
// applies the conditional logic needed to style the Input on error.
// https://github.com/styled-components/styled-components/issues/135
const OvalInput = styled(({ showError, ...otherProps }) => <InputBase {...otherProps} />)({
  height: props => props.height || '38px',
  width: props => props.width || '100%',
  fontSize: props => props.fontSize || 16,
  borderRadius: props => props.borderRadius || '19px',
  backgroundColor: props => props.disabled ? '#ccd1d6' : '#ecf0f3',
  border: props => props.showError ? `solid 1px ${colors.colors_fill_dark_yellow_2}` : 'none',
  padding: '0 20px',
  paddingLeft: props => props.startAdornment ? 0 : undefined,
  paddingRight: props => props.endAdornment ? 0 : undefined,
  fontFamily: 'DMSans, sans-serif',
  fontWeight: 500,
  shadowColor: 'rgba(0, 0, 0, 0.18)',
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowRadius: 1,
  shadowOpacity: 1,
  '&:hover': {
    // Using box shadow instead of border since box shadow doesn't affect the height of the input
    boxShadow: props => !props.disabled ? `0 0 0 1.5pt ${colors.carbon_black}` : 'none',
    // Focused styles should take priority when hovering AND focused
    '&.Mui-focused': {
      boxShadow: `0 0 0 1.8pt ${colors.carbon_black}`,
    },
  },
  '&.Mui-focused': {
    boxShadow: `0 0 0 1.8pt ${colors.carbon_black}`,
  },
});

export default function OvalTextInput (props) {
  return (
    <OvalInput {...props} />
  );
}
