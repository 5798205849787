import request from '../nasm_sdk_v2/request';

export default function client(apiVer) {
  function getCredentials(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/credentials`);
  }

  function updateGoals(data, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/users/client/goals`, data);
  }

  return {
    getCredentials,
    updateGoals,
  };
}
