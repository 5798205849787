import { combineReducers } from 'redux';
import currentUser from './currentUserReducer';
import programContext from './programContextReducer';
import selectedClient from './selectedClientReducer';
import selectedGroup from './selectedGroupReducer';
import selectedProgram from './selectedProgramReducer';
import selectedWorkout from './selectedWorkoutReducer';
import workouts from './workoutsReducer';
import exercises from './exercisesReducer';
import clubConnect from './clubConnectReducer';
import compoundRoutines from './compoundRoutineReducer';

export default combineReducers({
  currentUser,
  programContext,
  selectedProgram,
  selectedWorkout,
  selectedClient,
  selectedGroup,
  workouts,
  exercises,
  clubConnect,
  compoundRoutines,
});
