// Libraries
import React from 'react';
import { render } from 'react-dom';

// Sentry Error Tracking
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/* eslint-disable-next-line no-unused-vars */
import TagManager from 'react-gtm-module';
// Local
import App from './App';

// Side effects
import 'fontsource-roboto';

import { VERSION_NUMBER } from './constants';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_CONTAINER_ID,
});

Sentry.init({
  dsn: 'https://c0f6543289a74e498a64f06fc5667240@o363320.ingest.sentry.io/5895151',
  integrations: [new Integrations.BrowserTracing()], // required for tracking transactions
  environment: process.env.REACT_APP_DEPLOY_ENV, // Current build (DEV, STAGE or PROD)
  autoSessionTracking: true,
  release: VERSION_NUMBER, // Web app version (e.g. v0.11.0)
  tracesSampleRate: 1.0, // 100% of transactions recorded and sent to Sentry (can be adjusted)
});

render(<App />, document.querySelector('#root'));
