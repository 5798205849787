/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import nasmApi from '../../api/endpoints';

export default function useFetchLatestNutrition (clientUserId) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nutritionData, setNutritionData] = useState({});

  const fetchNutrition = useCallback(() => {
    setLoading(true);
    nasmApi.users.getLatestNutrition(clientUserId)
    .then(resp => {
      setNutritionData(resp.result);
      setError(false);
    })
    .catch(e => setError(true))
    .finally(() => setLoading(false));
  }, [clientUserId]);
  
  useEffect(() => {
    fetchNutrition();
  }, [fetchNutrition]);

  useEffect(() => {
    window.addEventListener('updateNutritionCard', fetchNutrition);
    return () => {
      window.removeEventListener('updateNutritionCard', fetchNutrition);
    };
  }, [fetchNutrition]);

  return {
    loading,
    error,
    nutritionData,
  };
}