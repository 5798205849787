import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CSSTransition } from 'react-transition-group';

import Panel from '../Panels/Panel';

const useStyles = makeStyles({
  transitionEnter: {
    flex: 0,
  },
  transitionEnterActive: {
    flex: 1,
    '-webkit-transition': 'all 300ms ease-in-out',
    '-moz-transition': 'all 300ms ease-in-out',
    '-o-transition': 'all 300ms ease-in-out',
    '-ms-transition': 'all 300ms ease-in-out',
    transition: 'all 300ms ease-in-out',
  },
  transitionEnterDone: {
    flex: 1,
  },
  transitionExit: {
    flex: 1,
  },
  transitionExitActive: {
    flex: 0,
    '-webkit-transition': 'all 300ms ease-in-out',
    '-moz-transition': 'all 300ms ease-in-out',
    '-o-transition': 'all 300ms ease-in-out',
    '-ms-transition': 'all 300ms ease-in-out',
    transition: 'all 300ms ease-in-out',
  },
});

export default function SlidingFlexTransition (props) {
  const classes = useStyles(props);
  const { visible } = props;

  const [isEnterAnimFinished, setIsEnterAnimFinished] = useState(false);

  function onAnimationEntered (node, isAppearing) {
    setIsEnterAnimFinished(true);
  }

  function onAnimationExited (node) {
    setIsEnterAnimFinished(false);
  }

  return (
    <CSSTransition
      timeout={{ enter: 300, exit: 300 }}
      in={visible}
      mountOnEnter
      unmountOnExit
      appear
      classNames={{
        enter: classes.transitionEnter,
        enterActive: classes.transitionEnterActive,
        enterDone: classes.transitionEnterDone,
        exit: classes.transitionExit,
        exitActive: classes.transitionExitActive,
      }}
      onEntered={onAnimationEntered}
      onExited={onAnimationExited}
    >
      <>
        {/* Placeholder Panel used until transition finishes.
        This will let the code mount props.children once transition ends. */}
        {!isEnterAnimFinished && <Panel />}
        {isEnterAnimFinished && props.children}
      </>
    </CSSTransition>
  );
}
