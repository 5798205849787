import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

import checkmarkOffFilter from '../../resources/interaction-toggle-checkmark-multi-select-off.svg';
import checkmarkOnFilter from '../../resources/interaction-toggle-checkmark-multi-select-on.svg';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      // disables secondary color overlay
      backgroundColor: 'transparent',
    },
  },
  checkboxRoot: {
    padding: '0 12px',
  },
  icon: {
    width: 20,
    height: 20,
    boxShadow: 'none',
    backgroundImage: `url(${checkmarkOffFilter})`,
  },
  checkedIcon: {
    width: 20,
    height: 20,
    backgroundImage: `url(${checkmarkOnFilter})`,
  },
});

export default function SquareCheckbox (props) {
  const { onToggle, isChecked } = props;
  const classes = useStyles();
  return (
    <Checkbox
      color='default'
      className={classes.root}
      disableRipple
      checked={isChecked}
      checkedIcon={<span className={classes.checkedIcon} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      classes={{ root: classes.checkboxRoot }}
      onChange={e => {
        if (onToggle) {
          onToggle(e.target.checked);
        }
      }}
    />
  );
}
