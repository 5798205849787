import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import querystring from 'query-string';
import { colors } from '../../styles';
import {
  initReducer,
  WORKOUT_BUTTONS_VISIBILITY_ACTIONS,
  workoutButtonsVisibilityReducer,
} from '../../reducers/local/workoutButtonsVisibilityReducer';
import { updateQuery } from '../../util/utilFunctions';
import { PageHeader } from '../../components';

import ClientBreadcrumbs from '../../components/ClientDashboard/ClientBreadcrumbs';
import PanelContainer from '../../components/Panels/PanelContainer';
import { rescheduleProgramContext } from '../../reducers/programContextReducer';
import WorkoutSectionExerciseContext from '../../components/WorkoutSectionExerciseContext';
import EditExercisePanel from '../../components/Panels/EditExercisePanel';
import ExerciseDetailsPanel from '../../components/Panels/ExerciseDetailsPanel';

import ScheduleWorkoutVideoPanelWrapper from '../../components/Workouts/ScheduleWorkoutVideoPanelWrapper';

import { selectWorkout } from '../../reducers/selectedWorkoutReducer';
import { selectScheduledProgram } from '../../reducers/selectedProgramReducer';
import AddSuperSetsPanel from '../../components/Panels/AddSuperSetsPanel';
import AddCircuitsPanel from '../../components/Panels/AddCircuitsPanel';

const useStyles = makeStyles({
  root: {
    height: '100%',
    backgroundColor: colors.white,
  },
});

const views = {
  workoutDetails: {
    key: 'workoutDetails',
    workoutDetails: true,
  },
  addExercises: {
    key: 'addExercises',
    workoutDetails: true,
    addExercises: true,
  },
  editExercise: {
    key: 'editExercise',
    editExercise: true,
    workoutDetails: true,
  },
  viewExercise: {
    key: 'viewExercise',
    addExercises: true,
    viewExercise: true,
    workoutDetails: false,
  },
  videoWorkout: {
    key: 'videoWorkout',
    videoWorkout: true,
    workoutDetails: false,
  },
  superSetDetails: {
    key: 'superSetDetails',
    workoutDetails: true,
    superSetDetails: true,
  },
  superSetExerciseDetails: {
    key: 'superSetExerciseDetails',
    superSetDetails: true,
    viewExercise: true,
    workoutDetails: false,
  },
  circuitDetails: {
    key: 'circuitDetails',
    workoutDetails: true,
    circuitDetails: true,
  },
  circuitExerciseDetails: {
    key: 'circuitExerciseDetails',
    circuitDetails: true,
    viewExercise: true,
    workoutDetails: false,
  },
  progressionRegressionExDetails: {
    key: 'progressionRegressionExDetails',
    progressionRegressionExDetails: true,
    viewExercise: true,
    workoutDetails: true,
  },
};

// this needs to include video workout panel
// if video workout is selected, then video workout panel should be displayed
function CustomizeWorkout(props) {
  const {
    pathMap,
    sectionIds,
    client,
  } = props;

  const history = useHistory();
  const location = history.location;
  const dispatch = useDispatch();
  const classes = useStyles();

  const query = querystring.parse(history.location.search, { parseBooleans: true, parseNumbers: true });
  const view = query?.view || 'workoutDetails';

  const clientFirstName = client.first_name.toLowerCase() ?? 'client';
  const clientFirstNameUpper = clientFirstName?.charAt(0).toUpperCase() + clientFirstName.slice(1) ?? 'Client';
  const programId = query?.programId;
  const scheduleId = query?.scheduleId;
  const isScheduledProgram = location?.state?.isScheduledProgram || false;

  const programStartDate = query?.programStartDate || '';
  const programEndDate = query?.programEndDate || programStartDate || '';

  const [workoutButtonsVisibilityFlags, localDispatch] =
    useReducer(workoutButtonsVisibilityReducer, {}, initReducer);

  const [selectedExerciseDraggableId, setSelectedExerciseDraggableId] = useState('none');
  const resetSelectedExercise = () => setSelectedExerciseDraggableId('none');
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});

  function updateButtonVisibilityFlags(
    {
      isCopyButtonVisible,
      isSaveButtonVisible,
      isAddExercisesButtonVisible,
      isAddButtonVisible,
    },
  ) {
    localDispatch({
      type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS,
      payload: {
        showCopyButton: isCopyButtonVisible,
        showSaveButton: isSaveButtonVisible,
        showAddExercisesButton: isAddExercisesButtonVisible,
        showAddButton: isAddButtonVisible,
      },
    });
  }

  useEffect(() => {
    updateButtonVisibilityFlags({
      isCopyButtonVisible: false,
      isSaveButtonVisible: false,
      isAddExercisesButtonVisible: true,
      isAddButtonVisible: false,
    });
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(rescheduleProgramContext());
  }, [dispatch]);

  useEffect(() => {
    dispatch(selectScheduledProgram({
      program_id: programId,
      schedule_id: scheduleId,
      programStartDate,
      programEndDate,
    })).then(program => {
      if (!isScheduledProgram) {
        if (program.workouts && program.workouts.length > 0) {
          const scheduledWorkout = program.workouts[0];
          dispatch(selectWorkout(scheduledWorkout, true, false));
        }
      }
    });
  }, [dispatch, programId, scheduleId, isScheduledProgram, programStartDate, programEndDate]);

  const backLink = () => {
    return { pathname: `/clients/my-clients/${clientFirstName.toLowerCase()}/dashboard/calendar` };
  };

  const viewWorkoutDetailsLink = () => {
    const query = updateQuery(history.location.search, { view: views.workoutDetails.key, programId });
    return `/clients/my-clients/${clientFirstName}/dashboard/calendar/customize-workout?${query}`;
  };

  const addExercisesListLink = () => {
    return updateQuery(location.search, { view: views.addExercises.key, exerciseId: undefined });
  };

  const editExerciseLink = () => {
    return updateQuery(location.search, { view: views.editExercise.key });
  };

  const viewExerciseLink = (exerciseId, toggleWorkoutDetailsVisibility = false, showProgressionsRegressions = true) => {
    // toggle secondary panel on the right-hand side to view
    views.viewExercise.workoutDetails = toggleWorkoutDetailsVisibility;
    views.viewExercise.addExercises = !toggleWorkoutDetailsVisibility;

    return updateQuery(location.search, { 
      view: views.viewExercise.key, 
      exerciseId: exerciseId, 
      showProgressionsRegressions,
    });
  };

  const viewSuperSetsLink = () => {
    return updateQuery(location.search, { view: views.superSetDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      view: views.superSetExerciseDetails.key, 
      exerciseId, 
      exerciseKey, 
      showProgressionsRegressions });
  };

  const viewCircuitLink = () => {
    return updateQuery(location.search, { view: views.circuitDetails.key });
  };

  const viewCircuitExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      view: views.circuitExerciseDetails.key,
      exerciseId, 
      exerciseKey, 
      showProgressionsRegressions,
    });
  };

  const viewProgressionRegressionExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    if(views[view].superSetExerciseDetails){
      views.progressionRegressionExDetails.superSetDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else if (views[view].circuitExerciseDetails) {
      views.progressionRegressionExDetails.circuitDetails = true;
      views.progressionRegressionExDetails.superSetDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else {
      views.progressionRegressionExDetails.workoutDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.superSetDetails = false;
    }
    return updateQuery(location.search, {
      view: views.progressionRegressionExDetails.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const queryMap = {
    workoutDetails: [
      { label: 'Edit Workout', link: viewWorkoutDetailsLink },
    ],
    addExercises: [
      { label: 'Edit Workout', link: viewWorkoutDetailsLink },
      { label: 'Add Exercises', link: addExercisesListLink },
    ],
    editExercise: [
      { label: 'Edit Workout', link: viewWorkoutDetailsLink },
      { label: 'Exercise Details', link: () => { } }, // this link will never be clickable
    ],
    viewExercise: [
      { label: 'Edit Workout', link: viewWorkoutDetailsLink },
      { label: 'Add Exercises', link: addExercisesListLink },
      { label: 'View Exercise', link: () => { } }, // this link will never be clickable
    ],
    videoWorkout: [
      { label: 'Edit Workout Video', link: () => { } }, // this link will never be clickable
    ],
    superSetDetails: [
      { label: 'Superset Details', link: () => { } }, // this link will never be clickable
    ],
    superSetExerciseDetails: [
      { label: 'Superset Details', link: () => { } }, // this link will never be clickable
    ],
    circuitDetails: [
      { label: 'Circuit Details', link: () => { } }, // this link will never be clickable
    ],
    circuitExerciseDetails: [
      { label: 'Circuit Details', link: () => { } }, // this link will never be clickable
    ],
    progressionRegressionExDetails: [
      { label: 'progression Regression Details', link: () => { } }, // this link will never be clickable
    ],
  };

  return (
    <Box className={classes.root}>
      <PageHeader
        title={`Customize ${clientFirstNameUpper}'s Workout`}
        color={colors.graphite}
        height={120}
      />
      <ClientBreadcrumbs
        paddingTop={21}
        paddingLeft='8%'
        ariaLabel='client-schedule-breadcrumbs'
        pathMap={pathMap}
        queryMap={queryMap}
        view={view === 'programList' ? '' : view}
        pathNamesExclusionList={['clients', clientFirstName]}
      />
      <PanelContainer>
        <ScheduleWorkoutVideoPanelWrapper
          visible={!!views[view].videoWorkout}
          showSaveButton
          showDeleteButton={false}
          showScheduleButton={false}
          headerText={'Video Workout Details'}
        />
        <VideoPanelPadding visible={!!views[view].videoWorkout} />
        <WorkoutSectionExerciseContext
          isWorkoutSectionsVisible={!!views[view].workoutDetails}
          isAddExercisesVisible={!!views[view].addExercises}
          sectionIds={sectionIds}

          // Navigation
          currentView={view}
          addExercisesKey={views.addExercises.key}
          addExercisesListLink={addExercisesListLink}
          backLink={backLink}
          copyWorkoutLink={() => { }}
          editExerciseLink={editExerciseLink}
          viewWorkoutDetailsLink={viewWorkoutDetailsLink}
          viewExerciseLink={viewExerciseLink}

          resetWorkoutIndex={() => { }}
          selectedExerciseDraggableId={selectedExerciseDraggableId}
          setSelectedExerciseDraggableId={setSelectedExerciseDraggableId}
          resetSelectedExercise={resetSelectedExercise}
          workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          viewSuperSetsLink={viewSuperSetsLink}
          setSuperSetData={setSuperSetData}
          viewCircuitLink={viewCircuitLink}
          setCircuitsData={setCircuitsData}
        />
        <AddSuperSetsPanel
          visible={!!views[view].superSetDetails}
          resetSelection={resetSelectedExercise}
          backLink={viewWorkoutDetailsLink}
          superSetData={superSetData}
          setSuperSetData={setSuperSetData}
          viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
          isAutoSaveEnabled
        />
        <AddCircuitsPanel
          visible={!!views[view].circuitDetails}
          resetSelection={resetSelectedExercise}
          backLink={viewWorkoutDetailsLink}
          circuitData={circuitData}
          setCircuitsData={setCircuitsData}
          viewCircuitExDetailsLink={viewCircuitExDetailsLink}
          isAutoSaveEnabled
        />
        <EditExercisePanel
          backLink={viewWorkoutDetailsLink}
          visible={!!views[view].editExercise}
          isAutoSaveEnabled
          viewExerciseDetailsLink={viewProgressionRegressionExDetailsLink}
        />
        <ExerciseDetailsPanel
          visible={!!views[view].viewExercise}
          isAutoSaveEnabled={workoutButtonsVisibilityFlags.isAutoSaveEnabled}
          backLink={views.viewExercise.workoutDetails ? viewWorkoutDetailsLink
            : views[view].superSetDetails ? viewSuperSetsLink : views[view].circuitDetails ? viewCircuitLink
              : addExercisesListLink}
          resetSelection={() => { }}
          isRescheduling
          viewExerciseDetailsLink={
            views[view].superSetDetails ? viewSuperSetExDetailsLink 
            : views[view].circuitDetails ? viewCircuitExDetailsLink 
            : viewProgressionRegressionExDetailsLink}
        />
      </PanelContainer>
    </Box>
  );
}

const Padding = styled(Box)({
  flex: 1,
});

/**
 * @return {JSX|null}
 */
function VideoPanelPadding(props) {
  const { visible } = props;
  if (!visible) {
    return null;
  }

  return <Padding />;
}

export default CustomizeWorkout;
