import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Exercises, Workouts, Programs } from './';

import { programLibrary } from '../../reducers/programContextReducer';
import { getWorkoutSections } from '../../reducers/workoutsReducer';
import { ExercisesProvider } from '../../contexts/ExercisesContext';
import { WorkoutsProvider } from '../../contexts/WorkoutsContext';

export default function Libraries (props) {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(programLibrary());
  }, [dispatch]);

  const defaultSections = useSelector(state => state.workouts.defaultSections);
  useEffect(() => {
    if (defaultSections.length === 0) {
      dispatch(getWorkoutSections());
    }
  }, [dispatch, defaultSections]);

  const sectionIds = defaultSections.map(section => section.id);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/exercises`} />
      </Route>
      <Route path={`${path}/exercises`}>
        <ExercisesProvider>
          <Exercises />
        </ExercisesProvider>
      </Route>
      <Route path={`${path}/workouts`}>
        <WorkoutsProvider>
          <Workouts sectionIds={sectionIds} />
        </WorkoutsProvider>
      </Route>
      <Route path={`${path}/programs`}>
        <Programs sectionIds={sectionIds} />
      </Route>
      {/* Redirect to exercises if subsection is invalid */}
      <Route path={`${path}/*`}>
        <Redirect to={`${path}/exercises`} />
      </Route>
    </Switch>
  );
}
