import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';

const useStyles = makeStyles({
  clientListSkeleton: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  searchFilterSkeleton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

/**
 * @return {JSX|null}
 */

export default function ClientListSkeleton (props) {
  const classes = useStyles();
  const { visible } = props;

  if(!visible) {
    return null;
  }

  const clientsList = [];
  for (let i = 0; i < 27; ++i) {
    clientsList.push(<ClientAvatarSkeleton key={i} />);
  }

  return (
    <Container disableGutters style={{ marginBottom: 10 }}>
      <Box className={classes.searchFilterSkeleton}>
        <Skeleton
          variant='rect'
          style={{
            borderRadius: 19,
            marginLeft: 15,
            marginRight: 14,
          }}
          width={175}
          height={38}
        />
        <Skeleton
          variant='rect'
          style={{
            borderRadius: 19,
            marginRight: 23,
          }}
          width={68}
          height={38}
        />
        <Skeleton
          variant='circle'
          style={{
            marginRight: 14,
          }}
          width={36}
          height={36}
        />
        <Skeleton
          variant='circle'
          width={36}
          height={36}
        />
      </Box>
      <Box className={classes.clientListSkeleton}>
        {clientsList}
      </Box>
    </Container>
  );
}

const useClientAvatarStyle = makeStyles({
  clientAvatarSkeleton: {
    marginRight: 40,
    marginTop: 20,
    marginBottom: 20,
  },
});

function ClientAvatarSkeleton () {
  const classes = useClientAvatarStyle();
  return (
    <Box className={classes.clientAvatarSkeleton}>
      <Skeleton variant='circle' width={88} height={88} />
      <Skeleton
        variant='rect'
        width={93}
        height={18}
        style={{ marginTop: 5 }}
      />
    </Box>
  );
}
