import request from '../nasm_sdk_v2/request';
import moment from 'moment';

export default function workoutSchedule(apiVer) {
  function getWorkoutsByMonth({
    userId,
    month,
    year,
  }, apiVersion = apiVer) {
    const currentTime = moment().toISOString(true);
    return request.get(`${apiVersion}/api/users/${userId}/month-workouts`, {
      params: { month, year, currentTime },
    });
  }

  function scheduleClientProgram(userId, programSchedule, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/${userId}/schedule-workout`, programSchedule);
  }

  function rescheduleClientProgram(userId, programId, programSchedule, apiVersion = apiVer) {
    return request.put(
      `${apiVersion}/api/users/${userId}/nasm-program/${programId}/reschedule`,
      programSchedule);
  }

  function getUserAssignedProgramDetails({
    userId,
    scheduleId,
    apiVersion = apiVer,
    program_start_date: programStartDate,
    program_end_date: programEndDate,
  }) {
    return request.get(`${apiVersion}/api/users/${userId}/schedule/${scheduleId}`, {
      params: {
        program_start_date: programStartDate,
        program_end_date: programEndDate,
      },
    });
  }

  function deleteClientProgram(userId, scheduleId, currentDate, apiVersion = apiVer) {
    const data = { userId, scheduleId, currentDate };
    return request.put(`${apiVersion}/api/users/trainer/cancel/workout`, data);
  }

  return {
    getWorkoutsByMonth,
    scheduleClientProgram,
    rescheduleClientProgram,
    getUserAssignedProgramDetails,
    deleteClientProgram,
  };
}