import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Local
import { colors } from '../styles';

const useStyles = makeStyles({
  toolBarContainer: props => ({
    paddingLeft: 133,
    paddingRight: 25,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    backgroundColor: props?.color ?? colors.lightBlue,
    minHeight: 40,
  }),
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 40,
    opacity: 0.75,
    fontSize: 17,
    fontWeight: 'bold',
    fontFamily: 'DMSans, sans-serif',
    textTransform: 'capitalize',
    textDecoration: 'none',
    color: colors.white,
    borderBottom: '5px solid transparent',
    transition: 'all 200ms ease-in-out',
    marginRight: '30px',
    '&:hover': {
      opacity: 1,
      color: colors.white,
      transition: 'all 10ms ease-in-out',
    },
    '&:active': {
      opacity: 0.75,
      transition: 'opacity 10ms ease-in-out',
    },
  },
  navLinkActive: {
    opacity: 1,
    borderBottomColor: colors.yellow_button_style,
    borderBottomWidth: '5px',
  },
});

const defaultButton = '/libraries/workouts/my-workouts/?view=workoutDetails&workoutId=new';

export default function PageToolBar ({
  linkOptions = [],
  buttonTo = defaultButton,
  buttonDisabled = false,
  onClickFloatingButton = (e) => {},
  color,
}) {
  const classes = useStyles({ color });

  return (
    <>
      <div className={classes.toolBarContainer}>
        {linkOptions && linkOptions.map(link => (
          <NavLink
            key={`link${link.label}`}
            to={link.to}
            className={classes.link}
            activeClassName={classes.navLinkActive}
          >{link.label}
          </NavLink>
        ))}
        {!linkOptions && <div className={classes.link} />}
      </div>
    </>
  );
}
