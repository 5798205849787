import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { useClientsContext } from '../../contexts/ClientsContext';
import AddNewClientForm from './AddNewClientForm';
import HealthWarning from './HealthWarning';
import SetGoals from './SetGoals';
import UpdateProfile from './UpdateProfile';
import { colors } from '../../styles';

const useStyles = makeStyles({
  dialogBox: {
    width: '578px',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
});

function AddNewClient (props) {
  const { open, onClose } = props;
  const classes = useStyles();

  const selectedClient = useSelector(state => state.selectedClient.client ?? {});
  const [newInviteSent, setNewInviteSent ] = useState(false);
  const [addClientFormVisible, setAddClientFormVisible] = useState(true);
  const [healthWarningVisible, setHealthWarningVisible] = useState(false);
  const [goalsVisible, setGoalsVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const { fetchClients } = useClientsContext();

  const resetAddClient = useCallback(() => {
    setNewInviteSent(false);
    setAddClientFormVisible(true);
    setHealthWarningVisible(false);
    setGoalsVisible(false);
    setProfileVisible(false);
  }, []);

  useEffect(() => {
    if (!open) {
      resetAddClient();
    }
  }, [open, resetAddClient, selectedProfile]);

  const onNewInviteSent = (isResendInvite) => {
    setNewInviteSent(true);
    if (selectedProfile?.ClubId) {
      setTimeout(() => {
        resetAddClient();
        onFetchClients();
        onClose();
      }, 2000);
    } else {
      if (isResendInvite) {
        setTimeout(() => {
          resetAddClient();
          onFetchClients();
          onClose();
        }, 2000);
      } else {
        setTimeout(() => {
          setAddClientFormVisible(false);
          setHealthWarningVisible(true);
        }, 2000);
      }
    }
  };

  const onReadHealthWarning = () => {
    setGoalsVisible(true);
    setHealthWarningVisible(false);
  };

  const onGoalSet = () => {
    setProfileVisible(true);
    setGoalsVisible(false);
  };

  const onFetchClients = () => {
    fetchClients(selectedProfile?.ClubId, selectedProfile?.Locations?.Id);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogBox }}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <Box className={classes.dialogBox}>
        <AddNewClientForm
          visible={addClientFormVisible}
          onClose={onClose}
          newInviteSent={newInviteSent}
          onNewInviteSent={onNewInviteSent}
        />
        <HealthWarning
          visible={healthWarningVisible}
          clientName={selectedClient?.first_name ?? 'Client'}
          onReadHealthWarning={onReadHealthWarning}
        />
        <SetGoals
          visible={goalsVisible}
          client={selectedClient}
          onGoalSet={onGoalSet}
          onClose={onClose}
        />
        <UpdateProfile
          newClient
          visible={profileVisible}
          user={selectedClient}
          onClose={onClose}
          onFetchClients={onFetchClients}
        />
      </Box>
    </Dialog>
  );
}

export default AddNewClient;
