import beginnersImg from '../../resources/scheduleBannerImages/module-program-card-banner-beginners@2x.png';
import generalFitnessImg from '../../resources/scheduleBannerImages/module-program-card-banner-general-fitness@2x.png';
import intervalTrainingImg
  from '../../resources/scheduleBannerImages/module-program-card-banner-interval-training@2x.png';
import lifestyleImg from '../../resources/scheduleBannerImages/module-program-card-banner-lifestyle@2x.png';
import maximalStrengthImg
  from '../../resources/scheduleBannerImages/module-program-card-banner-maximal-strength@2x.png';
import muscleGainImg from '../../resources/scheduleBannerImages/module-program-card-banner-muscle-gain@2x.png';
import powerImg from '../../resources/scheduleBannerImages/module-program-card-banner-power@2x.png';
import stabilizationEnduranceImg
  from '../../resources/scheduleBannerImages/module-program-card-banner-stabilization-endurance@2x.png';
import wakeupWorkoutImg from '../../resources/scheduleBannerImages/module-program-card-banner-wakeup-workout@2x.png';

// key - program category name | value - program category image
export const ScheduleBannerImages = Object.freeze({
  Beginners: beginnersImg,
  'General Fitness': generalFitnessImg,
  'Interval Training': intervalTrainingImg,
  Lifestyle: lifestyleImg,
  'Maximal Strength': maximalStrengthImg,
  'Muscle Gain': muscleGainImg,
  Power: powerImg,
  'Stabilization Endurance': stabilizationEnduranceImg,
  'Wakeup Workout': wakeupWorkoutImg,
});
