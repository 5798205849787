import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { IconButton, InputAdornment, Typography, CircularProgress } from '@material-ui/core';

import nasmApi from '../api/endpoints';
import { termsPrivacyDialogTextOptions } from '../constants';
import { login, LOGIN_STATES } from '../reducers/currentUserReducer';
import {
  password as validatePassword,
  email as validateEmail,
  hasSpecialCharactersOrNumbers,
} from '../util/validate';

import { colors } from '../styles';
import { OvalButton } from '../components/Buttons';
import OvalTextInput from '../components/Inputs/OvalTextInput';
import TermsConditionsDialog from '../components/Dialogs/TermsConditionsDialog';

import backgroundImg from '../resources/general-background@2x.jpg';
import iconHidePass from '../resources/icon-eye-hide-password.svg';
import iconShowPass from '../resources/icon-eye-show-password.svg';

const Root = styled('div')({
  backgroundImage: `url(${backgroundImg})`,
  backgroundPositionX: 'center',
  backgroundSize: 'cover',
  width: '100%',
  height: 'auto',
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Body = styled('div')({
  width: 514,
  margin: '0 auto',
  padding: '72px 0',
});

const CreateAccountCard = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: props => props.large ? 700 : 647,
  borderRadius: 19,
  backgroundColor: 'rgba(22, 57, 99, 0.75)',
});

const CardContents = styled('div')({
  padding: '10px 90px',
});

const Header = styled('div')({
  fontFamily: 'DMSans, sans-serif',
  fontWeight: 'bold',
  fontSize: '40px',
  color: colors.white,
  textAlign: 'center',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '400px',
});

const InputGroup = styled('div')({
  marginBottom: '22px',
  '&:last-child': {
    marginBottom: 0,
  },
});

const ErrorMessage = styled('div')({
  color: colors.yellow_button_style,
  fontFamily: 'DMSans-Medium, sans-serif',
  fontSize: '14px',
  lineHeight: 1.43,
  textAlign: 'right',
  marginTop: '5px',
  marginRight: '5px',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '130px',
});

const DisclaimerText = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: colors.white,
  display: 'flex',
  justifyContent: 'center',
});

function CreateAccount () {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(history.location?.state?.email || '');
  const [emailError, setEmailError] = useState('');

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [showPasswordToggle, setShowPasswordToggle] = useState(false);
  const [createAccountEnabled, setCreateAccountEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [dialogTextType, setDialogTextType] = useState(termsPrivacyDialogTextOptions.TERMS);

  const currentUser = useSelector(state => state.currentUser);
  const { loginState, errorMessage } = currentUser;

  const onCreateAccount = useCallback(() => {
    setLoading(true);
    const user = {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      accepted_terms: true,
    };

    // Create account and then log the newly created user in
    nasmApi.register.trainer(user).then(() => {
      dispatch(login({ email, password }));
      setCreateAccountEnabled(false);
    }).catch((error) => {
      if (error.data.error.code === 1000 || error.data.error.code === 3333) {
        setEmailError('This account already exists');
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch, email, firstName, lastName, password]);

  // Only enable the Create Account button when all fields are filled in
  useEffect(() => {
    setCreateAccountEnabled(!!email && !!firstName && !!lastName && !!password);
  }, [email, firstName, lastName, password]);

  // Hit landing page after logging in and successfully creating account
  useEffect(() => {
    if (loginState === LOGIN_STATES.LOGIN) {
      history.push('/');
    } else if (loginState === LOGIN_STATES.ERROR) {
      alert(errorMessage);
    }
  }, [loginState, history, errorMessage]);

  const handlePasswordVisibilityToggle = () => {
    setShowPasswordToggle(!showPasswordToggle);
  };

  const onChangeEmail = (e) => {
    setEmailError('');
    if (!e.target.value) {
      setEmailError('Email is required');
    }
    setEmail(e.target.value);
  };

  const onChangeFirstName = (e) => {
    let input = e.target.value;
    setFirstNameError('');
    if (!input || input.trim().length === 0) {
      setFirstNameError('Name is required');
    }

    setFirstName(input.trim());
  };

  const onChangeLastName = (e) => {
    let input = e.target.value;
    setLastNameError('');
    if (!input || input.trim().length === 0) {
      setLastNameError('Last name is required');
    }
    setLastName(input.trim());
  };

  const onChangePassword = (e) => {
    setPasswordError('');
    if (!e.target.value) {
      setPasswordError('Password is required');
    }
    setPassword(e.target.value);
  };

  const onAcceptAndContinue = () => {
    if (dialogTextType === termsPrivacyDialogTextOptions.TERMS) {
      setDialogTextType(termsPrivacyDialogTextOptions.PRIVACY);
    } else {
      onCreateAccount();
    }
  };

  const onLogIn = () => {
    history.push('/login');
  };

  const checkInputFields = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError('Password must have at least 8 characters, \n' +
        '1 uppercase, 1 lowercase and 1 special character.');
      return;
    }

    if (hasSpecialCharactersOrNumbers(firstName)) {
      setFirstNameError('Invalid first name');
      return;
    }

    if (hasSpecialCharactersOrNumbers(lastName)) {
      setLastNameError('Invalid last name');
      return;
    }

    if (!emailError && !firstNameError && !lastNameError && !passwordError) {
      setTermsDialogOpen(true);
    }
  };

  return (
    <Root>
      <Body>
        <CreateAccountCard>
          <Header>Create Account</Header>
          <CardContents>
            <Form onSubmit={checkInputFields}>
              <div>
                <InputGroup>
                  <OvalTextInput
                    placeholder='Email'
                    value={email}
                    onChange={onChangeEmail}
                  />
                  { emailError ? <ErrorMessage>{emailError}</ErrorMessage> : '' }
                </InputGroup>
                <InputGroup>
                  <OvalTextInput
                    placeholder='First Name'
                    value={firstName}
                    onChange={onChangeFirstName}
                  />
                  { firstNameError ? <ErrorMessage>{firstNameError}</ErrorMessage> : '' }
                </InputGroup>
                <InputGroup>
                  <OvalTextInput
                    placeholder='Last Name'
                    value={lastName}
                    onChange={onChangeLastName}
                  />
                  { lastNameError ? <ErrorMessage>{lastNameError}</ErrorMessage> : '' }
                </InputGroup>
                <InputGroup>
                  <OvalTextInput
                    placeholder='Password'
                    value={password}
                    type={showPasswordToggle ? 'text' : 'password'}
                    onChange={onChangePassword}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handlePasswordVisibilityToggle}
                        >
                          <img
                            src={showPasswordToggle ? iconHidePass : iconShowPass}
                            alt='toggle hide or show password'
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  { passwordError ? <ErrorMessage>{passwordError}</ErrorMessage> : '' }
                </InputGroup>
              </div>
              <ButtonContainer>
                <OvalButton
                  backgroundcolor={colors.radio_blue}
                  color={colors.white}
                  width='100%'
                  height={55}
                  borderradius={28}
                  disabled={!createAccountEnabled || loading}
                  fontSize={19}
                  fontWeight='bold'
                  type='submit'
                  border='none'
                >
                  {loading ? <CircularProgress size={21} color='inherit' /> : 'Create Account'}
                </OvalButton>
                <OvalButton
                  onClick={onLogIn}
                  backgroundcolor={colors.lightBlue}
                  color={colors.white}
                  width='100%'
                  height={55}
                  borderradius={28}
                  disabled={loading}
                  fontSize={19}
                  fontWeight='bold'
                  border='none'
                >
                  Log in
                </OvalButton>
              </ButtonContainer>
            </Form>
          </CardContents>
          <DisclaimerText>
            I understand and consent to my data being processed in the US.
          </DisclaimerText>
        </CreateAccountCard>
        <TermsConditionsDialog
          isOpen={termsDialogOpen}
          onClose={() => setTermsDialogOpen(false)}
          onAcceptAndContinue={onAcceptAndContinue}
          textType={dialogTextType}
          loading={loading}
        />
      </Body>
    </Root>
  );
}

export default CreateAccount;
