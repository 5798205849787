import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Box, CircularProgress} from '@material-ui/core';

const useStyles = makeStyles({
  schedulePreviewLoading: props => ({
    width: props.width || 419,
    height: props.height || 314,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
});

function CardContentLoading (props) {
  const classes = useStyles(props);
  return (
    <Box className={classes.schedulePreviewLoading}>
      <CircularProgress />
    </Box>
  );
}

CardContentLoading.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CardContentLoading;