/* eslint-disable class-methods-use-this, max-len */
/**
 * @author Donald Green <donald.green@medlmobile.com>
 */
import querystring from 'querystring';

import request from './request';
import { NASM_API_ENDPOINTS, MAX_RETRY_COUNT, NASM_OAUTH_CLIENT, API_VERSION } from './constants';
import Auth from './nasm_auth';
import Moment from 'moment';
import { FEATURE_FLAGS } from '../../constants';

const parseApiResponse = (response) => {
  if (!response.success) {
    let errorMessage = '';
    if (typeof response.error === 'string') {
      errorMessage = response.error;
    } else if (typeof response.message === 'string') {
      errorMessage = response.message;
    } else if (typeof response.error === 'object') {
      errorMessage = response.error.message || 'A server error occurred. Please try again later.';
    } else if (typeof response.message === 'object') {
      errorMessage = response.message.message;
    }
    return Promise.reject(new Error(errorMessage));
  }
  return Promise.resolve(response.result);
};

const parseApiError = (error) => {
  let serverError;
  try {
    serverError = error.response.data.error;
    if (!serverError.message) {
      serverError.message = error.response.data.message;
    }
  } catch (e) {
    serverError = error;
  }
  return serverError;
};

export default class Api extends Auth {
  /**
   *
   * @param storeToken {Function} Interface function that accepts token information and
   * will store it in persistant storage.
   * @param getToken {Function} Interface function that retrieves a token from persistent
   * storage
   */
  // constructor(storeToken, getToken, deleteToken) {
  //   super(storeToken, getToken, deleteToken);
  // }

  setLogoutHandler(logout) {
    this.logout = logout || (() => {});
  }

  /**
   * This is the basis of making requests to the Nasm API. This request function will be responsible for ensuring
   * the current user has access by utilizing the auth portion of the library and it's "authorize" middleware
   * function. The `apiReq` will contain the user information making the call and this user's token info will be
   * evaluated to see if we have tokens and if the access token has expired. The token will automatically be refreshed
   * and our original API call will proceed.
   *
   * @typedef Response
   * @type {Object}
   * @property success {Boolean} Value is true if the response from the API server is successful
   * @property error {Boolean} Value is true if the response from the API results in failure but returns a 200 status
   * @property result {Object|Array} This field contains the results of the endpoint.
   *
   * @param apiReq {Object} The object containing everything we need to make an API request to Nasm API.
   * @param apiReq.user {Object} The current user object containing the `token` data.
   * @param apiReq.user.token {Object} The token information of the user.
   * @param apiReq.user.token.access_token {String} The token used to authenticate API calls.
   * @param apiReq.user.token.refresh_token {String} The token used to refresh a user's access to the API.
   * @param apiReq.user.token.expires_in {Date} The date/timestamp for when the access token is scheduled to expire.
   * @param apiReq.endpoint {String} The qualified endpoint path. The base domain path is statically defined in this
   * libraries `request.js` file.
   * @param apiReq.method {String} The string representation of the http verb used to make the request. Example; get, post, put, delete, etc.
   * @param [apiReq.query] {Object|String} For requests that accept query parameters, this field will accept an object
   * or string representation of the parameters. Preferred option would be to send as an object as this module will convert
   * this object to query string parameters most effectively.
   * @param [apiReq.jsonData] {Object|String} For requests that intend to post data or utilize the request body to pass
   * data to the request, this field will contain this request body in either JSON/Object format or a json string. Default
   * Object is preferred as a string value will be parsed into an Object.
   * @param depth {Number} A number representing the level of recursion the method is currently in. Used to retry the request
   * if we should ever run into a failure that results in a 401 or 403, unauthorized or forbidden response code. Retrying will
   * attempt to check the user's token set and make sure they are up to date and make the request again. If it should fail
   * beyond the MAX_RETRY_COUNT, the error will be passed up.
   * @return {Promise<Response>}
   */
  async authedApiRequest(apiReq, depth = 0, version = API_VERSION) {
    return this.authorize(apiReq, (authedApiReq) => {
      try {
        return this.apiRequest(authedApiReq, true);
      } catch (err) {
        if ((err.response.status === 401 || err.response.status === 403)) {
          if (depth + 1 < MAX_RETRY_COUNT) {
            return this.authedApiRequest(authedApiReq, depth + 1, version);
          } else if (this.logout) {
            this.logout();
          } else {
            throw err;
          }
        }
        throw err;
      }
    }, version);
  }

  async apiRequest(apiReq, isAuth = false, version = API_VERSION) {
    const { endpoint, method, token, data, auth } = apiReq;
    let { query, jsonData } = apiReq;

    if (query && typeof query !== 'string' && typeof query !== 'object') {
      throw new Error(
        'Invalid type supplied for "query": Must be of type "string" or "object"',
      );
    }

    if (
      jsonData && typeof jsonData !== 'string' && typeof jsonData !== 'object'
    ) {
      throw new Error(
        'Invalid type supplied for "jsonData": Must be of type "string" or "object"',
      );
    }

    if (query && typeof query === 'object') {
      query = querystring.stringify(query);
    }

    if (query) {
      // query should  be a string. In case it was passed as a string, do a little sanitizing
      query = query.trim().replace(/^[?]/, '');
    }

    if (typeof jsonData === 'string') {
      jsonData = JSON.parse(jsonData);
    }

    const finalUrl = `/${version}` + (query ? `${endpoint}?${query}` : endpoint);

    const config = {
      method,
      url: finalUrl,
      headers: {},
      withCredentials: true,
      auth,
    };

    if (isAuth) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }

    if (jsonData) {
      config.data = jsonData;
    }

    if (data) {
      config.data = data;
    }

    try {
      const response = await request(config);
      return response.data;
    } catch (err) {
      if (
        (err.response && err.response.status === 400 && err.response.data.code === 4005)
        || (err.response && (err.response.status === 401 || err.response.status === 403))
      ) {
        throw err;
      } else {
        throw parseApiError(err);
      }
    }
  }

  /** ******************
   * **********
   * Users endpoints
   * **********
   * *******************
   */

  /**
   * Simple request to retrieve the user object utilizing the access token retrieved during login.
   */
  getMyUser(version = API_VERSION) {
    let currentDate = new Moment().format();
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/me?currentDate=${currentDate}`,
    };

    return this.authedApiRequest(config, version).then(parseApiResponse);
  }

  getUserById(userId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  updateUserAvatar(userId, avatarData) {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('avatar_file', {
      uri: avatarData.path,
      type: 'image/jpeg',
      name: `${Moment().format()}profile`,
    });

    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/uploadUserAvatar`,
      data: formData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  updateClientPreferences(userId, data) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s nutrition history.'));
    }
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.CLIENT_PREF}`,
      data,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method used to check the most current app version compared to the user's version
   * @param versionData {Object} A json object that holds the user's app version
   * @param versionData.currentVersion {String} The field containing the user's app version
   */
  getAppVersion(versionData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.ACTIONS}/check-version`,
      jsonData: versionData,
    };

    return this.apiRequest(config).then(parseApiResponse);
  }

  checkTerms() {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/me/check-tnc`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  acceptTerms(userId) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/accept-terms`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  registerForPush(deviceData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/pushtoken`,
      jsonData: deviceData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Program Endpoints
   * **********
   * *******************
   */
  getAllPrograms(page = 1, size = 10, search = '', filterIds = [], programSources = ['nasmOwned', 'trainerOwned']) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}`,
      query: {
        page,
        size,
        search,
        categoryIds: filterIds.join(),
        programSources: programSources.join(),
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getWakeUpWorkoutProgram() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}/wakeup`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getProgramCategories() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.PROGRAM_CATEGORIES}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  createProgram(program) {
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}`,
      jsonData: program,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  updateProgram(program) {
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}/${program.id}`,
      jsonData: program,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  duplicateProgram(programId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}/${programId}/duplicate`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  deleteProgram(programId) {
    const request = {
      method: 'DELETE',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}/${programId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Workout Endpoints
   * **********
   * *******************
   */

  getWorkouts({ page, size, search, sortField, sortOrder, getPublic, getPrivate }) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}`,
      query: {
        page,
        size,
        search,
        sortField,
        sortOrder,
        getPublic,
        getPrivate,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  // TODO: this needs to be refactored, very confusing to have the two boolean flags separated, probably use a config object
  getAllWorkouts(page = 1, size = 100, search = '', getPublic = true, excludeIds = [], getPrivate = true) {
    if (!Array.isArray(excludeIds)) {
      const type = typeof excludeIds;
      const message = `Invalid parameter 'excludeIds' passed. Expecting an array, instead got ${type}.`;
      return Promise.reject(new Error(message));
    }
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}/get`,
      query: {
        page,
        size,
        search,
        getPublic,
        getPrivate,
      },
      jsonData: {
        excludeIds,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getMyWorkouts(page, size, search, excludeIds = []) {
    return this.getAllWorkouts(page, size, search, false, excludeIds);
  }

  getWorkout(workoutId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}/${workoutId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getBatchWorkoutsByIds(workoutIds) {
    if (!Array.isArray(workoutIds)) {
      return Promise.reject(new Error('Unable to get workouts. workoutIds required!'));
    }
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}/get/batch`,
      jsonData: {
        workoutIds,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  deleteWorkout(workoutId) {
    const request = {
      method: 'DELETE',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}/${workoutId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  createWorkout(workout) {
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}`,
      jsonData: workout,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  updateWorkout(workout) {
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.NASM_WORKOUTS}/${workout.id}`,
      jsonData: workout,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getWorkoutSections() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.WORKOUT_SECTIONS}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Client endpoints
   * **********
   * *******************
   */

  scheduleClientProgram(userId, programSchedule) {
    // Do we need to validate programSchedule? Or should we just
    // let the API handle validation and showing error messages?
    if (userId) {
      const request = {
        method: 'POST',
        endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/schedule-workout`,
        jsonData: programSchedule,
      };
      return this.authedApiRequest(request).then(parseApiResponse);
    }
    return Promise.reject(new Error('userId is required to schedule a program'));
  }

  rescheduleClientProgram(userId, programId, programSchedule) {
    if (!userId || !programId || !programSchedule) {
      return Promise.reject(new Error('Insufficient data. Unable to reschedule program.'));
    }
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/nasm-program/${programId}/reschedule`,
      jsonData: programSchedule,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  deleteClientProgram(scheduleId, userId, currentDate) {
    if (!scheduleId || !userId || !currentDate) {
      return Promise.reject(new Error('Insufficient data. Unable to delete program.'));
    }
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/cancel/workout`,
      jsonData: {
        scheduleId,
        userId,
        currentDate,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  deleteWakeupWorkout(scheduleId, currentDate) {
    if (!scheduleId || !currentDate) {
      return Promise.reject(new Error('Insufficient data. Unable to delete program.'));
    }
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/client/cancelworkout`,
      jsonData: {
        scheduleId,
        currentDate,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getUserAssignedPrograms(userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get assigned programs.'));
    }
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/schedules`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  async getUserAssignedProgramDetails(userId, scheduleId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get assigned programs.'));
    }
    if (!scheduleId) {
      return Promise.reject(new Error('programId is required to get assigned programs.'));
    }
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/schedule/${scheduleId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /**
   * Method to retrieve a client's workout schedule
   * @param userId {String} The id of the client user
   * @param month {String} The month in (MM) format
   * @param year {String} The full year in (YYYY) format
   */
  getScheduleByMonth(clientId, month, year, currentTime) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/month-workouts`,
      query: {
        month,
        year,
        currentTime,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve a client's workout schedule
   * @param userId {String} The id of the client user
   * @param date {String} The date in (YYYY-MM-DD) format
   */
  getScheduleByDay(clientId, workoutDate, currentTime) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/day-workouts`,
      query: {
        workoutDate,
        currentTime,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  // TODO: Add user data model
  createClientUser(userData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}`,
      jsonData: userData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Dashboard endpoints
   * **********
   * *******************
   */

   getLatestNutrition(userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s nutrition history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/nutrition/latest`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getLatestMilestones(userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s milestone history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.DASHBOARD}/milestones`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getLatestWeeklyCompletion(userId, date) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s workout history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.DASHBOARD}/weekly-completion`,
      query: {
        date,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getLatestMeasurements(userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s measurement history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.DASHBOARD}/measurements`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getLatestAssessments(userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s measurement history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.DASHBOARD}/performance-assessments`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getCalendarMonth(clientId, date) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/dashboard/calendar-month`,
      query: {
        date,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve a client's workout schedule
   * @param userId {String} The id of the client user
   * @param date {String} The date in (YYYY-MM-DD) format
   */
  getCalendarDay(clientId, date) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/dashboard/calendar-day`,
      query: {
        date,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Assessment endpoints
   * **********
   * *******************
   */
  getAssessmentByName(name) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.NASM_ASSESSMENTS}/${name}`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to submit an ohst for a client.
   * @param userId clientId
   * @param ohstData {Object} A json object that holds the ohst responses
   * @param ohstData.two_ls_experience_pain {Bool}
   * @param ohstData.two_ls_foot_turns_out_r {Bool}
   * @param ohstData.two_ls_foot_turns_out_l {Bool}
   * @param ohstData.two_ls_foot_flattens_r {Bool}
   * @param ohstData.two_ls_foot_flattens_l {Bool}
   * @param ohstData.two_ls_knee_moves_in_val_r {Bool}
   * @param ohstData.two_ls_knee_moves_in_val_l {Bool}
   * @param ohstData.two_ls_knee_moves_out_var_r {Bool}
   * @param ohstData.two_ls_knee_moves_out_var_l {Bool}
   * @param ohstData.two_ls_excessive_forward_lean {Bool}
   * @param ohstData.two_ls_low_back_arches {Bool}
   * @param ohstData.two_ls_low_back_rounds {Bool}
   * @param ohstData.two_ls_arms_fall_forward {Bool}
   * @param ohstData.two_ls_heel_lifts_r {Bool}
   * @param ohstData.two_ls_heel_lifts_l {Bool}
   * @param ohstData.two_ls_weight_shift_r {Bool}
   * @param ohstData.two_ls_weight_shift_l {Bool}
   */
  submitOHST(userId, ohstData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/submit-ohst`,
      jsonData: ohstData,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method used to update a client's account info
   * TODO: add properties to user data object
   * @param {} userData
   */
  updateClientUser(userData, userId) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}`,
      jsonData: userData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to assign a goal/goals for a client
   * @param goalData {Object} A json object that holds the client goals and id
   * @param goalData.goals {Array} An array of goal id strings
   * @param goalData.userId {String} The user id of the client
   */
  assignGoalToClient(goalData) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/client/goals`,
      jsonData: goalData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to delete a goal/goals for a client
   * @param goalData {Object} A json object that holds the client goals and id
   * @param goalData.goals {Array} An array of goal id strings
   * @param goalData.userId {String} The user id of the client
   */
  deleteClientGoals(goalData) {
    const config = {
      method: 'delete',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/goals`,
      jsonData: goalData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve a client's weight history
   * @param userId {String} The id of the client user
   * @param startDate {String} The start date in (YYYY-MM-DD) format
   * @param endDate {String} the end date in (YYYY-MM-DD) format
   */
  getClientWeightHistory(userId, startDate, endDate) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/weights`,
      query: {
        startDate,
        endDate,
      },
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to complete an exercise for a client
   * @param clientId {string} Id of the client to complete the exercise for
   * @param exerciseData {Object} A json object holding the necessary exercise data
   * @param exerciseData.exerciseId {String} The id of the exercise
   * @param exerciseData.programId {String} The id of the program the exercise belongs to
   * @param exerciseData.schedule_date {String} The scheduled date of the exercise
   */
  completeClientExercise(clientId, exerciseData) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/exercise/complete`,
      jsonData: exerciseData,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  completeClientExercises(clientId, exerciseIds) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/exercises/complete`,
      jsonData: { exerciseIds },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  incompleteClientExercises(clientId, exerciseIds) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/exercises/incomplete`,
      jsonData: { exerciseIds },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve the very last ME assessment for the given user
   * @param clientId {String} The user id
   */
  getLastMeAssessmentResult(clientId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/lastmescreenresult`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve the very last OHSA assessment for the given user
   * @param clientId {String} The user id
   */
  getLastOHSAResult(clientId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/lastohstresult`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve the all of the users ME assessments
   * @param clientId {String} The user id
   */
  getAllMeResults(clientId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/results/mescreen`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve the all of the users OHST assessments
   * @param clientId {String} The user id
   */
  getAllOHSAResults(clientId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/results/ohst`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getAssessmentResult(clientId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/ohsa/results`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getAllCorrectiveAssessments(clientId, page = 1, size = 10) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/corrective-assessments`,
      query: {
        page,
        size,
      },
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getCorrectiveAssessment(clientId, assessmentId) {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/corrective-assessments/${assessmentId}`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Trainer endpoints
   * **********
   * *******************
   */
  getTrainerClients() {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/clients`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to update a trainer's information
   * TODO: add properties to trainer data
   * @param trainerData {Object} A json object that holds the trainer's data
   */
  updateTrainer(trainerData, trainerId) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/${trainerId}`,
      jsonData: trainerData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to update a trainer's certifications
   * @param certData {Object} A json object that holds an array of certificate ids and the user id
   * @param certData.certificates {Array} An array of certificate ids as strings
   * @param certData.userId {String} The id of the trainer
   */
  updateTrainerCerts(certData) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/certificates`,
      jsonData: certData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  updateSimpleCerts(certData) {
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/credentials`,
      jsonData: certData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  searchCertificates(searchParam) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/certificates/search`,
      jsonData: { searchParam },
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * TODO: This endpoint will likely be changed to '/trainer/certificates'
   * Method to delete a trainer's certifications
   * @param certData {Object} A json object that holds an array of certificate ids and the user id
   * @param certData.certificates {Array} An array of certificate ids as strings
   * @param certData.userId {String} The id of the trainer
   */
  deleteTrainerCerts(certData) {
    const config = {
      method: 'delete',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/certificates`,
      jsonData: certData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  deleteTrainerClient(clientId) {
    if (!clientId) {
      return Promise.reject(new Error('Can not delete a client without a clientId.'));
    }
    const config = {
      method: 'delete',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/client-relationship`,
      jsonData: { clientId },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  activateClient(userId) {
    const config = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/reactivate-clients`,
      jsonData: { userId },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  activateAllClients() {
    const config = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/reactivate-clients`,
      query: { activateAll: 'true' },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  deactivateAllClients() {
    const config = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/deactivate-clients`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Register endpoints
   * **********
   * *******************
   */
  // TODO: Add user data object
  registerTrainer(userData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/trainer`,
      jsonData: userData,
    };

    return this.apiRequest(config).then(parseApiResponse);
  }

  registerClient(userData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/client`,
      jsonData: userData,
    };

    return this.apiRequest(config).then(parseApiResponse);
  }

  /**
   * Method used to reset a user's password
   * @param passwordData {Object} A json object that holds the user's password
   * @param programData.password {String} The user's password
   */
  registerResetPassword(userId, password) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/${userId}/reset-password`,
      jsonData: {
        password,
      },
    };

    return this.apiRequest(config).then(parseApiResponse);
  }

  verifyEdgeCredentials(email, password) {
    if (!email) {
      return Promise.reject(new Error('no email provided'));
    }
    if (!password) {
      return Promise.reject(new Error('no password provided'));
    }

    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/verify-credentials`,
      jsonData: {
        email,
        password,
      },
      auth: {
        username: NASM_OAUTH_CLIENT.ID,
        password: NASM_OAUTH_CLIENT.SECRET,
      },
    };
    return this.apiRequest(config).then(parseApiResponse);
  }

  migrateEdgeAccountToUA(email, password) {
    if (!email) {
      return Promise.reject(new Error('no email provided'));
    }
    if (!password) {
      return Promise.reject(new Error('no password provided'));
    }

    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/migrate-account-ua`,
      jsonData: {
        email,
        password,
      },
      auth: {
        username: NASM_OAUTH_CLIENT.ID,
        password: NASM_OAUTH_CLIENT.SECRET,
      },
    };
    return this.apiRequest(config).then(parseApiResponse);
  }

  linkMatchingAccounts(email, password) {
    if (!email) {
      return Promise.reject(new Error('no email provided'));
    }
    if (!password) {
      return Promise.reject(new Error('no password provided'));
    }

    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/link-account-ua`,
      jsonData: {
        email,
        password,
      },
    };
    return this.apiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * IAP endpoints
   * **********
   * *******************
   */

   /**
   * Method used to reset a user's password
   * @param receipt {Object} A purchase receipt
   */
  uploadReceipt(receipt) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.PURCHASES}/`,
      jsonData: receipt,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }
  uploadReceiptotp(receipt) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.PURCHASES}/otp`,
      jsonData: receipt,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  checkSubscription(optionalTransactionId = null) {
    let endpoint = `${NASM_API_ENDPOINTS.PURCHASES}/check`;
    if (optionalTransactionId !== null) {
      endpoint = `${endpoint}/${optionalTransactionId}`;
    }
    const config = {
      method: 'get',
      endpoint: endpoint,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Certificates endpoints
   * **********
   * *******************
   */

  getAllCertificates() {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.CERTIFICATES}`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getSimpleCertificates() {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.CREDENTIALS}`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Goals endpoints
   * **********
   * *******************
   */

  getAllGoals() {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.GOALS}`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Nutrition endpoints
   * **********
   * *******************
   */

  getUserNutritionInfo(userId, page = 0, size = 10) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s nutrition history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.NUTRITION}`,
      query: {
        page,
        size,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  createNutritionRecord(data, userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to create a nutrition record.'));
    }
    if (!data) {
      return Promise.reject(new Error('Insufficient data. Unable to create a nutrition record.'));
    }
    data.assignedTime = new Moment().format();
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.NUTRITION}`,
      data,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  updateNutritionInformation(data, userId, nutritionId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to update nutrition record.'));
    }
    if (!nutritionId) {
      return Promise.reject(new Error('nutritionId is required to update nutrition record.'));
    }
    if (!data) {
      return Promise.reject(new Error('Insufficient data. Unable to update a nutrition record.'));
    }
    const config = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.NUTRITION}/${nutritionId}`,
      data,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  deleteNutritionRecord(userId, nutritionId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to delete nutrition record.'));
    }
    if (!nutritionId) {
      return Promise.reject(new Error('nutritionId is required to delete nutrition record.'));
    }
    const config = {
      method: 'delete',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.NUTRITION}/${nutritionId}`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Measurement endpoints
   * **********
   * *******************
   */

   getUserMeasurementsGraphData(userId, start = null, end = null, scale = 'daily') {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s measurement history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.GRAPH_DATA}`,
      query: {
        start,
        end,
        scale,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  getUserMeasurements(userId, page = 0, size = 10, start = null, end = null) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get a user\'s measurement history.'));
    }
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.MEASUREMENTS}`,
      query: {
        page,
        size,
        start,
        end,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  createMeasurement(data, userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to create a measurement.'));
    }
    if (!data) {
      return Promise.reject(new Error('Insufficient data. Unable to create a measurement.'));
    }
    data.measurement_time = new Moment().format();
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.MEASUREMENTS}`,
      data,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  deleteMeasurement(userId, measurementId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to delete measurement record.'));
    }
    if (!measurementId) {
      return Promise.reject(new Error('measurementId is required to delete measurement.'));
    }
    const config = {
      method: 'delete',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.MEASUREMENTS}/${measurementId}`,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Milestones endpoints
   * **********
   * *******************
   */
  getAllMilestones(userId) {
    return this.getMilestones(userId);
  }

  getMilestones(userId, isComplete = null) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to get milestones.'));
    }
    let query = {};
    if (isComplete !== null) {
      query = {
        isComplete,
      };
    }
    const request = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.MILESTONES}`,
      query,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  createMilestone(milestone, userId) {
    if (!milestone || !milestone.name || !milestone.ordinal || !userId) {
      return Promise.reject(new Error('Insufficient data. Cannot create a milestone!'));
    }
    const request = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.MILESTONES}`,
      jsonData: milestone,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  updateMilestone(milestone, userId) {
    return this.updateMilestones([milestone], userId);
  }

  updateMilestones(milestones, userId) {
    if (!userId) {
      return Promise.reject(new Error('userId is required to update milestones.'));
    }
    if (!Array.isArray(milestones)) {
      return Promise.reject(new Error('Corrupt data. Unable to update milestones.'));
    }
    const request = {
      method: 'put',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${userId}/${NASM_API_ENDPOINTS.MILESTONES}`,
      jsonData: {
        data: milestones,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  deleteMilestone(milestoneId) {
    if (!milestoneId) {
      return Promise.reject(new Error('milestoneId is required to delete a milestone.'));
    }
    const request = {
      method: 'delete',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${NASM_API_ENDPOINTS.MILESTONES}/${milestoneId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Milestones endpoints
   * **********
   * *******************
   */
  getActivityLevels() {
    const request = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.ACTIVITY_LEVELS}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Password Reset endpoints
   * **********
   * *******************
   */

  getPasswordReset() {
    const config = {
      method: 'get',
      endpoint: `${NASM_API_ENDPOINTS.PASS_RESET}`,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method used to reset a user's password
   * @param email {string} The user's email address who has requested a password reset
   */
  createPasswordResetRequest(email) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.PASS_RESET}`,
      jsonData: { email },
    };

    return this.apiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Exercise endpoints
   * **********
   * *******************
   */
  getNasmExercises(page, size, search, muscleFilterIds, fitnessFilterIds) {
    let muscleGrpIds = '';
    let fitnessCompIds = '';
    for (let i = 0; i < muscleFilterIds.length; i++) {
      if (muscleGrpIds.length > 0) {
        muscleGrpIds += ',';
      }
      muscleGrpIds += muscleFilterIds[i];
    }
    for (let j = 0; j < fitnessFilterIds.length; j++) {
      if (fitnessCompIds.length > 0) {
        fitnessCompIds += ',';
      }
      fitnessCompIds += fitnessFilterIds[j];
    }
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_EXERCISES}`,
      query: {
        page,
        size,
        search,
        muscleGrpIds,
        fitnessCompIds,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getNasmExerciseById(exerciseId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_EXERCISES}/${exerciseId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getProgramById(programId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.NASM_PROGRAMS}/${programId}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getCorrectiveExercises(clientId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/ohsa/corrective-exercises`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getFitnessComponents() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.FITNESS_COMPONENTS}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getMuscleGroups() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.MUSCLE_GROUPS}`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Measurement endpoints
   * **********
   * *******************
   */
  getMeasurements(clientId, page = 1, size = 10) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/measurements`,
      query: {
        page,
        size,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  updateMeasurements(clientId, measurements) {
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/measurements`,
      jsonData: measurements,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  updateMeasurementById(clientId, measurement) {
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/measurements/${measurement.id}`,
      jsonData: measurement,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  submitMeasurementImages(clientId, measurementId, frontImage, backImage, sideImage) {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (frontImage) {
      formData.append('front', {
        uri: frontImage.path,
        type: 'image/jpeg',
        name: `${Moment().format()}front`,
      });
    }
    if (backImage) {
      formData.append('back', {
        uri: backImage.path,
        type: 'image/jpeg',
        name: `${Moment().format()}back`,
      });
    }
    if (sideImage) {
      formData.append('side', {
        uri: sideImage.path,
        type: 'image/jpeg',
        name: `${Moment().format()}side`,
      });
    }
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/measurements/${measurementId}/photos`,
      jsonData: formData,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Performance assessment endpoints
   * **********
   * *******************
   */

  getAssessmentResults(clientId, start = null, end = null, scale = 'daily') {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/performance-assessments`,
      query: {
        start,
        end,
        scale,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getCurrentAssessmentResults(clientId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/performance-assessments/current`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  saveAssessmentResults(clientId, assessments) {
    const request = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/performance-assessments`,
      jsonData: assessments,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  updateAssessmentResults(clientId, assessments) {
    const request = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/performance-assessments`,
      jsonData: assessments,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  deleteAssessmentsForDate(clientId, date, category) {
    const request = {
      method: 'DELETE',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/${clientId}/performance-assessments`,
      query: {
        date,
        category,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Flash Card Endpoints
   * **********
   * *******************
   */

  getAllFlashCards(domainId = '') {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.EXAM_PREP}/flashcards`,
      query: {
        domainId,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Exam Prep Endpoints
   * **********
   * *******************
   */

  getAllDomains() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.EXAM_PREP}/exam-domains`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getQuizSetByDomain(domainId) {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.EXAM_PREP}/exam-domains/questions`,
      query: {
        domainId,
      },
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  getPracticeExamQuestions() {
    const request = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.EXAM_PREP}/practice-test`,
    };
    return this.authedApiRequest(request).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Tests endpoints
   * **********
   * *******************
   */
  trainerValidateClientEmail(jsonData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/validate-client-email`,
      jsonData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  inviteExistingClient(jsonData) {
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/trainer/send-client-invite`,
      jsonData,
    };

    return this.authedApiRequest(config).then(parseApiResponse);
  }

  resendClientInvite(clientId) {
    if (!clientId) {
      return Promise.reject(new Error('clientId is required to resend an invitation.'));
    }
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/resend-invitation`,
      jsonData: {
        clientId,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * UA endpoints
   * **********
   * *******************
   */

  checkEmail(email) {
    if (!email) {
      return Promise.reject(new Error('No email provided'));
    }
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/check-email`,
      jsonData: {
        email,
      },
    };
    return this.apiRequest(config).then(parseApiResponse);
  }

  registerTrainerWithUAAccount(email, password, acceptedTerms) {
    if (!email) {
      return Promise.reject(new Error('no email provided'));
    }
    if (!password) {
      return Promise.reject(new Error('no password provided'));
    }
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.REGISTER}/trainer/ua`,
      jsonData: {
        email,
        password,
        accepted_terms: acceptedTerms,
      },
    };
    return this.apiRequest(config).then(parseApiResponse);
  }

  linkUAAccount(email, password) {
    if (!email) {
      return Promise.reject(new Error('no email provided'));
    }
    if (!password) {
      return Promise.reject(new Error('no password provided'));
    }
    const config = {
      method: 'post',
      endpoint: `${NASM_API_ENDPOINTS.USERS}/link_ua_account`,
      jsonData: {
        email,
        password,
      },
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /** ******************
   * **********
   * Client Groups Endpoints
   * **********
   * *******************
   */
  async createGroup(jsonData) {
    const config = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.CLIENT_GROUP}`,
      jsonData,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  async updateGroup(jsonData) {
    const groupId = jsonData?.id;
    if (!groupId) {
      throw new Error(
        'A group identification is required to update group information.',
      );
    }
    const date = new Moment().format();
    jsonData.date = date;
    const config = {
      method: 'PUT',
      endpoint: `${NASM_API_ENDPOINTS.CLIENT_GROUP}/${groupId}`,
      jsonData,
    };
    if (jsonData.isDelete) {
      config.method = 'DELETE';
      config.query = {
        date,
      };
      if (jsonData?.club_id) {
        config.query.club_id = jsonData?.club_id;
        if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && jsonData?.location_id) {
          config.query.location_id = jsonData?.location_id;
        }
      }
      delete config.jsonData;
    }
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  async getTrainerGroups(clubId, locationId) {
    const config = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.CLIENT_GROUP}`,
      query: {},
    };
    if (clubId) {
      config.query.club_id = clubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && locationId) {
        config.query.location_id = locationId;
      }
    }
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  async scheduleForGroup(groupId, jsonData) {
    if (!groupId) {
      throw new Error(
        'A group identification is required to update group information.',
      );
    }
    const config = {
      method: 'POST',
      endpoint: `${NASM_API_ENDPOINTS.CLIENT_GROUP}/${groupId}/schedule-program`,
      jsonData,
    };
    return this.authedApiRequest(config).then(parseApiResponse);
  }

  /**
   * Method to retrieve a group's workout schedule dates for selected month
   * @param groupId {String} The id of the group
   * @param date {String} The date in (YYYY-MM-DD) format
   */
  async getGroupCalendarScheduleDates(groupId, date, clubId, locationId) {
    const config = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.CLIENT_GROUP}/${groupId}/month-workouts`,
      query: {
        date,
      },
    };
    if (clubId){
      config.query.club_id = clubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && locationId) {
        config.query.location_id = locationId;
      }
    }
    return this.authedApiRequest(config).then(parseApiResponse);
  }
  /**
   * Method to retrieve a group's workout schedule for selected date
   * @param groupId {String} The id of the group
   * @param date {String} The date in (YYYY-MM-DD) format
   */
  async getGroupCalendarDaySchedule(groupId, date, clubId, locationId) {
    const config = {
      method: 'GET',
      endpoint: `${NASM_API_ENDPOINTS.CLIENT_GROUP}/${groupId}/calendar-day`,
      query: {
        date,
      },
    };
    if (clubId) {
      config.query.club_id = clubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && locationId) {
        config.query.location_id = locationId;
      }
    }
    return this.authedApiRequest(config).then(parseApiResponse);
  }
}
