import React from 'react';
import PropTypes from 'prop-types';

import CardContentLoading from '../CardContentLoading';
import NutritionBar from './NutritionBar';
import CardDefaultContent from '../CardDefaultContent';

function NutritionController (props) {
  const { loading, nutritionData = {} } = props;

  if(loading) {
    return <CardContentLoading width={'100%'} height={145} />;
  }

  if(!nutritionData || Object.keys(nutritionData).length === 0) {
    return <CardDefaultContent
      label={'No nutrition data recorded. Click on this card to record.'}
      height={100}
    />;
  }

  return <NutritionBar nutritionData={nutritionData}/>;
}

NutritionController.propTypes = {
  loading: PropTypes.bool,
  nutritionData: PropTypes.shape({
    id: PropTypes.string,
    protein: PropTypes.number,
    carbohydrates: PropTypes.number,
    fat: PropTypes.number,
    /***
     * Date recorded in YYYY-MM-DD format
     */
    date: PropTypes.string,
    client_user_id: PropTypes.string,
  }),
};

export default NutritionController;