import React from 'react';
import {styled} from '@material-ui/core/styles';
import background2x from '../../resources/general-background@2x.jpg';

const LoginContainer = styled('div')({
  backgroundImage: `url(${background2x})`,
  backgroundSize: 'cover',
  backgroundPositionX: 'center',
  height: 'auto',
  minHeight: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
});

const LoginBody = styled('div')({
  width: 514,
  margin: '0 auto',
  padding: '72px 0',
});

export default function (props) {
  return (
    <LoginContainer>
      <LoginBody>
        {props.children}
      </LoginBody>
    </LoginContainer>
  );
}
