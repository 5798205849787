import {
  Avatar,
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIosTwoTone } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { colors } from "../../styles";

export const WorkoutActivity = (props) => {
  const {currentUser, activity } = props;
  const classes = useStyles();

  return (
    <ListItem style={{ display: "block" }}>
      <Box style={{ display: "flex" }}>
        <Avatar
          src={activity?.user?.avatar_url}
          alt={activity?.user?.full_name}
          variant="circle"
          className={classes.activityAvatar}
        />
        <ListItemText
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
          primary={
            <Typography
              component="span"
              variant="body2"
              className={classes.activityTitle}
            >
              {activity?.user?.full_name}
            </Typography>
          }
          secondary={
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              className={classes.activityDate}
            >
              {moment(activity?.created_at).format("M/D/YY")}
              <ArrowForwardIosTwoTone
                style={{
                  height: "13px",
                  width: "13px",
                }}
              />
            </Typography>
          }
        />
      </Box>
      <Box>
        <Typography className={classes.activityDescription}>
          {activity?.name}
        </Typography>
        {activity?.workout_feedback && (
          <Typography className={classes.activitySubDescription}>
            {`${currentUser?.full_name} left feedback on ${activity?.name}`}
          </Typography>
        )}
      </Box>
    </ListItem>
  );
};

const useStyles = makeStyles({
  activityItem: {
    display: "block",
    padding: "15px 25px",
  },
  activityAvatar: {
    width: "24px",
    height: "24px",
  },
  activityTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.black,
  },
  activityDescription: {
    fontFamily: "Avenir-Medium",
    fontSize: "15px",
    lineHeight: 1.2,
    color: colors.black,
    margin: "5px 0px",
  },
  activityDate: {
    fontFamily: "Roboto-Medium",
    fontSize: "12px",
    lineHeight: 1,
    color: colors.dark_grey,
    alignItems: 'center',
  },
  activitySubDescription: {
    fontFamily: "Roboto-Medium",
    fontSize: "12px",
    lineHeight: 1,
    color: colors.radio_blue,
  },
});
