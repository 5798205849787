export const subscriptionFrequencies = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const basePlan = {
  yearly: 0,
  monthly: 0,
  perks: [
    'Client Management',
    'Workout Builder',
    'Performance Assessments',
    'Overhead Squat Assessments',
    'In App Chat',
  ],
};

export const plusPlan = {
  yearly: 199.99,
  monthly: 19.99,
  perks: [
    'Unlimited Clients',
    'Client Management',
    'Workout Builder',
    'Performance Assessments',
    'Overhead Squat Assessments',
    'In App Chat',
  ],
};

export const proPlan = {
  yearly: 349.99,
  monthly: 34.99,
  perks: [
    'Unlimited Clients',
    'Client Management',
    'Workout Builder',
    'Performance Assessments',
    'Overhead Squat Assessments',
    'In App Chat',
    'Online Booking',
    'Payment Processing',
  ],
};