import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import ClientScheduleNavigator from './ClientScheduleNavigator';
import SubscriptionPlans from '../SubscriptionPlans';

export default function Clients (props) {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/my-clients`} />
        </Route>
        <Route path={`${path}/my-clients`}>
          <ClientScheduleNavigator />
        </Route>
        <Route exact path={`${path}/view-subscriptions`}>
          <SubscriptionPlans />
        </Route>
        {/* Redirect to my clients if path subsection is invalid */}
        <Route path={`${path}/*`}>
          <Redirect to={`${path}/my-clients`} />
        </Route>
      </Switch>
    </>
  );
}
