import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CountryCodes from '../../util/CountryCodes.json';
import nasmApi from '../../api/endpoints';
import useFetchMeasurementUnits from '../../hooks/users/FetchMeasurementUnits';
import { passwordRecoveryUAURL, USER_ROLES } from '../../constants';
import { formatPhoneNumber, phone, getDigits, getCountryISO } from '../../util/validate';
import { OvalButton, ActionButton } from '../Buttons';
import UploadProfilePicture from '../Buttons/UploadProfilePicture';
import UnderlineTextInput from '../Inputs/UnderlineTextInput';
import ProfileDatePicker from '../Inputs/ProfileDatePicker';
import { LabelTextInput, StatelessDropdown } from '../Inputs';
import { colors } from '../../styles';
import { selectClient, deselectClient } from '../../reducers/selectedClientReducer';
import { getMe } from '../../reducers/currentUserReducer';
import ConfirmDialog from './ConfirmDialog';
import { StatelessDropdown as Dropdown } from '../Inputs';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: props => props.isClient ? '780px' : '680px',
    padding: '0 28px',
    marginTop: 30,
    marginBottom: 30,
  },
  topBtnCont: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleSection: {
    marginBottom: '12px',
  },
  header: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    textAlign: 'center',
  },
  editHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  textFieldCont: {
    margin: '16px 0',
    '&:first-child': {
      marginTop: '8px',
    },
  },
  inputLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    color: colors.steel,
  },
  doubleInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 0',
    '&:first-child': {
      margin: '16px 0 8px 0',
    },
  },
});

const labelInputStyles = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '14px',
};

const GENDER_DROPDOWN_OPTIONS = {
  MALE: 'Male',
  FEMALE: 'Female',
};

const UNIT_HEIGHT = {
  INCHES: 'in',
  CENT: 'cm',
};

const defaultCountry = {
  iso: 'US',
  name: 'United States',
};

function HeightInputFields (
  {
    isClient,
    heightUnits,
    heightFeet,
    heightInches,
    heightCm,
    setHeightFeet,
    setHeightInches,
    setHeightCm,
  }) {

  if (!isClient) {
    return null;
  }

  if (heightUnits === UNIT_HEIGHT.CENT) {
    return (
      <LabelTextInput
        placeholder='cm'
        label='Centimeters'
        value={heightCm}
        onHandleChange={(e) => setHeightCm(e.target.value)}
        onHandleBlur={() => {}}
        labelStyles={labelInputStyles}
        fontFamily='Roboto, sans-serif'
        fontSize={14}
        width={512}
        height={40}
      />
    );
  }
  return (
    <>
      <LabelTextInput
        placeholder='ft'
        label="Feet '"
        value={heightFeet}
        onHandleChange={(e) => setHeightFeet(e.target.value)}
        onHandleBlur={() => {}}
        labelStyles={labelInputStyles}
        fontFamily='Roboto, sans-serif'
        fontSize={14}
        width={250}
        height={40}
      />
      <LabelTextInput
        label='Inches "'
        placeholder='in'
        value={heightInches}
        onHandleChange={(e) => setHeightInches(e.target.value)}
        onHandleBlur={() => {}}
        labelStyles={labelInputStyles}
        fontFamily='Roboto, sans-serif'
        fontSize={14}
        width={250}
        height={40}
      />
    </>
  );
}

function UpdateProfile (props) {
  const { visible, user, onClose, onFetchClients, newClient } = props;
  const classes = useStyles({ isClient: user.nasm_role === USER_ROLES.CLIENT });
  const dispatch = useDispatch();
  const history = useHistory();

  const [avatar, setAvatar] = useState(user.avatar_url);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileErrorMessage, setMobileErrorMessage] = useState('');
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [heightCm, setHeightCm] = useState('');
  const [birthday, setBirthday] = useState(moment());
  const [gender, setGender] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState('');

  const [confirmResetPassword, setConfirmResetPassword] = useState(false);
  const [confirmRemoveClient, setConfirmRemoveClient] = useState(false);

  const [countryName, setCountryName] = useState(defaultCountry.name);
  const [countryISO, setCountryISO] = useState(defaultCountry.iso);

  // eslint-disable-next-line camelcase
  const height = user?.client_user?.height;
  const { measurementUnits } = useFetchMeasurementUnits();
  const isClient = user.nasm_role === USER_ROLES.CLIENT || newClient;

  // Calculate height depending on the trainer's measurement settings
  const calculateClientHeight = useCallback(() => {
    if (height) {
      if (measurementUnits.unit_height === UNIT_HEIGHT.INCHES) {
        // Separate feet from inches for separate text fields
        const feet =  Math.floor(height / 12);
        setHeightFeet(feet.toString());
        setHeightInches((height - (feet * 12)).toFixed().toString());
      } else {
        // leave centimeters as is
        setHeightCm(height);
      }
    }
  }, [height, measurementUnits]);

  const updateCountry = useCallback(() => {
    if (user.phone_number) {
      const countryISO = getCountryISO(user.phone_number)?.country || defaultCountry.iso;
      const countryObj = CountryCodes.find((country) => country.iso === countryISO);
      const countryName = countryObj?.country || defaultCountry.name;

      setCountryName(countryName);
      setCountryISO(countryISO);
    }
  }, [user.phone_number]);

  useEffect(() => {
    // Pre-fill saved form values into the form
    updateCountry();
    setFirstName(user.first_name);
    setLastName(user.last_name);
    if (!newClient) {
      calculateClientHeight();
      setMobileNumber(user.phone_number || '');
      setBirthday(user.birth_date ? moment(user.birth_date) : moment());
      setGender(user.gender || 'MALE');
    }
  }, [
    user.first_name,
    user.last_name,
    user.phone_number,
    user.birth_date,
    user.gender,
    calculateClientHeight,
    updateCountry,
    newClient,
  ]);

  const updateCountryData = useCallback(async (countryName) => {
    const countryObj = CountryCodes.find(country => country.country === countryName);
    const countryISO = countryObj?.iso || defaultCountry.iso;
    setCountryISO(countryISO);
    const number = mobileNumber
      .substr(mobileNumber.indexOf(' ') + 1)
      .replace(/\s/g, '');
    setMobileNumber(formatPhoneNumber(getDigits(number), countryISO));
  }, [mobileNumber]);

  const onClickDone = async () => {
    if (mobileNumber && mobileNumber.length > 0 && !phone(mobileNumber)) {
      setMobileNumberError(true);
      setMobileErrorMessage('Invalid phone number');
      return;
    }
    setMobileNumberError(false);
    setMobileErrorMessage('');
    setLoading(true);
    const userData = {
      phone_number: mobileNumber,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      birth_date: birthday,
      avatar_url: avatar,
    };

    if (user.nasm_role === USER_ROLES.CLIENT) {
      if (measurementUnits.unit_height === UNIT_HEIGHT.CENT) {
        userData.height = heightCm;
      } else {
        userData.height = (parseInt(heightFeet, 10) * 12) + parseInt(heightInches, 10);
      }
    }

    if(selectedProfile){
      await updateAvatar();
    }
    if (user.nasm_role === USER_ROLES.TRAINER) {
      await updateTrainer(userData);
    } else {
      await updateClient(userData);
    }
  };

  const updateClient = async (userData) => {
    try {
      const request = await nasmApi.users.updateClientUser(userData, user.id);
      const newUser = request.result;
      if (newClient && onFetchClients) {
        // fetch most-up-to-date clients list after trainer successfully
        // adds a trainer | will only re-render the components that need
        // to be updated as opposed to refreshing the entire page
        onFetchClients();
      }
      await dispatch(selectClient({ ...newUser }));
      onClose();
    } catch {
      alert('could not update profile');
    } finally {
      setLoading(false);
    }
  };

  const updateTrainer = async (userData) => {
    try {
      await nasmApi.trainer.updateTrainer(userData, user.id);
      await dispatch(getMe());
      onClose();
    } catch {
      alert('could not update profile');
    } finally {
      setLoading(false);
    }
  };

  const onChangeAvatar = async (img) => {
    if (!img) return;
    setSelectedProfile(img);
  };

  const updateAvatar = async () => {
    try {
      const uploadResult = await nasmApi.users.uploadAvatar(user.id, selectedProfile);
      setAvatar(uploadResult.result.url);
      setSelectedProfile('');
      if (user.nasm_role === USER_ROLES.CLIENT) {
        await dispatch(selectClient({ ...user, avatar_url: uploadResult.result.url }));
      }
    } catch (error) {
      alert('unable to upload profile picture. ');
    }
  };

  const handleSelect = (e) => {
    const countryName = e.target.value;
    setCountryName(countryName);
    updateCountryData(countryName);
  };

  const onChangeMobileNumber = (number) => {
    if (isNaN(number)) {
      setMobileNumberError(true);
      setMobileErrorMessage('Please use digits between 0-9');
    } else if (number.length <= 10) {
      setMobileNumberError(false);
      setMobileErrorMessage('');
      setMobileNumber(number);
    }
  };

  const onFocusMobileNumber = () => {
    const number = mobileNumber
      .substr(mobileNumber.indexOf(' ') + 1)
      .replace(/\s/g, '');
    setMobileNumber(getDigits(number));
  };

  const onPressBottomBtn = () => {
    if (user.nasm_role === USER_ROLES.TRAINER) {
      setConfirmResetPassword(true);
    } else {
      setConfirmRemoveClient(true);
    }
  };

  const onResetPassword = () => {
    setConfirmResetPassword(false);
    const url = passwordRecoveryUAURL();
    window.open(url) || window.location.replace(url);
  };

  const onRemoveClient = () => {
    setLoading(true);
    setConfirmRemoveClient(false);
    nasmApi.trainer.removeClient(user.id).then(() => {
      setLoading(false);
      onClose();
      history.replace('/clients');
      dispatch(deselectClient());
    }).catch((e) => {
      setLoading(false);
      alert('Could not delete client');
    });
  };

  const getCountries = () => {
    const obj = {};
    for(let i = 0; i < CountryCodes.length; i++){
        const { country } = CountryCodes[i];
        obj[country] = country;
    }
    return obj;
  };

  if (!visible) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.topBtnCont}>
        {
          newClient
            ? <ActionButton
                loading={loading}
                label='Done'
                onClick={onClickDone}
                width={74}
              />
            : <Box>
                <ActionButton
                  loading={loading}
                  label='Save'
                  onClick={onClickDone}
                  width={74}
                  margin='0 16px 0 0'
                />
                <OvalButton
                  onClick={onClose}
                  disabled={loading}
                >
                  Close
                </OvalButton>
              </Box>
        }
      </Box>
      <Box className={classes.titleSection}>
        {
          newClient
            ? <Box>
                <Typography className={classes.header}>
                  {`${user.first_name ?? 'User'}'s Profile`}
                </Typography>
                <Typography className={classes.description}>
                  Help wrap up their account, or skip and finish later.
                </Typography>
              </Box>
            : <Typography className={classes.editHeader}>Edit Profile</Typography>
        }
      </Box>
      <UploadProfilePicture
        avatar={selectedProfile ? URL.createObjectURL(selectedProfile) : avatar}
        email={user.email}
        onChangeAvatar={onChangeAvatar}
      />
      <Box>
        <Box className={classes.textFieldCont}>
          <Typography className={classes.inputLabel}>First Name</Typography>
          <UnderlineTextInput
            value={firstName}
            placeholder=''
            onNameChanged={(e) => setFirstName(e.target.value)}
          />
        </Box>
        <Box className={classes.textFieldCont}>
          <Typography className={classes.inputLabel}>Last Name</Typography>
          <UnderlineTextInput
            value={lastName}
            placeholder=''
            onNameChanged={(e) => setLastName(e.target.value)}
          />
        </Box>
        <Box className={classes.textFieldCont}>
          <Typography className={classes.inputLabel}>Country</Typography>
            <Dropdown
              options={getCountries()}
              onOptionChanged={handleSelect}
              width='100%'
              fontFamily='Roboto, sans-serif'
              fontSize={14}
              currentValue={countryName}
            />
        </Box>
        <Box className={classes.textFieldCont}>
          <Typography className={classes.inputLabel}>Mobile Number</Typography>
          <UnderlineTextInput
            value={mobileNumber}
            placeholder='### ### ####'
            onNameChanged={(e) => onChangeMobileNumber(e.target.value)}
            showError={mobileNumberError}
            errorText={mobileErrorMessage}
            onNameEditFinished={() => setMobileNumber(formatPhoneNumber(mobileNumber, countryISO))}
            onFocus={onFocusMobileNumber}
          />
        </Box>
        { isClient ? <Typography className={classes.inputLabel}>Height</Typography> : null }
        <Box className={classes.doubleInputs}>
          <HeightInputFields
            isClient={isClient}
            heightUnits={measurementUnits.unit_height}
            heightFeet={heightFeet}
            heightInches={heightInches}
            heightCm={heightCm}
            setHeightCm={setHeightCm}
            setHeightFeet={setHeightFeet}
            setHeightInches={setHeightInches}
          />
        </Box>
        <Box className={classes.doubleInputs}>
          <ProfileDatePicker
            value={birthday}
            onChange={(date) => setBirthday(date)}
            width='250px'
            label='Birthday'
          />
          <StatelessDropdown
            width={10}
            currentValue={gender}
            options={GENDER_DROPDOWN_OPTIONS}
            onOptionChanged={(e) => setGender(e.target.value)}
            label='Assigned Sex'
            fontFamily='Roboto, sans-serif'
            sideMargin={10}
          />
        </Box>
        {
          !newClient
            ? <OvalButton
                width={135}
                backgroundcolor={confirmResetPassword || confirmRemoveClient ? colors.navy : colors.white}
                color={confirmResetPassword || confirmRemoveClient ? colors.white : colors.steel}
                onClick={onPressBottomBtn}
              >
                {user.nasm_role === USER_ROLES.TRAINER ? 'Reset Password' : 'Remove Client'}
              </OvalButton>
            : null
        }
      </Box>
      <ConfirmDialog
        open={confirmResetPassword}
        onClose={() => setConfirmResetPassword(false)}
        handleConfirmAction={onResetPassword}
        title='Reset Password'
        description='We’ll email you a link allowing you to reset your password.'
        actionButtonTitle='Reset'
      />
      <ConfirmDialog
        open={confirmRemoveClient}
        onClose={() => setConfirmRemoveClient(false)}
        handleConfirmAction={onRemoveClient}
        title='Remove Client?'
        description='Removing a client will delete their profile from your dashboard.'
        actionButtonTitle='Remove'
        actionButtonWidth={74}
      />
    </Box>
  );
}

export default UpdateProfile;
