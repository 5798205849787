// Unlimited clients are given to trainers who have this permission active
import {UA_PERMISSION_TYPES} from '../constants';
import moment from 'moment';

export function checkForActiveFoundationPermission(permissions) {
  if(permissions instanceof Array &&  permissions.length > 0) {
    for(const permission of permissions) {
      const { permission_type: permissionType, name, expiration_at: expirationAt } = permission;
      if(permissionType === 'edge' && name === UA_PERMISSION_TYPES.EDGE_TRAINER_FOUNDATION) {
        if(!!expirationAt) {
          const localExpirationDate = moment(expirationAt).local();
          return moment().isBefore(localExpirationDate);
        } else {
          return false;
        }
      }
    }
  }

  return false;
}