/**
 * @author Donald Green <donald.green@medlmobile.com>
 */

const axios = require('axios');

// Dev
// let envUrl = 'https://dev-mobileapi.nasm.org';
let envUrl = process.env.REACT_APP_BASE_URL;

// Local
//let envUrl = 'http://localhost:8080';

const nasmUrl = envUrl;
const request = axios.create({ baseURL: nasmUrl });

export default request;
