import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { colors } from '../../styles';
import CloseIcon from '@material-ui/icons/Close';

const SelectedFilterButton = withTheme(withStyles({
  root: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: props.width || 'auto',
    height: props.height ? props.height : '38px',
    padding: props.padding || '10px',
    borderRadius: props.borderradius || '19px',
    border: props.border || 'solid 1px #ced3d7',
    backgroundColor: props.backgroundcolor || colors.white,
    color: props.color || colors.steel,
    fontSize: props.fontSize || 14,
    fontWeight: props.fontWeight || 'normal',
    fontFamily: props.theme.typography.fontFamily,
    marginRight: props.marginRight || '12px',
    marginBottom: props.marginBottom || '5px',
    marginTop: props.marginTop || '5px', 
  }),
})(({ classes, children, onRemove }) => (
  <div className={classes.root}>
    <span>{children}</span>
    <CloseIcon style={{ cursor: 'pointer' }}  onClick={onRemove} />
  </div>
)));

export default SelectedFilterButton;