import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: props => ({
    width: 37,
    height: 37,
    marginRight: props.marginRight || 20,
    backgroundColor: props.selected ? '#16161d' : '#f0f2f3',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '-webkit-transition': 'background-color 100ms ease-in',
    '-moz-transition': 'background-color 100ms ease-in',
    '-o-transition': 'background-color 100ms ease-in',
    '-ms-transition': 'background-color 100ms ease-in',
    transition: 'background-color 100ms ease-in',
  }),
  text: props => ({
    height: 16,
    fontWeight: 900,
    fontSize: 12,
    color: props.selected ? '#f5f5f5' : colors.steel,
    cursor: 'pointer',

    '-webkit-transition': 'color 250ms ease-in',
    '-moz-transition': 'color 250ms ease-in',
    '-o-transition': 'color 250ms ease-in',
    '-ms-transition': 'color 250ms ease-in',
    transition: 'color 250ms ease-in',
  }),
});

export default function DayOfWeekCircle (props) {
  const { onClick, ...styleProps } = props;
  const classes = useStyles(styleProps);

  return (
    <Box className={classes.root} onClick={onClick}>
      <Typography className={classes.text}>
        {props.text}
      </Typography>
    </Box>
  );
}
