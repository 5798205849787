import React from 'react';

export const CheckboxOn = (props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.size ?? 20} height={props.size ?? 20} viewBox="0 0 20 20">
          <g fill="none" fill-rule="evenodd">
              <g>
                  <g>
                      <g>
                          <g transform="translate(-181 -690) translate(160 339) translate(2) translate(19 351)">
                              <rect width="20" height="20" fill={'#469A1E'} rx="2"/>
                              <path stroke="#FFF" stroke-width="2" d="M14 7L9.003 14 6 11.353"/>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
};