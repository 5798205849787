import request from '../nasm_sdk_v2/request';

const BODYFAT_BASE_ENDPOINT = (apiVersion, userId) => `${apiVersion}/api/users/${userId}/body-fat`;

export default function bodyfat(apiVer) {
  function saveBodyFatResults(userId, jsonData, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error("userId is required to save BodyFat results."),
      );
    }
    return request.post(`${BODYFAT_BASE_ENDPOINT(apiVersion, userId)}`, jsonData);
  }

  function getCurrentBodyFat(userId, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch current BodyFat.'),
      );
    }
    return request.get(`${BODYFAT_BASE_ENDPOINT(apiVersion, userId)}/current`);
  }

  function getBodyFatHistory(userId, searchParams, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch BodyFat history.'),
      );
    }
    return request.get(`${BODYFAT_BASE_ENDPOINT(apiVersion, userId)}/history`, {
      params: {
        ...searchParams,
        size: 10,
      },
    });
  }

  return {
    saveBodyFatResults,
    getCurrentBodyFat,
    getBodyFatHistory,
  };
}
