import {useEffect, useState} from 'react';
import nasmApi from '../../api/endpoints';

export default function useFetchClientMeasurements(clientUserId) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [measurements, setMeasurements] = useState([]);

  useEffect(() => {
    setLoading(true);

    nasmApi.dashboard.getMeasurements(clientUserId)
      .then(response => {
        setMeasurements(response.result);
      })
      .catch(e => setError(true))
      .finally(() => setLoading(false));

  }, [clientUserId]);

  return {
    loading,
    error,
    measurements,
  };
}