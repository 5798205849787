import { createSlice } from '@reduxjs/toolkit';

const clubConnectSlice = createSlice({
  name: 'clubConnect',
  initialState: {
    selectedProfile: null,
  },
  reducers: {
    saveSelectedProfile: (state, action) => {
      const selectedProfileData = action.payload;
      state.selectedProfile = selectedProfileData;
    },
    clearSelectedProfile: (state) => {
      state.selectedProfile = null;
    },
  },
});

const { actions, reducer } = clubConnectSlice;
export const {  saveSelectedProfile, clearSelectedProfile } = actions;
export default reducer;


// *****
// ACTION CREATORS
// *****

export const handleProfileClick = (profile, clubId) => async (dispatch, getState) => {
  let selectedClub = profile;
  if (clubId) {
    const { currentUser } = getState();
    const clubs = currentUser?.club_connect_user
        ? Array.isArray(currentUser?.club_connect_user)
            ? currentUser?.club_connect_user
            : Object.values(currentUser?.club_connect_user)
        : [];
    if (clubs?.length) {
      selectedClub = clubs.find((cl) => cl.ClubId === clubId);
    }
  }
  dispatch(saveSelectedProfile(selectedClub));
};
