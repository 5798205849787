import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { CircularProgress, Toolbar } from '@material-ui/core';
import nasmApi from '../api/endpoints';
import { colors } from '../styles';
import OvalTextInput from '../components/Inputs/OvalTextInput';
import { DescriptionTextbox } from '../components/Inputs';
import { OvalButton } from '../components/Buttons';
import { email as validateEmailInput } from '../util/validate';
import ErrorText from '../components/Inputs/ErrorText';
import logo from '../resources/logo-nasm.png';

const Container = styled('div')({
  height: '100%',
  backgroundColor: colors.navy,
});
const Root = styled('div')({
  height: '100%',
  backgroundColor: colors.navy,
  justifyContent: 'center',
  display: 'flex',
});

const Body = styled('div')({
  margin: 20,
});

const Header = styled('div')({
  fontFamily: 'DMSans-Bold, sans-serif',
  fontWeight: 'bold',
  fontSize: 30,
  color: colors.white,
});

const Title = styled('p')({
  fontSize: 26,
  fontWeight: 'bold',
  color: colors.white,
  fontFamily: "Roboto, sans-serif",
});

const Description = styled('p')({
  fontSize: 16,
  color: colors.white,
  fontFamily: "Roboto, sans-serif",
});

const Label = styled('p')({
  fontSize: 16,
  color: colors.white,
  fontFamily: 'Roboto-Bold, sans-serif',
});

const Form = styled('form')({
  padding: '0px 40px 0px 40px',
});

const DeleteAccount = ({
  params,
}) => {
  const [email, onChangeEmail] = useState('');
  const [reason, onChangeReason] = useState('');
  const [emailError, setEmailError] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const confirmValidation = () => {
    let isError = false;
    setEmailError('');
    setReasonError('');
    if (!validateEmailInput(email)) {
      setEmailError('Enter a valid email address');
      isError = true;
    }
    if (reason.length === 0) {
      setReasonError('Enter reason');
      isError = true;
    }
    return isError;
  };

  async function onSubmit(e) {
    e.preventDefault();
    if (!confirmValidation()) {
      try {
        setLoading(true);
        const response = await nasmApi.users.requestAccountRemoval({ email, reason });
        if (response) {
          alert(response?.result?.message);
          onChangeEmail('');
          onChangeReason('');
          setEmailError('');
          setReasonError('');
        }
      } catch (error) {
        alert(error.message || error?.data?.message || 'Something went wrong! Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Container>
      <Toolbar style={{ backgroundColor: colors.mediumBlue, height: 80 }}>
        <img src={logo} alt='NASM' />
      </Toolbar>
      <Root>
        <Body>
          <Header>Delete Account Request</Header>
          <Description>
            If you wish to delete your account,
            please provide your email address below and submit your request.
            We will process your request as soon as possible.
          </Description>
          <Form onSubmit={onSubmit}>
            <Title>Request Account Deletion</Title>
            <Label for="email">Email Address</Label>
            <OvalTextInput
              value={email}
              placeholder='Email'
              onChange={(e) => onChangeEmail(e.target.value)}
              borderRadius={5}
            />
            <ErrorText
              showError={!!emailError}
              errorText={emailError}
              justifyContent='flex-start'
            />
            <Label for="email">Reason for Deletion</Label>
            <DescriptionTextbox
              value={reason}
              onChange={(e) => onChangeReason(e.target.value)}
              borderRadius={5}
              backgroundColor='#ecf0f3'
              placeholder='Reason'
              showError={!!reasonError}
              errorText={reasonError}
              justifyContent='flex-start'
            />
            <OvalButton
              backgroundcolor={colors.lightBlue}
              color={colors.white}
              width='100%'
              height={55}
              borderradius={8}
              opacity={1}
              fontSize={19}
              fontWeight='bold'
              type='submit'
              border='none'
              margin='20px 0 0 0'
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={21} color='inherit' /> : 'Submit Request'}
            </OvalButton>
          </Form>
        </Body>
      </Root>
    </Container>
  );
};

export default DeleteAccount;
