import React from 'react';
import { Prompt } from 'react-router-dom';

import ConfirmDialog from './ConfirmDialog';

function ConfirmDialogPrompt (props) {
  const { onHandleBlockedNavigation = (location, action) => {} } = props;
  return (
    <>
      <Prompt message={onHandleBlockedNavigation} />
      <ConfirmDialog  {...props} />
    </>
  );
}

export default ConfirmDialogPrompt;