import React from 'react';
import PropTypes from 'prop-types';
import { Select, FormControl, MenuItem, InputBase, Box, Typography, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as BtnArrowExpand } from '../../resources/btn-arrow-expand.svg';

import { SquareCheckbox } from '../Inputs';
import { colors } from '../../styles';
import ErrorText from './ErrorText';

const useStyles = makeStyles({
  multiSelectDropdown: props => ({
    width: '100%',
    borderRadius: 3,
    boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.18)',
    border: props.showError && !!props.errorText ? `solid 1px ${colors.yellow_button_style}` : 'solid 1px #d2d7db',
  }),
  dropdownIcon: {
    position: 'absolute',
    top: 'calc(50% - 15px)',
    right: '2%',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
  listItemRoot: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'white',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'white',
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
    '&.Mui-focusVisible:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
  listItemText: {
    '&>span': {
      fontWeight: 500,
      lineHeight: 2.29,
    },
  },
  selectFocus: {
    // ensures opacity doesn't get modified after user clicks away from
    // the dropdown
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
});

function MultiSelectDropdown (props) {
  const {
    values = [],
    selectableItems = [],
    onChange,
    onBlur,
    label,
    style,
    name,
    errorText = '',
    showError = false,
  } = props;

  const classes = useStyles({ showError, errorText });

  return (
    <Box>
      <Box className={classes.multiSelectDropdown} style={style}>
        <FormControl style={{ width: '100%' }}>
          <Select
            multiple
            value={values}
            onChange={onChange}
            onBlur={onBlur}
            IconComponent={BtnArrowExpand}
            input={<InputBase name={name} />}
            // Allows to display "0" when no items are selected
            displayEmpty
            renderValue={selected => <DropdownDisplay label={label} selected={selected} />}
            MenuProps={{
              // set spacing between dropdown display and dropdown menu list
              style: { marginTop: 10 },
              // must set to null to use anchorOrigin prop below
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              onExited: () => {
                // lose focus of dropdown to to allow user to freely select
                // any input without double clicking
                document.activeElement.blur();
              },
            }}
            classes={{
              icon: classes.dropdownIcon,
              select: classes.selectFocus,
            }}
          >
            {selectableItems.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
                className={classes.menuItem}
                ListItemClasses={{
                  root: classes.listItemRoot,
                }}
              >
                <SquareCheckbox isChecked={values.indexOf(item.id) > -1} />
                <ListItemText primary={item.name} className={classes.listItemText} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <ErrorText
        showError={showError}
        errorText={errorText}
      />
    </Box>
  );
}

const useDropdownDisplayStyles = makeStyles({
  dropdownDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    marginLeft: 11,
    lineHeight: 1.43,
  },
  selectedCount: {
    marginRight: 20,
  },
});

function DropdownDisplay (props) {
  const { label, selected = [] } = props;
  const classes = useDropdownDisplayStyles();

  return (
    <Box className={classes.dropdownDisplay}>
      <Typography className={classes.label}>
        {label}
      </Typography>
      <Typography className={classes.selectedCount}>
        {selected.length}
      </Typography>
    </Box>
  );
}

MultiSelectDropdown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectableItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  name: PropTypes.string,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
};

export default MultiSelectDropdown;
