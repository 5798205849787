import { Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

export default function ExerciseDetailsSkeleton () {
  return (
    <Container>
      <Skeleton variant='text' width='100%' height={100} />
      <Skeleton variant='text' width='100%' height={30} />
      <Skeleton variant='rect' width='100%' height={200} />
      <Skeleton variant='text' width='100%' height={100} />
    </Container>
  );
}
