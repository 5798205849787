import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import imgFeedback from '../../../resources/image-feedback.png';
import {ReactComponent as ClearButton} from '../../../resources/button-create-new-outline-grey-ftu.svg';

const useStyles = makeStyles({
  scheduleWorkoutPreview: {
    width: 380,
    height: 314,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  clearPlusBtn: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(60%)',
    },
  },
  titleText: {
    fontSize: 19,
    lineHeight: 2.05,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  descriptionText: {
    fontSize: 17,
    lineHeight: 1.94,
    textAlign: 'center',
    marginBottom: 23,
  },
});

function ScheduleWorkoutPreview (props) {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <Box className={classes.scheduleWorkoutPreview}>
      <img alt={'no-schedule'} src={imgFeedback} width={163} height={115}/>
      <Typography className={classes.titleText}>
        Schedule Workout
      </Typography>
      <Typography className={classes.descriptionText}>
        Assign programs, workouts, or exercises.
      </Typography>
      <ClearButton className={classes.clearPlusBtn} onClick={onClick}/>
    </Box>
  );
}

ScheduleWorkoutPreview.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ScheduleWorkoutPreview;