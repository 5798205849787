import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  colors,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import client from "../../api/endpoints/client";

const useStyles = makeStyles({
  cardHeader: {
    backgroundColor: "#000",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  cardHeaderTitile: {
    fontFamily: "DMSans-Medium",
    fontSize: "20px",
    lineHeight: 1.39,
    color: colors.white,
  },
  cardItemTitle: {
    fontFamily: "DMSans-Bold",
    fontSize: "17px",
    color: colors.black,
  },
  trainerView: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
  },
  trainerAvatarView: {
    padding: "3px",
    borderRadius: "100%",
    border: "2px solid #469A1E",
  },
  trainerAvatar: {
    width: "84px",
    height: "84px",
  },
  trainerNameView: {
    marginLeft: "20px",
  },
  tainerName: {
    fontFamily: "DMSans-Medium",
    fontSize: "17px",
    lineHeight: 1.33,
    color: colors.black,
  },
  tainerEmail: {
    display: "flex",
    marginTop: "2px",
    alignItems: "center",
  },
  emailIcon: {
    width: "18px",
    height: "18px",
  },
  email: {
    fontFamily: "Roboto",
    fontSize: "13px",
    lineHeight: 1.2,
    marginLeft: "5px",
    color: colors.carbon_black,
  },
  scrollList: {
    minHeight: "480px",
    maxHeight: "480px",
    overflow: "auto",
  },
  activityItem: {
    display: "block",
    padding: "15px 25px",
  },
  activityTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.black,
  },
  activityDescription: {
    fontFamily: "Avenir-Medium",
    fontSize: "15px",
    lineHeight: 1.2,
    color: colors.black,
  },
  activityDate: {
    fontFamily: "Roboto-Medium",
    fontSize: "12px",
    lineHeight: 1,
    color: colors.dark_grey,
  },
  badgeCount: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.white,
  },
  clientItem: {
    padding: "30px 25px",
    cursor: "pointer",
  },
  clientAvatar: {
    height: "54px",
    width: "54px",
    marginRight: "15px",
  },
  clientName: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.black,
  },
  arrowIcon1: {
    height: "26px",
    width: "26px",
    color: colors.dark_grey,
  },
  noDateText: {
    fontFamily: "Roboto-Medium",
    fontSize: "20px",
    lineHeight: 1.5,
    color: colors.dark_grey,
  },
});

/**
 * @return {JSX|null}
 */

export default function CCDashboardSkeleton(props) {
  const classes = useStyles();

  const trainerReport = ["", "", ""];
  const latestActivity = ["", "", ""];
  const clientsList = ["", "", ""];

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }} marginX={15} marginY={4}>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4}>
          <Paper style={{ marginBottom: 20, borderRadius: 10 }}>
            <AppBar position="static" className={classes.cardHeader}>
              <Toolbar>
                <Typography variant="h6" className={classes.cardHeaderTitile}>
                  Trainer Account
                </Typography>
              </Toolbar>
            </AppBar>
            <Box className={classes.trainerView}>
              <Box className={classes.trainerAvatarView}>
                <Skeleton variant="circle">
                  <Avatar alt="trainer" className={classes.trainerAvatar} />
                </Skeleton>
              </Box>
              <Box className={classes.trainerNameView}>
                <Skeleton variant="text" className={classes.tainerName} />
                <Box className={classes.tainerEmail}>
                  <Skeleton variant="rect" className={classes.emailIcon} />
                  <Skeleton variant="text" className={classes.email} />
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ padding: 2 }}>
            <AppBar position="static" className={classes.cardHeader}>
              <Toolbar>
                <Typography variant="h6" className={classes.cardHeaderTitile}>
                  Trainer Report
                </Typography>
              </Toolbar>
            </AppBar>
            <List>
              {trainerReport.map((_, i) => (
                <ListItem key={i}>
                  <ListItemText
                    primary={
                      <Skeleton
                        variant="text"
                        className={classes.cardItemTitle}
                      />
                    }
                  />
                  <Skeleton variant="react">
                    <Avatar alt="trainer" className={classes.arrowIcon1} />
                  </Skeleton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Middle Column */}
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }}>
            <AppBar position="static" className={classes.cardHeader}>
              <Toolbar>
                <Typography variant="h6" className={classes.cardHeaderTitile}>
                  Latest Activity
                </Typography>
              </Toolbar>
            </AppBar>
            <List className={classes.scrollList}>
              {latestActivity.map((_, i) => (
                <div key={i}>
                  <ListItem className={classes.activityItem}>
                    <ListItemText
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      primary={
                        <Skeleton
                          variant="text"
                          className={classes.activityTitle}
                        />
                      }
                      secondary={
                        <Skeleton
                          variant="text"
                          className={classes.activityDate}
                        />
                      }
                    />
                    <Skeleton
                      variant="text"
                      className={classes.activityDescription}
                    />
                  </ListItem>

                  {i < latestActivity?.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }}>
            <AppBar position="static" className={classes.cardHeader}>
              <Toolbar
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h6" className={classes.cardHeaderTitile}>
                  Clients
                </Typography>
                <Badge
                  badgeContent={
                    <Typography className={classes.badgeCount}>0</Typography>
                  }
                />
              </Toolbar>
            </AppBar>
            <List className={classes.scrollList}>
              {clientsList.map((_, i) => (
                <div key={i}>
                  <ListItem className={classes.clientItem}>
                    <ListItemAvatar>
                      <Skeleton variant="circle">
                        <Avatar
                          alt="clientName"
                          variant="circle"
                          className={classes.clientAvatar}
                        />
                      </Skeleton>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          variant="text"
                          className={classes.clientName}
                        />
                      }
                    />
                    <Skeleton variant="rect" className={classes.arrowIcon1} />
                  </ListItem>

                  {i < client.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
