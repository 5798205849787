import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import querystring from 'query-string';
import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import { colors } from '../../styles';
import { ActionButton, OvalButton } from '../Buttons';
import ProgressionsRegressionsList from '../ProgressionsRegressionsList';
import useFetchExerciseDetails from '../../hooks/exercises/FetchExerciseDetails';
import ExerciseDetailsSkeleton from '../LoadingStates/ExerciseDetailsSkeleton';
import { editExercise } from '../../reducers/selectedWorkoutReducer';
import { programContexts } from '../../reducers/programContextReducer';

const useStyles = makeStyles({
  parentContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 720,
  },
  root: {
    margin: 20,
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 10,
    gap: 15,
  },
  exerciseNameStyle: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.63,
    color: colors.black,
    marginTop: 25,
  },
});

function SwapExerciseContainer(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { exerciseDetails, backLink, exeOrCompoundExe, exerciseKey, isScheduling } = props;
  const [selectedExercise, setSelectedExercise] = useState({});
  const originalProgRegExercises = exerciseDetails?.progressions_regressions ?? [];

  if (!exerciseDetails || !isScheduling) {
    return null;
  }

  function onPressSwapExercise() {
    if (!selectedExercise) {
      return;
    }
    const filteredProgReg = [];
    originalProgRegExercises.forEach((ex) => {
      if (ex.exercise?.id === selectedExercise.exercise?.id) {
        if (ex.exerciseType === selectedExercise.exerciseType) {
          return;
        }
      }
      const updatedExercise = {
        ...ex,
        primary_exercise_id: selectedExercise.exercise?.id,
      };
      filteredProgReg.push(updatedExercise);
    });
    const updatedExercise = {
      ...selectedExercise.exercise,
      original_exercise_id: exerciseDetails?.id,
      swapped_original_exercise: exeOrCompoundExe ?? exerciseDetails,
      progressions_regressions: [...filteredProgReg],
      progression_enabled: exerciseDetails?.progression_enabled ?? true,
      regression_enabled: exerciseDetails?.regression_enabled ?? true,
    };
    if (!exeOrCompoundExe) {
      updatedExercise.key = exerciseKey;
    }
    let compoundRoutine;
    if (exeOrCompoundExe) {
      const compoundRoutineExercises = exeOrCompoundExe?.compound_routine_exercises?.map((ex) => {
        if (ex.id === exerciseDetails?.id) {
          return updatedExercise;
        }
        return ex;
      });
      compoundRoutine = {
        ...exeOrCompoundExe,
        compound_routine_exercises: compoundRoutineExercises,
      };
    }
    dispatch(editExercise(compoundRoutine ?? updatedExercise));
    history.goBack();
  }

  function handleSaveBtn() {
    onPressSwapExercise();
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.topButtons}>
          <ActionButton
            width={72}
            height={38}
            onClick={handleSaveBtn}
            label='Save'
          />
          <Link to={{ search: backLink() }}>
            <OvalButton
              width={72}
              height={38}
              onClick={() => history.goBack()}
            >
              Close
            </OvalButton>
          </Link>
        </Box>
        <Typography className={classes.exerciseNameStyle}>
          Swap Exercise
        </Typography>
      </Box>
      <Box className={classes.parentContainer}>
        <ProgressionsRegressionsList
          exerciseDetails={exerciseDetails}
          exerciseKey={exerciseKey}
          viewExerciseDetailsLink={() => {}}
          comingFrom='swap-page'
          exeOrCompoundExe={exeOrCompoundExe}
          selectedExercise={selectedExercise}
          setSelectedExercise={setSelectedExercise}
        />
      </Box>
    </>
  );
}

export default function SwapExercisePanel(props) {
  const { visible, backLink = () => {} } = props;

  const location = useLocation();
  const [exerciseId, setExerciseId] = useState(null);
  const queryParams = querystring.parse(location.search);
  const newExerciseId = queryParams.exerciseId || null;
  const newExerciseKey = queryParams.exerciseKey || null;

  const { selectedWorkout, programContext } = useSelector(state => state);

  useEffect(() => {
    setExerciseId(newExerciseId);
  }, [newExerciseId]);

  const { isDataLoaded, exerciseDetails, error } = useFetchExerciseDetails(exerciseId);
  const isScheduling = programContext.context === programContexts.SCHEDULING;

  let exerciseData = exerciseDetails;
  let exeOrCompoundExe = {};

  exeOrCompoundExe = Object.values(selectedWorkout?.entities?.exercises)?.find(
    (ex) => ex.key === newExerciseKey,
  );
  if (!exeOrCompoundExe) {
    exeOrCompoundExe = exerciseDetails;
  }
  if (exeOrCompoundExe?.compound_routine_exercises?.length) {
    exerciseData = exeOrCompoundExe.compound_routine_exercises?.find(
      (ex) => {
        if (ex.id === exerciseDetails?.id) {
          return ex;
        }
        return null;
      },
    );
    if (!exerciseData) {
      exerciseData = exeOrCompoundExe.compound_routine_exercises.find(
        (ex) => {
          if (
            ex.swapped_original_exercise?.compound_routine_exercises?.some(
              (ex1) => ex1.id === exerciseDetails?.id,
            )
          ) {
            return ex;
          }
          return null;
        },
      );
    }
  } else {
    exerciseData = exeOrCompoundExe;
  }

  const isCompoundRoutine = exeOrCompoundExe?.compound_routine_exercises?.length;

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel>
        <FadeTransition visible={visible}>
          {isDataLoaded && !error && (
            <SwapExerciseContainer
              exerciseDetails={exerciseData}
              backLink={backLink}
              exeOrCompoundExe={isCompoundRoutine ? exeOrCompoundExe : null}
              exerciseKey={newExerciseKey}
              isScheduling={isScheduling}
            />
          )}
          {!isDataLoaded && <ExerciseDetailsSkeleton />}
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

SwapExercisePanel.propTypes = {
  visible: PropTypes.bool,
  backLink: PropTypes.func,
};


