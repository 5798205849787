export function uploadProgressHandler(updateProgressBar) {
  // See: https://github.com/axios/axios#request-config
  // onUploadProgress
  return progressEvent => {
    const progress =  Math.round((progressEvent.loaded / progressEvent.total) * 100);

    updateProgressBar({
      // Browser Compatibility
      // See: https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent#browser_compatibility
      canProgressBeCalculated: progressEvent.lengthComputable,
      progress: progress,
    });
  };
}