import { makeStyles } from '@material-ui/core/styles';
import FullScreenButtonSrc from '../../../../resources/button-expand-window.svg';
import FullScreenButtonActiveSrc from '../../../../resources/button-expand-window-active.svg';
import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useFullScreenButtonStyles = makeStyles({
  fullScreenButton: {
    width: 38,
    height: 38,
    margin: 10,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${FullScreenButtonSrc})`,
    '&:hover': {
      backgroundImage: `url(${FullScreenButtonActiveSrc})`,
    },
    cursor: 'pointer',
  },
});

function FullScreenButton (props) {
  const { onClick } = props;
  const classes = useFullScreenButtonStyles();
  return (
    <Box onClick={onClick} className={classes.fullScreenButton} />
  );
}

FullScreenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FullScreenButton;
