import FormData from 'form-data';

export function convertBlobToDataUrl (blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}

function captureSnapshot (video) {
  return new Promise((resolve, reject) => {
    video.addEventListener('seeked', function onCaptureImage (event) {
      getThumbnailBlob(event)
        .then(blob => resolve(blob))
        .catch(err => reject(err))
        .finally(() => {
          video.removeEventListener('seeked', onCaptureImage);
        });
    });
  });
}

function setVideoToMiddleFrame (event) {
  if (!event.target || isNaN(event.target.duration)) {
    console.warn("the video's total time could not be determined right now");
    return;
  }

  event.target.currentTime = event.target.duration / 2;
}

function getThumbnailBlob (event) {
  const canvas = document.createElement('canvas');

  return new Promise((resolve, reject) => {
    const videoRef = event.target;

    if (!videoRef) {
      const error = new Error('Video reference missing');
      error.name = 'ClientError';
      reject(error);
    }

    if (!videoRef.videoWidth || !videoRef.videoHeight) {
      const error = new Error('Video Dimensions missing');
      error.name = 'ClientError';
      reject(error);
    }

    canvas.width = videoRef.videoWidth;
    canvas.height = videoRef.videoHeight;
    const context = canvas.getContext('2d');

    if (!context) {
      const error = new Error('Unsupported canvas context: 2d');
      error.name = 'ClientError';
      reject(error);
    }

    context.drawImage(videoRef, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg');
  });
}

export async function prepareMediaFiles (videoFile) {
  const tempUrl = URL.createObjectURL(videoFile);
  const video = document.createElement('video');
  video.src = tempUrl;
  video.addEventListener('loadedmetadata', setVideoToMiddleFrame);

  const data = new FormData();

  try {
    const thumbnailBlob = await captureSnapshot(video);
    const thumbnailFile = new File(
      [thumbnailBlob],
      'thumbnail.jpg',
      { type: 'image/jpeg' } );

    data.append('video', videoFile);
    data.append('thumbnail', thumbnailFile);
  } catch (e) {
    console.log('error: ', e);
    return Promise.reject(e);
  } finally {
    // See: https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
    URL.revokeObjectURL(tempUrl);
  }

  return data;
}
