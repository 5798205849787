import  React from 'react';

import CardContentLoading from '../CardContentLoading';
import ClientMeasurementGraph from './ClientMeasurementGraph';
import CardDefaultContent from '../CardDefaultContent';

function ClientMeasurementController (props) {
  const { loading, measurementsData = [], unitPreferences = {} } = props;

  if(loading) {
    return <CardContentLoading height={245} />;
  }

  if(!loading && measurementsData.length === 0) {
    return (
      <CardDefaultContent
        height={100}
        label={'No measurements recorded.' +
        ' Please go to the NASM EDGE mobile app to record.'}
      />
    );
  }

  return (
    <ClientMeasurementGraph
      measurementsData={measurementsData}
      unitPreferences={unitPreferences}
    />
  );
}

export default ClientMeasurementController;