import request from '../nasm_sdk_v2/request';

export default function passwordReset(apiVer) {
  /***
   *  Used to reset passwords for user accounts
   *  that signed up since v1.7 and haven't linked their
   *  accounts to NASM UA system
   *
   * @param {string} email - user's email address that requested the password reset
   * @param {string} apiVersion
   * @returns {Promise<*|undefined>}
   */
  function legacyReset(email,  apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/password-reset`, { email });
  }

  return {
    legacyReset,
  };
}