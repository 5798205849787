import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CSSTransition from 'react-transition-group/CSSTransition';

const useStyles = makeStyles({
  transitionEnterDone: {
    opacity: 1,
  },
  transitionExit: {
    opacity: 1,
  },
  transitionExitActive: {
    opacity: 0,
    '-webkit-transition': 'opacity 300ms',
    '-moz-transition': 'opacity 300ms',
    '-o-transition': 'opacity 300ms',
    '-ms-transition': 'opacity 300ms',
    transition: 'opacity 300ms',
  },
  transitionExitDone: {
    opacity: 0,
    '-webkit-transition': 'opacity 300ms',
    '-moz-transition': 'opacity 300ms',
    '-o-transition': 'opacity 300ms',
    '-ms-transition': 'opacity 300ms',
    transition: 'opacity: 300ms',
  },
});

export default function FadeTransition (props) {
  const classes = useStyles();
  return (
    <CSSTransition
      timeout={{ enter: 300, exit: 300 }}
      in={props.visible}
      appear
      classNames={{
        enterDone: classes.transitionEnterDone,
        exit: classes.transitionExit,
        exitActive: classes.transitionExitActive,
        exitDone: classes.transitionExitDone,
      }}
    >
      <>
        {props.children}
      </>
    </CSSTransition>
  );
}
