import request from '../nasm_sdk_v2/request';
import axios from 'axios';

export default function nasmPrograms(apiVer) {
  function getProgram(programId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/nasm-programs/${programId}`);
  }

  function findPrograms(searchParams, cancelToken, apiVersion = apiVer) {
    return request
      .get(`${apiVersion}/api/nasm-programs`, {
        params: searchParams,
        cancelToken,
      })
      .catch(e => {
        if(axios.isCancel(e)) {
          // swallow cancel request error
          return Promise.resolve({
            isRequestCancelled: true,
          });
        } else {
          return Promise.reject(e);
        }
      });
  }

  function createProgram(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/nasm-programs`, data);
  }

  function updateProgram(programId, data, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/nasm-programs/${programId}`, data);
  }

  function duplicateProgram(programId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/nasm-programs/${programId}/duplicate`);
  }

  function deleteProgram(programId, apiVersion = apiVer) {
    return request.delete(`${apiVersion}/api/nasm-programs/${programId}`);
  }

  function getProgramCategories(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/program-categories`);
  }

  return {
    getProgram,
    findPrograms,
    createProgram,
    updateProgram,
    duplicateProgram,
    deleteProgram,
    getProgramCategories,
  };
}