import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';

import { colors } from '../../styles';

const useStyles = makeStyles({
  errorText: props => ({
    color: props.textColor || colors.yellow_button_style,
    margin: props.margin || 0,
    display: 'flex',
    justifyContent: props.justifyContent || 'flex-end',
    fontWeight: 500,
  }),
});

/**
 * @return {JSX | null}
 */
function ErrorText (props) {
  const { margin, textColor, justifyContent, errorText = '', showError = false } = props;
  const classes = useStyles({ margin, textColor, justifyContent });

  if (!showError) {
    return null;
  }

  return (
    <FormHelperText className={classes.errorText}>
      {errorText}
    </FormHelperText>
  );
}

ErrorText.propTypes = {
  margin: PropTypes.string,
  textColor: PropTypes.string,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
};

export default ErrorText;
