import { makeStyles } from "@material-ui/core";
import { colors } from "../../styles";

const useStyles = makeStyles({
  cardHeader: {
    backgroundColor: "#000",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  cardHeaderTitile: {
    fontFamily: "DMSans-Medium",
    fontSize: "20px",
    lineHeight: 1.39,
    color: colors.white,
  },
  cardItemTitle: {
    fontFamily: "DMSans-Bold",
    fontSize: "17px",
    color: colors.black,
  },
  trainerView: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
  },
  trainerAvatarView: {
    padding: "3px",
    borderRadius: "100%",
    border: "2px solid #469A1E",
  },
  trainerAvatar: {
    width: "84px",
    height: "84px",
  },
  trainerNameView: {
    marginLeft: "20px",
  },
  tainerName: {
    fontFamily: "DMSans-Medium",
    fontSize: "17px",
    lineHeight: 1.33,
    color: colors.black,
  },
  tainerEmail: {
    display: "flex",
    marginTop: "2px",
    alignItems: "center",
  },
  emailIcon: {
    width: "18px",
    height: "18px",
  },
  email: {
    fontFamily: "Roboto",
    fontSize: "13px",
    lineHeight: 1.2,
    marginLeft: "5px",
    color: colors.carbon_black,
  },
  scrollList: {
    minHeight: "480px",
    maxHeight: "480px",
    overflow: "auto",
  },
  activityItem: {
    display: "block",
    padding: "15px 25px",
  },
  activityAvatar: {
    width: "24px",
    height: "24px",
  },
  activityTitle: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.black,
  },
  activityDescription: {
    fontFamily: "Avenir-Medium",
    fontSize: "15px",
    lineHeight: 1.2,
    color: colors.black,
  },
  activityDate: {
    fontFamily: "Roboto-Medium",
    fontSize: "12px",
    lineHeight: 1,
    color: colors.dark_grey,
  },
  badgeCount: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.white,
  },
  clientItem: {
    padding: "30px 25px",
    cursor: "pointer",
  },
  clientAvatar: {
    height: "54px",
    width: "54px",
    marginRight: "15px",
  },
  clientName: {
    fontFamily: "Roboto-Bold",
    fontSize: "14px",
    lineHeight: 1,
    color: colors.black,
  },
  arrowIcon1: {
    height: "26px",
    width: "26px",
    color: colors.dark_grey,
  },
  noDateText: {
    fontFamily: "Roboto-Medium",
    fontSize: "20px",
    lineHeight: 1.5,
    color: colors.dark_grey,
  },
});

export default useStyles;
