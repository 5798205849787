import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';
import {colors} from '../../../styles';
import NutritionController from '../Nutrition/NutritionController';

const HeaderText = styled(Typography)({
  color: colors.white,
  fontWeight: 500,
  fontSize: 20,
  lineHeight: 1.95,
});

const CenterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: 'inherit',
  marginLeft: 20,
});

function NutritionCard (props) {
  const { loading, nutritionData = {}, onClick = () => {} } = props;

  return (
    <CardContainer
      width={419}
      height={200}
    >
      <CardHeader
        height={55}
        backgroundColor={colors.green}
        onClick={onClick}
        enableCursorPointer
      >
        <CenterContainer >
          <HeaderText>Nutrition</HeaderText>
        </CenterContainer>
      </CardHeader>
      <NutritionController loading={loading} nutritionData={nutritionData} />
    </CardContainer>
  );
}

NutritionCard.propTypes = {
  loading: PropTypes.bool,
  nutritionData: PropTypes.shape({
    id: PropTypes.string,
    protein: PropTypes.number,
    carbohydrates: PropTypes.number,
    fat: PropTypes.number,
    /***
     * Date recorded in YYYY-MM-DD format
     */
    date: PropTypes.string,
    client_user_id: PropTypes.string,
  }),
};

export default NutritionCard;