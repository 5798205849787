import { createSlice } from '@reduxjs/toolkit';

const exercisesSlice = createSlice({
  name: 'exercises',
  // Store a list of exercises retrieved from the backend API
  initialState: {
    byId: {},
  },
  reducers: {
    saveExercises: (state, action) => {
      const exerciseData = action.payload;
      if (exerciseData && exerciseData.length > 0) {
        for (const exercise of exerciseData) {
          state.byId[exercise.id] = exercise;
        }
      }
    },
    clearExercises: (state, action) => {
      state.byId = {};
    },
  },
});

const { actions, reducer } = exercisesSlice;
export const { saveExercises, clearExercises } = actions;
export default reducer;
