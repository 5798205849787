import React, {
  useReducer,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import querystring from "query-string";
import { Box } from "@material-ui/core";
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { updateQuery } from "../../util/utilFunctions";
import { colors } from "../../styles";

import ClientBreadcrumbs from "../../components/ClientDashboard/ClientBreadcrumbs";
import { PageHeader, PageToolBar } from "../../components";

import PanelContainer from "../../components/Panels/PanelContainer";
import WorkoutListPanel from "../../components/Panels/WorkoutListPanel";
import WorkoutSectionsPanel from "../../components/Panels/WorkoutSectionsPanel";
import ExerciseDetailsPanel from "../../components/Panels/ExerciseDetailsPanel";
import ScheduleDialog from "../../components/SchedulePopover/ScheduleDialog";

import { getWorkoutSections } from "../../reducers/workoutsReducer";
import {
  clearWorkout,
  denormalizeWorkout,
} from "../../reducers/selectedWorkoutReducer";
import {
  createNewProgram,
  addWorkoutsNoBackEndApi,
  scheduleProgramToday,
  scheduleProgram,
  clearProgram,
} from "../../reducers/selectedProgramReducer";

import {
  initScheduleReducer,
  WORKOUT_BUTTONS_VISIBILITY_ACTIONS,
  workoutButtonsVisibilityReducer,
} from "../../reducers/local/workoutButtonsVisibilityReducer";
import { scheduleProgramContext } from "../../reducers/programContextReducer";
import { FloatingCircleButton } from "../../components/Buttons";
import ScheduleWorkoutVideoPanelWrapper from "../../components/Workouts/ScheduleWorkoutVideoPanelWrapper";
import AddSuperSetsPanel from "../../components/Panels/AddSuperSetsPanel";
import AddCircuitsPanel from "../../components/Panels/AddCircuitsPanel";
import SwapExercisePanel from "../../components/Panels/SwapExercisePanel";

const views = {
  workoutList: {
    key: "workoutList",
    workoutList: true,
  },
  workoutDetails: {
    key: "workoutDetails",
    workoutList: true,
    workoutDetails: true,
  },
  viewExercise: {
    key: "viewExercise",
    viewExercise: true,
    workoutDetails: true,
  },
  videoWorkout: {
    key: "videoWorkout",
    workoutList: true,
    videoWorkout: true,
  },
  superSetDetails: {
    key: 'superSetDetails',
    workoutList: true,
    superSetDetails: true,
  },
  circuitDetails: {
    key: 'circuitDetails',
    workoutList: true,
    circuitDetails: true,
  },
  superSetExerciseDetails: {
    key: 'superSetExerciseDetails',
    superSetDetails: true,
    viewExercise: true,
    workoutList: false,
  },
  circuitExerciseDetails: {
    key: 'circuitExerciseDetails',
    circuitDetails: true,
    viewExercise: true,
    workoutList: false,
  },
  progressionRegressionExDetails: {
    key: 'progressionRegressionExDetails',
    progressionRegressionExDetails: true,
    viewExercise: true,
    workoutDetails: true,
  },
  swapPage: {
    key: 'swapPage',
    viewExercise: true,
    swapPage: true,
  },
};

export default function ScheduleWorkoutScreen(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathMap = {}, clientFirstName, selectedDate, selectedGroup } = props;

  const groupId = selectedGroup?.id;
  const groupName = selectedGroup?.title;

  let rootPath = "/clients/my-clients";
  
  if (groupId) {
    rootPath = "/groups/my-groups";
  }

  const [isScheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [selectedWorkoutIndex, setSelectedWorkoutIndex] = useState(-1);
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});
  const resetWorkoutIndex = () => {
    setSelectedWorkoutIndex(-1);
  };
  const handleOpen = () => setScheduleDialogOpen(true);
  const handleClose = useCallback(() => setScheduleDialogOpen(false), []);

  // IMPROVEMENT: consider moving up to Client or Group ScheduleNavigator component and passing these variables as props
  const defaultSections = useSelector(
    (state) => state.workouts.defaultSections,
  );

  const clearSelectedSchedule = useCallback(() => {
    dispatch(clearProgram());
    dispatch(clearWorkout());
  }, [dispatch]);

  // IMPROVEMENT: consider moving up Client or Group ScheduleNavigator component
  useEffect(() => {
    dispatch(getWorkoutSections());
  }, [dispatch]);

  useEffect(() => {
    dispatch(scheduleProgramContext());
  }, [dispatch]);

  // Used to create the invisible program responsible for scheduling the workout
  const selectedWorkout = useSelector((state) => state.selectedWorkout);
  // Used to populate workout details shown in Schedule Dialog
  const selectedProgram = useSelector((state) => state.selectedProgram);

  const [workoutButtonsVisibilityFlags, localDispatch] = useReducer(
    workoutButtonsVisibilityReducer,
    {},
    initScheduleReducer,
  );

  const view = useMemo(() => {
    const query = querystring.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true,
    });
    return query?.view ?? "workoutList";
  }, [location.search]);

  function updateButtonVisibilityFlags({
    isCopyButtonVisible,
    isSaveButtonVisible,
    isAddExercisesButtonVisible,
    isAddButtonVisible,
    isScheduleButtonVisible,
  }) {
    localDispatch({
      type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS,
      payload: {
        showCopyButton: isCopyButtonVisible,
        showSaveButton: isSaveButtonVisible,
        showAddExercisesButton: isAddExercisesButtonVisible,
        showAddButton: isAddButtonVisible,
        showScheduleButton: isScheduleButtonVisible,
      },
    });
  }

  function onChangeWorkout(workout, index) {
    setSelectedWorkoutIndex(index);
  }

  // NAVIGATOR HELPER FUNCTIONS //

  const getWorkoutUrlParams = (workoutId, isVideoWorkout) => {
    const view = isVideoWorkout
      ? views.videoWorkout.key
      : views.workoutDetails.key;
    const query = updateQuery(location.search, { view, workoutId: workoutId });
    return {
      pathname: location.pathname,
      search: query,
    };
  };

  const workoutDetailsLink = () => {
    const query = querystring.parse(location.search);
    return updateQuery(location.search, {
      view: views.workoutDetails.key,
      workoutId: query.workoutId,
      exerciseId: undefined,
    });
  };

  const viewExerciseLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, {
      view: views.viewExercise.key,
      exerciseId,
      exerciseKey,
      showProgressionsRegressions,
    });
  };

  const workoutListLink = () => {
    return updateQuery(location.search, {
      view: views.workoutList.key,
      workoutId: undefined,
    });
  };

  const viewSuperSetsLink = () => {
    return updateQuery(location.search, { view: views.superSetDetails.key });
  };

  const viewCircuitLink = () => {
    return updateQuery(location.search, { view: views.circuitDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      view: views.superSetExerciseDetails.key, 
      exerciseId, 
      exerciseKey, 
      showProgressionsRegressions,
     });
  };

  const viewCircuitExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      view: views.circuitExerciseDetails.key, 
      exerciseId,
      exerciseKey, 
      showProgressionsRegressions,
    });
  };

  const viewProgressionRegressionExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    if(views[view].superSetExerciseDetails){
      views.progressionRegressionExDetails.superSetDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else if (views[view].circuitExerciseDetails) {
      views.progressionRegressionExDetails.circuitDetails = true;
      views.progressionRegressionExDetails.superSetDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else {
      views.progressionRegressionExDetails.workoutDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.superSetDetails = false;
    }
    return updateQuery(location.search, {
      view: views.progressionRegressionExDetails.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewSwapPageLink = (exerciseId) => {
    return updateQuery(location.search, {
      view: views.swapPage.key,
      exerciseId,
    });
  };

  // END NAVIGATOR HELPER FUNCTIONS //

  const navigateBackToCalendar = (firstName, startDate) => {
    if (firstName) {
      const state = { selectedDate: startDate };
      const routeEnd = !groupId ? '/calendar' : '';
      history.replace(
        `${rootPath}/${firstName.toLowerCase()}/dashboard${routeEnd}`,
        state,
      );
    } else {
      // Fallback to navigating to trainer's client or group list
      history.replace(`${rootPath}`);
    }
  };

  const onPrepareWorkouts = useCallback(() => {
    dispatch(createNewProgram());
    // Assuming that selectedWorkout is retrieved by the time the user opens the Schedule Dialog Component
    const workout = denormalizeWorkout(selectedWorkout);
    dispatch(addWorkoutsNoBackEndApi([workout]));
  }, [dispatch, selectedWorkout]);

  const onScheduleOnce = async (startDate) => {
    try {
      await dispatch(scheduleProgramToday(startDate));
      navigateBackToCalendar(groupId ?? clientFirstName, startDate);
    } catch (e) {
      window.alert("Failed to schedule the workout");
      history.replace(location.pathname, workoutListLink());
      handleClose();
    } finally {
      clearSelectedSchedule();
    }
  };

  const onScheduleRepeat = async (startDate, endDate) => {
    try {
      await dispatch(scheduleProgram(startDate, endDate));
      navigateBackToCalendar(groupId ?? clientFirstName, startDate);
    } catch (e) {
      window.alert("Failed to schedule the workout");
      history.replace(location.pathname, workoutListLink());
      handleClose();
    } finally {
      clearSelectedSchedule();
    }
  };

  return (
    <Box>
      <PageHeader
        title={`Choose a workout for ${groupName ?? clientFirstName}`}
        color={colors.graphite}
      />
      <PageToolBar
        linkOptions={[
          {
            label: "My Workouts",
            to: `${rootPath}/${
              groupId ?? clientFirstName.toLowerCase()
            }/dashboard/calendar/schedule-workouts/my-workouts`,
          },
          {
            label: "NASM Workouts",
            to: `${rootPath}/${
              groupId ?? clientFirstName.toLowerCase()
            }/dashboard/calendar/schedule-workouts/nasm-workouts`,
          },
        ]}
        color={colors.graphite}
      />
      <FloatingCircleButton disabled />
      <ClientBreadcrumbs
        paddingTop={21}
        paddingLeft="7%"
        ariaLabel={`${groupId ? "group" : "client"}-schedule-breadcrumbs`}
        pathMap={pathMap}
        pathNamesExclusionList={[
          groupId ? "groups" : "clients",
          groupId ?? clientFirstName.toLowerCase(),
        ]}
      />
      {/* Default Route to My Workouts */}
      <Switch>
        <Route
          exact
          to={`${rootPath}/${
            groupId ?? clientFirstName.toLowerCase()
          }/dashboard/calendar/schedule-workouts`}
        >
          <Redirect
            to={`${rootPath}/${
              groupId ?? clientFirstName.toLowerCase()
            }/dashboard/calendar/schedule-workouts/my-workouts`}
          />
        </Route>
      </Switch>
      <PanelContainer>
        <WorkoutListPanel
          visible={!!views[view].workoutList}
          getWorkoutUrlParams={getWorkoutUrlParams}
          pathName={location.pathname}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          showDropdownOnWorkoutRecords={false}
          selectedWorkoutIndex={selectedWorkoutIndex}
          ftuBtnHidden
          onChangeWorkoutPending={onChangeWorkout}
        />
        <WorkoutSectionsPanel
          visible={!!views[view].workoutDetails}
          viewExerciseLink={viewExerciseLink}
          backLink={workoutListLink}
          workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          sectionIds={defaultSections.map((section) => section.id)}
          isDragAndDropEnabled={false}
          onOpenScheduleDialog={handleOpen}
          isScheduleDialogOpen={isScheduleDialogOpen}
          resetWorkoutIndex={resetWorkoutIndex}
          viewSuperSetsLink={viewSuperSetsLink}
          setSuperSetData={setSuperSetData}
          viewCircuitLink={viewCircuitLink}
          setCircuitsData={setCircuitsData}
        />
        <AddSuperSetsPanel
          visible={!!views[view].superSetDetails}
          resetSelection={resetWorkoutIndex}
          backLink={workoutDetailsLink}
          superSetData={superSetData}
          setSuperSetData={setSuperSetData}
          viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
        />
        <AddCircuitsPanel
          visible={!!views[view].circuitDetails}
          resetSelection={resetWorkoutIndex}
          backLink={workoutDetailsLink}
          circuitData={circuitData}
          setCircuitsData={setCircuitsData}
          viewCircuitExDetailsLink={viewCircuitExDetailsLink}
        />
         <ExerciseDetailsPanel
          visible={!!views[view].viewExercise}
          isAutoSaveEnabled={false}
          isScheduling
          backLink={
            views[view].superSetDetails ? viewSuperSetsLink 
            : views[view].circuitDetails ? viewCircuitLink 
            : workoutDetailsLink 
            }
          viewExerciseDetailsLink={
            views[view].superSetDetails ? viewSuperSetExDetailsLink 
            : views[view].circuitDetails ? viewCircuitExDetailsLink 
            : viewProgressionRegressionExDetailsLink}
          viewSwapPageLink={viewSwapPageLink}
        />
        <SwapExercisePanel
          visible={!!views[view].swapPage}
          backLink={viewExerciseLink}
        />
        <ScheduleWorkoutVideoPanelWrapper
          visible={!!views[view].videoWorkout}
          isScheduleDialogOpen={isScheduleDialogOpen}
          showSaveButton={false}
          showDeleteButton={false}
          showScheduleButton
          headerText={"Workout Details. Schedule first, edit later."}
          setScheduleDialogOpen={setScheduleDialogOpen}
          resetWorkoutIndex={resetWorkoutIndex}
        />
      </PanelContainer>
      <ScheduleDialog
        backdropFilterVisible
        open={isScheduleDialogOpen}
        workouts={Object.values(selectedProgram?.entities?.workouts ?? {})}
        onClose={handleClose}
        onScheduleOnce={onScheduleOnce}
        onScheduleRepeat={onScheduleRepeat}
        onPrepareWorkouts={onPrepareWorkouts}
        disableSaveButton={false}
        selectedDate={selectedDate}
      />
    </Box>
  );
}