import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { MacaroniButton, OvalButton } from '../Buttons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 10px 10px 10px',
  },
});

export default function ButtonsContainer (props) {
  const classes = useStyles();
  const location = useLocation();

  const {
    isScheduling,
    backLink,
    resetSelection,
    onAddExercise,
    hideAddBtn = false,
  } = props;

  const isOwnedByTrainer = useMemo(() => {
    return (
      location.pathname.includes('my-workouts') ||
      location.pathname.includes('my-programs')
    );
  }, [location.pathname]);
  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  const AddButton = () => {
    if (!currentUser?.cc_manager && isOwnedByTrainer && !isScheduling &&  !hideAddBtn) {
      return (
        <Link to={{ search: backLink() }}>
          <MacaroniButton
            margin='0px 14px 0px 0px' onClick={() => {
              resetSelection();
              onAddExercise();
            }}
          >
            <Typography>Add</Typography>
          </MacaroniButton>
        </Link>
      );
    }

    return null;
  };

  const CloseButton = () => {
    return (
      <Link to={{ search: backLink() }}>
        <OvalButton
          variant='outlined'
          onClick={() => { if (resetSelection) resetSelection(); }}
        >
          Close
        </OvalButton>
      </Link>
    );
  };

  return (
    <Box className={classes.subRoot}>
      <AddButton />
      <CloseButton />
    </Box>
  );
}
