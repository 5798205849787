import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import CardContentLoading from '../CardContentLoading';
import CardDefaultContent from '../CardDefaultContent';
import OhsaContent from './OhsaContent';

import {ReactComponent as OverActiveIcon} from '../../../resources/img-overactive.svg';
import {ReactComponent as UnderActiveIcon} from '../../../resources/img-underactive.svg';


const useStyles = makeStyles({
  ohsaContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    marginTop: 14,
  },
});

function OhsaController(props) {
  const {
    loading,
    overActiveMuscles = [],
    underActiveMuscles = [],
  } = props;

  const classes = useStyles();

  if (loading) {
    return <CardContentLoading width='auto' height={100}/>;
  }

  if(!loading && overActiveMuscles.length === 0 && underActiveMuscles.length === 0) {
    return (
      <CardDefaultContent
        label={'No OHSA recorded. Please go to the NASM EDGE mobile app to record.'}
        height={100}
      />
    );
  }

  return (
    <Box className={classes.ohsaContainer}>
      <OhsaContent icon={OverActiveIcon} content={overActiveMuscles} label='Overactive muscles' />
      <OhsaContent icon={UnderActiveIcon} content={underActiveMuscles} label='Underactive muscles'/>
    </Box>
  );
}

OhsaController.propTypes = {
  loading: PropTypes.bool,
  overActiveMuscles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_overactive: PropTypes.number,
  })),
  underActiveMuscles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_overactive: PropTypes.number,
  })),
};

export default OhsaController;