import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@material-ui/core';

import { MacaroniButton } from '../Buttons';
import { DialogCircleButton } from '../Buttons';
import { colors } from '../../styles';
import { ReactComponent as CloseIcon } from '../../resources/icons-global-close-med-gray.svg';

const FTUContainer = styled(Box)({
  width: '100%',
  height: 855,
});

const HeaderClose = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '16px 32px 0 32px',
  height: 50,
  width: '100%',
  backgroundColor: props => props.color ? props.color: colors.black,
});

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: props => props.color ? props.color: colors.black,
  height: 55,
  width: '100%',
  padding: '0 32px',
});

const HeaderText = styled(Typography)({
  color: colors.white,
  fontFamily: 'DMSans-Bold, sans-serif',
  fontSize: 24,
  lineHeight: 1.63,
  fontWeight: 'bold',
  marginRight: 10,
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 100,
});

const FTUHeading = styled(Typography)({
  fontFamily: 'DMSans-Bold, sans-serif',
  fontSize: 22,
  fontWeight: 'bold',
  lineHeight: 1.36,
  textAlign: 'center',
  marginTop: 32,
  width: '55%',
});

const FTUSubheading = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: 14,
  color: colors.dark_grey,
  lineHeight: 1.43,
  margin: '24px 0 48px 0',
  width: '65%',
  textAlign: 'center',
});

function CalculatorFTUE (props) {
  const { 
    headerText,
    headerImg,
    headerColor,
    heroImg,
    heroText,
    subHeadingText,
    startButtonText,
    onStart,
    onClose,
  } = props;

  return (
    <FTUContainer>
      <HeaderClose color={headerColor}>
        <DialogCircleButton margin='none' onClick={onClose}><CloseIcon /></DialogCircleButton>
      </HeaderClose>
      <Header color={headerColor}>
        <HeaderText>{headerText}</HeaderText>
        { headerImg ? <img src={headerImg} alt='header-img' /> : null }
      </Header>
      <ContentContainer>
        <img src={heroImg} alt='hero-img' />
        <FTUHeading>{heroText}</FTUHeading>
        <FTUSubheading>{subHeadingText}</FTUSubheading>
        <MacaroniButton
          onClick={onStart}
          width={185}
        >
          {startButtonText}
        </MacaroniButton>
      </ContentContainer>
    </FTUContainer>
  );
}

CalculatorFTUE.propTypes = {
  headerText: PropTypes.string,
  headerImg: PropTypes.string,
  heroImg: PropTypes.string,
  heroText: PropTypes.string,
  subHeadingText: PropTypes.string,
  startButtonText: PropTypes.string,
  onStart: PropTypes.func,
  onClose: PropTypes.func,
};

export default CalculatorFTUE;
