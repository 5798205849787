import React from 'react';
import { NavLink } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

// Local
import { formatNavTitle } from '../../util/utilFunctions';
import { colors } from '../../styles';

const useStyles = makeStyles({
  container: {
    marginLeft: 25,
    marginRight: 25,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: 500,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 136,
    height: 40,
    opacity: 0.75,
    fontSize: 19,
    fontWeight: 500,
    textTransform: 'capitalize',
    textDecoration: 'none',
    color: colors.white,
    borderBottom: '2px solid transparent',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      opacity: 1,
      color: colors.white,
      borderBottomColor: 'rgba(255, 255, 255, 0.85)',
      transition: 'all 10ms ease-in-out',
    },
    '&:active': {
      opacity: 0.75,
      transition: 'opacity 10ms ease-in-out',
    },
  },
  linkActive: {
    opacity: 1,
  },
});

export default function NavMenuSecondary ({ routes, parent }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {routes && routes.map(route => (
        <NavLink
          key={`${route}NavLink`}
          className={classes.link}
          activeClassName={classes.linkActive}
          to={`/${parent}/${route}`}
        >
          {formatNavTitle(route)}
        </NavLink>
      ))}
    </div>
  );
}
