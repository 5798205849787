import React, { useState } from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DeleteDuplicateButton from './DeleteDuplicateButton';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { colors } from '../../styles';

import ConfirmDialog from '../Dialogs/ConfirmDialog';

import { fetchAndCopyWorkout } from '../../reducers/selectedWorkoutReducer';

const useStyles = makeStyles({
  recordContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '89px',
    borderBottom: '1px solid #e6e6e6',
    backgroundColor: colors.white,
    padding: '10px 25px',
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
    '&:active': {
      backgroundColor: colors.white,
      filter: 'brightness(88%)',
    },
  },
  recordContainerSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '89px',
    border: 'solid 1px #e6e6e6',
    backgroundColor: colors.baby_blue,
    padding: '10px 25px',
  },
  leftSection: {
    width: '90%',
  },
  workoutName: {
    fontFamily: 'DMSans, sans-serif',
    fontWeight: 'bold',
    color: colors.black,
    fontSize: '17px',
    lineHeight: '39px',
  },
  exerciseInfo: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    color: colors.steel,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  duration: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    color: colors.black,
    marginRight: '28px',
    width: '50px',
    textAlign: 'right',
  },
});

export function ViewWorkouts (props) {
  const {
    isSelected,
    index,
    getWorkoutUrlParams,
    lastElementRef,
    workout = {},
    copyWorkoutLink,
    updateButtonVisibilityFlags,
    addingWorkouts,
    showDropdownOnWorkoutRecords,
    onDeleteWorkout,
    onChangeWorkoutPending,
  } = props;

  const {
    id,
    name,
    total_dur_seconds: totalDurSeconds = 0,
    exercise_count: exerciseCount,
  } = workout;

  const totalMins = Math.ceil(totalDurSeconds / 60);
  const exerciseCountStr = !!exerciseCount && (exerciseCount > 1 || exerciseCount === 0)
    ? `${exerciseCount} Exercises` : `${exerciseCount} Exercise`;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [showDeleteButton] = useState(!!location?.pathname?.includes('my-workouts'));
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const classes = useStyles();

  function onDuplicateWorkout () {
    dispatch(fetchAndCopyWorkout(id));
    updateButtonVisibilityFlags({
      isCopyButtonVisible: false,
      isSaveButtonVisible: true,
      isAddExercisesButtonVisible: true,
      isAddButtonVisible: false,
    });
    history.replace(copyWorkoutLink());
  }

  const getWorkoutLink = (id) => {
    if (addingWorkouts) {
      return location.pathname;
    } else {
      return getWorkoutUrlParams(id);
    }
  };

  const onClickWorkout = () => {
    if (!addingWorkouts) {
      onChangeWorkoutPending(workout, index);
    }
  };

  return (
    <>
      <ListItem
        ref={lastElementRef}
        className={
          isSelected ? classes.recordContainerSelected : classes.recordContainer
        }
      >
        <Link
          to={() => getWorkoutLink(id)}
          onClick={onClickWorkout}
          className={classes.leftSection}
        >
          <Typography className={classes.workoutName}>{name || 'Placeholder Workout Name'}</Typography>
          <Typography className={classes.exerciseInfo}>{exerciseCountStr}</Typography>
        </Link>
        <Box className={classes.rightSection}>
          <Typography className={classes.duration}>{totalMins} min</Typography>
          {
            !addingWorkouts && showDropdownOnWorkoutRecords &&
              <DeleteDuplicateButton
                onDuplicatePressed={onDuplicateWorkout}
                onDeletePressed={() => setShowConfirmDialog(true)}
                showDeleteButton={showDeleteButton}
              />
          }
        </Box>
      </ListItem>
      <ConfirmDialog
        title='Delete Workout'
        description={`Deleting ${workout.name ? `the workout "${workout.name}"` : 'this workout'} will remove it ` +
        'from your library and all programs. Are you sure? '}
        actionButtonTitle='Delete'
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        handleConfirmAction={() => onDeleteWorkout({ name: workout.name, id: workout.id })}
      />
    </>
  );
}
