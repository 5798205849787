import {useEffect, useState} from 'react';
import nasmApi from '../../api/endpoints';


export default function useFetchPerformanceAssessments(clientUserId) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    nasmApi.dashboard.getPerformanceAssessments(clientUserId)
      .then(response => {
        setResults(response.result);
      })
      .catch(e => setError(true))
      .finally(() => setLoading(false));
  }, [clientUserId]);

  return {
    loading,
    error,
    results,
  };
}