import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import { Link, useHistory } from 'react-router-dom';

import { TEMPO_MAPPINGS } from '../../constants';
import { colors } from '../../styles';
import { getDurationForSection } from '../../util/programUtils';
import ROUTINE_TYPES from '../../util/RoutineTypes';
import dragHandleImg from '../../resources/img-sort-gray-active.svg';
import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import ProgRegIconSet from "../ProgressionRegressionIconSet";

const useStyles = makeStyles({
  root: props => ({
    padding: '15px 20px 15px 20px',
    borderCollapse: 'collapse',
    backgroundColor: props.isSelected
      ? colors.baby_blue : props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET
          ? colors.mildGreenWithOpacity : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT
          ? colors.mildYellowWithOpacity : colors.white,
    width: props.isDragging ? '35%' : '100%',
    borderTop: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderLeft: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderRight: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderBottom: props.isDragging ? `2px solid ${colors.yellow_button_style}` : '1px solid #b6bdc3',
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
    '&:active': {
      filter: 'brightness(88%)',
      backgroundColor: props.exercise?.routine_type === ROUTINE_TYPES.SUPER_SET
        ? colors.mildGreenWithOpacity : props.exercise?.routine_type === ROUTINE_TYPES.CIRCUIT
        ? colors.mildYellowWithOpacity : colors.white,
    },
  }),
  outerContainer: {
    width: '100%',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 20,
  },
  outerContent: {
    paddingLeft: '20px',
  },
  exerciseName: {
    fontSize: '17px',
    lineHeight: 2.29,
    fontWeight: 500,
    color: 'black',
  },
  innerContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  descText: {
    fontSize: '12px',
    color: colors.steel,
    paddingRight: '10px',
  },
  exerciseThumbnail: {
    borderRadius: '6px',
    objectFit: 'cover',
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
  },
  durationBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  duration: {
    fontSize: '11px',
    color: colors.black,
  },
  round: {
    fontSize: '11px',
    color: colors.black,
  },
});

const ExerciseContent = React.forwardRef((props, ref) => {
  const { exercise, draggableProps = {}, dragHandleProps = {} } = props;
  const { reps, sets, rest, tempo, image_url: imageUrl, name } = exercise;

  const classes = useStyles(props);
  let exerciseRoutineType = exercise.routine_type || exercise.compound_routine_type;
  if (!exerciseRoutineType && exercise.compound_routine_exercises?.length) {
    exerciseRoutineType = exercise.compound_routine_exercises[0]?.compound_routine_type;
  }
  const isCircuit = exerciseRoutineType === ROUTINE_TYPES.CIRCUIT;
  const isSuperSetOrCircuit = 'compound_routine_exercises' in exercise;

  return (
    <Box
      className={classes.root}
      ref={ref}
      {...draggableProps}
    >
      <Box className={classes.outerContainer}>
        <img alt='drag handle' src={dragHandleImg} {...dragHandleProps} />
        <Box className={classes.innerContainer}>
          {!isSuperSetOrCircuit ?
          <div className={classes.imageContainer}>
            <img
              className={classes.exerciseThumbnail}
              alt='exercise thumbnail'
              src={imageUrl || thumbnailPlaceholder}
              width={75} height={48}
              onError={e => { e.target.src = thumbnailPlaceholder; }}
            />
            <ProgRegIconSet exercise={exercise} />
          </div>
          : null}
          <Box className={classes.outerContent}>
            <Typography className={classes.exerciseName}>
              {isSuperSetOrCircuit ? exercise?.title : name || 'Unnamed Exercise'}
            </Typography>
            {!isSuperSetOrCircuit ? <Box className={classes.innerContent}>
              <Typography className={classes.descText}>
                {reps || 'N/A'} reps
              </Typography>
              <Typography className={classes.descText}>
                {sets || 'N/A'} sets
              </Typography>
              <Typography className={classes.descText}>
                Tempo {TEMPO_MAPPINGS[tempo] || 'N/A'}
              </Typography>
              <Typography className={classes.descText}>
                {rest || 0}s rest
              </Typography>
            </Box> : null}
            {isCircuit ? (
                <Typography className={classes.descText}>
                  {`${exercise.reps} rounds`}
                </Typography>
            ) : null}
          </Box>
          {isSuperSetOrCircuit ? (
            <Box className={classes.durationBox}>
              <Typography className={classes.duration}>
                {getDurationForSection({
                  section: exercise,
                  isSuperSetOrCircuit: isSuperSetOrCircuit,
                })}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
});

export function ExerciseRow(props) {
  const {
    viewExerciseLink = () => {},
    exercise,
    setSelectedExerciseIndex,
    isSelected,
    viewSuperSetsLink = () => {},
    setSuperSetData,
    viewCircuitLink = () => {},
    setCircuitsData,
  } = props;

  const history = useHistory();

  const isSuperSet = exercise.routine_type === ROUTINE_TYPES.SUPER_SET;
  const isCircuit = exercise.routine_type === ROUTINE_TYPES.CIRCUIT;

  const onClickCompoundRoutine = () => {
    if (isSuperSet) {
      setSuperSetData(exercise);
      history.push({
        pathname: history.location?.pathname || `/libraries/workouts/my-workouts`,
        search: viewSuperSetsLink(),
      });
    } else {
      setCircuitsData(exercise);
      history.push({
        pathname: history.location?.pathname || `/libraries/workouts/my-workouts`,
        search: viewCircuitLink(),
      });
    }
  };

  return (
    <Link
      to={{ search: isSuperSet
        ? viewSuperSetsLink() : isCircuit
        ? viewCircuitLink() : viewExerciseLink(exercise.id, exercise.key) }}
      onClick={() => {
        setSelectedExerciseIndex(exercise.key);
        if (isSuperSet || isCircuit) {
          onClickCompoundRoutine();
          return;
        }
      }}
    >
      <ExerciseContent
        exercise={exercise}
        isSelected={isSelected}
      />
    </Link>
  );
}

export function DraggableExerciseRow(props) {
  const {
    draggableId,
    sectionId,
    index,
    editExerciseLink,
    viewExerciseLink,
    isDragDisabled,
    isEditable,
    workout,
    exercise,
    setSelectedExerciseIndex,
    isSelected,
    viewSuperSetsLink,
    setSuperSetData,
    viewCircuitLink,
    setCircuitsData,
  } = props;

  const history = useHistory();

  const isSuperSet = exercise.routine_type === ROUTINE_TYPES.SUPER_SET;
  const isCircuit = exercise.routine_type === ROUTINE_TYPES.CIRCUIT;

  const onClickCompoundRoutine = () => {
    if (isSuperSet) {
      setSuperSetData(exercise);
      history.push({
        pathname: history.location?.pathname || `/libraries/workouts/my-workouts`,
        search: viewSuperSetsLink(exercise.id),
      });
    } else {
      setCircuitsData(exercise);
      history.push({
        pathname: history.location?.pathname || `/libraries/workouts/my-workouts`,
        search: viewCircuitLink(exercise.id),
      });
    }
  };

  return (
    <Draggable
      draggableId={draggableId}
      isDragDisabled={isDragDisabled}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <>
            {isEditable &&
              <Link to={{
                search: isSuperSet ? viewSuperSetsLink() : isCircuit ? viewCircuitLink() : editExerciseLink(),
                state: {
                  exerciseKey: draggableId,
                  sectionId: sectionId,
                  exercise: exercise,
                  workout: workout,
                },
              }}
                onClick={() => {
                  setSelectedExerciseIndex(draggableId);
                  if (isSuperSet || isCircuit) {
                    onClickCompoundRoutine();
                    return;
                  }
                }}
              >
                <ExerciseContent
                  ref={provided.innerRef}
                  draggableProps={provided.draggableProps}
                  dragHandleProps={provided.dragHandleProps}
                  isDragging={snapshot.isDragging}
                  exercise={exercise}
                  isSelected={isSelected}
                />
              </Link>}
            {!isEditable &&
              <Link
                to={{ search: isSuperSet
                  ? viewSuperSetsLink() : isCircuit
                  ? viewCircuitLink() : viewExerciseLink(exercise.id, true) }}
                onClick={() => {
                  setSelectedExerciseIndex(draggableId);
                  if (isSuperSet || isCircuit) {
                    onClickCompoundRoutine();
                    return;
                  }
                }}
              >
                <ExerciseContent
                  ref={provided.innerRef}
                  draggableProps={provided.draggableProps}
                  dragHandleProps={provided.dragHandleProps}
                  isDragging={snapshot.isDragging}
                  exercise={exercise}
                  isSelected={isSelected}
                />
              </Link>}
          </>
        );
      }}
    </Draggable>
  );
}
