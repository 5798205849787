import React, {useEffect, useState} from 'react';
import {LOGIN_STATES} from '../../reducers/currentUserReducer';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import LinkAccountsFormView from './Views/LinkAccountsFormView';
import MigrateAccountFormView from './Views/MigrateAccountFormView';
import LoginFormView from './Views/LoginFormView';

import { LoginFormCard } from './FormComponents';
import nasmApi from '../../api/endpoints';
import { LOGIN_TYPES, LOGIN_VIEWS } from './../../util/loginConstants';

function LoginFormController () {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // used to change views
  const [loginType, setLoginType] = useState(LOGIN_TYPES.FRESH_LOGIN);
  const [currentLoginView, setCurrentLoginView] = useState(LOGIN_VIEWS.LOGIN_FORM);

  const loginState = useSelector(state => state.currentUser.loginState);
  const loggedIn = loginState === LOGIN_STATES.LOGIN;
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      const currentUserManager = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
      history.replace(!currentUserManager?.cc_manager ? `/clients/my-clients` : `/dashboard/cc-dashboard`);
    }
    // reset login view if an error occurs to allow trainer to re-attempt login
    if(loginType === LOGIN_TYPES.UNEXPECTED_ERROR) {
      onChangeToLoginFormView();
    }
  }, [loginType, loggedIn, history]);

  async function determineLoginType(email) {
    // Login Type will control which view to display
    let newLoginType = '';

    try {
      const response = await nasmApi.register.checkEmail(email);

      const result = response.result;
      const { edgeResult, uaResult, edgeAccountInUA, uaAccountInEDGE, edgeUserRole } = result;

      if(edgeUserRole === 'CLIENT') {
        setLoginType(LOGIN_TYPES.CLIENT);
        return LOGIN_TYPES.CLIENT;
      }

      // Documented Cases: (Only covers Trainer sign-in flow)
      // https://medlenterprises.atlassian.net/wiki/spaces/NASM/pages/294158337/UA+Login+Migration+-+v2.7.0

      // Case 1
      const isNewAccount = !edgeResult && !uaResult && !edgeAccountInUA && !uaAccountInEDGE;
      // Case 2
      const isLegacyEdgeAccount = edgeResult && !uaResult  && !edgeAccountInUA && !uaAccountInEDGE;
      // Case 3
      const isUAAccountOnly = (!edgeResult && uaResult && !edgeAccountInUA && !uaAccountInEDGE);
      // Case 4
      const areAccountsUnlinked = edgeResult && uaResult && !edgeAccountInUA && !uaAccountInEDGE;

      // Case 6
      // UA email differs from Edge Email but both share the same ua_guid ~ needs to prevent user from seeing password
      // field if a mismatch occurs and should prompt user to enter in their UA email
      // ~ using edge legacy email that differs from UA email
      const areAccountLinksMismatched = (edgeResult && !uaAccountInEDGE && edgeAccountInUA);

      // Case 5
      const doesAccountExistInUA = !edgeResult && uaResult && !edgeAccountInUA && uaAccountInEDGE;
      const areAccountsLinked = !!edgeResult && !!uaResult && !!edgeAccountInUA && !!uaAccountInEDGE;

      if(isNewAccount) {
        setLoginType(LOGIN_TYPES.NEW_ACCOUNT);
        newLoginType = LOGIN_TYPES.NEW_ACCOUNT;
      } else if(isLegacyEdgeAccount) {
        setLoginType(LOGIN_TYPES.LEGACY_EDGE_ACCOUNT);
        newLoginType = LOGIN_TYPES.LEGACY_EDGE_ACCOUNT;
      } else if(isUAAccountOnly)  {
        setLoginType(LOGIN_TYPES.UA_ACCOUNT_ONLY);
        newLoginType = LOGIN_TYPES.UA_ACCOUNT_ONLY;
      } else if(areAccountsUnlinked) {
        setLoginType(LOGIN_TYPES.UNLINKED_ACCOUNTS);
        newLoginType = LOGIN_TYPES.UNLINKED_ACCOUNTS;
      } else if(areAccountLinksMismatched) {
        setLoginType(LOGIN_TYPES.MISMATCHED_ACCOUNT_LINKS);
        newLoginType = LOGIN_TYPES.MISMATCHED_ACCOUNT_LINKS;
        setEmailError('Please use your NASM account to log in');
      } else if(areAccountsLinked || doesAccountExistInUA) {
        setLoginType(LOGIN_TYPES.LINKED_ACCOUNTS);
        newLoginType = LOGIN_TYPES.LINKED_ACCOUNTS;
      } else {
        window.alert('Unexpected error occurred, please try again later.');
        setLoginType(LOGIN_TYPES.UNEXPECTED_ERROR);
        newLoginType = LOGIN_TYPES.UNEXPECTED_ERROR;
      }

    } catch (e) {
      // failed response.. notify of server side error
      window.alert('Unexpected server error occurred, please try again later.');
      setLoginType(LOGIN_TYPES.UNEXPECTED_ERROR);
    }

    return newLoginType;
  }

  function switchEmail () {
    setEmail('');
  }

  function onChangeEmail (e) {
    setEmail(e.target.value);
  }

  function onChangeToMigrateAccountView () {
    setCurrentLoginView(LOGIN_VIEWS.MIGRATE_ACCOUNT);
  }

  function onChangeToLinkAccountsView () {
    setCurrentLoginView(LOGIN_VIEWS.LINK_ACCOUNTS);
  }

  function onChangeToLoginFormView () {
    setLoginType(LOGIN_TYPES.FRESH_LOGIN);
    setCurrentLoginView(LOGIN_VIEWS.LOGIN_FORM);
  }

  return (
    <LoginFormCard>
        {/* Migrating Edge Account to UA */}
        <MigrateAccountFormView
          email={email}
          loginType={loginType}
          loginView={currentLoginView}
        />

        {/* Linking 2 Accounts of same email */}
        <LinkAccountsFormView
          email={email}
          loginType={loginType}
          loginView={currentLoginView}
        />


        <LoginFormView
          email={email}
          emailError={emailError}
          setEmailError={setEmailError}
          onChangeEmail={onChangeEmail}
          onResetEmailError={() => setEmailError('')}
          switchEmail={switchEmail}
          checkEmail={determineLoginType}
          loginView={currentLoginView}
          loginType={loginType}
          onChangeToMigrateAccountView={onChangeToMigrateAccountView}
          onChangeToLinkAccountsView={onChangeToLinkAccountsView}
        />
      </LoginFormCard>
  );
}

export default LoginFormController;