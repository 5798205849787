import {useEffect, useState} from 'react';
import nasmApi from '../../api/endpoints';

/**
 *
 * @param {string} clientUserId
 * @param {string} date - contains date with timestamp and timezone
 * @param {boolean} canFetchData - if true will fetch data again from API
 * @returns {{isLoading: boolean, selectedScheduledWorkouts: *[], data: {}, error: boolean}}
 */
export default function useFetchCalendarDay (clientUserId, date, canFetchData) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [selectedScheduledWorkouts, setSelectedScheduledWorkouts] = useState([]);

  // Note: contains nutrition, ohsa, milestones, measurements, and perf_assessments data
  useEffect(() => {
    setLoading(true);
    nasmApi.dashboard
      .calendarDay(clientUserId, date)
      .then(response => {
        setLoading(false);
        setError(false);
        setData(response.result);

        if(response.result && response.result.scheduled_workouts instanceof Array) {
          const newScheduledWorkouts = response.result.scheduled_workouts.map(w => {
            return {
              minutesDuration: Math.floor(w.total_dur_seconds / 60),
              scheduledWorkoutName: w.workout_name,
              programName: w.program_name,
              programCategoryName: w.program_category_name,
              scheduleId: w.user_schedule_id,
              workoutId: w.workout_id,
            };
          });

          setSelectedScheduledWorkouts(newScheduledWorkouts);
        }

      }).catch(err => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [clientUserId, date, canFetchData]);

  return {
    isLoading, error, data, selectedScheduledWorkouts,
  };
}