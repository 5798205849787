import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  styled,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { ActionButton, OvalButton } from '../Buttons';
import { colors } from '../../styles';

const ConfirmDialogContentText = styled(DialogContentText)({
  fontSize: '19px',
  fontFamily: 'Roboto',
  lineHeight: 1.58,
  color: colors.confirmDialogBlack,
});
const ConfirmDialogTitle = styled(DialogTitle)({
  fontWeight: 'bold',
  fontFamily: 'DMSans',
  fontSize: '24px',
  color: colors.confirmDialogBlack,
});

const useStyle = makeStyles({
  scrollPaper: {
    alignItems: 'flex-start',
  },
});

function ConfirmDialog (props) {
  const classes = useStyle();
  return (
    <>
      <Dialog
        classes={{ scrollPaper: classes.scrollPaper }}
        open={props.open}
        onClose={props.onClose}
        BackdropProps={{ style: { backgroundColor: colors.mediumBlue, opacity: 0.35 } }}
        disableBackdropClick
      >
        <ConfirmDialogTitle disableTypography id='confirm-dialog-title'>{props.title}</ConfirmDialogTitle>
        <DialogContent>
          <ConfirmDialogContentText id='confirm-dialog-description'>
            {props.description}
          </ConfirmDialogContentText>
        </DialogContent>
        <DialogActions>
          {!props.hideCancelButton && <OvalButton width={72} height={38} margin={10} onClick={props.onClose}>
            {props.cancelButtonTitle || 'Cancel'}
          </OvalButton>}
          <ActionButton
            width={props.actionButtonWidth ? props.actionButtonWidth : 72}
            height={38}
            margin={10}
            onClick={props.handleConfirmAction}
            loading={!!props.loading}
            label={props.actionButtonTitle || 'OK'}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  actionButtonTitle: PropTypes.string,
  handleConfirmAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  actionButtonWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
export default ConfirmDialog;
