import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import MyGroupsList from "./MyGroupsList";
import { GroupsProvider } from "../../contexts/GroupsContext";
import {
  addPossessiveApostrophe,
  getSelectedDate,
} from "../../util/utilFunctions";
import GroupSchedule from "./GroupSchedule";
import AddExercisesScreen from "../schedules/AddExercisesScreen";
import ScheduleProgram from "../clients/ScheduleProgram";
import { WorkoutsProvider } from "../../contexts/WorkoutsContext";
import ScheduleWorkoutScreen from "../schedules/ScheduleWorkoutScreen";
import { getWorkoutSections } from "../../reducers/workoutsReducer";

const rootPath = "/groups/my-groups";

export default function GroupScheduleNavigator() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [pathMap, setPathMap] = useState({});

  const selectedGroup = useSelector(
    (state) => state.selectedGroup?.group ?? {},
  );
  const defaultSections = useSelector(
    (state) => state.workouts.defaultSections,
  );

  const [selectedDate, setSelectedDate] = useState(
    getSelectedDate(location?.state?.selectedDate ?? null),
  );
  // The month year date subheader e.g. < March 2021 >
  const [monthViewDate, setMonthViewDate] = useState(
    getSelectedDate(location?.state?.selectedDate ?? null),
  );

  useEffect(() => {
    if (defaultSections.length === 0) {
      dispatch(getWorkoutSections());
    }
  }, [dispatch, defaultSections]);

  const sectionIds = defaultSections.map((section) => section.id);

  useEffect(() => {
    if (selectedGroup.id) {
      setPathMap(generateTrailMap(selectedGroup.title, selectedGroup.id));
    }
  }, [selectedGroup.title, selectedGroup.id]);

  // Updates the selected date to the starting scheduled date made by the trainer
  useEffect(() => {
    if (!!location?.state?.selectedDate) {
      const startScheduleDate = location.state.selectedDate;
      setSelectedDate(getSelectedDate(startScheduleDate));
      setMonthViewDate(getSelectedDate(startScheduleDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.selectedDate]);

  return (
    <Switch>
      <Route exact path={rootPath}>
        <GroupsProvider>
          <MyGroupsList selectedGroup={selectedGroup} />
        </GroupsProvider>
      </Route>
      <Route exact path={`${rootPath}/:groupId/dashboard`}>
        <GroupSchedule
          selectedGroup={selectedGroup}
          pathMap={pathMap}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          monthViewDate={monthViewDate}
          setMonthViewDate={setMonthViewDate}
        />
      </Route>
      <Route
        path={`${rootPath}/:groupId/dashboard/calendar/schedule-exercises`}
      >
        <AddExercisesScreen
          selectedGroup={selectedGroup}
          pathMap={pathMap}
          selectedDate={selectedDate}
        />
      </Route>
      <Route
        path={`${rootPath}/:groupId/dashboard/calendar/schedule-workouts`}
      >
        <WorkoutsProvider>
          <ScheduleWorkoutScreen
            selectedGroup={selectedGroup}
            pathMap={pathMap}
            selectedDate={selectedDate}
          />
        </WorkoutsProvider>
      </Route>
      <Route
        path={`${rootPath}/:groupId/dashboard/calendar/schedule-programs`}
      >
        <ScheduleProgram
          pathMap={pathMap}
          sectionIds={sectionIds}
          selectedDate={selectedDate}
        />
      </Route>
    </Switch>
  );
}

function generateTrailMap(groupname, groupId) {
  return {
    "/groups/my-groups": "Groups",
    [`/groups/my-groups/${groupId}/dashboard`]: `${addPossessiveApostrophe(groupname)} Schedule`,
    [`/groups/my-groups/${groupId}/dashboard/calendar/schedule-programs/my-programs`]: 'Assign Program',
    [`/groups/my-groups/${groupId}/dashboard/calendar/schedule-programs/nasm-programs`]: 'Assign Program',
    [`/groups/my-groups/${groupId}/dashboard/calendar/schedule-workouts/my-workouts`]: 'Assign Workout',
    [`/groups/my-groups/${groupId}/dashboard/calendar/schedule-workouts/nasm-workouts`]: 'Assign Workout',
    [`/groups/my-groups/${groupId}/dashboard/calendar/schedule-exercises`]: 'Assign Exercises',
    [`/groups/my-groups/${groupId}/dashboard/calendar/edit-workout`]: 'Edit Workout',
    [`/groups/my-groups/${groupId}/dashboard/calendar/edit-workout/workout-details`]: 'Workout Details',
  };
}
