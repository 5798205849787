/**
 * @author Donald Green <donald.green@medlmobile.com>
 */
import request from './request';
import { NASM_API_ENDPOINTS, NASM_OAUTH_CLIENT, NASM_OAUTH_GRANT_TYPES, API_VERSION } from './constants';
import moment from 'moment';

const areTokensValid = tokenInfo =>
  tokenInfo.access_token &&
  tokenInfo.refresh_token &&
  tokenInfo.expires_in &&
  moment(tokenInfo.expires_in).isAfter(moment());

const authRequest = async (req) => {
  const { grantType, username, password, refresh_token: refreshToken, version } = req;
  const url = `/${version}${NASM_API_ENDPOINTS.OAUTH}`;
  const method = 'post';
  const headers = {
    'Content-type': 'application/json',
  };

  // Auth payload used in Axios request which translates to a Basic Authorization request
  const auth = {
    username: NASM_OAUTH_CLIENT.ID,
    password: NASM_OAUTH_CLIENT.SECRET,
  };

  const data = {
    scope: 'read',
  };

  switch (grantType) {
    case NASM_OAUTH_GRANT_TYPES.PASSWORD:
      data.username = username;
      data.password = password;
      data.grant_type = NASM_OAUTH_GRANT_TYPES.PASSWORD;
      break;
    case NASM_OAUTH_GRANT_TYPES.REFRESH_TOKEN:
      data.refresh_token = refreshToken;
      data.grant_type = NASM_OAUTH_GRANT_TYPES.REFRESH_TOKEN;
      break;
    default:
      throw new Error('Grant type provided is invalid');
  }

  const reqConfig = {
    url,
    method,
    headers,
    auth,
    data,
    withCredentials: true,
  };

  const response = await request(reqConfig);
  return response.data;
};

export default class Auth {
  /**
   *
   * @param storeToken {Function} This should be a function that provides an
   * interface to store the token information
   * @param getToken {Function} Interface function that retrieves a token from persistent
   * storage
   */
  constructor(storeToken, getToken, deleteToken) {
    this.storeToken = storeToken;
    this.getToken = getToken;
    this.deleteToken = deleteToken;
    this.tokenRequest = null;
  }

  async authorize(apiReq, next, version = API_VERSION) {
    if (!this.tokenRequest) {
      this.tokenRequest = this.manageAuthentication(version);
      this.tokenRequest.finally(() => {
        this.tokenRequest = null;
      });
    }
    apiReq.token = await this.tokenRequest;
    return next(apiReq);
  }

  async manageAuthentication() {
    const tokenInfo = await this.getToken();
    if (areTokensValid(tokenInfo)) {
      return tokenInfo;
    }

    if (!tokenInfo || !tokenInfo.access_token) {
      throw new Error('User does not have access information and must login');
    }

    if (!tokenInfo.refresh_token) {
      throw new Error('No refresh token found to authenticate with. Please log back in');
    }
    const response = await this.refreshToken(tokenInfo);

    const expiresIn = moment().add(response.expires_in, 'seconds');
    const newTokenInfo = {
      access_token: response.access_token,
      refresh_token: response.refresh_token,
      expires_in: expiresIn.valueOf(),
    };

    this.storeToken(newTokenInfo);

    return newTokenInfo;
  }

  /**
   *
   * @param userCreds {Object} A simple object that contains the credentials the user wishes to login with
   * @param userCreds.username {String} The username the user is attempting to login with
   * @param userCreds.password {String} The password to use to authorize with.
   * @return {Promise<*>}
   */
  async login(userCreds, version = API_VERSION) {
    const tokenRes = await authRequest({
      ...userCreds,
      grantType: NASM_OAUTH_GRANT_TYPES.PASSWORD,
      version,
    });

    const expires = moment().add(tokenRes.expires_in, 'seconds');
    const newToken = { ...tokenRes, expires_in: expires.valueOf() };
    this.storeToken(newToken);
    return newToken;
  }

  /**
   *
   * @param tokenRequest {Object} Request containing the refresh token to use to grant a new set of tokens with
   * @param tokenRequest.token {String} Refresh token to make the refresh token request with.
   * @return {Promise<*>}
   */
  refreshToken(tokenRequest, version = API_VERSION) {
    return authRequest({
      ...tokenRequest,
      grantType: NASM_OAUTH_GRANT_TYPES.REFRESH_TOKEN,
      version,
    });
  }
}
