import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';

import BtnArrowExpand from '../../resources/btn-arrow-expand.svg';

const useStyles = makeStyles({
  input: props => ({
    borderRadius: 3,
    boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.18)',
    border: 'solid 1px #d2d7db',
    width: props.width ?? 240,
    height: 40,
    fontSize: 14,
    fontFamily: 'Roboto, sans-serif',
    cursor: 'pointer',
    '&>input': {
      cursor: 'pointer',
    },
  }),
  calendarPreview: {
    marginTop: 25,
  },
});

const TextLabel = withStyles({
  root: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 30,
    fontFamily: 'Roboto, sans-serif',
  },
})(Typography);

function ProfileDatePicker (props) {
  const { onChange, value, label } = props;
  const classes = useStyles(props);

  return(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        autoOk
        allowKeyboardControl={false}
        variant='inline'
        format='MMMM DD, YYYY'
        inputVariant='standard'
        onChange={onChange}
        value={value}
        PopoverProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          classes: {
            paper: classes.calendarPreview,
          },
        }}
        InputProps={{
          className: classes.input,
          disableUnderline: true,
          startAdornment: <TextLabel>{label}</TextLabel>,
          endAdornment: <img src={BtnArrowExpand} alt='dropdown arrow' />,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
export default ProfileDatePicker;

ProfileDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
