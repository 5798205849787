import React from 'react';
import { Box, List, ListItem } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

export default function ExerciseListSkeleton (props) {
  return (
    <List>
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
      <ExerciseRecordSkeleton />
    </List>
  );
}

const useRecordStyles = makeStyles({
  recordSkeleton: {
    height: 80,
    borderBottom: '1px solid #e6e6e6',
    borderCollapse: 'collapse',
    borderLeft: 'none',
    borderRight: 'none',
  },
  boxLayout: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 16,
    marginBottom: 16,
  },
  imageSkeleton: {
    marginLeft: 12,
    marginRight: 23,
    borderRadius: 6,
  },
});

function ExerciseRecordSkeleton (props) {
  const classes = useRecordStyles();
  return (
    <ListItem className={classes.recordSkeleton}>
      <Box className={classes.boxLayout}>
        <Skeleton className={classes.imageSkeleton} variant='rect' width={75} height={47} />
        <Skeleton variant='text' width='50%' height={11} />
      </Box>
    </ListItem>
  );
}
