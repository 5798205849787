import request from '../nasm_sdk_v2/request';

export default function goals (apiVer) {
  function getAllGoals (apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/goals`);
  }

  function getActivityLevels(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/activity-levels`);
  }

  function getAllMilestones(userId, isComplete = false, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}/milestones`, { isComplete });
  }

  function createMilestone(userId, milestone, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/${userId}/milestones`, milestone);
  }

  function updateMilestones(userId, milestones = [], apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/users/${userId}/milestones`, { data: milestones });
  }

  function updateMilestone(userId, milestone, apiVersion = apiVer) {
    return updateMilestones(userId, [milestone], apiVersion);
  }

  function deleteMilestone(milestoneId, apiVersion = apiVer) {
    return request.delete(`${apiVersion}/api/users/milestones/${milestoneId}`);
  }

  return {
    getAllGoals,
    getActivityLevels,
    getAllMilestones,
    createMilestone,
    updateMilestones,
    updateMilestone,
    deleteMilestone,
  };
}