import React from 'react';
import PropTypes from 'prop-types';

import {Box, Container, Typography} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import Panel from './Panel';
import {CircleButton, OvalButton, SaveButton} from '../Buttons';
import { TermsAndConditionsLink, TermsAndConditionsDisclaimer } from '../Terms';

import CurrentWorkoutMediaView from '../Media/CurrentWorkoutMediaView';
import {UnderlineTextInput, DescriptionTextbox} from '../Inputs';

import {ReactComponent as Trash} from '../../resources/trash.svg';
import {colors} from '../../styles';

import validateUploadState from '../Media/validateUploadState';
import {programContexts} from '../../reducers/programContextReducer';
import FadeTransition from '../Transitions/FadeTransition';

const Header = styled(Typography)({
  fontSize: 24,
  fontWeight: 'bold',
  lineHeight: 1.63,
  marginTop: 14,
  marginBottom: 20,
  color: colors.carbon_black,
});

const SectionText = styled(Typography)({
  fontSize: 14,
  fontFamily: 'Roboto',
  color: colors.dark_grey,
});

const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  leftButtonContainer: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  rightButtonContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  workoutMediaView: {
    marginBottom: 27,
    display: 'flex',
    justifyContent: 'center',
  },
});


// Will be re-used in libraries and schedules
// libraries
// Workouts
// Program
//    --> Workout
// Scheduled Workout
// Scheduled  Program
//    ---> Video Workout
function WorkoutVideoPanel (props) {
  const {
    visible,
    workout,
    uploadState,
    canCalculateProgress,
    progressValue,
    streamingUrl,
    saveState,
    showSaveButton,
    showDeleteButton,
    showScheduleButton,
    programContext,
    isScheduleDialogOpen,
    headerText,

    onClose,
    onSaveWorkout,
    onChangeTitle,
    onChangeDescription,
    onCancelMediaUploadRequest,
    onUploadBegin,
    onClickDelete,
    onClickSchedule,
  } = props;

  const classes = useStyles();

  // default to workout template name if scheduled workout name not available
  const workoutName =
    [programContexts.SCHEDULING, programContexts.RESCHEDULING].includes(programContext) ?
      workout?.workout_name ?? workout?.name : workout?.name || '';

  const descriptionText = workout?.description || '';

  const isReadOnlyText = programContext === programContexts.SCHEDULING;
  const isVideoEditable = programContext === programContexts.LIBRARY;

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={'auto'}>
        <FadeTransition visible={visible}>
          <Container>
            <Box className={classes.buttonsContainer}>
              {showDeleteButton && <Box className={classes.leftButtonContainer}>
                <CircleButton onClick={onClickDelete}>
                  <Trash />
                </CircleButton>
              </Box>}
              <Box className={classes.rightButtonContainer}>
                {showSaveButton && <Box style={{ marginRight: 15 }}>
                  <SaveButton
                    saveState={saveState}
                    onClick={onSaveWorkout}
                  />
                </Box>}
                {showScheduleButton && <Box style={{ marginRight: 15 }}>
                  <OvalButton
                    border='none'
                    color={colors.white}
                    width={95}
                    backgroundcolor={isScheduleDialogOpen
                     ? colors.navy : colors.yellow_button_style}
                    disabled={isScheduleDialogOpen}
                    onClick={onClickSchedule}>
                    Schedule
                  </OvalButton>
                </Box>}
                <OvalButton onClick={onClose}>
                  Close
                </OvalButton>
              </Box>
            </Box>
            <Header>
              {headerText}
            </Header>
            <Box className={classes.workoutMediaView}>
              <CurrentWorkoutMediaView
                uploadState={uploadState}
                onUploadBegin={onUploadBegin}
                onCancelMediaUploadRequest={onCancelMediaUploadRequest}
                canCalculateProgress={canCalculateProgress}
                progressValue={progressValue}
                videoSrc={streamingUrl}
                isVideoEditable={isVideoEditable}
                workoutId={workout.id || 'new'}
              />
            </Box>
            <SectionText>
              Workout Name
            </SectionText>
            <WorkoutNameField
              value={workoutName || ''}
              placeholder={'Enter Workout Name'}
              readOnly={isReadOnlyText}
              onNameChanged={e => onChangeTitle(e.target.value)}
            />
            <WorkoutDescriptionField
              descriptionText={descriptionText}
              readOnly={isReadOnlyText}
              onChange={e => onChangeDescription(e.target.value)}
            />
            <Box style={{ marginBottom: 30 }}>
              <TermsAndConditionsDisclaimer>
                By uploading Outside Content, as defined in the EDGE Terms,
                I agree to the EDGE <TermsAndConditionsLink /> concerning Outside Content.
                NASM is not responsible or liable for Outside Content or any issues arising from Outside Content.
              </TermsAndConditionsDisclaimer>
            </Box>
          </Container>
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

const useWorkoutNameStyles = makeStyles({
  workoutName: {
    fontSize: 17,
    fontWeight: 500,
    marginTop: 13,
  },
});

function WorkoutNameField (props) {
  const {
    value,
    placeholder,
    readOnly,
    onNameChanged,
  } = props;

  const classes = useWorkoutNameStyles();

  if (readOnly) {
    return (
      <Typography className={classes.workoutName}>
        {value}
      </Typography>
    );
  }

  return (
    <UnderlineTextInput
      value={value}
      placeholder={placeholder}
      readOnly={readOnly}
      onNameChanged={onNameChanged}
    />
  );
}

const useWorkoutDescriptionStyles = makeStyles({
  descriptionBox: {
    margin: '30px 0 30px 0',
    minHeight: 180,
  },
  descriptionText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    margin: 12,
  },
});

function WorkoutDescriptionField (props) {
  const {
    descriptionText,
    readOnly,
    onChange,
  } = props;

  const classes = useWorkoutDescriptionStyles();

  if (readOnly) {
    return (
      <Box className={classes.descriptionBox}>
        <Typography className={classes.descriptionText}>
          {descriptionText}
        </Typography>
      </Box>
    );
  }

  return (
    <DescriptionTextbox
      descriptionText={descriptionText}
      readOnly={readOnly}
      onChange={onChange}
    />
  );
}

WorkoutVideoPanel.propTypes = {
  visible: PropTypes.bool.isRequired,
  programContext:  PropTypes.string,
  workout: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  saveState: PropTypes.string,
  uploadState: validateUploadState,
  canCalculateProgress: PropTypes.bool,
  progressValue: PropTypes.number,
  streamingUrl: PropTypes.string,
  showSaveButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showScheduleButton: PropTypes.bool,
  headerText: PropTypes.string,

  onClose: PropTypes.func,
  onSaveWorkout: PropTypes.func,
  onChangeTitle: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onCancelMediaUploadRequest: PropTypes.func,
  onUploadBegin: PropTypes.func,
};

export default WorkoutVideoPanel;
