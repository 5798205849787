// Consider using a more secure method of storing information
class LocalStore {
  hasKey(key) {
    return window.localStorage.getItem(key) != null;
  }

  save(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    const item = window.localStorage.getItem(key);

    if (item == null) {
      window.localStorage.remove(key);
      return null;
    }

    return JSON.parse(item);
  }

  delete(key) {
    window.localStorage.removeItem(key);
  }
}

export default new LocalStore();