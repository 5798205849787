import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../styles';
import { useExternalScript } from '../../hooks/ExternalScript';
import CalculatorFTUE from './CalculatorFTUE';
import FTUImg from '../../resources/ftu-mhr.svg';
import HeaderIcon from '../../resources/mhr-icon.svg';
import { ROLES } from '../../constants';
import nasmApi from '../../api/endpoints';

const useStyles = makeStyles({
  profilePaper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '560px',
      height: '760px',
      borderRadius: '17px',
      boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
  scrollPaper: {
      alignItems: 'flex-start',
  },
});

const MhrCalculatorContainer = ({ currentUser, isOpen, onClose }) => {
  const ftueShown = localStorage.getItem('mhr-shown');
  const [mhrShown, setMhrShown] = useState(ftueShown === 'true'); 

  const widgetUrl = process.env.REACT_APP_MHR_WIDGET_URL || '/dist/mhr/index.js';
  const scriptState = useExternalScript({ url: widgetUrl, canStartLoading: isOpen });
  const canMount = isOpen && scriptState === 'ready';

  const unitWeight = currentUser?.unit_weight || 'lb';
  const hasSelectedClient = currentUser?.role === ROLES.CLIENT;

  const classes = useStyles();

  useEffect(() => {
    // Pass onClose function to custom widget element:
    const mhrCalculator = document.querySelector('mhr-calculator');
    if (canMount && mhrCalculator) {
      mhrCalculator.closewidget = onClose;
    }
  }, [canMount, onClose, mhrShown]);

  const saveMHRRecord = useCallback(async (mhrData) => {
    if (!hasSelectedClient) {
      return;
    }
    // if we have client user ID for this screen in redux, save their MHR results
    try {
      await nasmApi.mhr.saveMHRResults(currentUser?.id, mhrData);
    } catch (error) {
      showAlert(error);
    } finally {
      const SaveRequestComplete = new CustomEvent("saveMhrRequestComplete");
      window.dispatchEvent(SaveRequestComplete);
    }
  }, [currentUser.id, hasSelectedClient]);

  useEffect(() => {
    window.addEventListener('onSaveMhrRequest', (event) => {
      const { mhrData } = event.detail;
      if (mhrData) {
        saveMHRRecord(mhrData);
      }
    });
    return () => {
      window.removeEventListener('onSaveMhrRequest');
    };
  }, [saveMHRRecord]);

  useEffect(() => {
    window.addEventListener('fetchCurrentMHRRecord', getCurrentMHR);
    window.addEventListener('fetchMHRPaginatedHistoryList', getMHRHistory);
    return () => {
      window.removeEventListener('fetchCurrentMHRRecord', getCurrentMHR);
      window.removeEventListener('fetchMHRPaginatedHistoryList', getMHRHistory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentMHR = async () => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const response = await nasmApi.mhr.getCurrentMHR(currentUser?.id);
      const receiveCurrentMHREvent = new CustomEvent("onReceiveCurrentMHRRecord", {
        cancelable: true,
        detail: {
          currentMHRRecord: response?.result,
        },
      });
      window.dispatchEvent(receiveCurrentMHREvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const getMHRHistory = async (event) => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const { page } = event?.detail;
      const response = await nasmApi.mhr.getMHRHistory(currentUser?.id, { page });
      const receiveMHRHistoryEvent = new CustomEvent("onReceiveMHRPaginatedHistoryList", {
        cancelable: true,
        detail: {
          MHRHistoryRecord: response?.result,
        },
      });
      window.dispatchEvent(receiveMHRHistoryEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const showAlert = (error) => {
    alert(error?.message || 'Something went wrong! Please try again later.');
  };

  const closeMhr = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const startMhr = () => {
    setMhrShown(true);
    localStorage.setItem('mhr-shown', 'true');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeMhr}
      classes={{ paper: classes.profilePaper, scrollPaper: classes.scrollPaper }}
      BackdropProps={{
          style: {
              backgroundColor: colors.backdrop_tint,
              opacity: 0.35,
          },
      }}
    >
      { !mhrShown
          ? <CalculatorFTUE 
              headerText='MHR Calculator'
              headerImg={HeaderIcon}
              headerColor={colors.carbon_black}
              heroText='Create your first MHR calculation'
              heroImg={FTUImg}
              subHeadingText={
                'The Maximal Heart Rate calculator estimates the intensity of your training.'
              }
              startButtonText='Calculate MHR'
              onStart={startMhr}
              onClose={onClose}
            />
          : <>
              {!canMount && <CircularProgress />}
              {canMount
                && <mhr-calculator 
                      data-edge-web='true' 
                      data-unit-weight={unitWeight}
                      selected-client={hasSelectedClient ? 'true' : 'false'}
                  />
              }
            </>
      }
    </Dialog>
  );
};

export default MhrCalculatorContainer;
