import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { colors } from "../../styles";

const useStyles = makeStyles({
  groupListSkeleton: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 60,
    marginTop: "10px",
  },
  searchFilterSkeleton: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

/**
 * @return {JSX|null}
 */

export default function GroupListSkeleton(props) {
  const classes = useStyles();
  const { visible } = props;

  if (!visible) {
    return null;
  }

  const groupsList = [];
  for (let i = 0; i < 27; ++i) {
    groupsList.push(<GroupAvatarSkeleton key={i} />);
  }

  return (
    <Box>
      <Box className={classes.searchFilterSkeleton}>
        <Skeleton
          variant="rect"
          style={{
            borderRadius: 19,
            marginLeft: 15,
            marginRight: 14,
          }}
          width={175}
          height={38}
        />
        <Skeleton
          variant="rect"
          style={{
            borderRadius: 19,
            marginRight: 23,
          }}
          width={68}
          height={38}
        />
        <Skeleton
          variant="circle"
          style={{
            marginRight: 14,
          }}
          width={36}
          height={36}
        />
        <Skeleton variant="circle" width={36} height={36} />
      </Box>
      <Box className={classes.groupListSkeleton}>{groupsList}</Box>
    </Box>
  );
}

const useGroupAvatarStyle = makeStyles({
  groupAvatarSkeleton: {
    marginTop: 20,
    marginBottom: 20,
    width: "30%",
  },
  skeletonContainer: {
    height: 120,
    width: "100%",
    borderRadius: "10px",
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    display: "flex",
  },
  iconView: {
    columns: "2 auto",
    columnGap: 10,
  },
  nameContainer: {
    display: "flex",
    width: "70%",
  },
  actionContainer: {
    display: "flex",
    width: "30%",
    justifyContent: "flex-end",
  },
  chatView: {
    marginRight: 10,
  },
  nameView: {
    marginLeft: 20,
  },
  count: {
    marginTop: 10,
  },
});

function GroupAvatarSkeleton() {
  const classes = useGroupAvatarStyle();
  return (
    <Box className={classes.groupAvatarSkeleton}>
      <Box className={classes.skeletonContainer}>
        <Box className={classes.nameContainer}>
          <Box className={classes.iconView}>
            <Skeleton
              variant="circle"
              width={36}
              height={36}
              style={{ borderRadius: 18, marginBottom: 10 }}
            />
            <Skeleton
              variant="circle"
              width={36}
              height={36}
              style={{ borderRadius: 18, marginBottom: 10 }}
            />
            <Skeleton
              variant="circle"
              width={36}
              height={36}
              style={{ borderRadius: 18, marginBottom: 10 }}
            />
            <Skeleton
              variant="circle"
              width={36}
              height={36}
              style={{ borderRadius: 18, marginBottom: 10 }}
            />
          </Box>
          <Box className={classes.nameView}>
            <Skeleton variant="react" width={150} height={36} />
            <Box className={classes.count}>
              <Skeleton variant="react" width={50} height={16} />
            </Box>
          </Box>
        </Box>
        <Box className={classes.actionContainer}>
          <Box className={classes.chatView}>
            <Skeleton
              variant="circle"
              width={36}
              height={36}
              style={{ borderRadius: 18 }}
            />
          </Box>
          <Skeleton
            variant="circle"
            width={36}
            height={36}
            style={{ borderRadius: 18 }}
          />
        </Box>
      </Box>
    </Box>
  );
}
