import React, { useState, useEffect, useCallback } from "react";
import { Dialog, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { colors } from "../../styles";
import { useExternalScript } from "../../hooks/ExternalScript";
import CalculatorFTUE from "./CalculatorFTUE";
import FTUImg from "../../resources/ftu-bodyfat.svg";
import HeaderIcon from "../../resources/bodyfat_icon.svg";
import { ROLES } from "../../constants";
import nasmApi from "../../api/endpoints";

const useStyles = makeStyles({
  profilePaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "560px",
    height: "760px",
    borderRadius: "17px",
    boxShadow: "0 3px 41px 0 rgba(0, 0, 0, 0.22)",
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
});

const BodyFatCalculatorContainer = ({ currentUser, isOpen, onClose }) => {
  const ftueShown = localStorage.getItem("bodyfat-shown");
  const [bodyfatShown, setBodyFatShown] = useState(ftueShown === "true");

  const widgetUrl = process.env.REACT_APP_BODYFAT_WIDGET_URL || "/dist/bodyfat/index.js";
  const scriptState = useExternalScript({
    url: widgetUrl,
    canStartLoading: isOpen,
  });
  const canMount = isOpen && scriptState === "ready";

  const unitWeight = currentUser?.unit_weight || "lb";
  const hasSelectedClient = currentUser?.role === ROLES.CLIENT;

  const classes = useStyles();

  useEffect(() => {
    // Pass onClose function to custom widget element:
    const bodyFatCalculator = document.querySelector("bodyfat-calculator");
    if (canMount && bodyFatCalculator) {
      bodyFatCalculator.closewidget = onClose;
    }
  }, [canMount, onClose, bodyfatShown]);

  const saveBodyFatRecord = useCallback(
    async (bodyFatData) => {
      if (!hasSelectedClient) {
        return;
      }
      // if we have client user ID for this screen in redux, save their BodyFat results
      try {
        await nasmApi.bodyFat.saveBodyFatResults(currentUser?.id, bodyFatData);
      } catch (error) {
        showAlert(error);
      } finally {
        const SaveRequestComplete = new CustomEvent(
          "saveBodyFatRequestComplete",
        );
        window.dispatchEvent(SaveRequestComplete);
      }
    },
    [currentUser.id, hasSelectedClient],
  );

  useEffect(() => {
    window.addEventListener("onSaveBodyFatRequest", (event) => {
      const { bodyFatData } = event.detail;
      if (bodyFatData) {
        saveBodyFatRecord(bodyFatData);
      }
    });
    return () => {
      window.removeEventListener("onSaveBodyFatRequest");
    };
  }, [saveBodyFatRecord]);

  useEffect(() => {
    window.addEventListener("fetchCurrentBodyFatRecord", getCurrentBodyFat);
    window.addEventListener(
      "fetchBodyFatPaginatedHistoryList",
      getBodyFatHistory,
    );
    return () => {
      window.removeEventListener(
        "fetchCurrentBodyFatRecord",
        getCurrentBodyFat,
      );
      window.removeEventListener(
        "fetchBodyFatPaginatedHistoryList",
        getBodyFatHistory,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentBodyFat = async () => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const response = await nasmApi.bodyFat.getCurrentBodyFat(currentUser?.id);
      const receiveCurrentBodyFatEvent = new CustomEvent(
        "onReceiveCurrentBodyFatRecord",
        {
          cancelable: true,
          detail: {
            currentBodyFatRecord: response?.result,
          },
        },
      );
      window.dispatchEvent(receiveCurrentBodyFatEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const getBodyFatHistory = async (event) => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const { page } = event?.detail;
      const response = await nasmApi.bodyFat.getBodyFatHistory(
        currentUser?.id,
        { page },
      );
      const receiveBodyFatHistoryEvent = new CustomEvent(
        "onReceiveBodyFatPaginatedHistoryList",
        {
          cancelable: true,
          detail: {
            BodyFatHistoryRecord: response?.result,
          },
        },
      );
      window.dispatchEvent(receiveBodyFatHistoryEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const showAlert = (error) => {
    alert(error?.message || "Something went wrong! Please try again later.");
  };

  const closeBodyFat = (_, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const startBodyFat = () => {
    setBodyFatShown(true);
    localStorage.setItem("bodyfat-shown", "true");
  };

  const getSubHeadingText = () => (
    <p>
      The Body Fat calculator estimate overall body fatness.
      <br></br>
      You will need a skin-fold caliper and/or a tape measure.
    </p>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeBodyFat}
      classes={{
        paper: classes.profilePaper,
        scrollPaper: classes.scrollPaper,
      }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      {!bodyfatShown ? (
        <CalculatorFTUE
          headerText="Body Fat Calculator"
          headerImg={HeaderIcon}
          headerColor={colors.carbon_black}
          heroText="Create your first Body Fat calculation"
          heroImg={FTUImg}
          subHeadingText={getSubHeadingText()}
          startButtonText="Calculate Body Fat"
          onStart={startBodyFat}
          onClose={onClose}
        />
      ) : (
        <>
          {!canMount && <CircularProgress />}
          {canMount && (
            <bodyfat-calculator
              data-edge-web="true"
              data-unit-weight={unitWeight}
              selected-client={hasSelectedClient ? "true" : "false"}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default BodyFatCalculatorContainer;
