import request from '../nasm_sdk_v2/request';

export default function dashboard(apiVer) {
  /***
   *
   * @param {string} userId - client's userId
   * @param {string} date - date with timestamp for the day to request
   * @param {string} apiVersion
   * @returns {Promise<*>}
   */
  function calendarDay (userId, date, apiVersion = apiVer) {
    const config = {
      params: {
        date: date,
      },
    };
    return request.get(`${apiVersion}/api/users/${userId}/dashboard/calendar-day`, config);
  }

  /***
   *
   * @param {string} userId - client's userId
   * @param {string} date - date with timestamp | Example : 2019-05-02T22:29:56-07:00
   * @param {string} apiVersion
   * @returns {Promise<*|undefined>}
   */
  function weeklyCompletion (userId, date, apiVersion = apiVer) {
    const config = {
      params: {
        date: date,
      },
    };
    return request.get(`${apiVersion}/api/users/${userId}/dashboard/weekly-completion`, config);
  }

  function getPerformanceAssessments(userId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}/dashboard/performance-assessments`);
  }
  
  function getMeasurements (userId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}/dashboard/measurements`);
  }
  
  function getDashboardMilestones(userId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}/dashboard/milestones`);
  }

  return {
    calendarDay,
    weeklyCompletion,
    getPerformanceAssessments,
    getMeasurements,
    getDashboardMilestones,
  };
}