import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import Milestone from './Milestone';
import { colors } from '../../styles';

const ListContainer = styled('div')({
  borderTop: `1px solid ${colors.cloudy_blue_50}`,
});

function DraggableMilestone (props) {
  const {
    draggableId,
    index,
    milestone,
    onFinishEditing,
    deleteMilestone,
    isFocused,
    markMilestoneAsComplete,
    placeholder,
    isDeleting,
  } = props;

  return (
    <Draggable
      draggableId={draggableId || ''}
      index={index}
      isDragDisabled={isFocused}
    >
      {(provided) => {
        return (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <Milestone
              isFocused={isFocused}
              key={draggableId}
              milestone={milestone}
              onFinishEditing={onFinishEditing}
              deleteMilestone={deleteMilestone}
              dragHandleProps={provided.dragHandleProps}
              markMilestoneAsComplete={markMilestoneAsComplete}
              placeholder={placeholder}
              isDeleting={isDeleting}
            />
          </Box>
        );
      }}
    </Draggable>
  );
}

function MilestoneList (props) {
  const {
    milestones,
    isAdding,
    isDeleting,
    onFinishEditing,
    deleteMilestone,
    markMilestoneAsComplete,
    isFtu,
  } = props;
  const milestonePlaceholder = isFtu ? 'Add your first goal' : 'Add Goal';
  return (
    <Droppable droppableId='milestone-list'>
      {(provided) => {
        return (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <ListContainer>
              { milestones.map((milestone, index) => {
                return (
                  <DraggableMilestone
                    isFocused={isAdding}
                    key={milestone.id || 'new-milestone'}
                    draggableId={milestone.id || 'new-milestone'}
                    index={index}
                    milestone={milestone}
                    onFinishEditing={onFinishEditing}
                    deleteMilestone={deleteMilestone}
                    placeholder={milestonePlaceholder}
                    markMilestoneAsComplete={markMilestoneAsComplete}
                    isDeleting={isDeleting}
                  />
                );
              })}
              {provided.placeholder}
            </ListContainer>
          </Box>
        );
      }}
    </Droppable>
  );
}

export default MilestoneList;