import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import { OvalButton } from '../Buttons';
import { colors } from '../../styles';
import AddingToWorkoutExercisesList from './AddingToWorkoutExercisesList';

const useStyles = makeStyles({
  root: {
    height: 800,
    padding: '20px 0 0',
    borderRadius: '6px',
    backgroundColor: colors.white,
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerText: {
    marginLeft: 26,
    marginRight: 12,
    marginTop: 100,
    marginBottom: 30,
    fontFamily: "DMSans, sans-serif",
    fontWeight: 700,
    fontSize: 24,
    color: colors.carbon_black,
  },
  backPadding: {
    marginRight: 33,
    marginLeft: 14,
  },
  exerciseListHeading: {
    borderTop: `1px solid ${colors.light_border}`,
    borderBottom: `1px solid ${colors.light_border}`,
    padding: "10px 25px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 17,
  },
});

export default function AddingToWorkoutListContainer (props) {
  const { isScheduleDialogOpen, onOpenScheduleDialog, viewExerciseDetailsLink = () => {} } = props;

  const classes = useStyles();
  const history = useHistory();
  const selectedWorkout = useSelector(state => state.selectedWorkout);

  let selectedExercises = [];
  if (selectedWorkout?.entities?.exercises){
    selectedExercises = Object.values(selectedWorkout.entities?.exercises);
  }
  const goBack = () => {
    history.goBack();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.controlContainer}>
        <Typography className={classes.headerText}>
          Adding to Workout
        </Typography>
        <Box className={classes.inputContainer}>
          <OvalButton
            border='none'
            width={95}
            fontWeight='bold'
            backgroundcolor={isScheduleDialogOpen ? colors.navy : colors.yellow_button_style}
            color={colors.white}
            onClick={onOpenScheduleDialog}
          >
            Schedule
          </OvalButton>
          <Box className={classes.backPadding}>
            <OvalButton
              width={82}
              onClick={goBack}
            >
              Back
            </OvalButton>
          </Box>
        </Box>
      </Box>
      <Box className={classes.exerciseListHeading}>
        <Typography className={classes.ListHeadingText}>
          {`${Object.values(selectedExercises)?.length || 0} Exercises Selected`}
        </Typography>
      </Box>
      <AddingToWorkoutExercisesList
        exerciseData={selectedExercises}
        viewExerciseDetailsLink={viewExerciseDetailsLink}
      />
    </Box>
  );
}
