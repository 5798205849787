import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import nasmApi from '../api/endpoints';

export const LOGIN_STATES = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    loginState: LOGIN_STATES.LOGOUT,
    hasSeenWelcomeBeta: false,
    loading: false,
    error: false,
    errorMessage: null,
  },
  reducers: {
    login: (state, action) => {
      state.loginState = LOGIN_STATES.LOGIN;
      state.error = false;
      state.errorMessage = null;
    },
    loginPending: (state, action) => {
      state.loginState = LOGIN_STATES.PENDING;
      state.error = false;
      state.errorMessage = null;
    },
    loginFailed: (state, action) => {
      // We return the full object to clear all user data on failure
      return {
        loginState: LOGIN_STATES.ERROR,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    logout: (state, action) => {
      // We return the full object to clear all user data
      return {
        loginState: LOGIN_STATES.LOGOUT,
        loading: false,
        error: false,
        errorMessage: null,
        hasSeenWelcomeBeta: false,
      };
    },
    requestUserPending: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    requestUserSuccess: (state, action) => {
      return {
        loginState: LOGIN_STATES.LOGIN,
        loading: false,
        error: false,
        errorMessage: null,
        hasSeenWelcomeBeta: state.hasSeenWelcomeBeta,
        // spread user object
        ...action.payload,
      };
    },
    requestUserFail: (state, action) => {
      state.loading = false;
      state.error = true;
      // Network Error
      if (action.payload instanceof Error) {
        state.errorMessage = action.payload.message;
      } else {
        state.errorMessage = action.payload;
      }
    },
    setWelcomeBetaAsSeen: (state, action) => {
      state.hasSeenWelcomeBeta = action.payload.seenWelcome;
    },
  },
});

const { actions, reducer } = currentUserSlice;
export default reducer;

// *****
// ACTION CREATORS
// *****
export function login ({ email, password }) {
  return async function (dispatch) {
    try {
      dispatch(actions.loginPending());
      const currentUser = await nasmApi.oauth.requestTokens(email, password);
      dispatch(actions.login(currentUser));
      dispatch(getMe());
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('login failed: ', e);
        console.log(JSON.stringify(e, null, 2));
      }

      dispatch(actions.loginFailed({
        errorMessage: e?.data?.message?.key?.status === 500 
          ? (e?.data?.message?.key?.error || 'Internal Server Error')
          : e?.data?.message?.title || e?.data?.message || e.message,
      }));
      dispatch(actions.logout());

      // Re-throw error so component calling this thunk can catch the error
      throw e;
    }
  };
}

export function logout () {
  return function (dispatch) {
    dispatch(actions.logout());
    nasmApi.removeAuthTokens();
  };
}

export const getMe = () => async (dispatch, getState) => {
  dispatch(actions.requestUserPending());
  try {
    const response = await nasmApi.users.getMyUser();

    const jsonStr = JSON.stringify(response.result, null, 2);
    Sentry.captureMessage(`currentUserReducer.getMe() with response\n ${jsonStr}`, Sentry.Severity.Warning);

    dispatch(actions.requestUserSuccess(response.result));
  } catch (e) {
    Sentry.captureException(e);
    dispatch(actions.requestUserFail(e));
  }
};

export const setWelcomeBetaAsSeen = (seenWelcome = false ) => (dispatch) => {
  dispatch(actions.setWelcomeBetaAsSeen({ seenWelcome }));
};

export const loginSuccess = () => async (dispatch) => {
  dispatch(actions.login());
};