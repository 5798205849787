import axios from 'axios';

import request from '../nasm_sdk_v2/request';
import { uploadProgressHandler } from './utils';
import { prepareMediaFiles } from '../../util/videoUtils';

export default function mediaUpload(apiVer) {
  async function uploadMedia(
    videoFile,
    cancelToken,
    updateProgressBar = ({
      canProgressBeCalculated,
      progress,
    }) => {},
    apiVersion = apiVer,
  ) {
    const mediaFormData = await prepareMediaFiles(videoFile);
    return request.post(`${apiVersion}/api/media-upload`, mediaFormData, {
      onUploadProgress: uploadProgressHandler(updateProgressBar),
      cancelToken: cancelToken,
    }).catch(e => {
      if(axios.isCancel(e)) {
        // swallow cancel request error
        return Promise.resolve({
          isRequestCancelled: true,
        });
      } else {
        return Promise.reject(e);
      }
    });

  }

  async function uploadMediaToTranscode(
    videoFile,
    cancelToken,
    updateProgressBar = ({
       canProgressBeCalculated,
       progress,
    }) => {},
    apiVersion = apiVer,
  ) {
    const mediaFormData = await prepareMediaFiles(videoFile);
    return request.post(`${apiVersion}/api/media-upload/transcode-video`, mediaFormData, {
      onUploadProgress: uploadProgressHandler(updateProgressBar),
      cancelToken: cancelToken,
    }).catch(e => {
      if(axios.isCancel(e)) {
        // swallow cancel request error
        return Promise.resolve({
          isRequestCancelled: true,
        });
      } else {
        return Promise.reject(e);
      }
    });
  }

  return {
    upload: uploadMedia,
    uploadMediaToTranscode: uploadMediaToTranscode,
  };
}