import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { Switch } from '@material-ui/core';
import React from 'react';

// Code is duplicated here because css style to change translation value can't
// be inserted via props
const useStyles = makeStyles(theme => ({
  root: {
    width: 80,
    height: 26,
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(54px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: colors.colors_fill_dark_yellow_2,
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    border: 'solid 0.8px rgba(182, 189, 195, 0.4)',
  },
  track: {
    borderRadius: 14.5,
    backgroundColor: colors.colors_fill_light_gray_2,
    opacity: 1,
    transition: theme.transitions.create(['background-color']),
  },
  checked: {},
  focusVisible: {},
}));

function LongYellowSwitch (props) {
  const classes = useStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}

export default LongYellowSwitch;