import {styled} from '@material-ui/core/styles';
import {colors} from '../../styles';
import {Typography} from '@material-ui/core';

const TermsAndConditionsDisclaimer = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: 12,
  fontStretch: 'condensed',
  lineHeight: 1.5,
  color: colors.steel,
});

export default TermsAndConditionsDisclaimer;