import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import querystring from 'query-string';
import { Favorite } from '@material-ui/icons';
import { Box, Button, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FavoriteBorderOutlined } from '@material-ui/icons';

import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import { colors } from '../../styles';
import { ReactComponent as ButtonDeleteInactive } from '../../resources/icon-trash-dark.svg';
import { ReactComponent as ButtonDeleteActive } from '../../resources/button-delete-active.svg';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import nasmApi from '../../api/endpoints';
import ProgRegIconSet from "../ProgressionRegressionIconSet";

const useStyles = makeStyles({
  recordContainer: {
    height: 80,
    borderBottom: '1px solid #e6e6e6',
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      backgroundColor: colors.baby_blue,
      cursor: 'pointer',
    },
    '&:active': {
      filter: 'brightness(88%)',
      backgroundColor: colors.white,
    },
  },
  recordContainerSelected: {
    height: 80,
    borderBottom: '1px solid #b6bdc3',
    borderCollapse: 'collapse',
    backgroundColor: colors.baby_blue,
  },
  boxLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    paddingLeft: 12,
    display: 'flex',
  },
  thumbnail: {
    borderRadius: 6,
    objectFit: 'cover',
  },
  nameContainer: {
    paddingLeft: 23,
    fontSize: 17,
    fontWeight: 500,
    color: colors.black,
  },
  deleteInactive: {
    position: 'absolute',
    right: 88,
    bottom: 30,
  },
  deleteActive: {
    position: 'absolute',
    right: 80,
    bottom: 21,
  },
  favorite: {
    position: 'absolute',
    right: 12,
    bottom: 21,
  },
});

export default function ExerciseRecord (props) {
  const history = useHistory();
  const [isTrashButtonInactive, setIsShown] = useState(false);
  const [isTrashButtonActive, setShown] = useState(false);

  const {
    isSelected,
    value,
    onChangeIndexSelected,
    exercise,
  } = props;

  const classes = useStyles();
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isFavorite, setIsFavorite] = useState(exercise.is_favorite);
  const isSuperSetOrCircuit = exercise?.routine_type ? true : false;
  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  const ROUTINE_TYPES = {
    SUPER_SET: "super_set",
    CIRCUIT: "circuit",
  };

  const onInvalidImg = (e) => {
    e.target.src = thumbnailPlaceholder;
  };

  const onClickExercise = () => {
    onChangeIndexSelected(value);
    history.push({
      pathname: `/libraries/exercises/${currentTab}`,
      search: props.viewExerciseDetailsLink(exercise.id),
    });
  };

  const onClickCompoundRoutine = (exercise) => {
    if (exercise.routine_type === ROUTINE_TYPES.SUPER_SET) {
      props.setSuperSetData(exercise);
      history.push({
        pathname: `/libraries/exercises/${currentTab}`,
        search: props.viewSuperSetsLink(exercise.id),
      });
    } else {
      props.setCircuitsData(exercise);
      history.push({
        pathname: `/libraries/exercises/${currentTab}`,
        search: props.viewCircuitLink(exercise.id),
      });
    }
  };

  const onDeleteSuperSet = (supersetId) => {
    nasmApi.supersets
      .removeSuperSet(supersetId)
      .then((res) => {
        // if the superset or circuit being viewed in the exercise details panel will be deleted
        // close the panel and only show the exercise list panel
        const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
        if (location.pathname.includes('libraries/exercises') && query.view !== 'exercisesList') {
          history.replace({
            pathname: history.location.pathname,
          });
        }
        window.location.reload();
      })
      .catch(e => window.alert(`Failed to delete ${exercise?.title ?? 'this exercise'} with error:\n
      ${JSON.stringify(e, null, 2)}`));
  };

  function onDeleteExercise () {
    nasmApi.customTrainerExercises.softDeleteExercise(exercise.id)
      .then(() => {
        // if the exercise being viewed in the exercise details panel will be deleted
        // close the panel and only show the exercise list panel
        const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
        if (location.pathname.includes('libraries/exercises') && query.view !== 'exercisesList') {
          history.replace({
            pathname: history.location.pathname,
          });
        }
        window.location.reload();
      })
      .catch(e => window.alert(`Failed to delete ${exercise?.name ?? 'this exercise'} with error:\n
      ${JSON.stringify(e, null, 2)}`));
  }

  function deleteExerciseHandle (exercise) {
    if (isSuperSetOrCircuit) {
      onDeleteSuperSet(exercise.id);
    } else {
      onDeleteExercise();
    }
  }

  function handleFavoriteExercise (exerciseId, favoriteApiFn) {
    favoriteApiFn(exerciseId)
      .then((response) => {
        const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
        if (location.pathname.includes('libraries/exercises') && query.view !== 'exercisesList') {
          exercise.is_favorite = response?.result?.is_favorite;
          setIsFavorite(response?.result?.is_favorite);
        }
      })
      .catch(e => window.alert(`Failed to favorite ${exercise?.name || exercise?.title || 'this exercise'} with error:\n
       ${JSON.stringify(e, null, 2)}`));
  }

  function onFavoriteExercise () {
    handleFavoriteExercise(exercise.id, nasmApi.nasmExercises.favoriteExercise);
  }

  function onFavoriteSuperSet (exercise) {
    handleFavoriteExercise(
      exercise.id,
      (exerciseId) => nasmApi.supersets.favoriteSuperSet(exerciseId, exercise?.routine_type),
      );
  }

  function favoriteExerciseHandle (exercise) {
    if (isSuperSetOrCircuit) {
      onFavoriteSuperSet(exercise);
    } else {
      onFavoriteExercise();
    }
  }

  const renderFavoriteIcon = () => (
    !currentUser?.cc_manager ?
    <Button
      className={classes.favorite}
      onClick={(ev) => {
        ev.stopPropagation();
        favoriteExerciseHandle(exercise);
      }}
    >
      {isFavorite ? (
        <Favorite htmlColor={colors.yellow_button_style} />
      ) : (
        <FavoriteBorderOutlined htmlColor={colors.selected_highlight_copy_6} />
      )}
    </Button>
    : null
  );

  let identifier = isSuperSetOrCircuit ? 'compound routine' : 'exercise';
  let identifierTitle = exercise.title || exercise.name || `this ${identifier}`;

  let deleteDescription = `Deleting ${identifierTitle} will remove it ` +
'from your library and all workouts. Are you sure? ';

  return (
    <>
      {isSuperSetOrCircuit &&
        <ListItem
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          ref={props.lastSuperSetRecordRef}
          className={
            isSelected ? classes.recordContainerSelected : classes.recordContainer
          }
          onClick={() => onClickCompoundRoutine(exercise)}
        >
          <Box className={classes.boxLayout}>
          <div className={classes.nameContainer}>{exercise.title}</div>
          </Box>
          {(!currentUser?.cc_manager) && location.pathname.includes('libraries/exercises/my-exercises') && (
            <Box
              onMouseEnter={() => setShown(true)}
              onMouseLeave={() => setShown(false)}
            >
              {isTrashButtonInactive && !isTrashButtonActive && (
                <ButtonDeleteInactive className={classes.deleteInactive} />
              )}
              {isTrashButtonActive && (
                <ButtonDeleteActive
                  className={classes.deleteActive}
                  onClick={(e) => {
                    setShowConfirmDialog(true);
                    e.stopPropagation();
                  }}
                />
              )}
              {renderFavoriteIcon()}
            </Box>
          )}
          {location.pathname.includes('libraries/exercises/my-exercises') ? null : renderFavoriteIcon()}
        </ListItem>
      }

      {!isSuperSetOrCircuit &&
        <ListItem
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          ref={props.lastExerciseRecordRef}
          className={
            isSelected ? classes.recordContainerSelected : classes.recordContainer
          }
          onClick={onClickExercise}
        >
          <Box
            className={classes.boxLayout}
          >
            <div className={classes.imageContainer}>
              <img
                width={75} height={48}
                alt='exercise-thumbnail'
                src={exercise.image_url || thumbnailPlaceholder}
                className={classes.thumbnail}
                onError={onInvalidImg}
              />
              <ProgRegIconSet exercise={exercise} />
            </div>
            <div className={classes.nameContainer}>{exercise.name}</div>
          </Box>
          {(!currentUser?.cc_manager) && location.pathname.includes('libraries/exercises/my-exercises') && (
            <Box
              onMouseEnter={() => setShown(true)}
              onMouseLeave={() => setShown(false)}
            >
              {isTrashButtonInactive && !isTrashButtonActive && (
                <ButtonDeleteInactive className={classes.deleteInactive} />
              )}
              {isTrashButtonActive && (
                <ButtonDeleteActive
                  className={classes.deleteActive}
                  onClick={(e) => {
                    setShowConfirmDialog(true);
                    e.stopPropagation();
                  }}
                />
              )}
              {renderFavoriteIcon()}
            </Box>
          )}
          {location.pathname.includes('libraries/exercises/my-exercises') ? null : renderFavoriteIcon()}
        </ListItem>
      }
      <ConfirmDialog
        title='Delete Exercise'
        description={deleteDescription}
        actionButtonTitle='Delete'
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        handleConfirmAction={()=> deleteExerciseHandle(exercise)}
      />
    </>
  );
}
