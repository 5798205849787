import React from 'react';
import OvalButton from './OvalButton';

import { colors } from '../../styles';

export default function FilterButton (props) {
  const { onOpenFilterMenu, filterCount, isPressed, filterLabel = 'Filter', fromExercise, ...rest } = props;
  const filterActive = filterCount > 0 || isPressed;
  return (
    <OvalButton
      {...rest}
      backgroundcolor={fromExercise ? colors.white : (filterActive ? colors.navy : colors.white)}
      color={fromExercise ? colors.steel : filterActive ? colors.white : colors.steel}
      onClick={onOpenFilterMenu}
      border={fromExercise ? null : filterActive ? 'none' : null} // null: default to standard oval button border
    > {fromExercise ? filterLabel : (filterCount > 0 ? filterCount : filterLabel)}
    </OvalButton>
  );
}
