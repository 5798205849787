import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Typography } from '@material-ui/core';

import {
  ContinueButton,
  InputDescription,
  InputGroup,
  InputLabel,
  PasswordTextField,
} from '../FormComponents';

import { ForgotPasswordLink } from '../FormLinks';
import { LOGIN_VIEWS } from '../../../util/loginConstants';
import nasmApi from '../../../api/endpoints';
import { login } from '../../../reducers/currentUserReducer';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';

const useStyles = makeStyles({
  linkText: {
    marginTop: 50,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 1.95,
    color: 'white',
    textAlign: 'center',
  },
  subText: {
    fontFamily: 'Roboto',
    lineHeight: 1.58,
    color: 'white',
    textAlign: 'center',
  },
});

// Links EDGE and UA accounts together given EDGE email and UA email match
/**
 * @return {null|JSX}
 */
function LinkAccountsFormView (props) {
  const { email, loginView } = props;
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [showAccountLockoutDialog, setShowAccountLockoutDialog] = useState(false);
  const [accountDialogText, setAccountDialogText] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  function handlePasswordVisibilityToggle () {
    setShowPass(!showPass);
  }

  function handlePassword (event) {
    setPassword(event.target.value);
  }

  async function onSubmit (e) {
    e.preventDefault();

    setPasswordError('');

    if(password.length === 0) {
      setPasswordError('Enter a password');
      return;
    }

    try {
      setLoading(true);
      await nasmApi.register.linkAccountUA(email, password);
      await dispatch(login({ email, password }));
      setLoading(false);
      history.push('/clients');
    } catch (err) {
      const errorKey = err?.data?.message?.key || err?.data?.error?.key;
      let incomingError = err?.data?.message?.title || err?.data?.error?.message?.title;

      if(errorKey === 'last_attempt') {
        incomingError = err?.data?.message?.description || err?.data?.error?.message?.description;
      }

      if(errorKey === 'locked') {
        const incomingErrorDescription  = err?.data?.message?.description || err?.data?.error?.message?.description;
        setAccountDialogText(incomingErrorDescription);
        setShowAccountLockoutDialog(true);
      }

      setPasswordError(incomingError);
      setLoading(false);
    }
  }

  if (loginView !== LOGIN_VIEWS.LINK_ACCOUNTS) {
    return null;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Typography className={classes.linkText}>
          Link Account
        </Typography>
        <Typography className={classes.subText}>
          {`We found an NASM.org account for: ${email}`}
        </Typography>
        <InputGroup>
          <InputLabel>Required</InputLabel>
          <PasswordTextField
            showPass={showPass}
            password={password}
            onChange={handlePassword}
            onBlur={() => {}}
            showError={!!passwordError}
            onTogglePasswordVisibility={handlePasswordVisibilityToggle}
          />
          {!!passwordError && <InputDescription>{passwordError}</InputDescription>}
        </InputGroup>
        <Box style={{ marginBottom: 20 }}>
          <ContinueButton
            isLoading={isLoading}
            actionText='Link'
          />
        </Box>
        <ForgotPasswordLink showLink={true}/>
      </form>
      <ConfirmDialog
        hideCancelButton
        open={showAccountLockoutDialog}
        onClose={() => setShowAccountLockoutDialog(false)}
        handleConfirmAction={() => setShowAccountLockoutDialog(false)}
        title='Your account is temporarily locked'
        description={accountDialogText}
      />
    </>
  );
}

LinkAccountsFormView.propTypes = {
  email: PropTypes.string.isRequired,
  loginView: PropTypes.string.isRequired,
};

export default LinkAccountsFormView;