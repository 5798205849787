import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import nasmApi from '../../api/endpoints';
import {SUBSCRIPTION_STATUS} from '../../util/trainerSubscription';

export async function useFetchTrainerClients ({
  searchText,
  subscriptionStatus,
  bypassSubscription,
  maxFreeClients,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  function onReceiveClients(newClients) {
    setLoading(false);
    setError(null);

    setAllClients(newClients);
    setClients(newClients);
    setActiveClients(
      newClients.filter(c => !!c.client_user.active_under_current_trainer),
    );
  }

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    setError(false);
    nasmApi.trainer.getClients(selectedProfile?.ClubId, selectedProfile?.Locations?.Id).then(data => {
      if (isMounted) {
        const newClients = data.result || [];
        onReceiveClients(newClients);
      }
    })
      // eslint-disable-next-line handle-callback-err
      .catch(err => {
        if (isMounted) {
          setError(err);
          setLoading(false);
        }
      });

    return () => { isMounted = false; };
  }, [selectedProfile]);

  useEffect(() => {
    if(!bypassSubscription &&
      [SUBSCRIPTION_STATUS.EXPIRED, SUBSCRIPTION_STATUS.NONE].includes(subscriptionStatus)
    ) {
      if(activeClients.length > maxFreeClients) {
        nasmApi.trainer.deactivateAllClients().then(response => {
          const newClients = response.result || [];
          onReceiveClients(newClients);
        }).catch(err => {
          setError(err);
          setLoading(false);
        });
      }

    } else if (subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBED || bypassSubscription) {
      if (allClients.length !== activeClients.length) {
        nasmApi.trainer.reactivateClients({}).then(response => {
          const newClients = response.result || [];
          onReceiveClients(newClients);
        }).catch(err => {
          setError(err);
          setLoading(false);
        });
      }
    }
  }, [
    activeClients.length,
    allClients.length,
    bypassSubscription,
    maxFreeClients,
    subscriptionStatus,
  ]);

  useEffect(() => {
    // There are no API parameters for searching clients
    // under a trainer, so filtering is implemented here
    if (searchText) {
      if (allClients.length > 0) {
        const clientList = allClients.filter((client) => {
          return client.first_name.toLowerCase().includes(searchText);
        });
        setClients(clientList);
      }
    } else {
      setClients(allClients);
    }
  }, [searchText, allClients]);

  return { loading, error, clients };
}
