import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';

import { colors } from '../../styles';
import {LongYellowSwitch, OvalButton, SaveButton} from '../Buttons';

import { ReactComponent as VisibleIcon } from '../../resources/icons-global-visible-gray-visible.svg';
import { ReactComponent as HiddenIcon } from '../../resources/icons-global-visible-gray-hidden.svg';

const DashboardTitle = styled(Typography)({
  fontWeight: 'bold',
  fontFamily: 'DMSans-Regular, sans-serif',
  fontSize: 24,
  color: colors.carbon_black,
  lineHeight: 1.63,
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent:  'flex-end',
  width: '100%',
});

const ColorSquare = styled(Box)({
  width: 20,
  height: 20,
  borderRadius: 4,
  backgroundColor: props => props.backgroundcolor,
  boxSizing: 'border-box',
});

const LabelText = styled(Typography)({
  fontWeight: 500,
});

const useSettingsRowStyle = makeStyles({
  settingsRow: {
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 20,
    marginLeft: 33,
  },
  labelSpacing: {
    marginLeft: 21,
    marginRight: 100,
    width: 285,
  },
});

function SettingsRow (props) {
  const {
    label,
    backgroundColor,
    isChecked,
    onChange,
  } = props;

  const classes = useSettingsRowStyle();

  return (
    <Box className={classes.settingsRow}>
      <ColorSquare backgroundcolor={backgroundColor} />
      <Box className={classes.labelSpacing}>
        <LabelText>
          {label}
        </LabelText>
      </Box>
      <LongYellowSwitch
        checked={isChecked}
        onChange={onChange}
      />
    </Box>
  );
}

const useOverheadSquatRowStyle = makeStyles({
  overheadSquatRow: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 33,
  },
  labelSpacing: {
    marginLeft: 21,
    marginRight: 150,
    width: 200,
  },
  visibilityTextContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  visibilityText: {
    fontSize: 12,
    lineHeight: '39px',
    color: colors.carbon_black,
    marginRight: 5,
    width: 105,
  },
});

function OverheadSquatRow (props) {
  const {
    backgroundColor,
    isVisibleToClient,
    onChange,
  } = props;

  const classes = useOverheadSquatRowStyle();

  return (
    <Box className={classes.overheadSquatRow}>
      <ColorSquare backgroundcolor={backgroundColor} />
      <Box className={classes.labelSpacing}>
        <LabelText>
          Overhead Squat
        </LabelText>
      </Box>
      <Box className={classes.visibilityTextContainer} onClick={onChange}>
        <Typography className={classes.visibilityText}>
          {isVisibleToClient ? 'Visible to Client' : 'Hidden from Client'}
        </Typography>
        {isVisibleToClient ? <VisibleIcon /> : <HiddenIcon/>}
      </Box>
    </Box>
  );
}

const useStyle = makeStyles({
  scrollPaper: {
    alignItems: 'flex-start',
  },
  saveButtonContainer: {
    marginRight: 16,
  },
  dashboardSettings: {
    padding: 33,
    width: 585,
  },
  paper: {
    borderRadius: 17,
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
});

function ClientDashboardSettings (props) {
  const {
    open,
    saveState,
    isGoalsVisible,
    isNutritionVisible,
    isMeasurementsVisible,
    isPerformanceAssessmentsVisible,
    isOverheadSquatVisibleToClient,

    // used to hide visibility of nutrition
    clubId = null,

    onToggleGoals,
    onToggleNutrition,
    onToggleMeasurements,
    onTogglePerformanceAssessments,
    onChangeOverHeadSquatVisibility,

    onSave,
    onClose,
  } = props;
  const classes = useStyle();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: colors.mediumBlue, opacity: 0.35 } }}
      classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}
    >
      <Box className={classes.dashboardSettings}>
        <ButtonContainer>
          <Box className={classes.saveButtonContainer}>
            <SaveButton saveState={saveState} onClick={onSave} />
          </Box>
          <OvalButton onClick={onClose}>Close</OvalButton>
        </ButtonContainer>
        <DashboardTitle>
          Dashboard Settings
        </DashboardTitle>
      </Box>
      <Box style={{ paddingBottom: 33 }}>
        <SettingsRow
          label={'Goals'}
          backgroundColor={colors.purple}
          isChecked={isGoalsVisible}
          onChange={onToggleGoals}
        />
        <Divider />
        {!clubId && <>
            <SettingsRow
              label={'Nutrition'}
              isChecked={isNutritionVisible}
              backgroundColor={colors.green}
              onChange={onToggleNutrition}
            />
            <Divider />
          </>}
        <SettingsRow
          label={'Measurements'}
          backgroundColor={colors.red}
          isChecked={isMeasurementsVisible}
          onChange={onToggleMeasurements}
        />
        <Divider />
        <SettingsRow
          label={'Performance Assessments'}
          backgroundColor={colors.backdrop_tint}
          isChecked={isPerformanceAssessmentsVisible}
          onChange={onTogglePerformanceAssessments}
        />
        <Divider />
        <OverheadSquatRow
          backgroundColor={colors.lightBlue}
          isVisibleToClient={isOverheadSquatVisibleToClient}
          onChange={onChangeOverHeadSquatVisibility}
        />
      </Box>
    </Dialog>
  );
}

ClientDashboardSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  saveState: PropTypes.string,
  isGoalsVisible: PropTypes.bool,
  isNutritionVisible: PropTypes.bool,
  isMeasurementsVisible: PropTypes.bool,
  isPerformanceAssessmentsVisible: PropTypes.bool,
  isOverheadSquatVisibleToClient: PropTypes.bool,

  onToggleGoals: PropTypes.func,
  onToggleNutrition: PropTypes.func,
  onToggleMeasurements: PropTypes.func,
  onTogglePerformanceAssessments: PropTypes.func,
  onChangeOverHeadSquatVisibility: PropTypes.func,

  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClientDashboardSettings;