import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { MacaroniButton } from '../../Buttons';
import FileUploadInput from '../FileUploadInput';

import { colors } from '../../../styles';

const useStyles = makeStyles({
  emptyUploadView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
  },
  header: {
    fontSize: 24,
    color: colors.white,
  },
});

function EmptyUploadView (props) {
  const { onUploadBegin, headerLabel, supportedFileTypes = [] } = props;
  const classes = useStyles();

  return (
    <Box className={classes.emptyUploadView}>
      <Typography className={classes.header}>{headerLabel}</Typography>
      <Box style={{ marginTop: 20 }}>
        <UploadButton
          onUploadBegin={onUploadBegin}
          supportedFileTypes={supportedFileTypes}
        />
      </Box>
    </Box>
  );
}

function UploadButton (props) {
  const { onUploadBegin, supportedFileTypes = [] } = props;
  const inputFileRef = useRef();

  function onClick (e) {
    // invoke click function for input element to start upload media process
    inputFileRef.current.click();
  }

  return (
    <Box>
      <FileUploadInput
        id='media-upload'
        inputFileRef={inputFileRef}
        onUploadBegin={onUploadBegin}
        supportedFileTypes={supportedFileTypes}
      />
      <MacaroniButton width={108} height={38} onClick={onClick}>
        Add
      </MacaroniButton>
    </Box>
  );
}

EmptyUploadView.propTypes = {
  onUploadBegin: PropTypes.func.isRequired,
  headerLabel: PropTypes.string.isRequired,
  /***
   * Comma separated strings tht specify the supported file types
   * that can be uploaded to the server. E.g. image/png
   */
  supportedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default EmptyUploadView;
