import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';

import { colors } from '../../../styles';
import UpdatedAtText from '../UpdatedAtText';
import OhsaController from '../OHSA/OhsaController';

const HeaderText = styled(Typography)({
  color: colors.white,
  fontWeight: 500,
  fontSize: 20,
  lineHeight: 1.95,
});

const CenterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: 'inherit',
  marginLeft: 20,
});

function OverheadSquatCard (props) {
  const {
    loading,
    overActiveMuscles = [],
    underActiveMuscles = [],
    assessmentDate,
  } = props;

  return (
    <CardContainer
      width={416}
      height={'auto'}
    >
      <CardHeader backgroundColor={colors.lightBlue} height={55}>
        <CenterContainer>
          <HeaderText>
            Overhead Squat
          </HeaderText>
          <UpdatedAtText date={assessmentDate} margin={'0 0 0 85px'}/>
        </CenterContainer>
      </CardHeader>
      <OhsaController
        loading={loading}
        overActiveMuscles={overActiveMuscles}
        underActiveMuscles={underActiveMuscles}
      />
    </CardContainer>
  );
}

OverheadSquatCard.propTypes = {
  loading: PropTypes.bool,
  overActiveMuscles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_overactive: PropTypes.number,
  })),
  underActiveMuscles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_overactive: PropTypes.number,
  })),
  /***
   *  Moment Date Object
   */
  assessmentDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
};

export default OverheadSquatCard;