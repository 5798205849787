import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExerciseRecord from "./ExerciseRecord";
import { colors } from "../../styles";
import { usePaginationObserver } from "../../util/utilFunctions";
import ROUTINE_TYPES from "../../util/RoutineTypes";

const useStyles = makeStyles({
  listContainer: {
    overflowY: "auto",
    height: "88%",
    padding: 0,
  },
  exerciseListHeading: {
    border: `1px solid ${colors.divider_line_white}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 25px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 14,
  },
});

function LoadingSpinner() {
  return (
    <ListItem
      key="loading spinner"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <CircularProgress size={20} />
    </ListItem>
  );
}

const ExerciseRecordWithRef = React.forwardRef((props, ref) => {
  return <ExerciseRecord lastExerciseRecordRef={ref} {...props} />;
});

const SupersetRecordWithRef = React.forwardRef((props, ref) => {
  return <ExerciseRecord lastSuperSetRecordRef={ref} {...props} />;
});

function ExerciseList(props) {
  const classes = useStyles(props);

  const {
    exerciseData,
    superSets,
    currentTab,
    isLoading,
    selectedIndex,
    setSelectedIndex,
    viewExerciseDetailsLink,
    viewSuperSetsLink,
    setSuperSetData,
    setSupersetPageNumber,
    hasMoreSuperset,
    isSuperSetLoading,
    viewCircuitLink,
    setCircuitsData,
  } = props;

  const allSuperSets = superSets?.filter(
    (superset) => superset.routine_type === ROUTINE_TYPES.SUPER_SET,
  );

  const allCircuits = superSets?.filter(
    (circuit) => circuit.routine_type === ROUTINE_TYPES.CIRCUIT,
  );

  const supersetrootElement = useRef();
  const lastSuperSetRecordRef = usePaginationObserver({
    setPageNumber: setSupersetPageNumber,
    isLoading: isSuperSetLoading,
    hasMoreDataToLoad: hasMoreSuperset,
    rootElement: supersetrootElement.current,
  });

  return (
    <List ref={supersetrootElement} className={classes.listContainer}>
      {allCircuits?.length > 0 && (
        <>
          {currentTab === "my-exercises" && (
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                Circuit
              </Typography>
            </Box>
          )}
          {allCircuits?.map((exercise, index) => {
            // last exercise record for the page
            if (allCircuits.length === index + 1) {
              return (
                <SupersetRecordWithRef
                  ref={lastSuperSetRecordRef}
                  key={exercise.id}
                  value={index}
                  isSelected={selectedIndex === index}
                  onChangeIndexSelected={setSelectedIndex}
                  exercise={exercise}
                  viewCircuitLink={viewCircuitLink}
                  setCircuitsData={setCircuitsData}
                />
              );
            }

            return (
              <ExerciseRecord
                key={exercise.id}
                value={index}
                isSelected={selectedIndex === index}
                onChangeIndexSelected={setSelectedIndex}
                exercise={exercise}
                viewCircuitLink={viewCircuitLink}
                setCircuitsData={setCircuitsData}
              />
            );
          })}
        </>
      )}
      {isSuperSetLoading && <LoadingSpinner />}
      {allSuperSets?.length > 0 && (
        <>
          {currentTab === "my-exercises" && (
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                Super Sets
              </Typography>
            </Box>
          )}
          {allSuperSets?.map((exercise, index) => {
            // last exercise record for the page
            if (allSuperSets.length === index + 1) {
              return (
                <SupersetRecordWithRef
                  ref={lastSuperSetRecordRef}
                  key={exercise.id}
                  value={index}
                  isSelected={selectedIndex === index}
                  onChangeIndexSelected={setSelectedIndex}
                  exercise={exercise}
                  viewSuperSetsLink={viewSuperSetsLink}
                  setSuperSetData={setSuperSetData}
                />
              );
            }

            return (
              <ExerciseRecord
                key={exercise.id}
                value={index}
                isSelected={selectedIndex === index}
                onChangeIndexSelected={setSelectedIndex}
                exercise={exercise}
                viewSuperSetsLink={viewSuperSetsLink}
                setSuperSetData={setSuperSetData}
              />
            );
          })}
        </>
      )}
      {isSuperSetLoading && <LoadingSpinner />}
      {exerciseData.length > 0 && (
        <>
          {currentTab === "my-exercises" && (
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                My Exercises
              </Typography>
            </Box>
          )}
          {!!exerciseData &&
            exerciseData.map((exercise, index) => {
              // last exercise record for the page
              if (exerciseData.length === index + 1) {
                return (
                  <ExerciseRecordWithRef
                    ref={props.lastExerciseRecordRef}
                    key={exercise.id}
                    value={index}
                    isSelected={selectedIndex === index}
                    onChangeIndexSelected={setSelectedIndex}
                    exercise={exercise}
                    viewExerciseDetailsLink={viewExerciseDetailsLink}
                  />
                );
              }

              return (
                <ExerciseRecord
                  key={exercise.id}
                  value={index}
                  isSelected={selectedIndex === index}
                  onChangeIndexSelected={setSelectedIndex}
                  exercise={exercise}
                  viewExerciseDetailsLink={viewExerciseDetailsLink}
                />
              );
            })}
        </>
      )}
      {isLoading && <LoadingSpinner />}
    </List>
  );
}

ExerciseList.propTypes = {
  exerciseData: PropTypes.array,
  superSets: PropTypes.array,
  currentTab: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  viewExerciseDetailsLink: PropTypes.func,
};

export default ExerciseList;
