import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../styles';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as ProcessingIcon } from '../../../resources/icon-processing-video.svg';

const useStyles = makeStyles({
  uploadProcessingView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
  },
  header: {
    fontSize: 24,
    color: colors.white,
  },
  subheader: {
    fontSize: 14,
    color: colors.white,
  },
});

function UploadProcessingView (props) {
  const { fileType = 'video', subheaderLabel } = props;
  const classes = useStyles();

  const message = subheaderLabel ||
    `${fileType[0].toUpperCase()}${fileType.slice(1, fileType.length)} will be ready soon.`;

  return (
    <Box className={classes.uploadProcessingView}>
      <Box style={{ marginBottom: 10 }}>
        <ProcessingIcon />
      </Box>
      <Typography className={classes.header}>
        {`We’re processing this ${fileType}.`}
      </Typography>
      <Box style={{ marginTop: 50 }}>
        <Typography className={classes.subheader}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

UploadProcessingView.propTypes = {
  fileType: PropTypes.string,
  subheaderLabel: PropTypes.string,
};

export default UploadProcessingView;
