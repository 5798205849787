import { createSlice } from '@reduxjs/toolkit';

export const programContexts = {
  LIBRARY: 'LIBRARY',
  SCHEDULING: 'SCHEDULING',
  RESCHEDULING: 'RESCHEDULING',
};

export const programContextSlice = createSlice({
  name: 'programContext',
  initialState: { context: null },
  reducers: {
    programLibrary: (state, action) => {
      state.context = programContexts.LIBRARY;
    },
    scheduleProgramContext: (state, action) => {
      state.context = programContexts.SCHEDULING;
    },
    rescheduleProgramContext: (state, action) => {
      state.context = programContexts.RESCHEDULING;
    },
    clearProgramContext: (state, action) => {
      state.context = null;
    },
  },
});

// Extract the action creators object and the reducer from the slice
export const { actions, reducer } = programContextSlice;
export default reducer;

// Extract and export each action creator by name
export const {
  programLibrary,
  scheduleProgramContext,
  rescheduleProgramContext,
  clearProgramContext,
} = actions;
