import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

import DragIcon from '../../resources/img-sort-gray-active.svg';
import { ReactComponent as CheckOff } from '../../resources/interaction-toggle-checkmark-off-dark-grey.svg';
import { ReactComponent as CheckOn } from '../../resources/interaction-toggle-checkmark-on-big-milestone.svg';
import { ReactComponent as Trash } from '../../resources/trash.svg';
import { ReactComponent as Clear } from '../../resources/icons-global-x-gray-clear-search.svg';

import { colors } from '../../styles';

const Container = styled('div')({
  height: '60px',
  width: '100%',
  cursor: props => props.completed ? 'default' : 'pointer',
  backgroundColor: colors.white,
  borderBottom: props => `1px solid ${!!props.focused ? colors.yellow : colors.cloudy_blue_50}`,
  opacity: props => props.completed ? 0.6 : 1,
  '&:hover': {
    backgroundColor: colors.baby_blue,
  },
});

const Content = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  alignItems: 'center',
  padding: '0 33px',
});

const SideSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const MilestoneText = styled(InputBase)({
  fontFamily: 'Roboto-Medium, Roboto, sans-serif',
  fontSize: '17px',
  marginLeft: '16px',
  color: colors.carbon_black,
  width: '300px',
});

const IconSpacing = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginLeft: '20px',
});

const CheckContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const TrashBkg = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '18px',
  width: '36px',
  height: '36px',
  '&:hover': {
    backgroundColor: colors.cloudy_blue_50,
  },
});

const TrashIcon = ({ deleteMilestone, milestoneId, isDeleting}) => {
  const deleteMilestoneHandler = () => {
    if(isDeleting) return;
    deleteMilestone(milestoneId);
  };

  return (
    <TrashBkg onClick={deleteMilestoneHandler}>
      <Trash />
    </TrashBkg>
  );
};

const CircleIcon = ({ focused, selected, toggleCheckMark, onClear }) => {
  if (focused) {
    return <Clear onMouseDown={onClear} />;
  }
  return (
    <CheckContainer onClick={toggleCheckMark}>
      { selected ? <CheckOn/> : <CheckOff/> }
    </CheckContainer>
  );
};

function Milestone (props) {
  const {
    milestone,
    isFocused,
    onFinishEditing,
    deleteMilestone,
    placeholder,
    dragHandleProps,
    completed,
    markMilestoneAsComplete,
    isDeleting,
  } = props;
  const [currentText, setCurrentText] = useState(milestone?.name ?? '');
  const [selected, setSelected] = useState(completed ?? false);
  const [deleteVisible, setDeleteVisible] = useState(!completed);
  const [focused, setFocused] = useState(isFocused ?? false);

  const onChangeText = (e) => {
    setCurrentText(e.target.value);
  };

  const toggleCheckMark = () => {
    setSelected(prev => !prev);
    markMilestoneAsComplete(milestone);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    if (!focused) {
      return;
    }
    setFocused(false);
    if (!currentText) {
      return;
    }

    onFinishEditing({
      ...milestone,
      name: currentText,
    });
  };

  // Save milestone by pressing the 'Enter/Return' key
  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };

  const onClear = () => {
    setFocused(true);
    setCurrentText('');
  };

  return (
    <Container
      onMouseEnter={() => setDeleteVisible(!completed)}
      // To prevent DOM errors:
      focused={focused ? "true" : undefined}
      completed={completed ? "true" : undefined}
    >
      <Content>
        <SideSection>
          <img src={DragIcon} alt='drag-handle' {...dragHandleProps} />
          <MilestoneText
            value={currentText}
            onChange={onChangeText}
            onFocus={onFocus}
            onBlur={onBlur}
            // automatically add text cursor on pending milestone
            autoFocus={!milestone.id}
            onKeyDown={onPressEnter}
            placeholder={placeholder}
            disabled={completed}
          />
        </SideSection>
        <SideSection>
          { deleteVisible &&
          <TrashIcon
            deleteMilestone={deleteMilestone}
            milestoneId={milestone}
            isDeleting={isDeleting}
          />
          }
          <IconSpacing>
            <CircleIcon
              focused={focused}
              selected={selected}
              toggleCheckMark={toggleCheckMark}
              onClear={onClear}
            />
          </IconSpacing>
        </SideSection>
      </Content>
    </Container>
  );
}

export default Milestone;

Milestone.propTypes = {
  milestone: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  completed: PropTypes.bool,
  isFocused: PropTypes.bool,
  onFinishEditing: PropTypes.func,
  markMilestoneAsComplete: PropTypes.func,
};
