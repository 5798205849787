import { parsePhoneNumberFromString } from 'libphonenumber-js';

const EMOJI_REGEX = '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|' +
    '[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]' +
    '|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]' +
    '|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]' +
    '|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04' +
    '|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf' +
    '|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])';

export function email (value) {
  return /\S+@\S+\.\S{2,}/.test(value);
}

export function name (value) {
  return value.match(
    /^[a-zA-Z](?:[a-zA-Z' ]*[a-zA-Z])?/,
  );
}
export function possessiveName (value) {
  const lastLetter = value.slice(-1);
  return lastLetter === 's' ? `${value}'` : `${value}'s`;
}

// Based on Jeremy, this is the validation used for UA password requirements
// when migrating an EDGE account to UA
export function password (value) {
  return value.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?._+@<>=#$%&\-^&*])[0-9a-zA-Z!?._+@<>=#$%&\-^*]{8,}$/,
  );
}

export function legacyPassword (value) {
  return value.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!?._+@<>=#$%&\-^&*])[0-9a-zA-Z!?._+@<>=#$%&\-^*]{8,}$/,
  );
}

export function confirmPassword (pw, confirm) {
  return pw === confirm && password(confirm);
}

export function height (value) {
  return isNumber(value) && value.length > 0;
}

export function heightFeet (value) {
  return isNumber(value) && value > 0 && value.length > 0;
}

export function heightInches (value) {
  return isNumber(value) && value >= 0 && value < 12;
}

export function weight (value) {
  return isNumber(value) && value.length >= 2 && parseFloat(value) >= 80;
}

export function phone (value) {
  const number = parsePhoneNumberFromString(value, 'US');
  if (!number) return false;
  return number.isValid();
}

// Helper functions
export function isNumber (value) {
  // double equal is intentional as type conversion is desireable here
  return parseInt(value) == value; // eslint-disable-line
}

// strip all non numeric characters
export function getDigits (value) {
  return `${value}`.replace(/\D/g, '');
}

export function formatPhoneNumber (value, countryISO = 'US') {
  let formattedNumber = "";
  if (value.length) {
    const digits = getDigits(value);
    formattedNumber = parsePhoneNumberFromString(digits, countryISO).formatInternational();
  }
  return formattedNumber;
}

export function getCountryISO(phoneNumber) {
  const obj = parsePhoneNumberFromString(phoneNumber);
  return obj;
}

export function convertSecondsToInput (totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds - minutes * 60);
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}${seconds}`;
}

export const hasEmojis = (text) => {
  return text.match(EMOJI_REGEX);
};

/* Exercise Validation ----------------------------------------- */
export function exerciseInt (value) {
  if (!value) { return true; } // to allow empty or 0 values
  return isNumber(value);
}

export function durationValue (value) {
  if (!value) { return true; } // to allow empty or 0 values
  return convertSecondsToInput(value).length < 6;
}

export function hasSpecialCharactersOrNumbers(inputStr) {
  // check for special characters, numbers, or underscore
  const regex = /((\W|\d|_))+/;
  return regex.test(inputStr);
}

export function containsAccentedChars(value) {
  const pattern = /[\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]/;
  return pattern.test(value);
}
