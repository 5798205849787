import React, { useState } from 'react';
import { FormControl, Select, MenuItem, InputBase, Typography, Box } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { ReactComponent as BtnArrowExpand } from '../../resources/btn-arrow-expand.svg';

import ErrorText from './ErrorText';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 15px)',
  },
  select: {
    '&.MuiSelect-select': {
      paddingRight: 50,
    },
  },
  dropdownBase: props => ({
    flex: props.flex || 1,
    marginLeft: props.sideMargin || 0,
    marginRight: props.sideMargin || 0,
    border: props.errorText ? `1px solid ${colors.yellow_button_style}` : '1px solid #ced4da',
    borderRadius: 3,
    width: props.width,
    height: 40,
  }),
});

const SelectInput = withStyles((theme) => ({
  root: {
    fontSize: 16,
  },
  input: props => ({
    fontSize: 16,
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.startAdornment ? 'flex-end' : 'flex-start',
    marginLeft: props.startAdornment ? 0 : 10,
    name: props.name,
    '&:focus': {
      backgroundColor: 'inherit',
    },
  }),
}))(InputBase);

const useMenuItemStyles = makeStyles({
  menuItem: props => ({
    fontSize: props.fontSize || 16,
    fontFamily: props.fontFamily,
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  }),
  listItemRoot: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: 'white',
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
});

const baseMenuItems = (optionsArray, fontFamily, fontSize, classes ) => {
  return optionsArray.map(([optionKey, optionLabel]) => {
    return (
      <MenuItem
        className={classes.menuItem}
        ListItemClasses={{
          root: classes.listItemRoot,
        }}
        key={optionKey}
        value={optionKey}
      >
        {optionLabel}
      </MenuItem>
    );
  });
};

const renderValue = (label, fontFamily, options, value) => {
  const optionLabel = options[value]?.label;
  return (
    <DropdownDisplay label={label} fontFamily={fontFamily} valueSelected={optionLabel ?? options[value]} />
  );
};

function DropdownBase (props) {
  const {
    options = {},
    sideMargin = 0,
    dropdownSpacing = 0,
    width = 132,
    name = '',
    fontFamily = 'DMSans, sans-serif',
    fontSize = 16,

    flex,
    label = '',
    currentValue,
    onOptionChanged,
    errorText = '',
    customMenuItems,
    menuItemClasses,
  } = props;

  const classes = useStyles({ sideMargin, flex, width, errorText, fontFamily });
  const menuClasses = menuItemClasses ?? useMenuItemStyles({ fontSize, fontFamily });

  const optionsArray = Object.entries(options);

  return (
    <Box className={classes.dropdownBase}>
      <FormControl style={{ width: '100%' }}>
        <Select
          value={currentValue}
          onChange={event => {
            onOptionChanged(event);
          }}
          input={
            <SelectInput
              // Used in Formik to identify which key value pair to update when calling handleChange()
              name={name}
            />
          }
          displayEmpty
          renderValue={(value) => renderValue(label, fontFamily, options, value)}
          IconComponent={BtnArrowExpand}
          classes={{
            root: classes.root,
            icon: classes.icon,
            select: classes.select,
          }}
          MenuProps={{
            // spacing between dropdown field and dropdown list options
            style: { marginTop: dropdownSpacing },
            // must be set to null in order to set the anchor's origin otherwise a react warning displays on the console
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            onExited: () => {
              // lose focus of dropdown to to allow user to freely select
              // any input without double clicking
              document.activeElement.blur();
            },
          }}
        >
          { customMenuItems
            ? customMenuItems(optionsArray, menuClasses)
            : baseMenuItems(optionsArray, fontFamily, fontSize, menuClasses)
          }
        </Select>
      </FormControl>
      <ErrorText
        textColor={colors.yellow_button_style}
        errorText={errorText}
        showError
      />
    </Box>
  );
}

export function StatelessDropdown (props) {
  const {
    options = {},
    name = '',
    sideMargin,
    flex,
    dropdownSpacing,
    width,
    label,
    currentValue,
    onOptionChanged,
    errorText,
    fontFamily,
    fontSize,
    customMenuItems,
    menuItemClasses,
  } = props;

  return (
    <DropdownBase
      currentValue={currentValue}
      options={options}
      sideMargin={sideMargin}
      flex={flex}
      dropdownSpacing={dropdownSpacing}
      width={width}
      fontFamily={fontFamily}
      fontSize={fontSize}
      label={label}
      onOptionChanged={onOptionChanged}
      name={name}
      errorText={errorText}
      customMenuItems={customMenuItems}
      menuItemClasses={menuItemClasses}
    />
  );
}

export function StatefulDropdown (props) {
  const {
    options = {},
    sideMargin,
    flex,
    dropdownSpacing,
    width,
    label,
    defaultValue,
    fontFamily,
    fontSize,
    customMenuItems,
    menuItemClasses,
  } = props;
  const [optionSelected, setOptionSelected] = useState(defaultValue);

  return (
    <DropdownBase
      currentValue={optionSelected}
      options={options}
      sideMargin={sideMargin}
      flex={flex}
      dropdownSpacing={dropdownSpacing}
      width={width}
      fontFamily={fontFamily}
      fontSize={fontSize}
      label={label}
      onOptionChanged={setOptionSelected}
      customMenuItems={customMenuItems}
      menuItemClasses={menuItemClasses}
    />
  );
}

const useDropdownDisplayStyles = makeStyles({
  dropdownDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: props => ({
    fontSize: 14,
    fontFamily: props.fontFamily || 'DMSans, sans-serif',
  }),
});

function DropdownDisplay (props) {
  const { label, valueSelected } = props;
  const classes = useDropdownDisplayStyles(props);

  const TextLabel = () => {
    if (label) {
      return (
        <Typography className={classes.label}>
          {label}
        </Typography>
      );
    }

    return null;
  };

  return (
    <Box className={classes.dropdownDisplay}>
      <TextLabel />
      <Typography className={classes.label}>
        {valueSelected}
      </Typography>
    </Box>
  );
}
