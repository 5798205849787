import request from '../nasm_sdk_v2/request';

export default function customTrainerExercises(apiVer) {
  function createExercise(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/trainers/exercises`, data);
  }

  function updateExercise(data, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/trainers/exercises`, data);
  }

  function softDeleteExercise(exerciseId, apiVersion = apiVer) {
    return request.delete(`${apiVersion}/api/trainers/exercises`, { data: { id: exerciseId }});
  }

  function getExercise(exerciseId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/trainers/exercises/${exerciseId}`);
  }

  return {
    createExercise,
    updateExercise,
    softDeleteExercise,
    getExercise,
  };
}