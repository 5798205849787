import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import WorkoutRows from './WorkoutRows';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { HighlightSearchBar, ClearOptions } from '../Inputs';
import { useFetchWorkoutFilters } from '../../workoutsHooks';
import { useWorkoutsContext } from '../../contexts/WorkoutsContext';
import { usePaginationObserver, updateQuery } from '../../util/utilFunctions';
import { OvalButton, SortButton, WorkoutFilterButton }  from '../Buttons';
import EmptySection from '../EmptyStates/EmptySection';
import ftuImgWorkouts from '../../resources/ftu-add-workout.png';
import NoSearchResults from '../EmptyStates/NoSearchResults';
import WorkoutListSkeleton from '../LoadingStates/WorkoutListSkeleton';

import { programContexts } from '../../reducers/programContextReducer';

import nasmApi from '../../api/endpoints';

const EmptySectionContents = {
  [programContexts.LIBRARY]: {
    title: 'Create your own Workouts',
    description: 'Get clients using your personal workouts by building your own workout library.',
  },
  [programContexts.SCHEDULING]: {
    title: 'You haven’t created any custom workouts yet',
    description: 'Create custom workouts within your library.',
  },
};

const useStyles = makeStyles({
  workoutListRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  workoutList: {
    overflowY: 'auto',
    height: '88%',
  },
  closeBtnCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '20px 20px 0 0',
  },
  filterCont: {
    width: '320px',
    marginTop: '5px',
    padding: 0,
  },
  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    margin: 0,
    backgroundColor: 'rgba(240, 241, 243, 0.41)',
    height: '58px',
  },
  menuHeaderText: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

function WorkoutList (props) {
  const classes = useStyles(props);
  const {
    selectedWorkoutIndex,
    setSelectedWorkoutIndex,
    selectedWorkoutId,
    getWorkoutUrlParams,
    copyWorkoutLink,
    getPublic,
    getPrivate,
    updateButtonVisibilityFlags,
    addWorkoutKey,
    programDetailsKey,
    showDropdownOnWorkoutRecords,
    createNewWorkout = () => {},
    ftuTo,
    ftuBtnHidden,
    onDeleteWorkout,
    onChangeWorkoutPending,
  } = props;

  const {
    loading: isLoading,
    workouts: workoutData,
    hasMore,
    fetchWorkouts,
    clearWorkouts,
    refreshing,
  } = useWorkoutsContext();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [selectedProgramWorkout, setSelectedProgramWorkout] = useState(-1);
  const context = useSelector(state => state.programContext.context);

  const [isSortActive, setSortActive] = useState(false);
  const [isClearOptionsVisible, setClearOptionsVisible] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [pendingSelectedFilters, setPendingSelectedFilters] = useState([]);
  const [isFirstTimeUserExp, setFirstTimeUserExp] = useState(false);

  // reset page number when switching between tabs
  useEffect(() => {
    setPageNumber(1);
    setSortActive(false);
    setClearOptionsVisible(false);
  }, [getPublic, getPrivate]);

  // This is for redirecting the user back to my-workouts when the trainer duplicates a workout
  const location = useLocation();
  const history = useHistory();
  const currentTab = location.pathname.includes('my-workouts') ? 'my-workouts' : 'nasm-workouts';
  const workoutDuplicateName = location?.state?.workoutDuplicateName ?? '';
  const selectedFilterString = selectedFilters.join(',');

  const { workoutFilters } = useFetchWorkoutFilters();

  useEffect(() => {
    if (refreshing) {
      setPageNumber(1);
    }
  }, [refreshing]);

  // Reset data when new search criteria is passed in
  useEffect(() => {
    clearWorkouts();
  }, [getPublic, getPrivate, searchText, sortField, sortOrder, selectedFilterString, clearWorkouts]);

  useEffect(() => {
    if (workoutDuplicateName.length > 0) {
      setPageNumber(1);
      setSearchText(workoutDuplicateName);
      // unset workoutDuplicateName
      history.replace(location.pathname, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutDuplicateName]);

  useEffect(() => {
    setPageNumber(1);
    if(isSortActive) {
      setSortField('created_at');
      setSortOrder('desc');
    } else {
      setSortField('');
      setSortOrder('');
    }
  }, [isSortActive]);

  useEffect(() => {
    fetchWorkouts({
      pageNumber: pageNumber,
      searchText: searchText,
      sizePerPage: 30,
      getPublic: getPublic,
      getPrivate: getPrivate,
      sortField: sortField,
      sortOrder: sortOrder,
      workoutFilters: selectedFilterString,
    });
  }, [
    fetchWorkouts,
    pageNumber,
    searchText,
    getPublic,
    getPrivate,
    sortField,
    sortOrder,
    selectedFilterString,
  ]);

  const rootElement = useRef();
  const lastWorkoutElementRef = usePaginationObserver({
    setPageNumber: setPageNumber,
    isLoading: isLoading,
    hasMoreDataToLoad: hasMore,
    rootElement: rootElement.current,
  });

  // Used to determine whether or not FTU Panel should be shown
  useEffect(() => {
    const cancel = axios.CancelToken.source();
    if(currentTab === 'my-workouts') {
      const searchParams = {
        page: 1,
        size: 1,
        getPublic: false,
        getPrivate: true,
      };

      nasmApi.nasmWorkouts.findWorkouts(searchParams, cancel.token)
        .then(data => {
          if(!data.isRequestCancelled) {
            if(!!data.result) {
              setFirstTimeUserExp(data.result.workouts.length === 0 &&
                currentTab === 'my-workouts');
            } else {
              setFirstTimeUserExp(false);
            }
          }
        });
    } else {
      setFirstTimeUserExp(false);
    }

    return () => cancel.cancel('Workout Search Request FTU Cancelled');

  }, [currentTab]);

  function handleSearch (e) {
    setSearchText(e.target.value);
    setPageNumber(1);
  }

  function onClickClear () {
    setSearchText('');
  }

  function onClearOptions () {
    setSortActive(false);
    setSelectedFilters([]);
    setPendingSelectedFilters([]);
    setClearOptionsVisible(false);
  }

  function onToggleSort () {
    setSortActive(prev => {
      if(prev) {
        setClearOptionsVisible(false);
      } else {
        setClearOptionsVisible(true);
      }

      return !prev;
    });
  }

  function onOpenFilter () {
    setFilterOpen(true);
  }

  function onCloseFilter ()  {
    setFilterOpen(false);
    setClearOptionsVisible(pendingSelectedFilters.length !== 0);
    setSelectedFilters(pendingSelectedFilters);
    setPageNumber(1);
  }

  function onSelectFilter (id) {
    setPendingSelectedFilters(prev => {
      const filterCopy = [ ...prev ];
      const selectedIndex = filterCopy.indexOf(id);
      if (selectedIndex !== -1) {
        filterCopy.splice(selectedIndex, 1);
      } else {
        filterCopy.push(id);
      }
      return [ ...filterCopy ];
    });
  }

  const areSearchResultsNotFound =
    workoutData.length === 0
    && (selectedFilters.length > 0 || searchText.length > 0)
    && !isLoading;

  const isInitialListLoading = workoutData.length === 0 && isLoading;

  return (
    <Box ref={rootElement} className={classes.workoutListRoot}>
      {
        addWorkoutKey &&
          <Box className={classes.closeBtnCont}>
            <Link to={{ search: updateQuery(location.search, { view: programDetailsKey }) }}>
              <OvalButton>Close</OvalButton>
            </Link>
          </Box>
      }
      <SearchAndFilters
        visible={!isFirstTimeUserExp}
        handleSearch={handleSearch}
        searchText={searchText}
        onClickClear={onClickClear}
        currentTab={currentTab}
        filterOpen={filterOpen}
        onOpenFilter={onOpenFilter}
        onCloseFilter={onCloseFilter}
        pendingSelectedFilters={pendingSelectedFilters}
        onSelectFilter={onSelectFilter}
        workoutFilters={workoutFilters}
        isSortActive={isSortActive}
        onToggleSort={onToggleSort}
        onClearOptions={onClearOptions}
        isClearOptionsVisible={isClearOptionsVisible}
      />
      <Box className={classes.workoutList}>
        <WorkoutListContent
          areSearchResultsNotFound={areSearchResultsNotFound}
          isInitialListLoading={isInitialListLoading}
          isFirstTimeUserExp={isFirstTimeUserExp}

          selectedWorkoutIndex={selectedWorkoutIndex}
          setSelectedWorkoutIndex={setSelectedWorkoutIndex}
          selectedWorkoutId={selectedWorkoutId}
          setSelectedProgramWorkout={setSelectedProgramWorkout}
          lastWorkoutElementRef={lastWorkoutElementRef}
          workoutData={workoutData}
          getWorkoutUrlParams={getWorkoutUrlParams}
          copyWorkoutLink={copyWorkoutLink}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          addWorkoutKey={addWorkoutKey}
          selectedProgramWorkout={selectedProgramWorkout}
          showDropdownOnWorkoutRecords={showDropdownOnWorkoutRecords}
          onDeleteWorkout={onDeleteWorkout}
          onChangeWorkoutPending={onChangeWorkoutPending}
          context={context}
          createNewWorkout={createNewWorkout}
          ftuTo={ftuTo}
          ftuBtnHidden={ftuBtnHidden}
        />
      </Box>
    </Box>
  );
}

const useSearchAndFiltersStyle = makeStyles({
  searchPadding: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
    justifyContent: 'flex-start',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '10%',
  },
});

/**
 * @return {JSX|null}
 */
function SearchAndFilters (props) {
  const {
    visible,
    handleSearch,
    searchText,
    onClickClear,
    currentTab,
    filterOpen,
    onOpenFilter,
    onCloseFilter,
    pendingSelectedFilters,
    onSelectFilter,
    workoutFilters,
    isSortActive,
    onToggleSort,
    onClearOptions,
    isClearOptionsVisible,
  } = props;

  const classes = useSearchAndFiltersStyle();

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.searchContainer}>
      <Box className={classes.searchPadding}>
        <HighlightSearchBar
          onChange={handleSearch}
          value={searchText}
          isClearable={searchText.length > 0}
          onClickClear={onClickClear}
        />
        <WorkoutFilterButton
          visible={currentTab === 'my-workouts'}
          filterOpen={filterOpen}
          onOpenFilter={onOpenFilter}
          onCloseFilter={onCloseFilter}
          selectedFilters={pendingSelectedFilters}
          onSelectFilter={onSelectFilter}
          workoutFilterOptions={workoutFilters}
        />
        <SortButton
          margin='0 0 0 20px'
          text='Most Recent'
          isActive={isSortActive}
          onClick={onToggleSort}
        />
        <ClearOptions
          text='Clear All'
          margin='0 0 0 20px'
          onClick={onClearOptions}
          visible={isClearOptionsVisible}
        />
      </Box>
    </Box>
  );
}

function WorkoutListContent (props) {
  const {
    areSearchResultsNotFound,
    isInitialListLoading,
    isFirstTimeUserExp,

    selectedWorkoutIndex,
    setSelectedWorkoutIndex,
    setSelectedProgramWorkout,
    lastWorkoutElementRef,
    workoutData,
    getWorkoutUrlParams,
    copyWorkoutLink,
    updateButtonVisibilityFlags,
    addWorkoutKey,
    selectedProgramWorkout,
    showDropdownOnWorkoutRecords,
    onDeleteWorkout,
    onChangeWorkoutPending,
    context,
    createNewWorkout,
    ftuTo,
    ftuBtnHidden,
  } = props;

  if(isFirstTimeUserExp) {
    const { title, description } = EmptySectionContents[context];
    return (
      <Box style={{ marginTop: 40 }}>
        <EmptySection
          ftuImg={ftuImgWorkouts}
          emptyTitle={title}
          emptyDescription={description}
          onClickPlus={createNewWorkout}
          to={ftuTo}
          ftuBtnHidden={ftuBtnHidden}
        />
      </Box>
    );
  }

  if (isInitialListLoading) {
    return <WorkoutListSkeleton />;
  }

  if (areSearchResultsNotFound) {
    return <NoSearchResults />;
  }

  return (
    <WorkoutRows
      selectedWorkoutIndex={selectedWorkoutIndex}
      setSelectedWorkoutIndex={setSelectedWorkoutIndex ?? setSelectedProgramWorkout}
      lastWorkoutElementRef={lastWorkoutElementRef}
      workouts={workoutData}
      getWorkoutUrlParams={getWorkoutUrlParams}
      copyWorkoutLink={copyWorkoutLink}
      updateButtonVisibilityFlags={updateButtonVisibilityFlags}
      addingWorkouts={!!addWorkoutKey}
      selectedProgramWorkout={selectedProgramWorkout}
      showDropdownOnWorkoutRecords={showDropdownOnWorkoutRecords}
      onDeleteWorkout={onDeleteWorkout}
      onChangeWorkoutPending={onChangeWorkoutPending}
    />
  );
}

export default WorkoutList;
