import request from '../nasm_sdk_v2/request';
import axios from 'axios';

export default function Terms (apiVer) {
  function termsConditions(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/terms-conditions`).then( async (result) => {
      try {
        return await axios.get(result.result.url);
      } catch (err) {
        return Promise.reject(err);
      }
    }).catch((err) => {
      return Promise.reject(err);
    });
  }

  function privacyPolicy(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/privacy-policy`).then ( async (result) => {
      try {
        return await axios.get(result.result.url);
      } catch (err) {
        return Promise.reject(err);
      }
    }).catch((err) => {
      return Promise.reject(err);
    });
  }

  return {
    termsConditions,
    privacyPolicy,
  };
}
