import React, { useState, useCallback, useContext, createContext } from 'react';
import nasmApi from '../api/endpoints';
import axios from 'axios';

const defaultState = {
  loading: false,
  error: null,
  hasMore: false,
  exercises: [],
  refreshing: false,
  pageNumber: 1,
  sizePerPage: 20,
  activeExerciseFilter: '',
  searchText: '',
  muscleGroupIds: '',
  fitnessComponentIds: '',
  sortField: '',
  sortOrder: '',
};

const ExercisesContext = createContext(null);

function ExercisesProvider({ children }) {
  const [state, dispatch] = useState({ ...defaultState });

  return (
    <ExercisesContext.Provider value={[state, dispatch]}>
      {children}
    </ExercisesContext.Provider>
  );
}

function useExercisesContext () {
  const [state, dispatch] = useContext(ExercisesContext);

  const onReceiveExercises = useCallback((newExercises, refreshing) => {
    dispatch( prevState => {
     return {
       ...prevState,
       exercises: refreshing ? newExercises : [ ...prevState.exercises, ...newExercises ],
       loading: false,
     };
   });
  }, [dispatch]);

  const onError = useCallback((e) => {
    dispatch( prevState => {
      return {
        ...prevState,
        error: e,
      };
    });
  }, [dispatch]);

  const clearExercises = useCallback(() => {
    dispatch( prevState => {
      return {
        ...prevState,
        exercises: [],
      };
    });
  }, [dispatch]);

  const getExerciseData = useCallback((searchParams, refreshing) => {
    const cancel = axios.CancelToken.source();
    nasmApi.nasmExercises
      .findExercises(searchParams, cancel.token)
      .then(data => {
        if (!data.isRequestCancelled) {
          dispatch( prevState => {
            return {
              ...prevState,
              hasMore: !!data.result.nextPage && data.result.nextPage.length > 0,
            };
          });
          onReceiveExercises(data.result.exercises, refreshing);
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(err => {
        onError(err);
      });
  }, [dispatch, onReceiveExercises, onError]);

  const fetchExercises = useCallback((filterParams, refreshing = false) => {
    dispatch(prevState => {
      return {
        ...prevState,
        ...filterParams,
        refreshing,
        loading: true,
      };
    });
    const { pageNumber } = filterParams;
    const searchParams = buildSearchParams(filterParams);
    return getExerciseData(searchParams, pageNumber === 1);
  }, [dispatch, getExerciseData]);

  const refreshExercises = () => {
    const {
      sizePerPage,
      activeExerciseFilter,
      searchText,
      muscleGroupIds,
      fitnessComponentIds,
      sortField,
      sortOrder,
    } = state;

   const searchParams = buildSearchParams({
     pageNumber: 1,
     sizePerPage,
     activeExerciseFilter,
     searchText,
     muscleGroupIds,
     fitnessComponentIds,
     sortField,
     sortOrder,
   });

    dispatch(prev => {
      return {
        ...prev,
        pageNumber: 1,
        refreshing: true,
      };
    });

    getExerciseData(searchParams, true);
  };

  return {
    ...state,
    fetchExercises,
    clearExercises,
    refreshExercises,
  };
}

const buildSearchParams = (filterParams) => {
  const {
    pageNumber,
    searchText,
    sizePerPage,
    muscleGroupIds,
    fitnessComponentIds,
    sortField,
    sortOrder,
    activeExerciseFilter,
  } = filterParams;

  const searchParams = {
    page: pageNumber,
    size: sizePerPage,
    exercisesFilter: activeExerciseFilter.split(','),
  };

  if (searchText && searchText.length > 0) {
    searchParams.search = searchText;
  }

  if (muscleGroupIds && muscleGroupIds.length > 0) {
    searchParams.muscleGrpIds = muscleGroupIds.split(',');
  }

  if (fitnessComponentIds && fitnessComponentIds.length > 0) {
    searchParams.fitnessCompIds = fitnessComponentIds.split(',');
  }

  if(!!sortField && sortField.length > 0) {
    searchParams.sortField = sortField;
  }

  if(!!sortOrder && sortOrder.length > 0) {
    searchParams.sortOrder = sortOrder;
  }

  return searchParams;
};

export {
  useExercisesContext,
  ExercisesProvider,
};
