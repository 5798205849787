import { useEffect, useState } from 'react';
import nasm from '../../dataManager/apiConfig';
import { useSelector } from 'react-redux';

/***
 *
 * @param {String} groupId - group's Id
 * @param {Number} date
 * @returns {{isDataLoaded: boolean, error: boolean, groupSchedulesByMonth: {}}}
 */
export default function useFetchGroupScheduleByMonth ({
  groupId, date,
}) {
  const [groupSchedulesByMonth, setGroupSchedulesByMonth] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  useEffect(() => {
    setIsDataLoaded(false);

    nasm.api.getGroupCalendarScheduleDates(groupId, date, selectedProfile?.ClubId, selectedProfile?.Locations?.Id)
        .then(data => {
          setGroupSchedulesByMonth(data);
          setIsDataLoaded(true);
          setError(false);
        })
        .catch(error => {
          setError(true);
          setGroupSchedulesByMonth({});
          setIsDataLoaded(false);
        });
  }, [groupId, date, selectedProfile]);

  return { groupSchedulesByMonth, isDataLoaded, error };
}
