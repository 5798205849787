import ROUTINE_TYPES from '../../util/RoutineTypes';
import request from '../nasm_sdk_v2/request';

export default function supersets(apiVer) {

  /**
   *
   * @param {string} title 
   * @param {string} reps
   * @param {object} exercises
   * @param {string} routine_type
   * @returns {Promise<*>}
   */
  function createSuperSet(jsonData, apiVersion = apiVer) {
    return request
      .post(`${apiVersion}/api/compound-routines`, jsonData )
      .catch(e => {
        Promise.reject(e);
      });
  }

  function fetchAllSuperSets(jsonData, apiVersion = apiVer) {
    const { page = 1, size = 20, search = '' } = jsonData;
    return request
      .get(`${apiVersion}/api/compound-routines?page=${page}&size=${size}&search=${search}`)
      .catch(e => {
        Promise.reject(e);
      });
  }

  function removeSuperSet(compoundRoutineId, apiVersion = apiVer) {
    if (!compoundRoutineId) {
      alert('Please select a superset to remove.');
    }
    return request
      .delete(`${apiVersion}/api/compound-routines/${compoundRoutineId}`)
      .catch(e => {
        Promise.reject(e);
      });
  }

  function favoriteSuperSet(compoundRoutineId, routineType, apiVersion = apiVer) {
    if (!compoundRoutineId) {
      alert('id is required to favorite a Super Set');
    }
    return request
      .get(`${apiVersion}/api/compound-routines/favorite/${compoundRoutineId}?routine_type=${routineType}`)
      .catch(e => {
        Promise.reject(e);
      });
  }

  function updateSuperSetById(jsonData, apiVersion = apiVer) {
    if (!jsonData.compound_routine_id) {
      const messageKey = jsonData.routine_type === ROUTINE_TYPES.CIRCUIT
        ? 'circuit'
        : 'superset';
      alert(`Please select a ${messageKey} to update.`);
    }
    return request
      .put(`${apiVersion}/api/compound-routines/${jsonData.compound_routine_id}`, jsonData)
      .catch(e => {
        Promise.reject(e);
      });
  }

  return {
    createSuperSet,
    fetchAllSuperSets,
    removeSuperSet,
    favoriteSuperSet,
    updateSuperSetById,
  };
}