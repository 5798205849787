import {Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {UpgradeButton} from '../Buttons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  subscriptionExpiredContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50,
    marginLeft: 300,
  },
  text: {
    marginRight: 20,
    fontSize: 15,
    fontWeight: 500,
  },
}));

/**
 * @return {JSXElement | null}
 */

export default function SubscriptionExpiredContainer (props) {
  const classes = useStyles();
  const history = useHistory();
  const { isVisible = false } = props;

  const onViewUpgradeOptions = () => {
    history.replace('/clients/view-subscriptions');
  };

  if(!isVisible) {
    return null;
  }

  return (
    <Box className={classes.subscriptionExpiredContainer}>
      <Typography className={classes.text}>
        Your subscription has expired. Upgrade to access all clients.
      </Typography>
      <Box>
        <UpgradeButton onClick={onViewUpgradeOptions} />
      </Box>
    </Box>
  );
}