/**
 * @author Harvey Limbo <harvey@medlmobile.com>
 */

import axios from 'axios';
import moment from "moment";
import queryString from 'query-string';
import LocalStore from './local_store';

import {
  STORAGE_KEYS,
  BASE_URL,
  API_VERSION,
  MAX_RETRY_COUNT,
  RETRY_STATUS_CODES,
} from './constants';

import Oauth from '../endpoints/oauth';

const { AUTH_TOKEN } = STORAGE_KEYS;
const oauth = new Oauth(API_VERSION, LocalStore);

// Place to hold current request for new tokens.
// Use this to prevent duplicating a token request using the same refresh token.
let currentTokenRequest = null;

// Use the configureRequest method as soon as the Redux Store is configured.
// This should be a method that will dispatch the logout action in redux.
let logoutAction = () => {};

const ax = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  // used to create a session that can be used between requests
  withCredentials: true,
  paramsSerializer: function(params) {
    return queryString.stringify(params, { arrayFormat: 'comma' });
  },
});

function requestWrapper(endpoint, config) {
  return requestWrapperInternal(endpoint, config, 0);
}

async function requestWrapperInternal(endpoint, config, currentRetryCount) {
  try {
   return await ax(endpoint, config);
  } catch (error) {
    if (axios.isCancel(error)) {
      // forward error to request endpoint methods
      return;
    }

    // Network Error (e.g. no internet)
    // See: https://github.com/axios/axios/issues/383#issuecomment-234079506
    if(!error.status) {
      throw error;
    }

    // This condition could be true if an access token was used just before it expired OR
    // the access token was revoked for some reason. This will capture requests that fail with 401 or 403
    // and attempt to refresh the access token and try the request again. Should the refresh token request
    // fail, the user will be logged out.
    if (currentRetryCount + 1 < MAX_RETRY_COUNT
      && error
      && RETRY_STATUS_CODES.includes(error.status)) {

      if (!LocalStore.hasKey(AUTH_TOKEN)) {
        logoutAction();
        // register endpoints that fail may optionally be caught in the components that call this function
        throw error;
      }

      // Retry logic when initial request fails due to tokens expiring
      const { refresh_token: refreshToken } = LocalStore.get(AUTH_TOKEN);
      try {
        await manageTokenRequest(refreshToken);
      } catch (e) {
        throw e;
      }
      const { token_type: tokenType, access_token: accessToken } = LocalStore.get(AUTH_TOKEN);

      config.headers = {
        ...config.headers,
        Authorization: `${tokenType} ${accessToken}`,
      };

      return requestWrapperInternal(endpoint, config, currentRetryCount + 1);
    } else {
      // no response or no config was defined
      throw error;
    }
  }
}

async function manageTokenRequest(refreshToken) {
  currentTokenRequest = currentTokenRequest || oauth.requestTokensByRefreshToken(refreshToken);
  try {
    return await currentTokenRequest;
  } catch (err) {
    // Something is wrong with using the refresh token and we should just log the user out.
    logoutAction();
    throw err;
    // return the unchanged config which will act as an unauthenticated call. This should be caught
  } finally {
    currentTokenRequest = null;
  }
}

function requestFulfilledInterceptor() {
  return async function (config) {
    // inject access token in request header
    if (LocalStore.hasKey(AUTH_TOKEN)) {
      const { expires_in: expiresIn, refresh_token: refreshToken } = LocalStore.get(AUTH_TOKEN);

      // check if token is expired
      if (moment(expiresIn).isSameOrBefore(moment())) {
        currentTokenRequest = currentTokenRequest || oauth.requestTokensByRefreshToken(refreshToken);
        try {
          await manageTokenRequest(refreshToken);
        } catch (err) {
          // return the unchanged config which will act as an unauthenticated call. This should be caught
          return config;
        }
      }

      const { access_token, token_type } = LocalStore.get(AUTH_TOKEN);
      config.headers = {
        ...config.headers,
        // eslint-disable-next-line camelcase
        Authorization: `${token_type} ${access_token}`,
      };
    }

    return config;
  };
}

function requestErrorInterceptor() {
  return function (error) {
    return Promise.reject(error);
  };
}

function responseFulfilledInterceptor() {
  return function (response) {
    if (response.config.responseType === 'json') {
      return response.data;
    }

    return response;
  };
}

function responseRejectedInterceptor() {
  return function (error) {
    // Cancelled HTTP requests
    if (axios.isCancel(error)) {
      return Promise.resolve({
        cancelMessage: error.message,
      });
    }

    if (error.response) {
      return Promise.reject({
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      });
    }

    return Promise.reject(error);
  };
}

ax.interceptors.request.use(requestFulfilledInterceptor(), requestErrorInterceptor());
ax.interceptors.response.use(responseFulfilledInterceptor(), responseRejectedInterceptor());

function request() {
  function post(endpoint, data, config = {}) {
    return requestWrapper(endpoint, {
      ...config,
      method: 'post',
      data: data,
    });
  }

  function put(endpoint, data, config = {}) {
    return requestWrapper(endpoint, {
      ...config,
      method: 'put',
      data: data,
    });
  }

  function get(endpoint, config = {}) {
    return requestWrapper(endpoint, {
      ...config,
      method: 'get',
    });
  }

  function deleteVerb(endpoint, config = {}) {
    return requestWrapper(endpoint, {
      ...config,
      method: 'delete',
    });
  }

  return {
    post: post,
    put: put,
    get: get,
    delete: deleteVerb,
  };
}

export default request();

/**
 * Use this method just after the redux store has been configured.
 * @param {Function} handleLogout - Callback function that when invoked, will dispatch the logout action in redux.
 */
export function configureRequest(handleLogout) {
  logoutAction = handleLogout;
}
