import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import WorkoutSectionsPanel from './Panels/WorkoutSectionsPanel';
import AddExercisesPanel from './Panels/AddExercisesPanel';

import {
  dragExerciseToWorkout,
  reorderExercise,
  dragExerciseToWorkoutAutoSave,
  reorderExerciseAutoSave,
} from '../reducers/selectedWorkoutReducer';

export default function WorkoutSectionExerciseContext (props) {
  const {
    isWorkoutSectionsVisible,
    isAddExercisesVisible,
    sectionIds,

    // Navigation
    currentView,
    addExercisesKey,
    addExercisesListLink,
    backLink,
    copyWorkoutLink,
    editExerciseLink,
    viewWorkoutDetailsLink,
    viewExerciseLink,

    resetWorkoutIndex,

    selectedExerciseDraggableId,
    setSelectedExerciseDraggableId,
    resetSelectedExercise,
    workoutButtonsVisibilityFlags,
    updateButtonVisibilityFlags,
    refreshWorkouts,
    viewSuperSetsLink = () => {},
    setSuperSetData,
    viewCircuitLink = () => {},
    setCircuitsData,
  } = props;

  const dispatch = useDispatch();
  const exercises = useSelector(state => state.exercises.byId);
  const compoundRoutines = useSelector(state => state.compoundRoutines.byId) ?? [];


  function onDragEnd (result) {
    const { source, destination, draggableId: exerciseId } = result;

    // stop handling the drag end event when item is not dropped in one of the droppables
    if (!destination) {
      return;
    }

    // do nothing if user dropped draggable back to where it started
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const isAutoSaveEnabled = workoutButtonsVisibilityFlags.isAutoSaveEnabled;

    // add exercise to a section
    if (source.droppableId === 'Exercise-List' && sectionIds.includes(destination.droppableId)) {
      const exercise = exercises[exerciseId];
      const originalCompoundRoutine = compoundRoutines[exerciseId];
      let compoundRoutine;
      if (originalCompoundRoutine) {
        const compoundRoutineExercises = [];
        originalCompoundRoutine.compound_routine_exercises.forEach((ex) => {
          compoundRoutineExercises.push({
            ...ex.exercise,
            compound_routine_id: ex.compound_routine_id,
          });
        });
        compoundRoutine = {
          ...originalCompoundRoutine,
          compound_routine_exercises: [...compoundRoutineExercises],
        };
      }
      if (exercise || compoundRoutine) {
        if (isAutoSaveEnabled) {
          dispatch(dragExerciseToWorkoutAutoSave({
            exercise: (exercise || compoundRoutine),
            sectionId: destination.droppableId,
            destinationIndex: destination.index,
          }));
          if (refreshWorkouts) {
            refreshWorkouts();
          }
        } else {
          dispatch(dragExerciseToWorkout({
            exercise: (exercise || compoundRoutine),
            sectionId: destination.droppableId,
            destinationIndex: destination.index,
          }));
        }
      }
    } else if (sectionIds.includes(destination.droppableId)) {
      // re-order list or move exercises between sections
      if (isAutoSaveEnabled) {
        dispatch(reorderExerciseAutoSave({
          srcSectionId: source.droppableId,
          destSectionId: destination.droppableId,
          srcIndex: source.index,
          destIndex: destination.index,
        }));
      } else {
        dispatch(reorderExercise({
          srcSectionId: source.droppableId,
          destSectionId: destination.droppableId,
          srcIndex: source.index,
          destIndex: destination.index,
        }));
      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <WorkoutSectionsPanel
        // Navigation
        visible={isWorkoutSectionsVisible}
        addExercisesKey={addExercisesKey}
        addExercisesListLink={addExercisesListLink}
        backLink={backLink}
        copyWorkoutLink={copyWorkoutLink}
        editExerciseLink={editExerciseLink}
        viewExerciseLink={viewExerciseLink}

        resetWorkoutIndex={resetWorkoutIndex}
        workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
        updateButtonVisibilityFlags={updateButtonVisibilityFlags}
        sectionIds={sectionIds}
        selectedExerciseIndex={selectedExerciseDraggableId}
        setSelectedExerciseIndex={setSelectedExerciseDraggableId}
        refreshWorkouts={refreshWorkouts}
        viewSuperSetsLink={viewSuperSetsLink}
        setSuperSetData={setSuperSetData}
        viewCircuitLink={viewCircuitLink}
        setCircuitsData={setCircuitsData}
      />
      <AddExercisesPanel
        // Navigation
        visible={isAddExercisesVisible}
        viewWorkoutDetailsLink={viewWorkoutDetailsLink}
        viewExerciseLink={viewExerciseLink}

        selectedExerciseDraggableId={selectedExerciseDraggableId}
        setSelectedExerciseDraggableId={setSelectedExerciseDraggableId}
        resetSelection={resetSelectedExercise}

        // flag to determine whether or not child elements within this panel are draggable
        // depending on which view the app is currently on
        isDragDisabled={currentView !== addExercisesKey}
        viewSuperSetsLink={viewSuperSetsLink}
        setSuperSetData={setSuperSetData}
        viewCircuitLink={viewCircuitLink}
        setCircuitsData={setCircuitsData}
      />
    </DragDropContext>
  );
}
