import React from 'react';
import { colors } from '../styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.colors_fill_dark_yellow_2,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
  },
});

export default function InfoBanner({ text }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.text}>{text}</div>
    </div>
  );
}