import {VictoryAxis, VictoryLegend, VictoryLine, VictoryScatter} from 'victory';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const GRAPH_COLORS = Object.freeze({
  red: '#d82c44',
  gray1: '#d8d8d8',
  gray2: '#b6bdc3',
});

function displayLatestMeasurement  (measurementData = []) {
  let latestMeasurement = {
    // Default to displaying this data if measurement data array is empty
    weight: 0,
    body_fat_percentage: 0,
    lean_body_mass_percentage: 0,
  };

  // Display today's date if available
  const now = moment();
  const measurements = measurementData.filter(m => moment(m.date).isSame(now, 'day'));
  if(measurements.length === 1) {
    latestMeasurement = measurements[0];
  } else if (measurementData.length  > 0) {
    // If today's date is not available, display the latest date returned by the API
    latestMeasurement = measurementData[0];
    for(let i = 1;i < measurementData.length; ++i) {
      if(moment(measurementData.date).isAfter(latestMeasurement.date)) {
        latestMeasurement = measurementData[i];
      }
    }
  }

  return latestMeasurement;
}

function formatMeasurementValues (measurement, weightUnitValue = 'lb') {
  const {
    weight,
    body_fat_percentage: bodyFatPercentage,
    lean_body_mass_percentage: leanBodyMassPercentage,
  } = measurement;

  const leanMuscleWeight = weight * (leanBodyMassPercentage / 100);

  return {
    weightText: weight ? `${weight.toFixed(1)}${weightUnitValue}` : 'n/a',
    bodyFatPercentageText: bodyFatPercentage ? `${bodyFatPercentage.toFixed(1)}%` : 'n/a',
    leanMuscleWeightText: leanMuscleWeight ? `${leanMuscleWeight.toFixed(1)}${weightUnitValue}` : 'n/a',
  };
}

/***
 *
 * @param {Array} data  - array of x,y data points to plot on a curve
 * @param {Number} maxValue - used for percentages where the expected number range is from 0 to 100
 * @returns {(*|{y: number})[]}
 */
function normalizeData (data = [], maxValue = 0) {
  const maxNum = Math.max(...data.map(d => d.y), maxValue);
  // rescales values from base units to 0 to 1 so values of different units fit evenly on the graph
  return data.map(d => ({ ...d, y: d.y / maxNum }));
}

function getDataByKey(key, measurementData = []) {
  return measurementData.map((d, index) => {
    return { x: index, y: d[key] };
  });
}

function ClientMeasurementGraph (props) {
  const { measurementsData = [], unitPreferences = {} } = props;

  // sort from oldest date record to newest date record from api
  const data = [...measurementsData].reverse();
  const weights = getDataByKey('weight', data);
  const bodyFatPercentages = getDataByKey('body_fat_percentage', data);
  const leanBodyMassPercentages = getDataByKey('lean_body_mass_percentage', data);

  const weightData = normalizeData(weights);
  const bodyFatPercentageData = normalizeData(bodyFatPercentages, 100);
  const leanBodyMassPercentageData = normalizeData(leanBodyMassPercentages, 100);

  const latestMeasurement = displayLatestMeasurement(measurementsData);
  const {
    weightText,
    bodyFatPercentageText,
    leanMuscleWeightText,
  } = formatMeasurementValues(latestMeasurement, unitPreferences.unit_weight);

  return (
    <div style={{ width: '100%', height: 200, position: 'relative' }}>
      <svg width={'100%'} height={200} viewBox="0 75 450 140" style={{ position: 'absolute', top: 0 }}>
        <PlotCurve
          data={weightData}
          color={GRAPH_COLORS.red}
          symbol={'circle'}
        />
        <PlotCurve
          data={bodyFatPercentageData}
          color={GRAPH_COLORS.gray2}
          symbol={'square'}
        />
        <PlotCurve
          data={leanBodyMassPercentageData}
          color={GRAPH_COLORS.gray1}
          symbol={'triangleUp'}
        />
        <VictoryAxis
          standalone={false}
          key={0}
          style={{
            axis: {stroke: ''},
            tickLabels: { fill: 'rgba(0,0,0,0)'},
            grid: {stroke: 'silver'},
          }}
          tickValues={[0, 1, 2, 3, 4, 5, 6]}
        />
      </svg>
      <svg width={'100%'} height={'35'} viewBox={'5 0 400 35'} style={{ position: 'absolute', top: 205 }}>
        <VictoryLegend
          standalone={false}
          orientation='horizontal'
          gutter={20}
          data={[
            {
              name: `weight: ${weightText}`,
              symbol: { fill: GRAPH_COLORS.red, type: "circle"},
            },
            {
              name: `body fat: ${bodyFatPercentageText}`,
              symbol: { fill: GRAPH_COLORS.gray2, type: "square"},
            },
            {
              name: `lean muscle: ${leanMuscleWeightText}`,
              symbol: { fill: GRAPH_COLORS.gray1, type: "triangleUp"},
            },
          ]}
        />
      </svg>
    </div>
  );
}


function PlotCurve (props) {
  const {
    data,
    color,
    symbol,
  } = props;
  return (
    <>
      <VictoryLine
        standalone={false}
        style={{ data: { stroke: color, strokeWidth: 2}}}
        data={data}
        interpolation={'catmullRom'}
        domain={{x: [0, 6], y: [0, 1.1] }}
      />
      <VictoryScatter
        standalone={false}
        style={{ data: { fill: color } }}
        size={4}
        symbol={symbol}
        data={data}
        domain={{x: [0, 6], y: [0, 1.1] }}
      />
    </>
  );
}

ClientMeasurementGraph.propTypes = {
  measurementsData: PropTypes.arrayOf(PropTypes.shape({
    /***
     * String format: YYYY-MM-DD
     */
    date: PropTypes.string,
    /***
     * Could be in either lbs or kg
     */
    weight: PropTypes.number,
    /***
     * number between 0 and 100
     */
    body_fat_percentage: PropTypes.number,
    /***
     * number between 0 and 100
     */
    lean_body_mass_percentage: PropTypes.number,
  })),
  unitPreferences: PropTypes.shape({
    /***
     * Unit Height can be set as in (inches) or cm (centimeters) for example
     */
    unit_height: PropTypes.string,
    /***
     * Unit Weight can be set as lb (pounds) or kg (kilograms) for example
     */
    unit_weight: PropTypes.string,
  }),
};

export default ClientMeasurementGraph;