import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../styles';

const useStyles = makeStyles({
  clearOptions: props => ({
    cursor: 'pointer',
    color: colors.steel,
    fontSize: 14,
    margin: props.margin || 0,
  }),
});

/**
 * @return {null|JSX}
 */
function ClearOptions(props) {
  const { text, onClick, visible, margin } = props;
  const classes = useStyles({ margin });

  if(!visible) {
    return null;
  }

  return (
    <Typography className={classes.clearOptions} onClick={onClick}>
      {text}
    </Typography>
  );
}

ClearOptions.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ClearOptions;