import React from 'react';
import OvalButton from './OvalButton';
import { colors } from '../../styles';

export default function NavyButton (props) {
  return (
    <OvalButton
      border='none'
      backgroundcolor={colors.navy}
      color={colors.grey_text_color}
      width={props.width}
      height={props.height}
      disabled
    >
      {props.children}
    </OvalButton>
  );
}
