import {useEffect, useState} from 'react';
import nasmApi from '../../api/endpoints';

export default function useFetchOhsaResults(clientUserId) {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [overActiveMuscles, setOverActiveMuscles] = useState([]);
  const [underActiveMuscles, setUnderActiveMuscles] = useState([]);
  const [correctiveExercises, setCorrectiveExercises] = useState([]);
  const [assessmentDate, setAssessmentDate] = useState('');

  useEffect(() => {
    setLoading(true);
    nasmApi.users.getOhsaResults(clientUserId).then((resp) => {
      const results = resp.result;

      // Backend returns an empty array when no results are recorded
      if(!(results instanceof Array)) {
        if(!!results.corrective_exercises) {
          setCorrectiveExercises(results.corrective_exercises);
        }

        if(!!results.over_active_muscles) {
          setOverActiveMuscles(results.over_active_muscles);
        }

        if(!!results.under_active_muscles) {
          setUnderActiveMuscles(results.under_active_muscles);
        }

        if(!!results.assessment_date) {
          setAssessmentDate(results.assessment_date);
        }
      }

      setError(false);
    }).catch(err => {
        setError(true);
    }).finally(() => setLoading(false));
  }, [clientUserId]);

  return {
    loading,
    error,
    overActiveMuscles,
    underActiveMuscles,
    assessmentDate,
    correctiveExercises,
  };
}