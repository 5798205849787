import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import DashboardNavigator from './DashboardNavigator';
import { ClientsProvider } from '../../contexts/ClientsContext';

export default function Dashboard (props) {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/cc-dashboard`} />
        </Route>
        <Route path={`${path}/cc-dashboard`}>
          <ClientsProvider>
            <DashboardNavigator />
          </ClientsProvider>
        </Route>
        {/* Redirect to my dashboard if path subsection is invalid */}
        <Route path={`${path}/*`}>
          <Redirect to={`${path}/cc-dashboard`} />
        </Route>
      </Switch>
    </>
  );
}
