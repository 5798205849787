// Used to control which buttons are visible to the end-user
// for the WorkoutSectionsPanel component

export function initReducer () {
  return {
    isAutoSaveEnabled: true,
    showAddExercisesButton: true,
    showSaveButton: false,
    showCopyButton: false,
    showAddButton: false,
    showScheduleButton: false,
  };
}

export function initScheduleReducer () {
  return {
    isAutoSaveEnabled: false,
    showScheduleButton: true,
    showSaveButton: false,
    showCopyButton: false,
    showAddButton: false,
    showAddExercisesButton: false,
  };
}

export const WORKOUT_BUTTONS_VISIBILITY_ACTIONS = Object.freeze({
  UPDATE_BUTTON_VISIBILITY_FLAGS: 'updateButtonVisibilityFlags',
  RESET_FLAGS: 'resetFlags',
  RESET_FLAGS_WITH_SCHEDULE: 'resetFlagsWithSchedule',
});

export function workoutButtonsVisibilityReducer (state, action) {
  const ACTIONS = Object.freeze(WORKOUT_BUTTONS_VISIBILITY_ACTIONS);

  if (action.type === ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS) {
    const {
      showCopyButton,
      showSaveButton,
      showAddExercisesButton,
      showAddButton,
      showScheduleButton,
    } = action.payload;

    return {
      showSaveButton,
      showCopyButton,
      showAddExercisesButton,
      showAddButton,
      showScheduleButton: !!showScheduleButton,
      isAutoSaveEnabled:
        showSaveButton === false &&
        showCopyButton === false &&
        !!showScheduleButton === false,
    };
  } else if (action.type === ACTIONS.RESET_FLAGS) {
    return {
      isAutoSaveEnabled: true,
      showAddExercisesButton: true,
      showSaveButton: false,
      showCopyButton: false,
      showAddButton: false,
      showScheduleButton: false,
    };
  } else if (action.type === ACTIONS.RESET_FLAGS_WITH_SCHEDULE) {
    return {
      isAutoSaveEnabled: false,
      showScheduleButton: true,
      showSaveButton: false,
      showCopyButton: false,
      showAddButton: false,
      showAddExercisesButton: false,
    };
  }

  return { ...state };
}
