import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import querystring from "query-string";

import { PageHeader, PageToolBar } from "../../components";
import PanelContainer from "../../components/Panels/PanelContainer";
import { FloatingCircleButton } from "../../components/Buttons";
import { colors } from "../../styles";
import { updateQuery } from "../../util/utilFunctions";
import ClientBreadcrumbs from "../../components/ClientDashboard/ClientBreadcrumbs";
import ScheduleDialog from "../../components/SchedulePopover/ScheduleDialog";

import ProgramListPanel from "../../components/Panels/ProgramListPanel";
import ProgramDetailsPanel from "../../components/Panels/ProgramDetailsPanel";
import WorkoutSectionsPanel from "../../components/Panels/WorkoutSectionsPanel";

import {
  initReducer,
  WORKOUT_BUTTONS_VISIBILITY_ACTIONS,
  workoutButtonsVisibilityReducer,
} from "../../reducers/local/workoutButtonsVisibilityReducer";
import ExerciseDetailsPanel from "../../components/Panels/ExerciseDetailsPanel";
import {
  clearProgram,
  scheduleProgram,
} from "../../reducers/selectedProgramReducer";
import { clearWorkout } from "../../reducers/selectedWorkoutReducer";
import { scheduleProgramContext } from "../../reducers/programContextReducer";
import AddSuperSetsPanel from "../../components/Panels/AddSuperSetsPanel";
import AddCircuitsPanel from "../../components/Panels/AddCircuitsPanel";
import SwapExercisePanel from "../../components/Panels/SwapExercisePanel";

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
  },
});

// Breadcrumbs for queries
const queryMap = {
  programDetails: ["Program Details"],
  workoutDetails: ["Program Details", "Preview Workout"],
  viewExercise: ["Program Details", "Preview Workout", "Preview Exercise"],
  superSetDetails: [],
  superSetExerciseDetails: [],
  circuitDetails: [],
  circuitExerciseDetails: [],
  progressionRegressionExDetails: [],
  swapPage: [],
};

function ScheduleProgram(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pathMap, sectionIds, selectedDate } = props;

  const query = querystring.parse(history.location.search, {
    parseBooleans: true,
    parseNumbers: true,
  });
  const view = query?.view || "programList";
  const { programId } = query;

  const client = useSelector((state) => state.selectedClient?.client ?? {});
  const group = useSelector((state) => state.selectedGroup?.group ?? {});
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const clientFirstName = client?.first_name?.toLowerCase() ?? "client";
  const groupId = group?.id;
  const groupName = group?.title;
  const clientFirstNameUpper =
    clientFirstName?.charAt(0)?.toUpperCase() + clientFirstName?.slice(1) ??
    "Client";
  const groupNameUpper = groupName
    ? groupName?.charAt(0)?.toUpperCase() + groupName?.slice(1) ?? "Group"
    : null;

  let rootPath = "/clients/my-clients";

  if (groupName) {
    rootPath = "/groups/my-groups";
  }

  const [workoutButtonsVisibilityFlags, localDispatch] = useReducer(
    workoutButtonsVisibilityReducer,
    {},
    initReducer,
  );

  function updateButtonVisibilityFlags({
    isCopyButtonVisible,
    isSaveButtonVisible,
    isAddExercisesButtonVisible,
    isAddButtonVisible,
  }) {
    localDispatch({
      type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS,
      payload: {
        showCopyButton: isCopyButtonVisible,
        showSaveButton: isSaveButtonVisible,
        showAddExercisesButton: isAddExercisesButtonVisible,
        showAddButton: isAddButtonVisible,
      },
    });
  }

  const clearSelectedSchedule = useCallback(() => {
    dispatch(clearProgram());
    dispatch(clearWorkout());
  }, [dispatch]);

  useEffect(() => {
    updateButtonVisibilityFlags({
      isCopyButtonVisible: false,
      isSaveButtonVisible: false,
      isAddExercisesButtonVisible: false,
      isAddButtonVisible: false,
    });
  }, [history.location]);

  useEffect(() => {
    dispatch(scheduleProgramContext());
  }, [dispatch]);

  const views = {
    programList: {
      key: "programList",
      programList: true,
    },
    programDetails: {
      key: "programDetails",
      programList: true,
      programDetails: true,
    },
    workoutDetails: {
      key: "workoutDetails",
      workoutDetails: true,
      programDetails: true,
    },
    viewExercise: {
      key: "viewExercise",
      viewExercise: true,
      workoutDetails: true,
    },
    superSetDetails: {
      key: 'superSetDetails',
      workoutDetails: true,
      superSetDetails: true,
    },
    superSetExerciseDetails: {
      key: 'superSetExerciseDetails',
      superSetDetails: true,
      viewExercise: true,
    },
    circuitDetails: {
      key: 'circuitDetails',
      workoutDetails: true,
      circuitDetails: true,
    },
    circuitExerciseDetails: {
      key: 'circuitExerciseDetails',
      circuitDetails: true,
      viewExercise: true,
    },
    progressionRegressionExDetails: {
      key: 'progressionRegressionExDetails',
      progressionRegressionExDetails: true,
      viewExercise: true,
      workoutDetails: true,
    },
    swapPage: {
      key: 'swapPage',
      viewExercise: true,
      swapPage: true,
    },
  };

  const [selectedProgramIndex, setSelectedProgramIndex] = useState(-1);
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});

  const resetProgramIndex = () => {
    setSelectedProgramIndex(-1);
  };

  const getProgramUrl = (programId) => {
    const query = updateQuery(history.location.search, {
      view: views.programDetails.key,
      programId,
    });
    return `${history.location.pathname}?${query}`;
  };

  const getWorkoutUrlParams = (workoutId) => {
    const query = updateQuery(history.location.search, {
      view: views.workoutDetails.key,
      workoutId: workoutId,
    });
    return {
      pathname: history.location.pathname,
      search: query,
    };
  };

  const viewWorkoutDetailsLink = (workoutId) => {
    return updateQuery(history.location.search, {
      view: views.workoutDetails.key,
      exerciseId: undefined,
      workoutId,
    });
  };

  const viewExerciseLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(history.location.search, {
      view: views.viewExercise.key,
      exerciseId,
      exerciseKey,
      showProgressionsRegressions,
    });
  };

  const viewSuperSetsLink = () => {
    return updateQuery(history.location.search, { view: views.superSetDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(history.location.search, { 
      view: views.superSetExerciseDetails.key,
      exerciseId, 
      exerciseKey, 
      showProgressionsRegressions,
    });
  };

  const viewCircuitLink = () => {
    return updateQuery(history.location.search, { view: views.circuitDetails.key });
  };

  const viewCircuitExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(history.location.search, { 
      view: views.circuitExerciseDetails.key,
      exerciseId, 
      exerciseKey, 
      showProgressionsRegressions,
    });
  };

  const viewProgressionRegressionExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    if(views[view].superSetExerciseDetails){
      views.progressionRegressionExDetails.superSetDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else if (views[view].circuitExerciseDetails) {
      views.progressionRegressionExDetails.circuitDetails = true;
      views.progressionRegressionExDetails.superSetDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else {
      views.progressionRegressionExDetails.workoutDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.superSetDetails = false;
    }
    return updateQuery(history.location.search, {
      view: views.progressionRegressionExDetails.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewSwapPageLink = (exerciseId) => {
    return updateQuery(history.location.search, {
      view: views.swapPage.key,
      exerciseId,
    });
  };

  const navigateBackToCalendar = (firstName, startDate) => {
    if (firstName) {
      const state = { selectedDate: startDate };
      const routeEnd = !groupId ? '/calendar' : '';
      history.replace(
        `${rootPath}/${firstName.toLowerCase()}/dashboard${routeEnd}`,
        state,
      );
    } else {
      // Fallback to navigating to trainer's client/group list
      history.replace(`${rootPath}`);
    }
  };

  const onClickSchedule = () => {
    setIsScheduleDialogOpen(true);
  };

  const onCloseSchedule = useCallback(() => {
    setIsScheduleDialogOpen(false);
  }, []);

  const onFail = useCallback(
    (errorMessage) => {
      window.alert(errorMessage);
      onCloseSchedule();
    },
    [onCloseSchedule],
  );

  const onScheduleRepeat = async (startDate, endDate) => {
    try {
      await dispatch(scheduleProgram(startDate, endDate));
      navigateBackToCalendar(groupId ?? clientFirstName, startDate);
    } catch (e) {
      onFail(e.message);
    } finally {
      onCloseSchedule();
      clearSelectedSchedule();
    }
  };

  return (
    <Box className={classes.root}>
      <PageHeader
        title={`Choose a program for ${groupNameUpper ?? clientFirstNameUpper}`}
        color={colors.graphite}
      />
      <PageToolBar
        linkOptions={[
          {
            label: "My Programs",
            to: `${rootPath}/${groupId ?? clientFirstName}/dashboard/calendar/schedule-programs/my-programs`,
          },
          {
            label: "NASM Programs",
            to: `${rootPath}/${groupId ?? clientFirstName}/dashboard/calendar/schedule-programs/nasm-programs`,
          },
        ]}
        color={colors.graphite}
      />
      <FloatingCircleButton disabled />
      <ClientBreadcrumbs
        paddingTop={21}
        paddingLeft="8%"
        ariaLabel={`${groupName ? "group" : "client"}-schedule-breadcrumbs`}
        pathMap={pathMap}
        queryMap={queryMap}
        view={view === "programList" ? "" : view}
        pathNamesExclusionList={[groupId ? "groups" : "clients", groupId ?? clientFirstName.toLowerCase()]}
      />
      <PanelContainer>
        <ProgramListPanel
          visible={!!views[view].programList}
          getProgramUrl={getProgramUrl}
          selectedProgramIndex={selectedProgramIndex}
          setSelectedProgramIndex={setSelectedProgramIndex}
          resetProgramIndex={resetProgramIndex}
          showDuplicateDeleteButtons={false}
          ftuBtnHidden
        />
        <ProgramDetailsPanel
          visible={!!views[view].programDetails}
          programId={programId}
          getWorkoutUrlParams={getWorkoutUrlParams}
          onClickSchedule={onClickSchedule}
        />
        <WorkoutSectionsPanel
          visible={!!views[view].workoutDetails}
          workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          backLink={getProgramUrl}
          addExercisesListLink={() => {}}
          isDragAndDropEnabled={false}
          sectionIds={sectionIds}
          viewExerciseLink={viewExerciseLink}
          viewSuperSetsLink={viewSuperSetsLink}
          setSuperSetData={setSuperSetData}
          viewCircuitLink={viewCircuitLink}
          setCircuitsData={setCircuitsData}
        />
        <AddSuperSetsPanel
          visible={!!views[view].superSetDetails}
          backLink={viewWorkoutDetailsLink}
          superSetData={superSetData}
          setSuperSetData={setSuperSetData}
          viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
        />
        <AddCircuitsPanel
          visible={!!views[view].circuitDetails}
          backLink={viewWorkoutDetailsLink}
          circuitData={circuitData}
          setCircuitsData={setCircuitsData}
          viewCircuitExDetailsLink={viewCircuitExDetailsLink}
        />
        <ExerciseDetailsPanel
          visible={!!views[view].viewExercise}
          resetSelection={() => {}}
          isAutoSaveEnabled={workoutButtonsVisibilityFlags.isAutoSaveEnabled}
          isScheduling
          backLink={
            views[view].superSetDetails ? viewSuperSetsLink 
            : views[view].circuitDetails ? viewCircuitLink 
            : viewWorkoutDetailsLink 
            }
          viewExerciseDetailsLink={
            views[view].superSetDetails ? viewSuperSetExDetailsLink 
            : views[view].circuitDetails ? viewCircuitExDetailsLink 
            : viewProgressionRegressionExDetailsLink}
            viewSwapPageLink={viewSwapPageLink}
          />
          <SwapExercisePanel
            visible={!!views[view].swapPage}
            backLink={viewExerciseLink}
          />
        <ScheduleDialog
          backdropFilterVisible
          open={isScheduleDialogOpen}
          workouts={Object.values(selectedProgram?.entities?.workouts ?? {})}
          onClose={onCloseSchedule}
          onScheduleOnce={async () => {}}
          onScheduleRepeat={onScheduleRepeat}
          onPrepareWorkouts={() => {}}
          disableSaveButton={false}
          onlyRepeat
          selectedDate={selectedDate}
        />
        <Switch>
          <Route
            exact
            to={`${rootPath}/${groupId ?? clientFirstName}/dashboard/calendar/schedule-programs`}
          >
            <Redirect
              to={`${rootPath}/${groupId ?? clientFirstName}/dashboard/calendar/schedule-programs/my-programs`}
            />
          </Route>
        </Switch>
      </PanelContainer>
    </Box>
  );
}

export default ScheduleProgram;
