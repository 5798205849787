import React, {useReducer, useContext, useCallback} from 'react';
import nasmApi from '../api/endpoints';

const defaultState = {
  loading: true,
  error: null,
  allClients: [],
  activeClients: [],
  filteredClients: [],
  areNoClientsAdded: false,
};

const ACTIONS = Object.freeze({
  UPDATE_CLIENTS: 'update_clients',
  FILTER_CLIENTS: 'filter_clients',
  ERROR: 'error',
  LOADING: 'loading',
});

const ClientsContext = React.createContext(null);

function reducer (state, action) {
  const payload = action.payload;

  const getActiveClients = newClients => newClients.filter(c => !!c.client_user.active_under_current_trainer);

  if(action.type === ACTIONS.UPDATE_CLIENTS) {
    const { newClients } = payload;
    return {
      loading: false,
      error: null,
      allClients: newClients,
      activeClients: getActiveClients(newClients),
      filteredClients: newClients,
    };
  }

  if(action.type === ACTIONS.FILTER_CLIENTS) {
    const {searchText} = payload;
    return {
      ...state,
      filteredClients: state.allClients.filter(c => {
        return c.first_name.toLowerCase().includes(searchText);
      }),
    };
  }

  if(action.type === ACTIONS.ERROR) {
    const {error} = payload;
    return {
      ...state,
      loading: false,
      error: error,
    };
  }

  if(action.type === ACTIONS.LOADING) {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }

  return { ...state };
}

function ClientsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState, () => defaultState);

  // pass in function calls in value as well as all the state values
  return (
    <ClientsContext.Provider value={[state, dispatch]}>
      {children}
    </ClientsContext.Provider>
  );
}

function useClientsContext () {
  const [state, dispatch] = useContext(ClientsContext);

  const onReceiveClients = useCallback((newClients) => {
    dispatch({
      type: ACTIONS.UPDATE_CLIENTS,
      payload: { newClients: newClients },
    });
  }, [dispatch]);

  const onError = useCallback((e) => {
    dispatch({
      type: ACTIONS.ERROR,
      payload: {error: e},
    });
  }, [dispatch]);

  const fetchClients = useCallback((clubId, locationId) => {
    dispatch({ type: ACTIONS.LOADING });
    nasmApi.trainer.getClients(clubId, locationId)
      .then(response => onReceiveClients(response.result || []))
      .catch(e => onError(e));
  }, [dispatch, onReceiveClients, onError]);

  const deactivateClients = useCallback(() => {
    dispatch({ type: ACTIONS.LOADING });
    nasmApi.trainer.deactivateAllClients()
      .then(response => onReceiveClients(response.result || []))
      .catch(e => onError(e));
  }, [dispatch, onReceiveClients, onError]);

  // eslint-disable-next-line camelcase
  const activateClients = useCallback(({ club_id, location_id }) =>{
    dispatch({ type: ACTIONS.LOADING });
    nasmApi.trainer.reactivateClients({ club_id, location_id })
      .then(response => onReceiveClients(response.result || []))
      .catch(e => onError(e));
  }, [dispatch, onReceiveClients, onError]);

  const activateClient = useCallback(({ userId, club_id, location_id }) => {
    dispatch({type: ACTIONS.LOADING});
    nasmApi.trainer.reactivateClients({ activateAll: false, userId, club_id, location_id })
      .then(response => onReceiveClients(response.result || []))
      .catch(e => onError(e));
  }, [dispatch, onReceiveClients, onError]);

  const findClients = useCallback((searchText) => {
    dispatch({
      type: ACTIONS.FILTER_CLIENTS,
      payload: {searchText: searchText.toLowerCase()},
    });
  }, [dispatch]);

  return {
    ...state,
    fetchClients,
    deactivateClients,
    activateClients,
    activateClient,
    findClients,
  };
}

export {
  ClientsProvider,
  useClientsContext,
  ACTIONS,
};