import { createSlice } from '@reduxjs/toolkit';
import nasmApi from '../api/endpoints';
import { v4 as uuidv4 } from 'uuid';
import { normalize, schema, denormalize } from 'normalizr';
import { actions as workoutActions } from './workoutsReducer';
import { actions as selectedProgramActions, saveProgram } from './selectedProgramReducer';
import { formatSuperSets, unformatSuperSets } from "../util/programUtils";

import moment from 'moment';
import { programContexts } from './programContextReducer';

const initialState = {
  loading: false,
  error: false,
  workoutChanged: false,
  duplicate: false,
  editable: false,
  workout: {
    uploaded_media: {},
  },
  entities: {
    sections: {},
    exercises: {},
  },
};

export const selectedWorkoutSlice = createSlice({
  name: 'selectedWorkout',
  initialState,
  reducers: {
    // -----
    // ERROR RECEIVED
    workoutErrorReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response.error;
      }
    },
    // -----
    // GET WORKOUT DETAILS
    workoutDetailsRequested: (state, action) => {
      const { id } = action.payload;
      return {
        ...initialState,
        loading: true,
        id: id,
      };
    },
    workoutDetailsReceived: (state, action) => {
      const { workout, editable } = action.payload;
      state.loading = false;
      state.editable = editable;
      const [newWorkout] = formatSuperSets([workout]);
      const keyedWorkout = addExerciseKeys(newWorkout);
      const { result, entities } = normalize(keyedWorkout, workoutSchema);
      state.workoutChanged = false;
      // Default to the scheduled workout name (NE-2399)
      result.name = result.workout_name || result.name;
      state.workout = result;
      state.entities = {
        sections: entities.sections || {},
        exercises: entities.exercises || {},
      };
    },
    // -----
    // SAVE WORKOUT
    saveWorkoutRequested: (state, action) => {
      state.loading = true;
      state.workout.name = action.payload.name;
    },
    saveWorkoutReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response.error;
      } else {
        state.workoutChanged = false;

        state.workout = {
          // Start with current state
          ...state.workout,
          // Add any updated base properties from the API
          ...response.result,
          // We want to overwrite API sections to keep our normalized sections
          sections: state.workout.sections,
        };
      }
    },
    // -----
    // LOCAL STATE UPDATES
    editWorkout: (state, action) => {
      const keyedWorkout = addExerciseKeys(action.paylod);
      state.workout = keyedWorkout;
      state.workoutChanged = true;
    },
    editWorkoutName: (state, action) => {
      const name = action.payload;
      state.workout.name = name;
      state.workoutChanged = true;
    },
    editScheduledWorkoutName: (state, action) => {
      const name = action.payload;
      state.workout.workout_name = name;
    },
    editWorkoutDescription: (state, action) => {
      const description = action.payload;
      state.workout.description = description;
    },
    addWorkoutMedia: (state, action) => {
      const mediaId = action.payload;
      state.workout.uploaded_media_id = mediaId;
      // Video workouts don't have exercise sections
      state.workout.sections = [];
    },
    addExercises: (state, action) => {
      const { exercises, sectionId } = action.payload;
      exercises.forEach(exercise => {
        const key = uuidv4();
        state.entities.exercises[key] = { key, ...exercise };
        state.entities.sections[sectionId].exercises.push(key);
      });
      state.workoutChanged = true;
    },
    addExerciseToSection: (state, action) => {
      const { exercise } = action.payload;
      let sectionId = action.payload?.sectionId;

      if (!sectionId) {
        // Locate and use Warm-Up as default section to put exercise under
        const workoutSections = state?.entities?.sections ?? {};
        const defaultSection = Object.values(workoutSections).find(section => section.name === 'Warm-Up');
        sectionId = defaultSection?.id;
        if (!sectionId) {
          throw new Error('addExercises: SectionId is required');
        }
      }

      const key = uuidv4();
      state.entities.exercises[key] = { key, ...exercise };
      state.entities.sections[sectionId].exercises.push(key);
    },
    dragExerciseToWorkout: (state, action) => {
      const { exercise, sectionId, destinationIndex } = action.payload;
      const key = uuidv4();
      state.entities.exercises[key] = { key, ...exercise };
      state.entities.sections[sectionId].exercises.splice(destinationIndex, 0, key);
      state.workoutChanged = true;
    },
    reorderExercise: (state, action) => {
      const { srcSectionId, destSectionId, srcIndex, destIndex } = action.payload;
      const [removedExerciseKey] = state.entities.sections[srcSectionId].exercises.splice(srcIndex, 1);
      state.entities.sections[destSectionId].exercises.splice(destIndex, 0, removedExerciseKey);
      state.workoutChanged = true;
    },
    editExercise: (state, action) => {
      const exercise = action.payload;
      state.entities.exercises[exercise.key] = exercise;
      state.workoutChanged = true;
    },
    removeExercise: (state, action) => {
      const { exercise, sectionId, flagChanges = true } = action.payload;
      const { exercises } = state.entities.sections[sectionId];
      const index = exercises.findIndex(item => item === exercise.key);
      exercises.splice(index, 1);
      delete state.entities.exercises[exercise.key];
      if (flagChanges) {
        state.workoutChanged = true;
      }
    },
    sortExercises: (state, action) => {
      const newSections = action.payload;
      newSections.forEach(newSection => {
        state.entities.sections[newSection.id].exercises = newSection.exercises;
      });
      state.workoutChanged = true;
    },
    clearWorkout: () => initialState,
    createNewWorkout: (state, action) => {
      const { result, entities } = normalize(action.payload, workoutSchema);
      state.editable = true;
      state.workout = result;
      state.entities = {
        sections: entities.sections || {},
        exercises: entities.exercises || {},
      };
    },
    duplicateWorkout: (state) => {
      state.duplicate = true;
      state.editable = true;
      state.workoutChanged = true;
      state.workout.name = `${state.workout.name} copy`;
    },
    deselectExercise: (state, action) => {
      const { exercise, sectionId} = action.payload;
      if(Object.keys(state.entities.sections).length !== 0 && Object.keys(state.entities.exercises).length !== 0){
        const { exercises } = state.entities.sections[sectionId];
        const exerciseKey = Object.keys(exercise)[0];
        state.entities.sections[sectionId].exercises = exercises.filter(key => key !== exerciseKey);
        // Remove exercise from exercises object
        delete state.entities.exercises[exerciseKey];
      }
    },
  },
  extraReducers: {
    [selectedProgramActions.assignExercises]: (state, action) => {
      const { result, entities } = normalize(action.payload, workoutSchema);
      state.workout = result;
      state.entities = {
        sections: entities.sections || {},
        exercises: entities.exercises || {},
      };
    },
    // Can be used to ensure WorkoutSectionsPanel displays the sections and exercise placeholder rows
    // [workoutActions.workoutSectionsReceived]: (state, action) => {
    //   const sections = action.payload;
    //   const blankSections = sections.map(section => ({ ...section, exercises: [] }));
    //   state.entities.sections = blankSections;
    // },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = selectedWorkoutSlice;
// Extract and export each action creator by name
export const {
  editWorkout,
  editWorkoutName,
  removeExercise,
  sortExercises,
  clearWorkout,
  duplicateWorkout,
  dragExerciseToWorkout,
  reorderExercise,
  addExerciseToSection,
  editExercise: editExerciseNoAutoSave,
  editScheduledWorkoutName,
  editWorkoutDescription,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

// *****
// ACTION CREATORS
// *****

// -----
// SELECT WORKOUT
export const selectWorkout = (workout, editable = false, fetch = true) => async (dispatch, getState) => {
  const { id, key } = workout;
  const { selectedProgram, programContext, currentUser } = getState();
  const { SCHEDULING, RESCHEDULING } = programContexts;

  // This is a special case for quick-add workouts which must be plucked from schedule data
  if (!fetch) {
    dispatch(actions.workoutDetailsReceived({ workout, editable }));
    return Promise.resolve(workout);
  }

  // See if this workout already exists as part of a currently selected program
  const programWorkouts = selectedProgram?.entities?.workouts ?? {};
  if (key && programWorkouts[key]) {
    const isEditable = (
      programContext === SCHEDULING ||
      programContext === RESCHEDULING ||
      selectedProgram.editable
    );
    dispatch(actions.workoutDetailsReceived({ workout: programWorkouts[key], editable: isEditable }));
    return;
  }

  // Else fetch the workout
  dispatch(actions.workoutDetailsRequested({ id }));
  const response = await nasmApi.nasmWorkouts.getWorkout(id)
    .catch(error => ({ error: error.message }));
  if (response.error) {
    dispatch(actions.workoutErrorReceived(response));
  } else {
    dispatch(actions.workoutDetailsReceived({
      workout: response.result,
      // eslint-disable-next-line camelcase
      editable: !!response?.result?.owner_id && response.result.owner_id === currentUser.id,
    }));

    return response.result;
  }
};
// -----
// DUPLICATE WORKOUT
export const fetchAndCopyWorkout = (id) => async (dispatch) => {
  dispatch(actions.workoutDetailsRequested(id));
  const response = await nasmApi.nasmWorkouts.getWorkout(id)
    .catch(error => ({ error: error.message }));
  if (response.error) {
    dispatch(actions.workoutErrorReceived(response.error));
    return Promise.reject(response.error);
  }

  dispatch(actions.workoutDetailsReceived({ workout: response.result }));
  dispatch(actions.duplicateWorkout());
  return Promise.resolve(response);
};
// -----
// CREATE WORKOUT
export const createNewWorkout = () => (dispatch, getState) => {
  const { defaultSections } = getState().workouts;
  const blankSections = defaultSections.map(section => ({ ...section, exercises: [] }));
  const workout = {
    name: '',
    sections: blankSections,
  };
  dispatch(actions.createNewWorkout(workout));
};
// -----
// SAVE WORKOUT
export const saveWorkout = () => async (dispatch, getState) => {
  const { programContext, selectedProgram, selectedWorkout } = getState();
  const { workout, entities, duplicate, loading } = selectedWorkout;
  const { SCHEDULING, RESCHEDULING } = programContexts;
  const normalizedWorkout = denormalize(workout, workoutSchema, entities);
  const [newWorkout] = unformatSuperSets([normalizedWorkout]);

  // If the workout is part of program being edited, update the local program copy
  if (selectedProgram.program) {
    // Find the key on the parent workout if it doesn't exist
    if (!newWorkout.key) {
      const key = Object.values(selectedProgram.entities.workouts)
        .find(workout => workout.id === newWorkout.id)?.key;
      if (!key) throw new Error('Current program does not have a matching workout');
      newWorkout.key = key || uuidv4();
    }
    dispatch(selectedProgramActions.editWorkout(newWorkout));
  }

  // Program can't be saved until a schedule is created
  if (programContext.context === SCHEDULING) {
    dispatch(actions.saveWorkoutReceived({ error: false }));
    return;
  }

  // When editing a schedule, we can update the schedule after every workout edit
  if (programContext.context === RESCHEDULING) {
    dispatch(actions.saveWorkoutReceived({ error: false }));
    if (selectedProgram.program.name === null || selectedProgram.program.name === undefined) {
      // is quick add workout
      // save program as well
      if (workout.days_of_week) {
        const days = workout.days_of_week.map(day => day.toString());
        dispatch(selectedProgramActions.setWorkoutDays({ workoutKey: selectedProgram.program.workouts[0], days }));
      }
    }
    return dispatch(saveProgram());
  }

  // Else make an API call to udpate the workout directly
  if (loading) return;
  if (!newWorkout.name) {
    newWorkout.name = 'New Workout ' + moment().format('MM-DD');
  }
  // TODO: saveWorkout requested/received seems redundant to update/create here
  dispatch(actions.saveWorkoutRequested(newWorkout));
  if (workout.id && !duplicate) {
    dispatch(workoutActions.updateWorkoutRequested(newWorkout));
    // Remove uploaded_media object from request
    delete newWorkout.uploaded_media;
    const response = await nasmApi.nasmWorkouts.updateWorkout(newWorkout.id, newWorkout);
    // .catch(error => ({ error: error.message }));

    dispatch(workoutActions.updateWorkoutReceived(response));
    dispatch(actions.saveWorkoutReceived(response));
    if (response.error) throw new Error(response.error);
    return response.result;
  } else {
    // Remove uploaded_media object from request
    delete newWorkout.uploaded_media;
    dispatch(workoutActions.createWorkoutRequested(newWorkout));
    const response = await nasmApi.nasmWorkouts.createWorkout(newWorkout)
      .catch(error => ({ error: error.message }));
    dispatch(workoutActions.createWorkoutReceived(response));
    dispatch(actions.saveWorkoutReceived(response));
    if (response.error) throw new Error(response.error);
    return response.result;
  }
};

export const saveVideoWorkout = (mediaId) => async (dispatch) => {
  // Add media ID to workout, then save workout
  dispatch(actions.addWorkoutMedia(mediaId));
  return (dispatch(saveWorkout()));
};

// -----
// AUTO SAVE ACTIONS
export const addExercises = ({ exercises, sectionId }) => async (dispatch, getState) => {
  if (!exercises || (Array.isArray(exercises) && exercises.length < 1)) {
    throw new Error('addExercises: No exercises were provided');
  }
  const state = getState();
  const workoutSections = state.selectedWorkout.entities.sections;
  const defaultSection = Object.values(workoutSections).find(section => section.name === 'Warm-Up');
  const id = sectionId || defaultSection?.id;
  if (!id) {
    throw new Error('addExercises: SectionId is required');
  }

  dispatch(actions.addExercises({ exercises, sectionId: id }));
  return dispatch(saveWorkout());
};
export const editExercise = (exercise) => async (dispatch) => {
  dispatch(actions.editExercise(exercise));
  // TODO: need to revisit the auto save when using quick add exercises
  return dispatch(saveWorkout());
};

export const dragExerciseToWorkoutAutoSave = ({ exercise, sectionId, destinationIndex }) => async (dispatch) => {
  dispatch(actions.dragExerciseToWorkout({ exercise, sectionId, destinationIndex }));
  return dispatch(saveWorkout());
};

export const reorderExerciseAutoSave = ({ srcSectionId, destSectionId, srcIndex, destIndex }) => async (dispatch) => {
  dispatch(actions.reorderExercise({ srcSectionId, destSectionId, srcIndex, destIndex }));
  return dispatch(saveWorkout());
};

export const removeExerciseAndSaveWorkout = ({ exercise, sectionId, flagChanges = true }) => async (dispatch) => {
  dispatch(actions.removeExercise({ exercise, sectionId, flagChanges }));
  return dispatch(saveWorkout())
    .catch(e => {
      // undo removal
      dispatch(actions.addExerciseToSection({ exercise, sectionId }));
      throw e;
    });
};

// -----
// Remove selected exercise ACTION

export const deSelectExercise = ({ exercise, sectionId }) => async (dispatch) => {
  dispatch(actions.deselectExercise({ exercise, sectionId }));
  return;
};

// *****
// HELPER FUNCTIONS
// *****
const addExerciseKeys = (workout) => ({
  ...workout,
  sections: workout.sections.map(section => ({
    ...section,
    exercises: (section.exercises || section.scheduled_exercises || []).map(exercise => ({
      ...exercise,
      key: exercise.key ? exercise.key : uuidv4(),
    })),
  }),
  ),
});

export const denormalizeWorkout = (selectedWorkout) => {
  const { workout: workoutKey, entities } = selectedWorkout;
  return denormalize(workoutKey, workoutSchema, entities);
};

// *****
// Normalizr
// *****
const exerciseSchema = new schema.Entity('exercises', {}, { idAttribute: 'key' });
const sectionSchema = new schema.Entity('sections', { exercises: [exerciseSchema] });
const workoutSchema = { sections: [sectionSchema] };
