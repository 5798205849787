import React from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../styles';

const useStyles = makeStyles({
  menu: {
    width: 189,
    height: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
  },
  dropdownHeader: {
    width: 189,
    height: 58,
    backgroundColor: 'rgba(240, 241, 243, 0.41)',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  dropdownTitle: {
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    marginLeft: 20,
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
  },
  popoverPaper: {
    boxShadow: '0 3px 2px 0 rgba(0, 0, 0, 0.38)',
    borderRadius: '6px',
    objectFit: 'contain',
    border: `solid 1px ${colors.selected_highlight_copy_6}`,
  },
  menuPopover: {
    // used to shift menu down
    marginTop: 17,
  },
});

function AddWorkoutMenu (props) {
  const {
    open,
    anchorEl,
    onCreateWorkout = () => {},
    onCreateWorkoutVideo = () => {},
    onCloseMenu = () => {},
  } = props;
  const classes = useStyles();

  const onCloseDropdown = e => {
    onCloseMenu();
  };

  return (
    <Menu
      className={classes.menuPopover}
      MenuListProps={{
        className: classes.menu,
      }}
      PopoverClasses={{
        paper: classes.popoverPaper,
      }}
      keepMounted
      open={open}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      onClose={onCloseDropdown}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 150,
      }}
    >
      <Box className={classes.dropdownHeader}>
        <Typography className={classes.dropdownTitle}>
          Create New
        </Typography>
      </Box>
      <MenuLink
        label='Workout'
        onClick={() => onCreateWorkout()}
      />
      <MenuLink
        label='Workout Video'
        onClick={() => onCreateWorkoutVideo()}
      />
    </Menu>
  );
}

const useMenuLinkStyles = makeStyles({
  menuItem: {
    '&:hover': {
      backgroundColor: colors.light_blue_grey_32,
    },
  },
  menuListText: {
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 2.29,
    color: colors.carbon_black,
  },
  removeLinkDecorations: {
    textDecoration: 'none',
    color: colors.carbon_black,
    '&:hover': {
      color: colors.carbon_black,
    },
  },
});

function MenuLink (props) {
  const { label, onClick } = props;
  const classes = useMenuLinkStyles();
  return (
    <MenuItem className={classes.menuItem} onClick={onClick}>
      <Typography className={classes.menuListText}>
        {label}
      </Typography>
    </MenuItem>
  );
}

AddWorkoutMenu.propTypes = {
  open: PropTypes.bool,
  /***
   * Reference to a DOM element that is used as an anchor point for this menu
   */
  anchorEl: PropTypes.object,
  onCreateWorkout: PropTypes.func,
  onCreateWorkoutVideo: PropTypes.func,
  onCloseMenu: PropTypes.func,
};

export default AddWorkoutMenu;