import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clearPlus from '../../resources/button-create-new-outline-grey-ftu.png';

const useStyles = makeStyles({
  ftuCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
    marginBottom: '20px',
    height: '30%',
  },
  uploadHeader: {
    fontSize: '19px',
    fontWeight: 'bold',
    lineHeight: '39px',
    paddingLeft: 20,
    paddingRight: 20,
  },
  uploadSubheader: {
    fontSize: '17px',
    paddingLeft: 20,
    paddingRight: 20,
  },
  clearPlusBtn: {
    marginTop: '20px',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(60%)',
    },
  },
});

export default function EmptySection (props) {
  const classes = useStyles();
  const { ftuImg, emptyTitle, emptyDescription, onClickPlus, to, ftuBtnHidden } = props;

  const ftuClearBtn = () => {
    if (ftuBtnHidden) {
      return null;
    } else {
      return (
        <Link to={to}>
          <img
            src={clearPlus}
            alt='add-exercises'
            className={classes.clearPlusBtn}
            onClick={onClickPlus}
          />
        </Link>
      );
    }
  };

  return (
    <Box className={classes.ftuCont}>
      <img src={ftuImg} alt='ftu-exercises' />
      <Typography className={classes.uploadHeader}>
        {emptyTitle}
      </Typography>
      <Typography className={classes.uploadSubheader}>
        {emptyDescription}
      </Typography>
      {ftuClearBtn()}
    </Box>
  );
}
