import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, ButtonBase } from '@material-ui/core';

import placeholderIcon from '../../resources/user-dashboard-client-profile-with-no-photo.svg';
import plus from '../../resources/btn-addwhite.svg';
import { ReactComponent as Mail } from '../../resources/icons-global-contact-email-gray.svg';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarPlaceholder: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '123px',
    height: '123px',
    backgroundColor: colors.colors_fill_light_2,
    borderRadius: '61px',
  },
  avatar: {
    position: 'relative',
    width: '123px',
    height: '123px',
    borderRadius: '61px',
    objectFit: 'cover',
  },
  plusIconPosition: {
    position: 'absolute',
    bottom: 1,
    left: 90,
  },
  plusIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.yellow_button_style,
    width: '33px',
    height: '33px',
    borderRadius: '16px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
  emailContainer: {
    display: 'flex',
    marginTop: '15px',
    alignItems: 'center',
  },
  email: {
    fontSize: '13px',
    fontFamily: 'Roboto, sans-serif',
    color: colors.carbon_black,
    textAlign: 'center',
    marginLeft: '6px',
  },
});

function UploadProfilePicture (props) {
  const { avatar, email, onChangeAvatar } = props;

  const classes = useStyles();
  const inputRef = useRef();

  const onAddPhoto = () => {
    inputRef.current.click();
  };

  function renderProfileAvatar () {
    if (!avatar) {
      return (
        <Box className={classes.avatarPlaceholder}>
          <img src={placeholderIcon} alt='profile-icon' className={classes.root} />
          <Box className={classes.plusIconPosition}>
            <Box className={classes.plusIcon}>
              <img src={plus} alt='add' />
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box>
          <img src={avatar} alt='profile-icon' className={classes.avatar} />
          <Box className={classes.plusIconPosition}>
            <Box className={classes.plusIcon}>
              <img src={plus} alt='add' />
            </Box>
          </Box>
        </Box>
      );
    }
  }

  return (
    <Box className={classes.root}>
      <ButtonBase style={{ borderRadius: '32px' }} onClick={onAddPhoto}>
        <input
          type='file'
          onChange={(e) => onChangeAvatar(e.target.files[0])}
          style={{ display: 'none' }}
          ref={inputRef}
          accept='image/png, image/jpeg'
        />
        { renderProfileAvatar() }
      </ButtonBase>
      <Box className={classes.emailContainer}>
        <Mail />
        <Typography className={classes.email}>{email || 'email'}</Typography>
      </Box>
    </Box>
  );
}

export default UploadProfilePicture;
