import { Box, FormControl, InputBase, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ErrorText from './ErrorText';
import { colors } from '../../styles';

const useStyles = makeStyles({
  labelTextRoot: props => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: props.sideMargin ? props.sideMargin : 0,
    marginRight: props.sideMargin ? props.sideMargin : 0,
    flex: props.flex ? props.flex : 1,
    width: props.width ? props.width : 232,
    height: props.height ? props.height : 60,
  }),
  label: props => ({
    fontSize: 16,
    ...props.labelStyles,
  }),
  formControlContainer: props => ({
    border: props.showError && !!props.errorText ? `solid 1px ${colors.yellow_button_style}` : 'solid 1px #d2d7db',
    boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.18)',
    borderRadius: 3,
    height: 40,
    width: props.width ? props.width : 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  }),
  inputStyle: props => ({
    textAlign: 'right',
    fontSize: props.fontSize ? props.fontSize : 16,
    fontFamily: props.fontFamily ? props.fontFamily : 'DMSans, sans-serif',
    // Turns off number spinners ~ may not work on all browsers
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      marginRight: 20,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      marginRight: 20,
    },
  }),
});

function LabelTextInput (props) {
  const classes = useStyles(props);
  const {
    label,
    name,
    placeholder,
    value,
    errorText,
    showError,
    style,
    inputType = 'text',
    // event handlers
    onHandleChange,
    onHandleBlur,
    isDisabled = false,
  } = props;

  return (
    <Box className={classes.labelTextRoot} style={style}>
      <Box className={classes.formControlContainer}>
        <Typography className={classes.label}>
          {label}
        </Typography>
        <FormControl>
          <InputBase
            inputProps={{
              className: classes.inputStyle,
              type: inputType,
            }}
            // Used by Formik to identify which key value pair to change in values
            // when handleChange() function is invoked
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={e => {
              onHandleChange(e);
            }}
            onBlur={e => {
              onHandleBlur(e);
            }}
            disabled={isDisabled}
          />
        </FormControl>
      </Box>
      <ErrorText
        margin='5px 0 0 0'
        textColor={colors.yellow_button_style}
        errorText={errorText}
        showError={showError}
      />
    </Box>
  );
}

LabelTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
  style: PropTypes.object,
  inputType: PropTypes.string,
  labelStyles: PropTypes.object,
  onHandleChange: PropTypes.func.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontFamily: PropTypes.string,
};

export default LabelTextInput;
