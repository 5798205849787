import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../styles';
import { Box, CircularProgress, LinearProgress, Typography } from '@material-ui/core';

import {ReactComponent as CancelButton} from '../../../resources/button-cancel-upload.svg';

const useStyles = makeStyles({
  uploadInProgressView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
  },
  header: {
    fontSize: 24,
    color: colors.white,
  },
  subheader: {
    fontSize: 14,
    color: colors.white,
  },
  cancelHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: 10,
    marginTop: 10,
    cursor: 'pointer',
  },
});

function UploadInProgressView (props) {
  const { canCalculateProgress, progressValue, onCancelMediaUploadRequest } = props;
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.cancelHeader}>
        <Box className={classes.cancelButton}>
          <CancelButton
            onClick={() => {
              if(onCancelMediaUploadRequest) {
                onCancelMediaUploadRequest();
              }
            }}
          />
        </Box>
      </Box>
      <Box className={classes.uploadInProgressView}>
        <Typography className={classes.header}>
          Uploading...
        </Typography>
        <Box style={{ marginTop: 20 }}>
          <LoadingIndicator
            canCalculateProgress={canCalculateProgress}
            progressValue={progressValue}
          />
        </Box>
        <Box style={{ marginTop: 60 }}>
          <Typography className={classes.subheader}>
            You can continue to fill out the details while this is processing
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const useLoadingBarStyle = makeStyles({
  loadingRoot: {
    width: 301,
    height: 9,
  },
  colorPrimary: {
    borderRadius: 13.5,
    border: `1px solid ${colors.steel}`,
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    borderRadius: 13.5,
    backgroundColor: colors.yellow_button_style,
  },
});

function LoadingBar (props) {
  const { value } = props;
  const classes = useLoadingBarStyle();

  return (
    <LinearProgress
      classes={{
        root: classes.loadingRoot,
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
      variant='determinate'
      value={value}
    />
  );
}

function LoadingIndicator (props) {
  const { canCalculateProgress, progressValue } = props;

  // Note: not all browsers support calculating progress and will fallback
  // to use circular progress instead: https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent
  // See ProgressEvent.lengthComputable property which is the same as canCalculateProgress prop
  if (canCalculateProgress) {
    return (
      <LoadingBar value={progressValue} />
    );
  }

  return <CircularProgress color='secondary' />;
}

UploadInProgressView.propTypes = {
  canCalculateProgress: PropTypes.bool.isRequired,
  progressValue: PropTypes.number.isRequired,
  onCancelMediaUploadRequest: PropTypes.func,
};

export default UploadInProgressView;
