import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {Typography} from '@material-ui/core';
import {makeStyles, styled} from '@material-ui/core/styles';
import { colors } from '../../../styles';
import { DAY_COLOR_STATES } from './colors';

import DayNumber from './DayNumber';

const [SELECTED, COMPLETE, INCOMPLETE, UPCOMING, NONE] = DAY_COLOR_STATES;

const DayLabel = styled(Typography)({
  textAlign: 'center',
  textTransform: 'uppercase',
  color: colors.dark_grey,
  fontWeight: 500,
  fontSize: 14,
});

const useStyles = makeStyles({
  calendarWeekStrip: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 2fr)',
    rowGap: 5,
    gridTemplateRows: '50% 25px',
  },
});

// needs to support taking in scheduled program dates
function CalendarWeekStrip (props) {
  const {
    todaysDate,
    selectedDate,
    setSelectedDate,
    scheduledWorkoutsByDate,
  } = props;

  // at least one of the scheduled workouts on the same day
  // need to be marked as complete?
  const [weekDates, setWeekDates] = useState([]);

  useEffect(() => {
    const newWeekDates = [];
    // 0 - sunday
    // 6 - saturday
    for(let d = 0;d < 7; ++d) {
      newWeekDates.push(moment(todaysDate).day(d).startOf('day'));
    }
    setWeekDates(newWeekDates);
  }, [todaysDate]);

  const classes = useStyles();
  return (
    <div className={classes.calendarWeekStrip}>
      <DayLabel>Sun</DayLabel>
      <DayLabel>Mon</DayLabel>
      <DayLabel>Tue</DayLabel>
      <DayLabel>Wed</DayLabel>
      <DayLabel>Thu</DayLabel>
      <DayLabel>Fri</DayLabel>
      <DayLabel>Sat</DayLabel>
      {weekDates.map(date => {
        const dateString = date.format('YYYY-MM-DD');
        // determine what selected state the current date be under
        // to color the day number
        let selectedState = '';
        if(date.isSame(selectedDate, 'day')) {
          selectedState = SELECTED;
        } else if(!!scheduledWorkoutsByDate[dateString] === false) {
          // if no workouts are scheduled for the current date
          selectedState = NONE;
        } else if(date.isSameOrAfter(todaysDate, 'day')) {
          // if upcoming in future or present
          selectedState = UPCOMING;
        } else if(IsAtLeastOneWorkoutIncomplete(scheduledWorkoutsByDate[dateString])) {
          selectedState = INCOMPLETE;
        } else {
          selectedState = COMPLETE;
        }

        return (
          <DayNumber
            key={date.format('YYYY-MM-DD')}
            date={date}
            selectedState={selectedState}
            onClick={() => setSelectedDate(date)}
          />
        );
      })}
    </div>
  );
}

/**
 * @return {boolean}
 */
function IsAtLeastOneWorkoutIncomplete(scheduledWorkouts = []) {
  for(const workout of scheduledWorkouts) {
    if(!!workout.is_complete === false) {
      return true;
    }
  }

  return false;
}

CalendarWeekStrip.propTypes = {
  todaysDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  setSelectedDate: PropTypes.func,
  scheduledWorkoutsByDate: PropTypes.object,
};

export default CalendarWeekStrip;