import { useEffect, useState } from 'react';
import nasmApi from '../api/endpoints';

export default function useFetchFitnessComponentsList () {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fitnessComponents, setFitnessComponents] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    let isMounted = true;

    nasmApi.fitnessComponents.getList().then(data => {
      if (isMounted) {
        setLoading(false);
        setError(false);
        setFitnessComponents(data.result);
      }
    })
      // eslint-disable-next-line handle-callback-err
      .catch(error => {
        if (isMounted) {
          setError(true);
          setLoading(false);
          setFitnessComponents([]);
        }
      });

    return () => { isMounted = false; };
  }, []);

  return { loading, error, fitnessComponents };
}
