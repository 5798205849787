import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { colors } from '../../styles';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import ExerciseDetails from '../Exercises/ExerciseDetails';

const originalContainerHeight = 800;
const useStyles = makeStyles({
  root: {
    height: '90%',
    borderRadius: '6px',
    backgroundColor: colors.white,
    transition: 'height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

function ExerciseDetailsPanel (props) {
  const {
    visible,
    backLink,
    resetSelection = () => {},
    isAutoSaveEnabled,
    isScheduling,
    isRescheduling,
    viewExerciseDetailsLink = () => {},
    viewSwapPageLink = () => {},
  } = props;

  const classes = useStyles();
  const location = useLocation();

  const [exerciseId, setExerciseId] = useState(null);
  const [containerHeight, setContainerHeight] = useState(classes.root.height);

  const queryParams = querystring.parse(location.search);
  const newExerciseId = queryParams.exerciseId || null;
  const exerciseKey = queryParams.exerciseKey || null;

  useEffect(() => {
    setExerciseId(newExerciseId);
  }, [newExerciseId]);

  function onToggleCollapse (e, expanded, accordionDetailsRef) {
    if (expanded) {
      setContainerHeight(
        originalContainerHeight + accordionDetailsRef.current.clientHeight,
      );
    } else {
      setContainerHeight(originalContainerHeight);
    }
  }

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={containerHeight}>
        <FadeTransition visible={visible}>
          <Box className={classes.root}>
            <ExerciseDetails
              exerciseId={exerciseId}
              exerciseKey={exerciseKey}
              isAutoSaveEnabled={isAutoSaveEnabled}
              isScheduling={isScheduling}
              isRescheduling={isRescheduling}
              // Callback functions
              backLink={backLink}
              onToggleCollapse={onToggleCollapse}
              resetSelection={resetSelection}
              hideAddBtn={props.hideAddBtn}
              viewExerciseDetailsLink={viewExerciseDetailsLink}
              viewSwapPageLink={viewSwapPageLink}
            />
          </Box>
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

ExerciseDetailsPanel.propTypes = {
  visible: PropTypes.bool,
  backLink: PropTypes.func,
  resetSelection: PropTypes.func,
  isAutoSaveEnabled: PropTypes.bool,
  isScheduling: PropTypes.bool,
  isRescheduling: PropTypes.bool,
};

export default ExerciseDetailsPanel;
