import React, {useEffect, useState } from "react";
import { Dialog, Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import DialogCircleButton from "./DialogCircleButton";
import ScheduleBanner from "./ScheduleBanner";
import WorkoutsList from "./WorkoutsList";
import SectionDivider from "./SectionDivider";
import moment from "moment";
import nasm from '../../dataManager/apiConfig';

import { colors } from "../../styles";

const useStyles = makeStyles({
  dialogContainer: {
    minWidth: 554,
    minHeight: 645,
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    borderRadius: 17,
    boxShadow: "0 3px 41px 0 rgba(0, 0, 0, 0.22)",
    overflowY: "hidden",
    overflowX: "hidden",
    marginBottom: 0,
  },
});

export default function ViewGroupScheduleDialog(props) {
  const {
    open,
    setOpen,
    selectedScheduleId,
    selectedWorkoutId,
    selectedGroup,
    eventDate,
  } = props;

  const classes = useStyles();
  const [selectedScheduleData, setSelectedScheduleData] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const onClose = () => {
    setSelectedScheduleData({});
    setOpen(false);
  };

  const viewScheduledWorkout = (scheduleId, workoutId, eventDate) => {
    setIsDataLoaded(false);
    const date = moment(eventDate).format();
    nasm.api.getGroupCalendarDaySchedule(
      selectedGroup?.id,
      date,
      selectedProfile?.ClubId,
      selectedProfile?.Locations?.Id,
    ).then((response) => {
        const workouts = response.workouts?.filter((workout) => workout.workout_id === workoutId);
        setSelectedScheduleData(workouts[0]);
      }).catch((error) => {
        setIsError(true);
        alert(error?.message || 'Something went wrong! Please try again later.');
      });
      setIsDataLoaded(true);
  };

  useEffect(() => {
    if(open){
      viewScheduledWorkout(selectedScheduleId, selectedWorkoutId, eventDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open,selectedScheduleId, selectedWorkoutId, eventDate, selectedProfile]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogContainer }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <DialogContent
        selectedScheduleId={selectedScheduleId}
        selectedScheduleData={selectedScheduleData}
        eventDate={eventDate}
        onClose={onClose}
        isDataLoaded={isDataLoaded}
        isError={isError}
      />
    </Dialog>
  );
}

function DialogContent(props) {
  const {
    selectedScheduleData,
    selectedScheduleId,
    eventDate,
    onClose,
    isDataLoaded,
    isError,
  } = props;

  const [scheduledWorkout, setScheduledWorkout] = useState({});
  const [videoWorkoutImage, setVideoWorkoutImage] = useState("");

  useEffect(() => {
    if (selectedScheduleData) {
      setScheduledWorkout(selectedScheduleData ?? {});
      if (
        selectedScheduleData?.workout?.uploaded_media?.thumbnail_url
      ) {
        setVideoWorkoutImage(selectedScheduleData.workout.uploaded_media.thumbnail_url);
      }
    }
  }, [selectedScheduleData]);

  if (!isDataLoaded || Object.keys(selectedScheduleData).length === 0) {
    return <LoadingContainer />;
  }

  if (isError) {
    return <ErrorContainer onClose={onClose} />;
  }

  // eslint-disable-next-line camelcase
  const totalDurSeconds = scheduledWorkout?.total_dur_seconds ?? 0;
  const timeDisplay =
    Math.floor(totalDurSeconds / 60) > 0
      ? `${Math.floor(totalDurSeconds / 60)}+ mins`
      : `${totalDurSeconds} secs`;

  // Remove workout name label if video workout
  // Fallback to workout template name if scheduled workout name not available
  const workoutName = !!selectedScheduleData?.workout?.uploaded_media
    ? ""
    : scheduledWorkout?.workout_name
    ? scheduledWorkout?.workout_name
    : scheduledWorkout?.name ?? "Test Workout Schedule";

  const programCategoryName = selectedScheduleData?.workout?.uploaded_media
    ? "Video Workout"
    : selectedScheduleData.program_category?.label ?? "Lifestyle";

  return (
    <>
      <ScheduleBanner
        workoutName={workoutName}
        programCategoryName={programCategoryName}
        timeDisplay={timeDisplay}
        eventDate={eventDate}
        scheduleId={selectedScheduleId}
        videoWorkoutImage={videoWorkoutImage}
        onClose={onClose}
        isGroup={true}
      />
      {/* This list is empty if a Video Workout */}
      <WorkoutsList sections={scheduledWorkout?.workout?.sections ?? []} />
      <VideoWorkoutContent
        visible={!!scheduledWorkout?.workout?.uploaded_media}
        scheduledWorkout={scheduledWorkout?.workout}
      />
    </>
  );
}

const useLoadingContainerStyle = makeStyles({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "600px",
  },
});

function LoadingContainer() {
  const classes = useLoadingContainerStyle();
  return (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  );
}

const useErrorContainerStyle = makeStyles({
  errorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    marginRight: 20,
  },
  close: {
    fontSize: 18,
  },
  failText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "500px",
    fontSize: 28,
  },
});

function ErrorContainer(props) {
  const { onClose } = props;
  const classes = useErrorContainerStyle();

  return (
    <Box>
      <Box className={classes.errorContainer}>
        <DialogCircleButton onClick={onClose}>
          <Typography className={classes.close}>X</Typography>
        </DialogCircleButton>
      </Box>
      <Typography className={classes.failText}>
        Failed to load schedule data.
      </Typography>
    </Box>
  );
}

const useVideoWorkoutContentStyle = makeStyles({
  videoWorkoutDescription: {
    marginTop: 15,
    marginLeft: 23,
    overflowY: "auto",
  },
});

/**
 * @return {JSX|null}
 */
function VideoWorkoutContent(props) {
  const { visible, scheduledWorkout } = props;

  const classes = useVideoWorkoutContentStyle();

  if (!visible) {
    return null;
  }

  return (
    <Box>
      <SectionDivider
        name={scheduledWorkout.workout_name || scheduledWorkout.name}
        fontColor={colors.black}
      />
      <Typography className={classes.videoWorkoutDescription}>
        {scheduledWorkout.description}
      </Typography>
    </Box>
  );
}
