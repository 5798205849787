export const colors = {
  mediumBlue: '#163963',
  lightBlue: '#245186',
  divider_line_light_blue: 'rgba(182, 189, 195, 0.5)',
  steel: '#7c8084',
  navy: '#06203f',
  divider_line_white: '#f5f5f5',
  yellow_button_style: '#ce8209',
  baby_blue: '#a1ccff',
  light_blue_grey_32: 'rgba(206, 211, 215, 0.33)',
  black: '#000',
  mildGreenWithOpacity: '#f4f7e8',
  mildYellowWithOpacity: '#f7f2e7',
  white: '#fff',
  grey_text_color: '#aeb6c0',
  graphite: '#3e3e3e',
  selected_highlight_copy_6: '#ced3d7',
  light_grey: '#e6e6e6',
  colors_fill_light_2: '#e8eaec',
  cloudy_blue_50: 'rgba(182, 189,195, 0.5)',
  red: '#d82c44',
  colors_fill_light_gray_2: '#b6bdc3',
  backdrop_tint: '#163963',
  confirmDialogBlack: '#16161d',
  colors_fill_dark_yellow_2: '#dc8c00',
  checkbox_grey: '#f3f4f5',
  radio_blue: '#2592ec',
  subtle_grey: 'rgba(240, 241, 243, 0.41)',
  carbon_black: '#16161d',
  lightest_blue: '#97ccff',
  charcoal_grey: '#3f3e3f',
  dark_grey: '#7c8084',
  green: '#2c775c',
  purple: '#812fa3',
  lime_green: '#8eb013',
  perfAssessHeader: '#0f2c50',
  lightest_grey: '#ebedee',
  yellow: '#f6aa2c',
  light_border: '#f0f2f3',
  filter_blue: '#1f4879',
};

// Create the blue backdrop tint for dialog pop ups
export const backdropStyles = {
  backgroundColor: colors.backdrop_tint,
  opacity: 0.35,
};
