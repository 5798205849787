import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Local
import { colors } from '../styles';
import { getDurationFromSeconds } from '../util/programUtils';
import { YellowSwitch } from './Buttons';
import exercisePlaceholder from '../resources/image-exercise-placeholder@2x.png';
import arrowRight from "../resources/icons-arrows-buttons-right-arrow-dark-gray.svg";
import { EXERCISE_TYPE } from '../constants';
import { programContexts } from '../reducers/programContextReducer';
import progressionIcon from '../resources/progressionIcon@2x.png';
import regressionIcon from '../resources/regressionIcon@2x.png';
import { ReactComponent as CheckOff } from '../resources/interaction-toggle-checkmark-off-dark-grey.svg';
import { ReactComponent as CheckOn } from '../resources/interaction-toggle-checkmark-on-big-milestone.svg';
import { editExercise } from '../reducers/selectedWorkoutReducer';

const useStyles = makeStyles({
  exerciseListHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: `1px solid ${colors.divider_line_white}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 20px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 600,
    fontSize: 14,
  },
  boxLayout: (props) => ({
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "0px 20px",
    borderBottom: '1px solid #e6e6e6',
    '&:hover': {
      backgroundColor: props.commingFrom ? 'none' : colors.baby_blue,
      cursor: props.commingFrom ? 'cursor' : 'pointer',
    },
  }),
  thumbnail: {
    borderRadius: 6,
    objectFit: 'cover',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameContainer: {
    paddingLeft: 23,
    fontSize: 17,
    fontWeight: 500,
    color: colors.black,
    marginRight: 10,
  },
  rightArrow: {
    height: "22px",
    width: "22px",
    objectFit: "cover",
  },
  timeText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 12,
    color: colors.colors_fill_light_gray_2,
    width: 40,
  },
  progressionRegressionIcon: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  checkmarkCont: {
    display: 'flex',
    alignItems: 'center',
  },
  checkmark: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '20px',
  },
});

function ProgressionsRegressionsList ({
  exerciseDetails = {},
  viewExerciseDetailsLink,
  comingFrom,
  exeOrCompoundExe,
  selectedExercise,
  setSelectedExercise,
  exerciseKey,
  ...props
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const context = useSelector(state => state.programContext.context);
  const classes = useStyles(props);
  const sections = [];
  const isComingFromSummary = comingFrom === 'SummaryCard';
  const isComingFromSwapPage = comingFrom === 'swap-page';
  const isViewOnly = context === programContexts.LIBRARY || isComingFromSwapPage || isComingFromSummary;

  const {
    progressions_regressions,
    progression_enabled: progressionEnabled = true,
    regression_enabled: regressionEnabled = true,
  } = exerciseDetails;

  const progressionExercises = progressions_regressions?.filter(
    (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
  );
  const regressionExercises = progressions_regressions?.filter(
    (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
  );

  if (progressionExercises?.length) {
    sections.push({
      id: 0,
      title: isViewOnly ? 'Progressions' : 'Make Progressions Available',
      data: progressionExercises,
      isEnabled: progressionEnabled,
      exerciseType: EXERCISE_TYPE.PROGRESSION,
    });
  }

  if (regressionExercises?.length) {
    sections.push({
      id: 1,
      title: isViewOnly ? 'Regressions' : 'Make Regressions Available',
      data: regressionExercises,
      isEnabled: regressionEnabled,
      exerciseType: EXERCISE_TYPE.REGRESSION,
    });
  }

  const onInvalidImg = (e) => {
    e.target.src = exercisePlaceholder;
  };

  const onClickExercise = (id) => {
    if(isComingFromSwapPage){
      return;
    }
    const firstArgument = id;
    const secondArgument = context === programContexts.LIBRARY ? false : exerciseKey;
    const thirdArgument = false;

    history.push({
      pathname: location.pathname,
      search: viewExerciseDetailsLink(firstArgument, secondArgument, thirdArgument),
    });
  };

  const getCheckmarkButton = (exerciseData) => {
    if (
      exerciseData.exerciseType === selectedExercise?.exerciseType
      && exerciseData.exercise.id === selectedExercise?.exercise?.id
    ) {
      return <CheckOn/>;
    }
    return <CheckOff/>;
  };

  const RenderCheckBox = ({exerciseData}) => {
    return (
      <Box 
        className={classes.checkmarkCont} 
        onClick={() => setSelectedExercise(exerciseData)}
      >
        {getCheckmarkButton(exerciseData)}
      </Box>
    );
  };

  const onToggleChanged = (section, isEnabled) => {
    const updatedExercise = {
      ...exerciseDetails,
    };
    if (section.id === 0) {
      updatedExercise.progression_enabled = isEnabled;
    } else if (section.id === 1) {
      updatedExercise.regression_enabled = isEnabled;
    }
    if (exeOrCompoundExe?.compound_routine_exercises?.length) {
      const updatedCompoundExe = {
        ...exeOrCompoundExe,
      };
      const compoundExeArr = updatedCompoundExe.compound_routine_exercises.map((ex) => {
        if (ex.id === exerciseDetails?.id) {
          return updatedExercise;
        }
        return ex;
      });
      updatedCompoundExe.compound_routine_exercises = compoundExeArr;
      saveDataLocally(updatedCompoundExe);
      return;
    }
    saveDataLocally(updatedExercise);
  };

  const saveDataLocally = (updatedData) => {
    dispatch(editExercise(updatedData));
    handleRescheduling(updatedData);
  };

  const handleRescheduling = (updatedData) => {
    if (context === programContexts.RESCHEDULING && updatedData.routine_type) {
      const eventName = `progRegToggle_${updatedData.routine_type}`;
      const eventParams = {
        cancelable: true,
        detail: {
          updatedData,
        },
      };
      const progRegToggleEvent = new CustomEvent(eventName, eventParams);
      window.dispatchEvent(progRegToggleEvent);
    }
  };

  const renderProgressionsRegressions = (section) =>  {
    return (
      <Box>
        <Box className={classes.exerciseListHeading}>
          <Box className={classes.imageContainer}>
            {!isViewOnly ? 
              <img
                alt="exercise-thumbnail"
                src={section.exerciseType === EXERCISE_TYPE.PROGRESSION ? 
                    progressionIcon 
                  : regressionIcon}
                className={classes.progressionRegressionIcon}
                onError={onInvalidImg}
              /> 
            : null}
            <Typography className={classes.ListHeadingText}>
              {section.title}
            </Typography>
          </Box>
          {!isViewOnly && (
            <YellowSwitch
              checked={section.isEnabled}
              onChange={(e) => onToggleChanged(section, e.target.checked)}
            />
          )}
        </Box>
        {section.data.map((exerciseData, index) => (
          <Box 
            className={classes.boxLayout} 
            key={`${section.title}_${index}`}
            onClick={() => {
              if (isComingFromSwapPage) {
                setSelectedExercise(exerciseData);
              } else {
                onClickExercise(exerciseData?.exercise?.id);
              }
            }}
          >
            <div className={classes.imageContainer}>
              <img
                width={75}
                height={48}
                alt="exercise-thumbnail"
                src={exerciseData?.exercise?.image_url || exercisePlaceholder}
                className={classes.thumbnail}
                onError={onInvalidImg}
              />
              <div className={classes.nameContainer}>
                {exerciseData?.exercise?.name}
              </div>
            </div>
            {isComingFromSwapPage ? (
              <div className={classes.checkmark}>
                <RenderCheckBox
                  key={exerciseData?.exercise?.id}
                  exerciseData={exerciseData}
                />
              </div>
            ) : (
              <div className={classes.imageContainer}>
                <div className={classes.timeText}>
                  {getDurationFromSeconds(
                    exerciseData?.exercise?.total_dur_seconds,
                    exerciseData?.exercise?.duration_plus,
                  )}
                </div>
                <img
                  className={classes.rightArrow}
                  src={arrowRight}
                  alt="arrow"
                  onError={(e) => {
                    e.target.src = arrowRight;
                  }}
                />
              </div>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  if(!sections.length){
    return null;
  }

  return (
    <>
      {sections[0] && renderProgressionsRegressions(sections[0])}
      {sections[1] && renderProgressionsRegressions(sections[1])}
    </>
  );
}

export default memo(ProgressionsRegressionsList);