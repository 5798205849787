import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {styled} from '@material-ui/core/styles';

import {colors} from '../../styles';
import { makeResetPasswordRequest } from '../../util/utilFunctions';
import {LOGIN_TYPES} from '../../util/loginConstants';
import nasmApi from '../../api/endpoints';

import ConfirmDialog from '../Dialogs/ConfirmDialog';

const FormLink = styled('div')({
  textTransform: 'none',
  color: colors.baby_blue,
  fontWeight: 500,
  fontSize: 14,
  marginTop: 15,
  marginBottom: 20,
  cursor: 'pointer',
  textAlign: 'center',
  '&:hover': {
    color: 'white',
  },
});

/**
 * @return {null|JSX}
 */
function SignUpLink ({ showLink }) {
  const history = useHistory();

  function onClickCreateAccount() {
    history.push('/create-account');
  }
  if(!showLink) {
    return null;
  }
  return (
    <FormLink onClick={onClickCreateAccount}>
      Don’t have an account? Sign up
    </FormLink>
  );
}

/**
 * @return {null|JSX}
 */
function ForgotPasswordLink ({ showLink, loginType, email }) {
  const [showLegacyResetDialog, setShowLegacyResetDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResetPassConfirmDialog, setShowResetPassConfirmDialog] = useState(false);

  function onForgotPasswordClicked () {
    if([LOGIN_TYPES.UNLINKED_ACCOUNTS, LOGIN_TYPES.LEGACY_EDGE_ACCOUNT].includes(loginType)) {
      setShowLegacyResetDialog(true);
    } else {
      // Open NASM UA's password reset webpage
      makeResetPasswordRequest();
    }
  }

  async function onResetPasswordRequest () {
    try {
      setLoading(true);
      await nasmApi.passwordReset.legacyReset(email);
      setShowLegacyResetDialog(false);
      setShowResetPassConfirmDialog(true);
    } catch (e) {
      window.alert(`Something went wrong with making the password reset request. Please try again.`);
    } finally {
      setLoading(false);
    }
  }

  function onCloseResetPassConfirmDialog() {
    setShowResetPassConfirmDialog(false);
  }

  if(!showLink) {
    return null;
  }

  return (
    <>
      <FormLink onClick={onForgotPasswordClicked}>
        Forgot your password?
      </FormLink>
      <ConfirmDialog
        title='Reset Password?'
        description="You'll receive an email to reset your password"
        actionButtonTitle='Confirm'
        open={showLegacyResetDialog}
        onClose={() => setShowLegacyResetDialog(false)}
        handleConfirmAction={onResetPasswordRequest}
        loading={loading}
      />
      <ConfirmDialog
        title='Password Reset'
        description='Check your email for further instructions'
        open={showResetPassConfirmDialog}
        onClose={onCloseResetPassConfirmDialog}
        handleConfirmAction={onCloseResetPassConfirmDialog}
        hideCancelButton
      />
    </>
  );
}

export {
  SignUpLink,
  ForgotPasswordLink,
};