import request from '../nasm_sdk_v2/request';

export default function workoutSections(apiVer) {
  function getAllSections(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/workout-sections`);
  }

  return {
    getAllSections,
  };
}