import { FEATURE_FLAGS } from '../../constants';
import request from '../nasm_sdk_v2/request';

export default function trainer(apiVer) {
  function resendClientInvite(data, apiVersion = apiVer) {
    if (!data.clientId) {
      return Promise.reject(new Error('clientId is required to resend an invitation.'));
    }
    return request.post(`${apiVersion}/api/users/resend-invitation`, data);
  }

  function updateCredentials(data, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/users/trainer/credentials`, data);
  }

  function removeClient(clientId, apiVersion = apiVer) {
    return request.delete(`${apiVersion}/api/users/client-relationship`, { data: { clientId }});
  }

  function getClients(clubId, locationId, apiVersion = apiVer) {
    let config = request.get(`${apiVersion}/api/users/trainer/clients`);
    if (clubId) {
      config = request.get(`${apiVersion}/api/users/trainer/clients?club_id=${clubId}`);
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && locationId) {
        config = request.get(`${apiVersion}/api/users/trainer/clients?club_id=${clubId}&location_id=${locationId}`);
      }
    }
    return config;
  }

  function registerClient(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users`, data);
  }

  /**
   *
   * @param activateAll {Boolean} - used to activate all clients for the current trainer if set to true
   * @param userId {String} - client's userId to activate as an active client for the trainer
   * @param apiVersion {String}
   * @returns {Promise<*|undefined>}
   */
  function reactivateClients({
    activateAll = true,
    userId = '',
    apiVersion = apiVer,
    club_id: clubId,
    location_id: locationId,
  }) {
    const reqObj = { userId };
    if (clubId) {
      reqObj.club_id = clubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && locationId) {
        reqObj.location_id = locationId;
      }
    }
    return request.post(`${apiVersion}/api/users/trainer/reactivate-clients`,
      reqObj,
      { params: { activateAll: activateAll } },
    );
  }

  function deactivateAllClients(apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/trainer/deactivate-clients`, {});
  }

  function trainerValidateClientEmail(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/trainer/validate-client-email`, data);
  }

  function inviteExistingClient(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/trainer/send-client-invite`, data);
  }

  function createClientUser(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users`, data);
  }

  function updateTrainer(trainerData, userId, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/users/trainer/${userId}`, trainerData);
  }

  return {
    updateCredentials,
    removeClient,
    getClients,
    registerClient,
    reactivateClients,
    deactivateAllClients,
    trainerValidateClientEmail,
    inviteExistingClient,
    resendClientInvite,
    createClientUser,
    updateTrainer,
  };
}