import Api from './nasm_api';
import Auth from './nasm_auth';

export default class NASM {
  /**
   *
   * @param storeToken {Function} Interface function that accepts token information and
   * will store it in persistant storage.
   */
  constructor(storeToken, getToken, deleteToken) {
    this.auth = new Auth(storeToken, getToken, deleteToken);
    this.api = new Api(storeToken, getToken, deleteToken);
  }
}
