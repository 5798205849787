import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { StatelessDropdown, TextInput } from '../Inputs';
import { DISTANCE_UNIT_OPTIONS, VOLUME_OPTIONS } from '../../constants';

const useComboBoxStyle = makeStyles({
  root: props => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: props.marginTop || 0,
    marginBottom: props.marginBottom || 0,
    marginLeft: props.marginLeft || 0,
    marginRight: props.marginRight || 0,
  }),
});

function VolumeCombobox (props) {
  const {
    reps,
    onChangeReps,
    duration,
    onChangeDuration,
    distance,
    onChangeDistance,
    volumeChoice,
    onChangeVolumeChoice,
    distanceType,
    onChangeDistanceType,
    fieldErrors = {},
    touchedFields = {},
    resetFieldErrors,
    resetVolumeValues,
  } = props;

  const classes = useComboBoxStyle(props);

  // whenever the volume type gets changed,
  function onChangePrimaryOption (e) {
    resetVolumeValues();
    resetFieldErrors();

    const newVolumeChoice = e.target.value;
    if (newVolumeChoice === 'distance') {
      const milesValue = { target: { value: 'miles' } };
      onChangeDistanceType(milesValue);
    }

    onChangeVolumeChoice(e);
  }

  function onChangeValue (e) {
    switch (volumeChoice) {
      case 'reps':
        onChangeReps(e);
        break;
      case 'duration':
        onChangeDuration(e);
        break;
      case 'distance':
        onChangeDistance(e);
        break;
      default:
        break;
    }
  }

  function getCurrentValue () {
    switch (volumeChoice) {
      case 'reps':
        return reps;
      case 'duration':
        return duration;
      case 'distance':
        return distance;
      default:
        break;
    }

    return '';
  }

  const DistanceDropdown = () => {
    if (volumeChoice === 'distance') {
      return (
        <StatelessDropdown
          currentValue={distanceType}
          options={DISTANCE_UNIT_OPTIONS}
          onOptionChanged={onChangeDistanceType}
          width='100%'
          dropdownSpacing={10}
        />
      );
    }

    return null;
  };

  return (
    <Box className={classes.root}>
      <Box style={{ marginRight: 20 }}>
        <StatelessDropdown
          onOptionChanged={onChangePrimaryOption}
          currentValue={volumeChoice}
          options={VOLUME_OPTIONS}
          width='100%'
          dropdownSpacing={10}
        />
      </Box>
      <Box style={{ marginRight: 20 }}>
        <TextInput
          flex={1.25}
          placeholder={volumeChoice === 'duration' ? '00:00' : 'Amount'}
          currentValue={getCurrentValue()}
          onChange={onChangeValue}
          onBlur={e => onChangeValue(e)}
          inputType={volumeChoice === 'duration' ? 'text' : 'number'}
          errorText={fieldErrors[volumeChoice]}
          showError={touchedFields[volumeChoice]}
        />
      </Box>
      <DistanceDropdown />
    </Box>
  );
}

VolumeCombobox.propTypes = {
  reps: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeReps: PropTypes.func,
  duration: PropTypes.string,
  onChangeDuration: PropTypes.func,
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeDistance: PropTypes.func,
  volumeChoice: PropTypes.string.isRequired,
  onChangeVolumeChoice: PropTypes.func.isRequired,
  distanceType: PropTypes.string,
  onChangeDistanceType: PropTypes.func,
  fieldErrors: PropTypes.object,
  touchedFields: PropTypes.object,
  resetFieldErrors: PropTypes.func,
  resetVolumeValues: PropTypes.func,
};

export default VolumeCombobox;
