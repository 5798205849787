import React, {useReducer, useContext, useCallback} from 'react';
import nasm from '../dataManager/apiConfig';

const defaultState = {
  loading: true,
  error: null,
  allGroups: [],
  activeGroups: [],
  filteredGroups: [],
  areNoGroupsAdded: false,
};

const ACTIONS = Object.freeze({
  UPDATE_GROUPS: 'update_groups',
  FILTER_GROUPS: 'filter_groups',
  ERROR: 'error',
  LOADING: 'loading',
});

const GroupsContext = React.createContext(null);

function reducer (state, action) {
  const payload = action.payload;

  if(action.type === ACTIONS.UPDATE_GROUPS) {
    const { groups } = payload;
    return {
      loading: false,
      error: null,
      allGroups: groups,
      activeGroups: groups,
      filteredGroups: groups,
    };
  }

  if(action.type === ACTIONS.FILTER_GROUPS) {
    const {searchText} = payload;
    return {
      ...state,
      filteredGroups: state.allGroups.filter(g => {
        return g.title.toLowerCase().includes(searchText);
      }),
    };
  }

  if(action.type === ACTIONS.ERROR) {
    const {error} = payload;
    return {
      ...state,
      loading: false,
      error: error,
    };
  }

  if(action.type === ACTIONS.LOADING) {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }

  return { ...state };
}

function GroupsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState, () => defaultState);

  // pass in function calls in value as well as all the state values
  return (
    <GroupsContext.Provider value={[state, dispatch]}>
      {children}
    </GroupsContext.Provider>
  );
}

function useGroupsContext () {
  const [state, dispatch] = useContext(GroupsContext);

  const onReceiveGroups = useCallback((newGroups) => {
    dispatch({
      type: ACTIONS.UPDATE_GROUPS,
      payload: { groups: newGroups },
    });
  }, [dispatch]);

  const onError = useCallback((e) => {
    dispatch({
      type: ACTIONS.ERROR,
      payload: {error: e},
    });
  }, [dispatch]);

  const fetchGroups = useCallback((clubId, locationId) => {
    dispatch({ type: ACTIONS.LOADING });
    nasm.api.getTrainerGroups(clubId, locationId)
      .then(response => onReceiveGroups(response || []))
      .catch(e => onError(e));
  }, [dispatch, onReceiveGroups, onError]);

  const findGroups = useCallback((searchText) => {
    dispatch({
      type: ACTIONS.FILTER_GROUPS,
      payload: {searchText: searchText.toLowerCase()},
    });
  }, [dispatch]);

  return {
    ...state,
    fetchGroups,
    findGroups,
  };
}

export {
  GroupsProvider,
  useGroupsContext,
  ACTIONS,
};