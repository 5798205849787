import React from 'react';

export const CheckboxOff = (props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.size ?? 20} height={props.size ?? 20} viewBox="0 0 20 20">
          <g fill="none" fill-rule="evenodd">
              <g stroke={props.color ?? "#3F3E3F"} stroke-width="2">
                  <g>
                      <g>
                          <g transform="translate(-181 -589) translate(160 339) translate(2) translate(19 250)">
                              <rect width="18" height="18" x="1" y="1" rx="2"/>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
};