import { useEffect, useState } from 'react';
import nasmApi from '../../api/endpoints';

/***
 *
 * @param {String} userId - client's userId (app_user table)
 * @param {Number} month
 * @param {Number} year
 * @param {Boolean} canFetchData - used to control when data from the API can be retrieved. Useful when
 * a scheduled workout is newly added or deleted and an updated list of scheduled workouts needs to be fetched.
 * @returns {{isDataLoaded: boolean, error: boolean, workoutSchedulesByMonth: {}}}
 */
export default function useFetchWorkoutScheduleByMonth ({
  userId, month, year, canFetchData = false,
}) {
  const [workoutSchedulesByMonth, setWorkoutSchedulesByMonth] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsDataLoaded(false);

    if (canFetchData) {
      nasmApi.workoutSchedule.getWorkoutsByMonth({ userId, month, year })
        .then(data => {
          setWorkoutSchedulesByMonth(data.result);
          setIsDataLoaded(true);
          setError(false);
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {
          setError(true);
          setWorkoutSchedulesByMonth({});
          setIsDataLoaded(false);
        });
    }
  }, [userId, month, year, canFetchData]);

  return { workoutSchedulesByMonth, isDataLoaded, error };
}
