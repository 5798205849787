import { ModelType, Muscle, IMuscleData } from '../component/metadata';

export const DEFAULT_MUSCLE_DATA: Record<Muscle, IMuscleData> = {
  back: { exercises: [], frequency: 0 },
  chest: { exercises: [], frequency: 0 },
  biceps: { exercises: [], frequency: 0 },
  triceps: { exercises: [], frequency: 0 },
  forearms: { exercises: [], frequency: 0 },
  shoulders: { exercises: [], frequency: 0 },
  trunk: { exercises: [], frequency: 0 },
  adductor: { exercises: [], frequency: 0 },
  hamstrings: { exercises: [], frequency: 0 },
  quadriceps: { exercises: [], frequency: 0 },
  abductors: { exercises: [], frequency: 0 },
  calves: { exercises: [], frequency: 0 },
  hips: { exercises: [], frequency: 0 },
  head: { exercises: [], frequency: 0 },
  neck: { exercises: [], frequency: 0 },
  knees: { exercises: [], frequency: 0 },
  leftsoleus: { exercises: [], frequency: 0 },
  rightsoleus: { exercises: [], frequency: 0 },
};

export const DEFAULT_BODY_COLOR = '#B6BDC3';
export const DEFAULT_HIGHLIGHTED_COLORS = ['#81b1d9', '#277abf'];

export const DEFAULT_MODEL_TYPE = ModelType.ANTERIOR;
