import React from 'react';
import { List, ListItem, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

export default function ProgramListSkeleton () {
  return (
    <List>
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
      <ProgramRowSkeleton />
    </List>
  );
}

const useStyles = makeStyles({
  root: {
    height: 80,
    width: '100%',
    borderBottom: '1px solid #e6e6e6',
    display: 'flex',
    flexDirection: 'column',
  },
  group: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  margins: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
});

function ProgramRowSkeleton () {
  const classes = useStyles();
  return (
    <ListItem className={classes.root}>
      <Box className={classes.margins}>
        <Box style={{ width: '100%' }}>
          <Skeleton variant='text' style={{ borderRadius: 5.5 }} width='50%' />
        </Box>
        <Box className={classes.group}>
          <Skeleton variant='text' style={{ marginRight: '2%', borderRadius: 5.5 }} width='10%' />
          <Skeleton variant='text' style={{ marginRight: '80%', borderRadius: 5.5 }} width='10%' />
          <Skeleton variant='text' style={{ borderRadius: 5.5 }} width='10%' />
        </Box>
      </Box>
    </ListItem>
  );
}
