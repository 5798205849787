import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import  { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

const useStyles = makeStyles({
  cardHeader: props => ({
    backgroundColor: props.backgroundColor || colors.graphite,
    width:  props.width || '100%',
    height: props.height || '20%',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    '&:hover': {
      cursor: !!props.enableCursorPointer ? 'pointer' : 'default',
    },
  }),
});

function CardHeader (props) {
  const { onClick } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.cardHeader} onClick={onClick}>
      {props.children}
    </Box>
  );
}

CardHeader.propTypes = {
  backgroundColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  enableCursorPointer: PropTypes.bool,
};

export default CardHeader;