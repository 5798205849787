import {styled} from '@material-ui/core/styles';
import {ButtonBase} from '@material-ui/core';
import appStore3x from '../../resources/button-app-store@3x.png';
import appStore from '../../resources/button-app-store.png';
import appStore2x from '../../resources/button-app-store@2x.png';
import googlePlay from '../../resources/button-google-play.png';
import googlePlay2x from '../../resources/button-google-play@2x.png';
import googlePlay3x from '../../resources/button-google-play@3x.png';
import React from 'react';

const AppStoreLinks = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 40,
});

export default function() {
  return (
    <AppStoreLinks>
      <ButtonBase
        href='https://nasm-edge.app.link/download'
        target='_blank'
        disableRipple
      >
        <img
          src={appStore3x}
          srcSet={`${appStore} 1x, ${appStore2x} 2x, ${appStore3x} 3x`}
          alt='Apple App Store link'
        />
      </ButtonBase>
      <ButtonBase
        href='https://nasm-edge.app.link/download'
        target='_blank'
        disableRipple
      >
        <img
          src={appStore3x}
          srcSet={`${googlePlay} 1x, ${googlePlay2x} 2x, ${googlePlay3x} 3x`}
          alt='Google Play link'
        />
      </ButtonBase>
    </AppStoreLinks>
  );
}