import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import MacaroniButton from '../Buttons/MacaroniButton';
import OvalButton from '../Buttons/OvalButton';
import checkMark from '../../resources/interaction-toggle-checkmark-on-big.svg';
import { colors } from '../../styles';
import placeholderIcon from '../../resources/user-dashboard-client-profile-with-no-photo@2x.png';
import closeCircleWhite from '../../resources/close_circle_white.svg';

const useStyles = makeStyles({
  root: {
    width: '553px',
    borderRadius: '16px',
    padding: '12px',
  },
  actionBtns: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  btnContainer: {
    display: 'flex',
    width: '210px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  statusMsg: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '36px',
  },
  header: {
    fontSize: '32px',
    fontWeight: 700,
  },
  description: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '19px',
  },
  topProfileCont: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  profileImg: {
    height: '48px',
    width: '48px',
    borderRadius: '48px',
    border: '1px solid',
    borderColor: colors.yellow_button_style,
    objectFit: 'cover',
  },
  profileListName: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 700,
    marginLeft: '15px',
  },
  formClose: {
    height: '38px',
    width: '38px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  btnContClubConnect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '180px',
    marginBottom: '35px',
    marginRight: '10px',
  },
});

function ResendInviteDialog (props) {
  const { open, onClose, onResend, loading, inviteSent, message } = props;
  const classes = useStyles();
  const selectedProfile = useSelector(state => state.clubConnect.selectedProfile);

  const renderProfile = () => {
    return (
      <>
        {selectedProfile?.ClubId ?
          <Box className={classes.topProfileCont}>
            <img
              className={classes.profileImg}
              src={selectedProfile?.ClubLogoUrl || placeholderIcon}
              alt='Profile'
              onError={(e) => {
                e.target.src = placeholderIcon;
              }}
            />
            <Typography className={classes.profileListName}>
              {`${selectedProfile?.ClubName}`}
            </Typography>
          </Box> : <></>
        }
      </>
    );
  };

  function LoadingState () {
    if (loading) {
      return (
        <>
          {renderProfile()}
          <Box className={classes.statusCont}>
            <Typography className={classes.statusMsg}>Sending...</Typography>
            <CircularProgress />
          </Box>
        </>
      );
    }
    return null;
  }

  function SuccessfulInviteState () {
    if (inviteSent) {
      return (
        <>
          {renderProfile()}
          <Box className={classes.statusCont}>
            <Typography className={classes.statusMsg}>Invite Sent!</Typography>
            <img src={checkMark} alt='check' />
          </Box>
        </>
      );
    }
    return null;
  }

  function ResendInviteContent () {
    if (!loading && !inviteSent) {
      return (
        <Box>
          <DialogTitle>
            {renderProfile()}
            <Typography className={classes.header}>Resend Invite?</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.description}>
              {message || 'A client already exists with that email. Would you like to resend an invite?'}
            </Typography>
          </DialogContent>
          <DialogActions>
            {selectedProfile?.ClubId ?
              <Box className={classes.btnContClubConnect}>
                <MacaroniButton width='127px' onClick={onResend}>Resend Invite</MacaroniButton>
                <img
                  className={classes.formClose}
                  src={closeCircleWhite}
                  alt='close'
                  onError={(e) => {
                    e.target.src = closeCircleWhite;
                  }}
                  onClick={onClose}
                />
              </Box>
              :
              <>
                <OvalButton onClick={onClose}>Cancel</OvalButton>
                <MacaroniButton width='120px' onClick={onResend}>Resend Invite</MacaroniButton>
              </>
            }
          </DialogActions>
        </Box>
      );
    }
    return null;
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <ResendInviteContent />
      <LoadingState />
      <SuccessfulInviteState />
    </Dialog>
  );
}

export default ResendInviteDialog;
