import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';
import ClientIcon from '../ClientIcon';
import { ReactComponent as MailIcon } from '../../../resources/icons-global-contact-email-gray.svg';

import { colors } from '../../../styles';

const HeaderTitle = styled(Typography)({
  fontFamily: 'DMSans-Medium, DMSans, sans-serif',
  color: colors.white,
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 2.5,
  marginLeft: 22,
});

const CardContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 22px',
});

const BasicInfo = styled('div')({
  marginLeft: '16px',
});

const FirstName = styled(Typography)({
  fontFamily: 'DMSans-Medium, DMSans, sans-serif',
  fontSize: '17px',
  lineHeight: '33px',
  fontWeight: '400',
});

const EmailContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Email = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '13px',
  marginLeft: '8px',
});

function AccountCard (props) {
  const { user, onOpen } = props;

  return (
    <CardContainer
      width={416}
      height={190}
    >
      <CardHeader
        backgroundColor={colors.dark_grey}
        height={55}
        enableCursorPointer
        onClick={onOpen}
      >
        <HeaderTitle>Account</HeaderTitle>
      </CardHeader>
      <CardContent>
        <ClientIcon
          client={user}
          onClick={onOpen}
          isActive
          showName={false}
          margin='20px 0'
        />
        <BasicInfo>
          <FirstName>{user.first_name}</FirstName>
          <EmailContainer>
            <MailIcon />
            <Email>{user.email}</Email>
          </EmailContainer>
        </BasicInfo>
      </CardContent>
    </CardContainer>
  );
}

export default AccountCard;

AccountCard.propTypes = {
  user: PropTypes.object.isRequired,
};
