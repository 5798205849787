import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  InputGroup,
  InputLabel,
  PasswordTextField,
  ContinueButton,
  InputDescription,
} from '../FormComponents';

import { LOGIN_VIEWS } from '../../../util/loginConstants';
import nasmApi from '../../../api/endpoints';
import { login } from '../../../reducers/currentUserReducer';
import { confirmPassword as verifyPasswordsMatch, password as validatePassword } from '../../../util/validate';
import { colors } from '../../../styles';

const useStyles = makeStyles({
  updateText: {
    marginTop: 63,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.95,
    color: colors.white,
    textAlign: 'center',
  },
  description: {
    fontSize: 19,
    lineHeight: 1.58,
    color: colors.white,
    textAlign: 'center',
    marginBottom: 46,
  },
});

/**
 * @return {null|JSX}
 */
function MigrateAccountFormView (props) {
  const { email, loginView } = props;
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  function handlePassword (e) {
    setPassword(e.target.value);
  }

  function handlePwVisibility () {
    setShowPass(!showPass);
  }

  function handleConfirmPassword (e) {
    setConfirmPassword(e.target.value);
  }

  function handleConfirmPwVisibility () {
    setShowConfirmPass(!showConfirmPass);
  }

  // Need custom submit function to ensure email and the password's state for this view
  // is being submitted over
  async function onSubmit (e) {
    e.preventDefault();

    setPasswordError('');
    setConfirmPasswordError('');

    if(password.length === 0) {
      setPasswordError('Enter a password');
      return;
    }

    if(confirmPassword.length === 0) {
      setConfirmPasswordError('Enter a password');
      return;
    }

    if(!validatePassword(password)) {
      setPasswordError('Password must have at least 8 characters,' +
        ' 1 uppercase, 1 lowercase, and 1 special character');
      return;
    }

    if(!verifyPasswordsMatch(password, confirmPassword)) {
      setConfirmPasswordError('The passwords entered do not match');
      return;
    }

    try {
      setLoading(true);
      // TODO: trainer may need to accept terms before migrating their account to UA
      await nasmApi.register.migrateEdgeAccountToUA(email, password);
      await dispatch(login({ email, password}));
      setLoading(false);
      history.push('/clients');
    } catch (err) {
      setLoading(false);
    }
  }

  if(loginView !== LOGIN_VIEWS.MIGRATE_ACCOUNT) {
    return null;
  }

  return (
    <form onSubmit={onSubmit}>
      <Typography className={classes.updateText}>Update Password</Typography>
      <Typography className={classes.description}>Create new password for NASM.org account</Typography>
      <InputGroup>
        <InputLabel>Required</InputLabel>
        <PasswordTextField
          placeholder='New Password'
          showPass={showPass}
          password={password}
          showError={!!passwordError || !!confirmPasswordError}
          onChange={handlePassword}
          onTogglePasswordVisibility={handlePwVisibility}
        />
        {!!passwordError && <InputDescription>{passwordError}</InputDescription>}
      </InputGroup>
      <InputGroup>
        <InputLabel>Required</InputLabel>
        <PasswordTextField
          placeholder='Confirm Password'
          showPass={showConfirmPass}
          password={confirmPassword}
          showError={!!confirmPasswordError}
          onChange={handleConfirmPassword}
          onTogglePasswordVisibility={handleConfirmPwVisibility}
        />
        {!!confirmPasswordError && <InputDescription>{confirmPasswordError}</InputDescription>}
      </InputGroup>
      <div style={{ marginBottom: 15 }}>
        <ContinueButton
          isLoading={isLoading}
        />
      </div>
    </form>
  );
}

MigrateAccountFormView.propTypes = {
  email: PropTypes.string.isRequired,
  loginView: PropTypes.string.isRequired,
};

export default MigrateAccountFormView;