import request from '../nasm_sdk_v2/request';

const BWP_BASE_ENDPOINT = (apiVersion, userId) => `${apiVersion}/api/users/${userId}/body-weight-plan`;

export default function bwp(apiVer) {
  function saveResults(userId, jsonData, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error("userId is required to save BMI results."),
      );
    }
    return request.post(`${BWP_BASE_ENDPOINT(apiVersion, userId)}`, jsonData);
  }

  function getCurrentPlan(userId, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch current BMI.'),
      );
    }
    return request.get(`${BWP_BASE_ENDPOINT(apiVersion, userId)}/current-plan`);
  }

  function getHistory(userId, searchParams, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch BMI history.'),
      );
    }
    return request.get(`${BWP_BASE_ENDPOINT(apiVersion, userId)}/history`, {
      params: {
        ...searchParams,
        size: 20,
      },
    });
  }

  return {
    saveResults,
    getCurrentPlan,
    getHistory,
  };
}