import Nasm from '../api/nasm_sdk';

const AUTH_TOKEN = 'AUTH_TOKEN';

async function saveToken(tokenData) {
  let LS;
  if (tokenData && typeof tokenData === 'object') {
  }
    try {
      LS = localStorage.setItem(AUTH_TOKEN, JSON.stringify(tokenData));
    } catch {
      return false;
    }

  return LS;
}

async function getToken() {
  let tokenString;
  try {
    tokenString = localStorage.getItem(AUTH_TOKEN);
    if (tokenString !== null) {
      return JSON.parse(tokenString);
    }
    return false;
  } catch {
    return false;
  }
}

export async function deleteAccessToken() {
  try {
    localStorage.removeItem(AUTH_TOKEN);
    return true;
  } catch(error) {
    return false;
  }
}

// Create api instance
export default new Nasm(saveToken, getToken, deleteAccessToken);
