import React from 'react';
import PropTypes from 'prop-types';
import {Box, Input} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import ErrorText from './ErrorText';
import {colors} from '../../styles';

const useStyles = makeStyles({
  descriptionTextbox: props => ({
    border: props.showError && !!props.errorText ? `solid 1px ${colors.yellow_button_style}` : 'solid 1px #d2d7db',
    borderRadius: 3,
    boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.18)',
    backgroundColor: props.backgroundColor || 'transparent' ,
    height: 180,
  }),
  descriptionText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    margin: 12,
  },
  width: {
    width: '100%',
  },
});

function DescriptionTextbox (props) {
  const {
    descriptionText,
    onChange,
    onBlur,
    errorText = '',
    showError = false,
    name,
    readOnly = false,
    backgroundColor,
    placeholder,
    justifyContent,
  } = props;
  const classes = useStyles({ showError, errorText, backgroundColor });

  return (
    <Box style={{ margin: '30px 0 30px 0' }}>
      <Box className={classes.descriptionTextbox}>
        <Input
          name={name}
          readOnly={readOnly}
          multiline
          rowsMax={8}
          disableUnderline
          inputProps={{ className: classes.descriptionText }}
          className={classes.width}
          placeholder={placeholder || 'Description'}
          value={descriptionText}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Box>
      <ErrorText
        showError={showError}
        errorText={errorText}
        justifyContent={justifyContent}
      />
    </Box>
  );
}

DescriptionTextbox.propTypes = {
  descriptionText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default DescriptionTextbox;