import React from 'react';
import PropTypes from 'prop-types';
import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import CreateSuperSet from '../../pages/superSet/CreateSuperSetDialog';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: {
    height: '90%',
    borderRadius: '6px',
    backgroundColor: colors.white,
    transition: 'height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

function AddSuperSetsPanel (props) {
  const {
    visible,
    backLink,
    resetSelection = () => {},
    isAutoSaveEnabled,
    isScheduling,
    isRescheduling,
    superSetData,
    setSuperSetData,
    viewSuperSetExDetailsLink,
    isDeletable,
  } = props;

  const classes = useStyles();

  const containerHeight = classes.root.height;

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={containerHeight}>
        <FadeTransition visible={visible}>
          <Container className={classes.root}>
            <CreateSuperSet
              isAutoSaveEnabled={isAutoSaveEnabled}
              isScheduling={isScheduling}
              isRescheduling={isRescheduling}
              backLink={backLink}
              resetSelection={resetSelection}
              superSetData={superSetData}
              setSuperSetData={setSuperSetData}
              viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
              isDeletable={isDeletable}
            />
          </Container>
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

AddSuperSetsPanel.propTypes = {
  visible: PropTypes.bool,
  backLink: PropTypes.func,
  resetSelection: PropTypes.func,
  isAutoSaveEnabled: PropTypes.bool,
  isScheduling: PropTypes.bool,
  isRescheduling: PropTypes.bool,
};

export default AddSuperSetsPanel;

