import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { colors } from "../../styles";

import ClientBreadcrumbs from "../../components/ClientDashboard/ClientBreadcrumbs";
import { PageHeader, PageToolBar } from "../../components";

import ScheduleDialog from "../../components/SchedulePopover/ScheduleDialog";
import ExerciseScheduleListContainer from "../../components/Schedule/ExerciseScheduleListContainer";

import { useDispatch, useSelector } from "react-redux";
import { getWorkoutSections } from "../../reducers/workoutsReducer";
import {
  clearWorkout,
  addExercises,
} from "../../reducers/selectedWorkoutReducer";
import {
  assignExercises,
  clearProgram,
  scheduleProgram,
  scheduleProgramToday,
} from "../../reducers/selectedProgramReducer";
import { scheduleProgramContext } from "../../reducers/programContextReducer";
import { FloatingCircleButton } from "../../components/Buttons";
import AddingToWorkoutPanelContainer from "../../components/Schedule/AddingToWorkoutPanelContainer";

export default function AddExercisesScreen(props) {
  const { pathMap = {}, clientFirstName, selectedGroup, selectedDate, isScheduling } = props;

  const groupId = selectedGroup?.id;
  const groupName = selectedGroup?.title;
  let rootPath = "/clients/my-clients";
  if (groupId) {
    rootPath = "/groups/my-groups";
  }

  const [isScheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  // key - exerciseId | value - exercise object
  const [selectedExercises, setSelectedExercises] = useState({});
  // Flag is used to prevent user from scheduling a workout UNTIL workout sections is successfully fetched from the API
  // Trainer can't schedule a program/workout until the workout sections are retrieved from the backend API
  const [isPreparingWorkoutsPayload, setPreparingWorkoutsPayload] = useState(
    false,
  );

  const dispatch = useDispatch();
  const selectedProgram = useSelector((state) => state.selectedProgram);
  const defaultSections = useSelector(
    (state) => state.workouts.defaultSections,
  );

  const handleOpen = () => setScheduleDialogOpen(true);
  const handleClose = useCallback(() => {
    setScheduleDialogOpen(false);
  }, []);

  const history = useHistory();
  /**
   * @param {String} firstName - Client's first name
   * @param {String} startDate
   */
  const navigateBackToCalendar = (firstName, startDate) => {
    if (firstName) {
      const state = { selectedDate: startDate };
      const routeEnd = !groupId ? '/calendar' : '';
      history.replace(
        `${rootPath}/${firstName.toLowerCase()}/dashboard${routeEnd}`,
        state,
      );
    } else {
      // Fallback to navigating to trainer's client or group list
      history.replace(`${rootPath}`);
    }
  };

  const clearSelectedSchedule = useCallback(() => {
    dispatch(clearProgram());
    dispatch(clearWorkout());
  }, [dispatch]);

  const onFail = useCallback(
    (errorMessage) => {
      window.alert(errorMessage);
      handleClose();
    },
    [handleClose],
  );

  // NOTE: workoutSections must be fetched from the API BEFORE preparing the workouts and programs payloads
  // otherwise the end user won't be able to create a scheduled workout
  useEffect(() => {
    dispatch(getWorkoutSections()).catch((e) =>
      onFail(`Failed to fetch workout sections:\n${e}`),
    );
  }, [dispatch, onFail]);

  useEffect(() => {
    dispatch(scheduleProgramContext());
  }, [dispatch]);

  const onPrepareWorkouts = useCallback(() => {
    clearSelectedSchedule();
    setPreparingWorkoutsPayload(true);

    // Assumption: Assign Exercises depends on knowing the Client Object before running
    // if selected client is null, it will not be able to set a default workout name
    dispatch(assignExercises());

    const defaultSection = defaultSections.find((s) => s.name === "Warm-Up");
    if (defaultSection) {
      const exercisesArray = Object.values(selectedExercises)?.map(
        (ex) => {
          const compoundRoutineExercises = [];
          if ('compound_routine_exercises' in ex) {
            ex.compound_routine_exercises.forEach((compoundExe) => {
              const exercise = compoundExe.exercise || compoundExe;
              compoundRoutineExercises.push({
                ...exercise,
                compound_routine_id: compoundExe.compound_routine_id,
              });
            });
            return {
              ...ex,
              compound_routine_exercises: compoundRoutineExercises,
            };
          }
          return ex;
        },
      );
      dispatch(
        addExercises({
          sectionId: defaultSection.id,
          exercises: exercisesArray,
        }),
      )
        .then(() => setPreparingWorkoutsPayload(false))
        .catch((e) => {
          onFail(`Failed to schedule exercises as a workout: ${e}`);
        });
    } else {
      onFail("Could Not find a default section to save the exercises under");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onFail, selectedExercises, defaultSections]);

  const onScheduleOnce = async (startDate) => {
    try {
      await dispatch(scheduleProgramToday(startDate));
      navigateBackToCalendar(groupId ?? clientFirstName, startDate);
    } catch (e) {
      onFail(JSON.stringify(e, null, 2));
    } finally {
      clearSelectedSchedule();
    }
  };

  const onScheduleRepeat = async (startDate, endDate) => {
    try {
      await dispatch(scheduleProgram(startDate, endDate));
      navigateBackToCalendar(groupId ?? clientFirstName, startDate);
    } catch (e) {
      onFail(JSON.stringify(e, null, 2));
    } finally {
      clearSelectedSchedule();
    }
  };

  return (
    <Box>
      <PageHeader
        title={`Choose exercises for ${groupName ?? clientFirstName}`}
        color={colors.graphite}
      />
      <PageToolBar color={colors.graphite} />
      <FloatingCircleButton disabled />
      <ClientBreadcrumbs
        paddingTop={21}
        paddingLeft="7%"
        ariaLabel="client-schedule-breadcrumbs"
        pathMap={pathMap}
        pathNamesExclusionList={[
          groupId ? "groups" : "clients",
          groupId ?? clientFirstName.toLowerCase(),
        ]}
      />
      <Box style={{ marginBottom: 66 }}>
        {isScheduling ? (
          <AddingToWorkoutPanelContainer
            isScheduleDialogOpen={isScheduleDialogOpen}
            onOpenScheduleDialog={handleOpen}
          />
        ) : (
          <ExerciseScheduleListContainer
            selectedExercises={selectedExercises}
            setSelectedExercises={setSelectedExercises}
            onPrepareWorkouts={onPrepareWorkouts}
            clientFirstName={clientFirstName}
          />
        )}
      </Box>
      <ScheduleDialog
        backdropFilterVisible
        open={isScheduleDialogOpen}
        workouts={Object.values(selectedProgram?.entities?.workouts ?? {})}
        onClose={handleClose}
        onScheduleOnce={onScheduleOnce}
        onScheduleRepeat={onScheduleRepeat}
        disableSaveButton={isPreparingWorkoutsPayload}
        selectedDate={selectedDate}
      />
    </Box>
  );
}
