export const NASM_OAUTH_GRANT_TYPES = {
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
};

export const NASM_OAUTH_CLIENT = {
  ID: process.env.REACT_APP_CLIENT_ID || 'client',
  SECRET: process.env.REACT_APP_CLIENT_SECRET || 'secret',
};

export const STORAGE_KEYS = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  LOGIN_PREFERENCES: 'LOGIN_PREFERENCES',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:9090';
export const API_VERSION = process.env.REACT_APP_API_VERSION || 'v2.2';
export const MAX_RETRY_COUNT = parseInt(process.env.REACT_APP_MAX_RETRY_COUNT || '2', 10);
export const RETRY_STATUS_CODES = [401, 403];
