import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

import SchedulePreview from './SchedulePreview';
import CardContentLoading from '../CardContentLoading';
import ScheduleWorkoutPreview from './ScheduleWorkoutPreview';

import {SchedulePreviewImages} from './SchedulePreviewImages';

const SchedulePreviewList = styled(Box)({
  display: 'grid',
  rowGap: 20,
  overflowY: 'scroll',
  height: 375,
  margin: '30px 0px 30px 20px',
});

const SchedulePreviewContainer = styled(Box)({
  margin: '30px 20px',
});

function SchedulePreviewController (props) {
  const {
    isDashboardDataLoading,
    selectedScheduledWorkouts = [],
    isPastSchedule = false,
    onNavigateToScheduling,
    onSelectSchedule,
  } = props;

  if(isDashboardDataLoading) {
    return (
      <SchedulePreviewContainer>
        <CardContentLoading />
      </SchedulePreviewContainer>
    );
  }

  if(!isDashboardDataLoading && selectedScheduledWorkouts.length === 0) {
    return (
      <SchedulePreviewContainer>
        <ScheduleWorkoutPreview onClick={onNavigateToScheduling}/>
      </SchedulePreviewContainer>
    );
  }

  return (
    <SchedulePreviewList>
      {
        selectedScheduledWorkouts.map((w, index) => (
          <SchedulePreview
            key={index}
            programCategoryName={w.programCategoryName}
            minutesDuration={w.minutesDuration}
            programName={w.programName}
            scheduleName={w.scheduledWorkoutName}
            backgroundImage={SchedulePreviewImages[w.programCategoryName]}
            isPastSchedule={isPastSchedule}
            onSelectSchedule={() => onSelectSchedule(w.scheduleId, w.workoutId)}
          />
        ))
      }
    </SchedulePreviewList>
  );
}

SchedulePreviewController.propTypes = {
  selectedScheduledWorkouts: PropTypes.array,
  isDashboardDataLoading: PropTypes.bool,
  onNavigateToScheduling: PropTypes.func,
  isPastSchedule: PropTypes.bool,
};

export default SchedulePreviewController;