import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Paper } from '@material-ui/core';

import { colors } from '../../styles';
import { subscriptionFrequencies } from '../View Subscriptions/SubscriptionPlanConstants';

const useStyles = makeStyles({
  paper: {
    backgroundColor: colors.steel,
    borderRadius: '6px',
  },
  buttonGroup: {
    margin: '2px',
    height: '44px',
  },
  button: {
    fontFamily: 'DMSans, sans-serif',
    fontWeight: 'bold',
    fontSize: '17px',
    width: '161px',
    textTransform: 'capitalize',
    borderRadius: '6px',
    border: 'none',
    color: colors.white,
    backgroundColor: colors.steel,

    '&.Mui-selected': {
      color: colors.black,
      backgroundColor: colors.white,
      borderRadius: '4px',

      '&:hover': {
        backgroundColor: colors.white,
        filter: 'brightness(94%)',
      },
    },
  },
});

function SubscriptionToggleButton (props) {
  const { subscriptionFrequency, onChangeSubscriptionFrequency } = props;
  const classes = useStyles();

  const handleToggleChange = (e, newFrequency) => {
    onChangeSubscriptionFrequency(newFrequency);
  };

  return (
    <>
      <Paper elevation={1} className={classes.paper}>
        <ToggleButtonGroup
          className={classes.buttonGroup}
          exclusive
          value={subscriptionFrequency}
          onChange={handleToggleChange}
          aria-label="subscription-types"
        >
          <ToggleButton
            value={subscriptionFrequencies.MONTHLY}
            className={classes.button}
            selected={subscriptionFrequency === subscriptionFrequencies.MONTHLY}
            aria-label="monthly"
          >
            Billed Monthly
          </ToggleButton>
          <ToggleButton
            value={subscriptionFrequencies.YEARLY}
            className={classes.button}
            selected={subscriptionFrequency === subscriptionFrequencies.YEARLY}
            aria-label="yearly"
          >
            Billed Yearly
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>
    </>
  );
}

export default SubscriptionToggleButton;
