import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { checkForActiveFoundationPermission } from '../../util/trainerFoundationPermission';

// Menu
import NavMenuPrimary from './NavMenuPrimary';
import NavMenuSecondary from './NavMenuSecondary';
import NavProfileMenu from './NavProfileMenu';

// Local
import logo from '../../resources/logo-nasm.png';
import SubscriptionExpiredContainer from './SubscriptionExpiredContainer';

import { getMe } from '../../reducers/currentUserReducer';
import {checkTrainerSubscriptionStatus, SUBSCRIPTION_STATUS} from '../../util/trainerSubscription';

import { colors } from '../../styles';

// Material UI Styles
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  toolBarRoot: {
    height: '93px',
  },
  title: {
    flexGrow: 1,
  },
  logoGroup: {
    marginTop: '10px;',
    marginRight: '42px',
    textAlign: 'center',
  },
  logo: {
    marginRight: '0px',
  },
  iconButton: {
    padding: '5px 10px 5px 10px',
    opacity: 1,
    transition: 'opacity 100ms ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:active': {
      opacity: 0.5,
      transition: 'opacity 10ms ease-in-out',
    },
  },
}));

// Component
export default function NavTop (props) {
  const {
    activeRoute,
    routes = [],
    subRoutes = [],
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const currentUser = useSelector(state => state.currentUser);
  const selectedProfileClubId = useSelector(state => state?.clubConnect?.selectedProfile?.ClubId);
  const [isSubscriptionMessageVisible, setSubscriptionMessageVisible] = useState(false);
  const userPermissions = JSON.stringify(currentUser.permissions);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    const expirationDate = currentUser.subscription_expiration_date;
    const bypassSubscription = !!currentUser.bypass_subscription;
    const subStatus = checkTrainerSubscriptionStatus(expirationDate);
    const hasFoundation = checkForActiveFoundationPermission(currentUser.permissions);

    Sentry.addBreadcrumb({
      category: 'TrainerSubscriptions',
      message: `Trainer email: ${currentUser.email} |` +
       `sub status: ${subStatus} |` +
       `hasFoundation: ${hasFoundation} |` +
        `expire date: ${expirationDate} |` +
        `bypass sub: ${bypassSubscription}`,
      level: 'info',
    });

    Sentry.captureMessage('From NavTop component', Sentry.Severity.Warning);

    setSubscriptionMessageVisible(
      !bypassSubscription &&
      [SUBSCRIPTION_STATUS.EXPIRED, SUBSCRIPTION_STATUS.NONE].includes(subStatus) &&
      activeRoute === 'clients' &&
      !hasFoundation,
    );

    Sentry.addBreadcrumb({
      category: 'TrainerSubscriptions',
      message: `Trainer email: ${currentUser.email} |` +
        `sub status: ${subStatus} |` +
        `hasFoundation: ${hasFoundation} |` +
        `expire date: ${expirationDate} |` +
        `bypass sub: ${bypassSubscription}`,
      level: 'info',
    });

    Sentry.captureMessage('From NavTop component', Sentry.Severity.Warning);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser.subscription_expiration_date,
    currentUser.bypass_subscription, activeRoute,
    userPermissions,
  ]);


  return (
    <div id='nasm-edge-web-nav-bar' className={classes.root}>
      <AppBar position='static'>
        {/* Overwrite theme color here to prevent BWP widget from changing its color */}
        <Toolbar
          classes={{ root: classes.toolBarRoot }}
          style={{ backgroundColor: selectedProfileClubId ? colors.black : colors.mediumBlue, color: colors.white }}
        >
          <div className={classes.logoGroup}>
            <img src={logo} alt='NASM' className={classes.logo} />
          </div>
          <NavMenuPrimary routes={routes} activeRoute={activeRoute} />
          <NavMenuSecondary routes={subRoutes} parent={activeRoute} />
          <SubscriptionExpiredContainer
            isVisible={isSubscriptionMessageVisible && !selectedProfileClubId}
          />
          <div>
            <NavProfileMenu routes={routes} activeRoute={activeRoute} component='span' basicUserInfo={currentUser} />
            {/* <IconButton color="primary" component="span"><settingsIcon/></IconButton>
            <IconButton component="span"><helpIcon/></IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
