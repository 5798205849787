import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import Panel from './Panel';
import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';

import CreateExerciseContent from '../Exercises/CreateExerciseContent';

import { colors } from '../../styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
  },
});

function CustomExercisePanel (props) {
  const { visible, ...rest } = props;
  const classes = useStyles();

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={1525}>
        <Container className={classes.root}>
          <CreateExerciseContent {...rest} />
        </Container>
      </Panel>
    </SlidingFlexTransition>
  );
}

CustomExercisePanel.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default CustomExercisePanel;
