import {makeStyles} from '@material-ui/core/styles';
import {colors} from '../../styles';
import {Box, Container, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  chooseClientsPageHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: colors.colors_fill_dark_yellow_2,
    height: '119px',
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 0.98,
    letterSpacing: -0.4,
    color: colors.white,
    marginBottom: 8,
  },
  subtitle: {
    fontSize: 19,
    fontWeight: 500,
    color: colors.white,
  },
});

function ChooseClientsPageHeader (props) {
  const { remainingFreeClientsCount } = props;
  const titleText = remainingFreeClientsCount > 1 || remainingFreeClientsCount === 0 ?
    `Choose ${remainingFreeClientsCount} Clients Free` :
    `Choose ${remainingFreeClientsCount} Client Free`;

  const classes = useStyles();

  return (
    <Box className={classes.chooseClientsPageHeader}>
      <Container>
        <Typography className={classes.title}>
          {titleText}
        </Typography>
        <Typography className={classes.subtitle}>
          Tap on a current client to activate or add a new one.
        </Typography>
      </Container>
    </Box>
  );
}

export default ChooseClientsPageHeader;