import request from '../nasm_sdk_v2/request';
import FormData from 'form-data';

export default function users(apiVer) {

  function getMyUser(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/me`);
  }

  function getUserById(userId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}`);
  }

  function requestAccountRemoval(data, apiVersion =  apiVer) {
    return request.post(`${apiVersion}/api/pages/account-removal-request`, data);
  }

  function checkTermsAndPrivacyPolicyAccepted(user, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/check-tc-pp`, user);
  }

  function acceptTermsAndPrivacyPolicy(apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/accept-usage`);
  }

  function uploadAvatar(userId, data, apiVersion = apiVer) {
    const formData = new FormData();
    formData.append('avatar_file', data);
    return request.post(`${apiVersion}/api/users/${userId}/uploadUserAvatar`, formData);
  }

  function updateClientPreferences(userId, data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/${userId}/client-pref`, data);
  }

  function updateClientUser(data, userId, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/users/${userId}`, data);
  }

  function getAssessmentResults(userId, assessmentName, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}/${assessmentName}/results`);
  }

  function getOhsaResults(userId, apiVersion = apiVer) {
    return getAssessmentResults(userId, 'ohsa', apiVersion);
  }

  function getLatestNutrition(userId, apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/${userId}/nutrition/latest`);
  }
  
  function setMeasurementSystem(unitHeight, unitWeight, apiVersion = apiVer) {
    return request.put(`${apiVersion}/api/users/unit-preference`,
      { unit_height: unitHeight, unit_weight: unitWeight });
  }

  function getMeasurementSystem(apiVersion = apiVer) {
    return request.get(`${apiVersion}/api/users/unit-preference`);
  }

  function savePerformanceAssessments(userId, data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/users/${userId}/performance-assessments`, data);
  }

  function updatePerformanceAssessments(userId,  data, apiVersion =  apiVer) {
    return request.put(`${apiVersion}/api/users/${userId}/performance-assessments`, data);
  }

  return {
    getMyUser,
    getUserById,
    checkTermsAndPrivacyPolicyAccepted,
    acceptTermsAndPrivacyPolicy,
    uploadAvatar,
    updateClientPreferences,
    updateClientUser,
    getOhsaResults,
    getLatestNutrition,
    savePerformanceAssessments,
    updatePerformanceAssessments,
    setMeasurementSystem,
    getMeasurementSystem,
    requestAccountRemoval,
  };
}
