import { useEffect, useState } from 'react';
import moment from 'moment';
import nasmApi from '../../api/endpoints';

/**
 *
 * @param {string} clientUserId
 * @param {string} date - contains date with timestamp and timezone
 * @param {boolean} canFetchData - if true, will fetch data from API again
 * @returns {{isLoading: boolean, scheduledWorkoutsByDate: {}, error: boolean}}
 */
export default function useFetchWeeklyCompletion (clientUserId, date, canFetchData) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // key - workout date string
  // value - array of workoutSchedule objects
  const [scheduledWorkoutsByDate, setScheduledWorkoutsByDate] = useState({});

  useEffect(() => {
    setLoading(true);
    nasmApi.dashboard.weeklyCompletion(clientUserId, date)
      .then(response => {
        setError(false);

        // group scheduled workouts by workout date
        const workoutsByDate = {};
        if (response.result instanceof Array && response.result.length > 0) {
          for(const workoutSchedule of response.result) {
            // group all workouts that are scheduled on the same day regardless of time
            const workoutDate = moment(workoutSchedule.workout_date).format('YYYY-MM-DD');
            if(!!workoutsByDate[workoutDate] === false) {
              workoutsByDate[workoutDate] = [workoutSchedule];
            } else {
              workoutsByDate[workoutDate].push(workoutSchedule);
            }
          }
        }

        setScheduledWorkoutsByDate(workoutsByDate);
      })
      .catch(err => {
        console.error(err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [clientUserId, date, canFetchData]);

  return {
    isLoading,
    error,
    scheduledWorkoutsByDate,
  };
}