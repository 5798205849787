import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../styles';


const useStyles = makeStyles({
  root: props => ({
    minHeight: '500px',
    borderRadius: '6px',
    boxShadow: '0 -1px 6px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #e6e6e6',
    backgroundColor: colors.white,
    flex: props.flex || 1,
    height: props.height || 'auto',
  }),
});

function Panel (props) {
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      {props.children}
    </Box>
  );
}

Panel.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Panel;
