import React, { useEffect, useState } from "react";
import { termsPrivacyDialogTextOptions } from "../constants";
import nasmApi from '../api/endpoints';
import ReactMarkdown from "react-markdown";
import { CircularProgress } from "@material-ui/core";

export default function TermsAndPrivacy(props) {
  const { docType } = props;
  const [markdown, setMarkdown] = useState();

  useEffect(() => {
    switch (docType) {
      case termsPrivacyDialogTextOptions.PRIVACY:
        fetchPrivacy();
        break;

      case termsPrivacyDialogTextOptions.TERMS:
        fetchTerms();
        break;
    
      default:
        alert('unsupported document type');
        break;
    }
  }, [docType]);

  const fetchTerms = () => {
    nasmApi.terms.termsConditions().then((termsText) => {
      setMarkdown(termsText.data);
    }).catch(() => {
      setMarkdown('Could not load terms and conditions');
    });
  };

  const fetchPrivacy = () => {
    nasmApi.terms.privacyPolicy().then((privacyText) => {
      setMarkdown(privacyText.data);
    }).catch(() => {
      setMarkdown('Could not load privacy policy');
    });
  };

  if (!markdown) {
    return (
      <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ padding: '30px', paddingBottom: '80px' }}>
      <ReactMarkdown children={markdown} />
    </div>
  );
}