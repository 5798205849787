import React from 'react';
import { List, ListItem, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

export default function WorkoutListSkeleton () {
  return (
    <List>
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
      <WorkoutRowSkeleton />
    </List>
  );
}

const useStyles = makeStyles({
  recordSkeleton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '80px',
    borderBottom: '1px solid #e6e6e6',
    backgroundColor: colors.white,
    padding: '10px 25px',
  },
  leftSection: {
    width: '90%',
  },
  rightSection: {
    width: '10%',
    display: 'flex',
    alignItems: 'flex-end',
  },
});

function WorkoutRowSkeleton () {
  const classes = useStyles();
  return (
    <ListItem className={classes.recordSkeleton}>
      <Box className={classes.leftSection}>
        <Skeleton
          variant='text'
          width='50%'
          style={{ borderRadius: 5.5 }}
        />
        <Skeleton
          variant='text'
          width='10%'
          style={{ borderRadius: 5.5 }}
        />
      </Box>
      <Box className={classes.rightSection}>
        <Skeleton
          variant='text'
          width='50%'
          style={{ borderRadius: 5.5 }}
        />
      </Box>
    </ListItem>
  );
}
