import React, { useState, useEffect, useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { LOGIN_STATES, logout } from '../reducers/currentUserReducer';
import ConfirmDialog from './Dialogs/ConfirmDialog';

// Amount of time, in milliseconds, that the Timout Dialog will be open
const WARNING_TIMEOUT = 2 * 60 * 1000;      // 2 minutes
// Amount of time, in milliseconds, before presenting the Timout Warning dialog
const IDLE_WARNING_TIMEOUT = 18 * 60 * 1000; // 18 minutes

const SessionTimeout = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTimeoutDialogOpen, setIsTimeoutDialogOpen] = useState(false);

  const loginState = useSelector(state => state.currentUser.loginState);
  const isLoggedIn = loginState === LOGIN_STATES.LOGIN;
  const dispatch = useDispatch();

  // Method used when
  const handleOnIdle = () => {
    if (isLoggedIn && !isTimeoutDialogOpen) {
      setIsTimeoutDialogOpen(true);
    }
  };

  const handleOnAction = () => {
    if (isLoggedIn && !isTimeoutDialogOpen) {
      reset();
    }
  };

  const handleLogout = useCallback(() => {
    setIsTimeoutDialogOpen(false);
    dispatch(logout());
  }, [dispatch]);

  const handleOnStayLoggedIn = () => {
    setIsTimeoutDialogOpen(false);
    setTimeLeft(0);
    reset();
  };

  const { reset } = useIdleTimer({
    timeout: IDLE_WARNING_TIMEOUT,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 400,
  });

  useEffect(() => {
    let timeout;
    let interval;

    if (isLoggedIn && isTimeoutDialogOpen) {
      // Handle warning timeout
      timeout = setTimeout(() => {
        handleLogout();
      }, WARNING_TIMEOUT);

      // Handle warning countdown display
      let countDown = WARNING_TIMEOUT;
      interval = setInterval(() => {
        countDown -= 1000;
        setTimeLeft(countDown);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
      setTimeLeft(0);
    };
  }, [isLoggedIn, isTimeoutDialogOpen, dispatch, handleLogout]);

  const timeLeftDuration = moment.duration(timeLeft);
  const minutes = timeLeftDuration.minutes();
  const seconds = timeLeftDuration.seconds().toString().padStart(2, "0");
  const timeLeftString = `${minutes}:${seconds}`;

  const dialogTitle = 'Session Timeout';
  const dialogDesc = `You’re being timed out due to inactivity. You will be logged out in ${timeLeftString}`;
  return (
    <>
      <ConfirmDialog
        open={isTimeoutDialogOpen}
        onClose={handleLogout}
        handleConfirmAction={handleOnStayLoggedIn}
        title={dialogTitle}
        description={dialogDesc}
        cancelButtonTitle='Log out'
        actionButtonTitle='Stay Logged in'
        actionButtonWidth={138}
      />
    </>
  );
};

export default SessionTimeout;
