import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import {colors} from '../../../styles';
import {DAY_COLORS} from './colors';

const useStyles = makeStyles({
  dayNumber: {
    placeSelf: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 14,
    width: 25,
    height: 25,
    // vertically aligns text content by making it 25px tall
    lineHeight: '25px',
    borderRadius: 34,
    backgroundColor: props => props.backgroundColor,
    color: props => props.textColor,
  },
});

function DayNumber (props) {
  // datePassed flag, dateCompleted flag
  const { date, onClick, selectedState } = props;
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [textColor, setTextColor] = useState(colors.dark_grey);

  useEffect(() => {
    // if state is invalid, default to setting background color to transparent
    if(!!DAY_COLORS[selectedState] === false) {
      setBackgroundColor('transparent');
      setTextColor(colors.dark_grey);
    } else {
      setBackgroundColor(DAY_COLORS[selectedState].backgroundColor);
      setTextColor(DAY_COLORS[selectedState].textColor);
    }
  }, [selectedState]);

  const classes = useStyles({ backgroundColor, textColor });

  return (
    <Typography className={classes.dayNumber} onClick={onClick}>
      {moment(date).date()}
    </Typography>
  );
}

DayNumber.propTypes = {
  /**
   * The date that represents the day of the month for the label. This should be a moment date object.
   */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /**
   * The date selected when user clicks on one of the dates in a weekly calendar strip
   */
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onClick: PropTypes.func,
};

export default DayNumber;