import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';

import ProgramListSkeleton from '../LoadingStates/ProgramListSkeleton';
import NoSearchResults from '../EmptyStates/NoSearchResults';
import { ProgramListItem, ProgramItemRef } from './ProgramListItem';
import nasmApi from '../../api/endpoints';
import {useFetchProgramsList} from '../../hooks/programs/FetchProgramList';
import {usePaginationObserver} from '../../util/utilFunctions';
import {makeStyles} from '@material-ui/core/styles';
import {ClearOptions, HighlightSearchBar} from '../Inputs';
import ProgramFilter from './ProgramFilter';
import {SortButton} from '../Buttons';

/**
 * @return {JSX|null}
 */
function ProgramListContent (props) {
  const {
    isInitialListLoading,
    areSearchResultsNotFound,
    programData,
    sizePerPage,
    lastElementRef,
    selectedProgramIndex,
    setSelectedProgramIndex,
    currentTab,
    copyProgramLink,
    showDuplicateDeleteButtons,
    getProgramUrl,
    isFirstTimeUserExp,
  } = props;

  if(isFirstTimeUserExp) {
    return null;
  }

  if (isInitialListLoading) {
    return <ProgramListSkeleton/>;
  }

  if (areSearchResultsNotFound) {
    return <NoSearchResults/>;
  }

  return (
    <Box>
      {programData.map((program, index) => {
        if (index + 1 === sizePerPage) {
          return (
            <ProgramItemRef
              key={program.id}
              ref={lastElementRef}
              programData={program}
              getProgramUrl={getProgramUrl}
              index={index}
              setSelectedProgramIndex={setSelectedProgramIndex}
              isSelected={selectedProgramIndex === index}
              showDeleteButton={currentTab === 'my-programs'}
              copyProgramLink={copyProgramLink}
              showDuplicateDeleteButtons={showDuplicateDeleteButtons}
            />
          );
        }

        return (
          <ProgramListItem
            key={program.id}
            programData={program}
            getProgramUrl={getProgramUrl}
            index={index}
            setSelectedProgramIndex={setSelectedProgramIndex}
            isSelected={selectedProgramIndex === index}
            showDeleteButton={currentTab === 'my-programs'}
            copyProgramLink={copyProgramLink}
            showDuplicateDeleteButtons={showDuplicateDeleteButtons}
          />
        );
      })}
    </Box>
  );
}

const useSearchAndFiltersStyle = makeStyles({
  searchAndFilter: {
    display: 'flex',
    padding: '20px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '10%',
  },
});

/**
 * @return {null}
 */
function SearchAndFilters (props) {
  const  {
    visible,
    searchText,
    programCategories,
    isSortActive,
    isClearOptionVisible,
    onClickClear,
    updateSearchText,
    onPressFilter,
    onCloseFilters,
    onToggleSort,
    onClearOptions,
  } = props;

  const classes = useSearchAndFiltersStyle();

  if(!visible) {
    return null;
  }

  return (
    <Box className={classes.searchAndFilter}>
      <HighlightSearchBar
        isClearable={searchText.length > 0}
        value={searchText}
        onClickClear={onClickClear}
        onChange={updateSearchText}
      />
      <ProgramFilter
        categories={programCategories || []}
        onPressFilter={onPressFilter}
        onCloseFilters={onCloseFilters}
      />
      <SortButton
        text='Most Recent'
        margin='0 0 0 12px'
        isActive={isSortActive}
        onClick={onToggleSort}
      />
      <ClearOptions
        text='Clear All'
        margin='0 0 0 12px'
        onClick={onClearOptions}
        visible={isClearOptionVisible}
      />
    </Box>
  );
}

const useStyles = makeStyles( {
  listContainer: props => ({
    overflowY: props.isFirstTimeUserExp ? 'visible' : 'auto',
    height: props.isFirstTimeUserExp ? 'auto': '88%',
  }),
  listContent: props => ({
    height: props.isFirstTimeUserExp ? 'auto' : 'inherit',
  }),
});

/**
 * @return {JSX|null}
 */
function Wrapper (props) {
  const { isFirstTimeUserExp, currentTab } = props;

  const rootElement = useRef();
  const classes = useStyles( {isFirstTimeUserExp: props.isFirstTimeUserExp});

  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage] = useState(20);
  const [searchText, setSearchText] = useState('');
  const [programCategories, setProgramCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  const [isSortActive, setSortActive] = useState(false);
  const [isClearOptionVisible, setClearOptionVisible] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');


  function clearAllFilters () {
    setCategoryIds([]);
    setProgramCategories(prev => {
      return prev.map(filter => {
        filter.selected = false;
        return filter;
      });
    });
  }

  function updateSearchText (e) {
    setSearchText(e.target.value);
    setPageNumber(1);
  }

  function onClickClear () {
    setSearchText('');
  }

  function onCloseFilters () {
    setCategoryIds(programCategories.filter((category) => {
      return category.selected === true;
    }).map((category) => {
      return category.id;
    }));
    setPageNumber(1);
  }

  function onClearOptions () {
    setSortActive(false);
    setClearOptionVisible(false);
    clearAllFilters();
  }

  function onToggleSort () {
    setSortActive(prev => !prev);
    // toggle clear all to be visible if program category filters are selected
    // or when it was previously set to false
    setClearOptionVisible(prev => categoryIds.length > 0 || prev === false);
  }

  function onPressFilter (index) {
    const filter = { ...programCategories[index], selected: !programCategories[index].selected };
    setProgramCategories(prev => [...prev.slice(0, index), filter, ...prev.slice(index + 1)]);
    setClearOptionVisible(true);
  }

  useEffect(() => {
    let isMounted = true;
    // Get filter categories from API
    nasmApi.nasmPrograms.getProgramCategories().then((categories) => {
      if (isMounted) {
        setProgramCategories(categories.result);
      }
    }).catch(() => {
      if (isMounted) {
        setProgramCategories([]);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  // Reset page number and filters when the current tab changes
  useEffect(() => {
    setPageNumber(1);
    setSortActive(false);
    setClearOptionVisible(false);
    setSearchText('');
    clearAllFilters();
  }, [currentTab]);

  useEffect(() => {
    setPageNumber(1);
    if(isSortActive) {
      setSortField('created_at');
      setSortOrder('desc');
    } else {
      setSortField('');
      setSortOrder('');
    }
  }, [isSortActive]);

  // Tab selection
  const programSources = [];
  if (currentTab === 'my-programs') {
    programSources.push('trainerOwned');
  } else if (currentTab === 'nasm-programs') {
    programSources.push('nasmOwned');
  }

  const { loading, programData, hasMoreDataToLoad } = useFetchProgramsList({
    pageNumber: pageNumber,
    sizePerPage: sizePerPage,
    searchText: searchText,
    programSources: programSources.join(','),
    categoryIds: categoryIds.join(','),
    sortField: sortField,
    sortOrder: sortOrder,
  });

  const lastElementRef = usePaginationObserver({
    setPageNumber,
    hasMoreDataToLoad,
    isLoading: loading,
    rootElement: rootElement.current,
  });

  const isInitialListLoading = loading && programData.length === 0;
  const areSearchResultsNotFound =
    !loading &&
    programData.length === 0 &&
    (searchText.length > 0 || programCategories.length > 0);

  return (
    <Box ref={rootElement} className={classes.listContent}>
      <SearchAndFilters
        visible={!isFirstTimeUserExp}
        searchText={searchText}
        programCategories={programCategories}
        isSortActive={isSortActive}
        isClearOptionVisible={isClearOptionVisible}
        onClickClear={onClickClear}
        updateSearchText={updateSearchText}
        onPressFilter={onPressFilter}
        onCloseFilters={onCloseFilters}
        onToggleSort={onToggleSort}
        onClearOptions={onClearOptions}
      />
      <Box className={classes.listContainer}>
        <ProgramListContent
          isInitialListLoading={isInitialListLoading}
          areSearchResultsNotFound={areSearchResultsNotFound}
          lastElementRef={lastElementRef}
          programData={programData}
          sizePerPage={sizePerPage}
          currentTab={currentTab}
          {...props}
        />
      </Box>
    </Box>
  );
}

ProgramListContent.propTypes = {
  isInitialListLoading: PropTypes.bool,
  areSearchResultsNotFound: PropTypes.bool,
};

export default Wrapper;