import { useState, useEffect, useCallback, useRef } from 'react';
import querystring from 'query-string';
import moment from 'moment';
import {passwordRecoveryUAURL} from '../constants';
import { AVATAR_BASE_URL } from '../api/nasm_sdk/constants';
import placeholderIcon from "../resources/user-dashboard-client-profile-with-no-photo@2x.png";

export function useDebounce (value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Take a URL with a - or _ and replace with spaces to be used in a nav title
export function formatNavTitle (title) {
  if (!(typeof title === 'string')) return;
  return title.replace(/(-|_)/g, ' ');
}

/***
 * @description Used to handle pagination for infinite scrolling panels.
 * The return value is used as a ref for the dom element that needs to trigger this callback
 * where the ref will be a valid DOM element on mount and ref will be null on unmount
 * @param setPageNumber - callback function to update page number
 * @param {Boolean} isLoading
 * @param {Boolean} hasMoreDataToLoad
 * @param rootElement - DOM node used for the root of the IntersectionObserver. If not supplied, IntersectionObserver
 * should default to the viewport
 */
export function usePaginationObserver ({ setPageNumber, isLoading, hasMoreDataToLoad, rootElement }) {
  const observer = useRef();
  return useCallback(
    node => {
      if (!node || isLoading) {
        return;
      }

      // Remove stale observer once list finds new DOM element to observe
      if (observer.current) {
        observer.current.disconnect();
      }

      // See https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreDataToLoad) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      }, {
        root: rootElement,
        threshold: 0.5,
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMoreDataToLoad, setPageNumber, rootElement],
  );
}

export function updateQuery (search, next) {
  const prev = querystring.parse(search, { parseBooleans: true });
  return querystring.stringify({
    ...prev,
    ...next,
  });
}

export function convertFromSecondsToMinutesAndSeconds (seconds) {
  if (!seconds && seconds !== 0) {
    return '';
  }

  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (remainingSeconds < 10) {
    remainingSeconds = `0${remainingSeconds}`;
  }

  return `${minutes}:${remainingSeconds}`;
}

export function convertFromMinutesAndSecondsToSeconds (minutesAndSeconds) {
  if (minutesAndSeconds === '') {
    return 0;
  }

  const [minutes, seconds] = minutesAndSeconds.split(':');
  return (Number.parseInt(minutes, 10) * 60) + Number.parseInt(seconds, 10);
}

/* eslint-disable camelcase */
export function updateExercisePayload (volumeChoice, intensityChoice, variables = {}) {
  const midTempo = 2;

  return {
    sets: variables?.sets ?? '',
    weight: variables?.weight ?? '',
    rest_tempo: variables?.rest_tempo ?? 0, // n/a,
    rest: variables?.rest ? convertFromSecondsToMinutesAndSeconds(variables?.rest) : '',

    // volume type
    volumeChoice: volumeChoice,
    reps: volumeChoice === 'reps' ? variables?.reps ?? '' : '',
    dur_seconds: volumeChoice === 'duration' ? variables?.dur_seconds ?? '' : '',
    duration: volumeChoice === 'duration'
      ? convertFromSecondsToMinutesAndSeconds(variables?.dur_seconds ?? null) : '',
    distance: volumeChoice === 'distance' ? variables?.distance ?? '' : '',
    distance_units: volumeChoice === 'distance' ? variables?.distance_units : '',

    // intensity type ~ set to empty string when intensityChoice is set to n/a to trigger
    // validation error whenever volumeChoice is set to distance
    intensityChoice: (!intensityChoice || intensityChoice === 'n/a') ? '' : intensityChoice,
    tempo: intensityChoice === 'tempo' ? variables?.tempo ?? midTempo : '',
    pace: intensityChoice === 'pace' ? variables?.pace ?? '' : '',
    pace_units: intensityChoice === 'pace' ? variables?.pace_units ?? 'mph' : '',

    exercise_sides: variables?.exercise_sides ?? '',

    notes: variables?.notes ?? '',
    name: variables.name,
    id: variables.id,
  };
}
/* eslint-enable camelcase */

export function snakeToCamelCase (str) {
  return str.replace(
    /_[a-zA-Z]/g,
    matchedStr => {
      return matchedStr.toUpperCase()
        .replace('_', '');
    },
  );
}

export function findPrimaryChoice (objectMap, choices) {
  let finalChoice = '';
  for (const choice of choices) {
    let backendChoice = '';
    if (choice === 'duration') {
      backendChoice = 'dur_seconds';
    } else {
      backendChoice = choice;
    }

    if (objectMap[backendChoice]) {
      if (finalChoice.length === 0) {
        finalChoice = choice;
      } else if (finalChoice.length > 0 && (objectMap[finalChoice] === 0 || objectMap[finalChoice] === '')) {
        // Prefer choice with non-zero value
        finalChoice = choice;
      }
    }
  }

  // if finalChoice is still an empty string default to 'n/a' option
  // this is needed especially when setting the intensity choice
  if (finalChoice === '') {
    finalChoice = 'n/a';
  }

  return finalChoice;
}

/**
 *
 * @param {String} startDate
 * @returns {Moment}
 */
export function getSelectedDate (startDate) {
  if (startDate) {
    return moment(startDate).startOf('day');
  }

  return moment().startOf('day');
}

export function addPossessiveApostrophe(name) {
  if(!name || name.length === 0) {
    return '';
  }

  if(name[name.length - 1] === 's') {
    return `${name}'`;
  }

  return `${name}'s`;
}

// Opens password reset request page for Trainers that are on UA's system
export function makeResetPasswordRequest () {
  const url = passwordRecoveryUAURL();
  window.open(url) || window.location.replace(url);
}

export function formatMinutesAndSecondsDisplay (value) {
  // remove non-numeric values and colon
  let timeSlotArray = value.replace(/[^\d]/g, '')
    .replace(':', '').split('');

  // strip consecutive leading 0s
  for (let i = 0;i < timeSlotArray.length; ++i) {
    if(timeSlotArray[i] === '0') {
      timeSlotArray.splice(i, 1);
      --i;
    } else {
      break;
    }
  }

  const time = timeSlotArray.join('');
  let timeDisplay = '';

  if (time.length === 0) {
    timeDisplay = '';
  }  else if (time.length === 1) {
    timeDisplay = `00:0${time}`;
  } else if (time.length === 2) {
    timeDisplay = `00:${time[0]}${time[1]}`;
  } else if (time.length === 3) {
    timeDisplay = `0${time[0]}:${time[1]}${time[2]}`;
  } else if (time.length >= 4) {
    // cap number of digits inputted to 4 characters
    timeDisplay = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
  }

  return timeDisplay;

}

/**
 * Generates the avatar URL for a user based on their avatar data.
 *
 * The function checks if the `avatar` object exists and contains both `blob_name` and `user_id` properties.
 * If both are present, it constructs and returns the full URL to the user's avatar using the base URL.
 * If either property is missing, it returns an empty string.
 *
 * @param {Object} avatar - The avatar object containing the `blob_name` and `user_id`.
 * @returns {string} - The constructed URL of the user's avatar or an empty string if the necessary data is unavailable.
 */
export function getAvatarUrl(avatar) {
  if (!avatar || !avatar?.blob_name || !avatar?.user_id) {
    return '';
  }

  return `${AVATAR_BASE_URL}/useravatars/${avatar.user_id}/${avatar.blob_name}`;
}

/**
 * Renders the user's full name based on the available properties of the item object.
 * @param {*} user - The object containing user data.
 * @returns - The user's name based on available information, or an empty string if no name data is available.
 */
export function renderUserName(user){
  if (user?.full_name)  {
    return user.full_name;
  }

  let fullName = '';
  if (user?.first_name) {
    fullName += user.first_name;
  }
  if (user?.last_name) {
    fullName += fullName.length ? ` ${user.last_name}` : user.last_name;
  }

  return fullName;
}

export function reconcileAvatarUrlSource(user) {
  if (!user) {
    return placeholderIcon;
  }

  if (user.avatar_url) {
    return user?.avatar_url;
  }

  return getAvatarUrl(user.avatar) || placeholderIcon;
}
