import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  VictoryStack,
  VictoryBar,
  VictoryLegend,
} from 'victory';

const useStyles = makeStyles({
  // Needs explicit dimensions in pixels to keep bar chart within Card's boundaries
  nutritionContainer: {
    width: '100%',
    height: 145,
    // graph elements are set as absolute positions and will need a relative point to reference
    // their positions from
    position: 'relative',
  },
  kcal: {
    position: 'absolute',
    textAlign: 'right',
    fontSize: 14,
    right: 5,
    top: 45,
  },
});

const BAR_COLORS = Object.freeze({
  yellow: '#f6aa2c',
  purple: '#b666d7',
  navyBlue: '#163963',
});

function transformData (nutritionData) {
  const data = [];

  // Note: axes will be inverted when horizontal prop is passed into VictoryBar component
  if(nutritionData.protein) {
    data.push([{x: 0, y: nutritionData.protein }]);
  }

  if(nutritionData.carbohydrates) {
    data.push([{x: 0, y: nutritionData.carbohydrates}]);
  }

  if(nutritionData.fat) {
    data.push([{x: 0, y: nutritionData.fat}]);
  }

  return data;
}

function getLegendData (nutritionData) {
  return [
    {name: `protein: ${nutritionData.protein}g `, symbol: { fill: BAR_COLORS.yellow, type: "circle"}},
    {name: `carbs: ${nutritionData.carbohydrates}g`, symbol: { fill: BAR_COLORS.purple, type: "circle"}},
    {name: `fat: ${nutritionData.fat}g`, symbol: { fill: BAR_COLORS.navyBlue, type: "circle"}},
  ];
}

function NutritionBar (props) {
  const { nutritionData = {} } = props;
  const classes = useStyles();
  const [graphData, setGraphData] = useState([]);

  const nutritionId = nutritionData.id;
  useEffect(() => {
    setGraphData(transformData(nutritionData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nutritionId]);

  // VictoryChart is not used here because it will automatically include
  // axis lines which isn't part of the current design when presenting this graph on a card
  return (
    <Box className={classes.nutritionContainer}>
      {/* https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox */}
      {/* SVG height to viewBox height ratio =  svgHeight / viewBoxHeight  */}
      {/* SVG width to viewBox width ratio =  svgWidth / viewBoxWidth  */}
      {/* The ratios scale the graph up when svgHeight > viewBoxHeight or svgWidth > viewBoxHeight */}
      {/* The ratios scale he graph down when svgHeight < viewBoxHeight or svgWidth < viewBoxHeight */}
      <svg width={'100%'} height={'145'} viewBox={'25 200 500 145'} style={{ position: 'absolute' }}>
        <VictoryStack
          standalone={false}
          colorScale={[BAR_COLORS.yellow, BAR_COLORS.purple, BAR_COLORS.navyBlue]}
        >
          {graphData.map((data, i) => {
            return <VictoryBar
              standalone={false}
              horizontal
              data={data}
              key={i}
              barWidth={40}
            />;
          })}
        </VictoryStack>
      </svg>
      <svg width={'100%'} height={'35'} viewBox={'-15 -5 375 35'} style={{ position: 'absolute', top: 100 }}>
        <VictoryLegend
          standalone={false}
          orientation='horizontal'
          gutter={20}
          width={100}
          height={75}
          data={getLegendData(nutritionData)}
        />
      </svg>
      <Typography className={classes.kcal}>
        kcal {nutritionData.calories}
      </Typography>
    </Box>
  );
}

NutritionBar.propTypes = {
  nutritionData: PropTypes.shape({
    id: PropTypes.string,
    protein: PropTypes.number,
    carbohydrates: PropTypes.number,
    fat: PropTypes.number,
    /***
     * Date recorded in YYYY-MM-DD format
     */
    date: PropTypes.string,
    client_user_id: PropTypes.string,
  }),
};

export default NutritionBar;