import { useState, useEffect } from 'react';
import axios from 'axios';

import nasmApi from './api/endpoints';

export function useFetchWorkoutsList ({
  pageNumber = 1,
  searchText = '',
  sizePerPage = 10,
  getPublic = false,
  getPrivate = false,
  sortField = '',
  sortOrder = '',
  workoutFilters = '',
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [workoutData, setWorkoutData] = useState([]);
  const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false);

  // Reset data when new search criteria is passed in
  useEffect(() => {
    setWorkoutData([]);
  }, [getPublic, getPrivate, searchText, sortField, sortOrder, workoutFilters]);

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    setHasError(false);

    const searchParams = {
      page: pageNumber,
      size: sizePerPage,
      getPublic: getPublic,
      getPrivate: getPrivate,
      workoutFilter: workoutFilters,
    };

    if (searchText && searchText.length > 0) {
      searchParams.search = searchText;
    }

    if(sortField && sortOrder && sortField.length > 0 && sortOrder.length > 0) {
      searchParams.sortField = sortField;
      searchParams.sortOrder = sortOrder;
    }

    const cancel = axios.CancelToken.source();
    nasmApi.nasmWorkouts
      .findWorkouts(searchParams, cancel.token)
      .then(data => {
        // prevents receiving React memory leak error
        if (isMounted) {
          setIsLoading(false);

          if (!data.isRequestCancelled) {
            setHasMoreDataToLoad(data.result.nextPage && data.result.nextPage.length > 0);
            setWorkoutData(prevWorkoutData => {
              return [
                ...prevWorkoutData,
                ...data.result.workouts,
              ];
            });
          } else {
            setHasMoreDataToLoad(false);
          }
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(err => setHasError(true));

    // Acts as a clean up function that gets called before executing the next effect
    // and runs before the component is removed from the UI
    return () => {
      isMounted = false;
      cancel.cancel('Workout Search Request Cancelled');
    };
  }, [pageNumber, searchText, sizePerPage, getPublic, getPrivate, sortField, sortOrder, workoutFilters]);

  return { isLoading, hasError, workoutData, hasMoreDataToLoad };
}

export function useFetchWorkoutFilters () {
  const [workoutFilters, setWorkoutFilters] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    nasmApi.nasmWorkouts.getWorkoutFilters().then((result) => {
      setWorkoutFilters(result.result);
    }).catch(() => {
      setError(true);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return {
    workoutFilters,
    loading,
    error,
  };
}
