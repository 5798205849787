import { useEffect, useState } from 'react';
import nasmApi from '../../api/endpoints';
import nasm from "../../dataManager/apiConfig";

export default function useFetchProgramScheduleById ({ userId, scheduleId, eventDate }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scheduleStatus, setScheduleStatus] = useState(-1);
  const [nasmProgramId, setNasmProgramId] = useState('');
  const [programCategory, setProgramCategory] = useState({});
  const [workouts, setWorkouts] = useState([]);
  const [programId, setProgramId] = useState('');
  const [programName, setProgramName] = useState('');
  const [programStartDate, setProgramStartDate] = useState('');
  const [programEndDate, setProgramEndDate] = useState('');

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  function clearData () {
    setStartDate(null);
    setEndDate(null);
    setScheduleStatus(-1);
    setNasmProgramId('');
    setProgramCategory({});
    setWorkouts([]);
  }

  useEffect(() => {
    setIsDataLoaded(false);
    getProgramDetails(userId, scheduleId, eventDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, scheduleId, eventDate]);

  async function getProgramDetails(userId, scheduleId, eventDate) {
    try {
      const res = await nasm.api.getCalendarDay(userId, eventDate);
    if (res && res.scheduled_workouts?.length) {
      const program = res.scheduled_workouts?.find((wrkt) => wrkt.user_schedule_id === scheduleId);
      if (program) {
        const programStartDate = program.program_start_date;
        const programEndDate = program.program_end_date;
        setProgramStartDate(programStartDate);
        setProgramEndDate(programEndDate);
        await fetchSchedule(userId, scheduleId, programStartDate, programEndDate);
      } else {
        throw new Error('Unable to fetch schedule. Please try again later.');
      }
    }
    } catch (error) {
      window.alert(error.message ?? error);
      setError(true);
      setIsDataLoaded(false);
      clearData();
    }
  }

  async function fetchSchedule(userId, scheduleId, programStartDate, programEndDate) {
    nasmApi.workoutSchedule.getUserAssignedProgramDetails({
      userId,
      scheduleId,
      program_start_date: programStartDate,
      program_end_date: programEndDate,
    })
      .then(data => {
        setIsDataLoaded(true);
        setError(false);

        const response = data.result;
        setStartDate(response.start_date);
        setEndDate(response.end_date);
        setScheduleStatus(response.status);
        setNasmProgramId(response.nasm_program_id);
        setProgramCategory(response.program_category);
        setWorkouts(response.workouts);
        setProgramId(response.id);
        setProgramName(response.name);
      })
      // eslint-disable-next-line handle-callback-err
      .catch(error => {
        setError(true);
        setIsDataLoaded(false);
        clearData();
      });
  }

  return {
    isDataLoaded,
    error,
    startDate,
    endDate,
    scheduleStatus,
    nasmProgramId,
    programCategory,
    workouts,
    programId,
    programName,
    programStartDate,
    programEndDate,
  };
}
