import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { colors } from '../../../styles';

import { ASSESSMENT_TYPES} from '../../../constants';
import {validateAssessmentType} from './propValidator';


const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 65,
  },
  assessmentTypePicker: props => ({
    border: 'solid 2px #ccd3d7',
    borderRadius: 4,
    width: props.width || 'auto',
  }),
  assessmentTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    position: 'relative',
    flex: 1,
    borderRight: '1px solid #ccd3d7',
    borderCollapse: 'collapse',
    textAlign: 'center',
  },
  tab2: {
    position: 'relative',
    flex: 1,
    textAlign: 'center',
  },
});

function AssessmentTypePicker (props) {
  const { assessmentType, setAssessmentType = (newAssesssmentType) => {}, width } = props;
  const classes = useStyles({ width });

  function onClickAssessment (e) {
    setAssessmentType(e.target.id);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.assessmentTypePicker}>
        <Box className={classes.assessmentTypeContainer}>
          <Box className={classes.tab}>
            <TabButton
              assessmentType={ASSESSMENT_TYPES.CARDIO}
              isSelected={assessmentType === ASSESSMENT_TYPES.CARDIO}
              onClick={onClickAssessment}
            />
          </Box>
          <Box className={classes.tab}>
            <TabButton
              assessmentType={ASSESSMENT_TYPES.ENDURANCE}
              isSelected={assessmentType === ASSESSMENT_TYPES.ENDURANCE}
              onClick={onClickAssessment}
            />
          </Box>
          <Box className={classes.tab2}>
            <TabButton
              assessmentType={ASSESSMENT_TYPES.STRENGTH}
              isSelected={assessmentType === ASSESSMENT_TYPES.STRENGTH}
              onClick={onClickAssessment}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useTabButtonStyles = makeStyles({
  opacityOverlay: props => ({
    width: 'calc(100% + 2px)',
    height: '100%',
    cursor: 'pointer',
    borderRadius: 1,
    backgroundColor: props.isSelected ? colors.selected_highlight_copy_6 : 'inherit',
    '&:hover': {
      backgroundColor: props.isSelected ? colors.selected_highlight_copy_6 : 'rgba(0, 0, 0, 0.06)',
    },
    '&:active': {
      backgroundColor: props.isSelected ? colors.selected_highlight_copy_6 : 'rgba(0, 0, 0, 0.12)',
    },
  }),
  text: props => ({
    padding: '4px 0',
    color: props.isSelected ? colors.charcoal_grey : colors.dark_grey,
  }),
});

function TabButton (props) {
  const { assessmentType, isSelected, onClick } = props;
  const classes = useTabButtonStyles({ isSelected });

  return (
    <Box className={classes.opacityOverlay} onClick={onClick}>
      <Typography id={assessmentType} className={classes.text}>
        {assessmentType}
      </Typography>
    </Box>
  );
}

AssessmentTypePicker.propTypes = {
  assessmentType: validateAssessmentType,
  setAssessmentType: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AssessmentTypePicker;