import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { colors } from '../../../styles';

const useStyles = makeStyles({
  divider: {
    height: 28,
    width: props => props.width || '100%',
    backgroundColor: 'white',
    borderTop: `1px solid ${colors.divider_line_light_blue}`,
    borderBottom: `1px solid ${colors.divider_line_light_blue}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  smallRect: {
    width: 64,
    height: 8,
    borderRadius: 16,
    border: `solid 2px ${colors.light_grey}`,
  },
});

function CalendarDivider (props) {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <Box className={classes.divider} onClick={onClick}>
      <Box className={classes.smallRect} />
    </Box>
  );
}

export default CalendarDivider;