import { FormControl, InputBase, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';

import ErrorText from './ErrorText';
import { colors } from '../../styles';

const useStyles = makeStyles({
  underlineText: {
    marginTop: '13px',
  },
  input: {
    fontSize: '17px',
    fontWeight: 500,
  },
  underlineColor: props => ({
    width: '100%',
    borderBottom: props.showError && !!props.errorText
      ? `1px solid ${colors.yellow_button_style}`
      : '1px solid rgba(182, 189, 195, 0.5)',
  }),
});

function UnderlineTextInput (props) {
  const {
    value,
    readOnly,
    placeholder,
    onNameChanged,
    onNameEditFinished,
    errorText,
    showError,
    name,
    onFocus,
    errorColor = colors.yellow_button_style,
  } = props;

  const classes = useStyles({ showError, errorText });

  return (
    <Box className={classes.underlineText}>
      <FormControl className={classes.underlineColor}>
        <InputBase
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onNameChanged}
          onBlur={(e) => {
            if (!onNameEditFinished) {
              return;
            }

            // When text field is readonly, this callback gets triggered
            // when you un-highlight text that exists within the text field.
            // Modify the workout name value when it can be editable.
            if (!readOnly) {
              onNameEditFinished(e);
            }
          }}
          className={classes.input}
          readOnly={readOnly}
          onFocus={(e) => {
            if (!onFocus) {
              return;
            }

            if (!readOnly){
              onFocus(e);
            }
          }}
        />
      </FormControl>
      <ErrorText
        textColor={errorColor}
        errorText={errorText}
        showError={showError}
      />
    </Box>
  );
}

UnderlineTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  onNameEditFinished: PropTypes.func,
  errorText: PropTypes.string,
  showError: PropTypes.bool,
  name: PropTypes.string,
};

export default UnderlineTextInput;
