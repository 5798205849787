import nasmApi from '../api/endpoints';
import { CREATE_USER_FLOW, FEATURE_FLAGS } from '../constants';

// Adopted from EDGE App
export async function validateEmail(email, clubId, locationId) {
  const result = {};
  try {
    const data = {
      email,
    };
    if (clubId) {
      data.club_id = clubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && locationId) {
          data.location_id = locationId;
      }
    }
    await nasmApi.trainer.trainerValidateClientEmail(data);
    result.status = CREATE_USER_FLOW.CREATE;
  } catch (error) {

    if (error.data.error.code === 4005) {
      throw error;
    }

    result.message = error.data.message;
    if (error.data.error.code === 2001) {
      result.status = CREATE_USER_FLOW.INVITE;
    } else {
      result.status = CREATE_USER_FLOW.ERROR;
    }
  }

  return result;
}
