import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useExternalScript } from '../../hooks/ExternalScript';
import nasmApi from '../../api/endpoints';
import CalculatorFTUE from './CalculatorFTUE';
import FTUImg from '../../resources/ftue-1-rm-icon.svg';
import HeaderIcon from '../../resources/weight-icon.svg';
import { ROLES } from '../../constants';
import { colors } from '../../styles';

const useStyles = makeStyles({
  profilePaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '560px',
    height: '760px',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
});

const OneRmCalculatorContainer = ({ currentUser, isOpen, onClose }) => {
  const ftueShown = localStorage.getItem('one-rm-shown');
  const [oneRmShown, setOneRmShown] = useState(ftueShown === 'true');

  const widgetUrl = process.env.REACT_APP_ONE_RM_WIDGET_URL || '/dist/onerm/index.js';
  const scriptState = useExternalScript({ url: widgetUrl, canStartLoading: isOpen });
  const canMount = isOpen && scriptState === 'ready';
  const unitWeight = currentUser?.unit_weight || 'lb';
  const hasSelectedClient = currentUser?.role === ROLES.CLIENT;

  const classes = useStyles();

  useEffect(() => {
    // Pass onClose function to custom widget element:
    const oneRmCalculator = document.querySelector('one-rm-calculator');
    if (canMount && oneRmCalculator) {
      oneRmCalculator.closewidget = onClose;
    }
  }, [canMount, onClose, oneRmShown]);

  const saveOneRmRecord = useCallback(async (oneRmData) => {
    if (!hasSelectedClient) {
      return;
    }
    // if we have client user ID for this screen in redux, save their OneRm results
    try {
      await nasmApi.oneRm.saveResults(currentUser?.id, oneRmData);
    } catch (error) {
      showAlert(error);
    } finally {
      const SaveRequestComplete = new CustomEvent("saveRequestComplete");
      window.dispatchEvent(SaveRequestComplete);
    }
  }, [currentUser.id, hasSelectedClient]);

  useEffect(() => {
    window.addEventListener('onSaveRequest', (event) => {
      const { oneRmData } = event.detail;
      if (oneRmData) {
        saveOneRmRecord(oneRmData);
      }
    });
    return () => {
      window.removeEventListener('onSaveRequest');
    };
  }, [saveOneRmRecord]);

  useEffect(() => {
    window.addEventListener('fetchCurrentOneRm', getCurrentOneRm);
    window.addEventListener('fetchOneRmHistory', getOneRmHistory);
    return () => {
      window.removeEventListener('fetchCurrentOneRm', getCurrentOneRm);
      window.removeEventListener('fetchOneRmHistory', getOneRmHistory);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentOneRm = async () => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const response = await nasmApi.oneRm.getCurrentOneRm(currentUser?.id);
      const receiveCurrentOneRmEvent = new CustomEvent("onReceiveCurrentOneRm", {
        cancelable: true,
        detail: {
          currentOneRmRecord: response?.result,
        },
      });
      window.dispatchEvent(receiveCurrentOneRmEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const getOneRmHistory = async (event) => {
    if (!hasSelectedClient) {
      return;
    }
    try {
      const { page } = event?.detail;
      const response = await nasmApi.oneRm.getHistory(currentUser?.id, { page, size: 20 });
      const receiveOneRmHistoryEvent = new CustomEvent("onReceiveOneRmHistory", {
        cancelable: true,
        detail: {
          OneRmHistoryRecord: response?.result,
        },
      });
      window.dispatchEvent(receiveOneRmHistoryEvent);
    } catch (error) {
      showAlert(error);
    }
  };

  const showAlert = (error) => {
    alert(error?.message || 'Something went wrong! Please try again later.');
  };

  const closeOneRm = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const startOneRm = () => {
    setOneRmShown(true);
    localStorage.setItem('one-rm-shown', 'true');
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeOneRm}
      classes={{ paper: classes.profilePaper, scrollPaper: classes.scrollPaper }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      {!oneRmShown
        ? <CalculatorFTUE
          headerText='1RM Calculator'
          headerImg={HeaderIcon}
          headerColor={colors.carbon_black}
          heroText='Create your first 1RM calculation'
          heroImg={FTUImg}
          subHeadingText={
            'Your one-rep max is the max weight you can lift for a single repetition for a given exercise.'
          }
          startButtonText='Calculate 1RM'
          onStart={startOneRm}
          onClose={onClose}
        />
        : <>
          {!canMount && <CircularProgress />}
          {canMount
            && <one-rm-calculator
              data-edge-web='true'
              data-unit-weight={unitWeight}
              selected-client={hasSelectedClient ? 'true' : 'false'}
            />
          }
        </>
      }
    </Dialog>
  );
};

export default OneRmCalculatorContainer;
