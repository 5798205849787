import React from 'react';
import { Box, Typography } from '@material-ui/core';

import DayOfWeekCircle from './DayOfWeekCircle';

import { colors } from '../../styles';

import { useDispatch } from 'react-redux';
import { selectWorkoutDay } from '../../reducers/selectedProgramReducer';

export default function DayOfWeekSelector (props) {
  const { workout = {}, handleDayOfWeekSelector } = props;
  const daysOfWeek = workout.days_of_week || workout.days_of_week || [];
  const dispatch = useDispatch();

  return (
    <Box>
      <Box style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <Typography>
          {workout.name}
        </Typography>
        {/* Show workout duration when retrieving the workout from the backend */}
        {!!workout.total_dur_seconds &&
          <Typography style={{ marginRight: 32, color: colors.steel }}>
            {convertSecondsToMinutes(workout.total_dur_seconds)} min
          </Typography>}
      </Box>
      <Box style={{ display: 'flex', marginTop: 30 }}>
        {DAY_IDS.map(([dayId, dayOfWeek], index) => {
          const d = Number.parseInt(dayId, 10);
          return (
            <DayOfWeekCircle
              key={dayId}
              text={dayOfWeek}
              marginRight={index === 0 ? 0 : 20}
              selected={daysOfWeek.includes(d)}
              onClick={() => {
                dispatch(selectWorkoutDay({
                  workoutKey: workout.key,
                  dayId: d,
                }));
                handleDayOfWeekSelector();
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

const DAY_IDS_MAP = Object.freeze({
  0: 'S', // Sunday
  1: 'M', // Monday
  2: 'T', // Tuesday
  3: 'W', // Wednesday
  4: 'T', // Thursday
  5: 'F', // Friday
  6: 'S', // Saturday
});

const DAY_IDS = Object.entries(DAY_IDS_MAP);

const convertSecondsToMinutes = (seconds) => Math.floor(seconds / 60);
