import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import VideoWorkoutRow from './VideoWorkoutRow';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

// Handling navigation and redux logic in here
// that will wrap around the UI component
function ViewVideoWorkout (props) {
  const {
    workout,
    index,

    getWorkoutUrlParams,
    onDeleteWorkout,
    onChangeWorkoutPending,
  } = props;

  const history = useHistory();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onClickWorkout = () => {
    // Pass in true to getWorkoutUrlParams to open the Video Workout panel
    const { pathname, search } = getWorkoutUrlParams(workout.id, true);
    history.push({ pathname, search });
    onChangeWorkoutPending(workout, index);
  };

  return (
    <>
      <VideoWorkoutRow
        onClickWorkout={onClickWorkout}
        isVideoProcessing={workout.uploaded_media?.status === 'PENDING'}
        onDeletePressed={() => setShowDeleteDialog(true)}
        showDeleteButton
        showDuplicateButton={false}
        { ...props }
      />
      <ConfirmDialog
        title='Delete Workout'
        description={`Deleting ${workout.name ? `the workout "${workout.name}"` : 'this workout'} will remove it ` +
        'from your library and all programs. Are you sure? '}
        actionButtonTitle='Delete'
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        handleConfirmAction={() => onDeleteWorkout({ name: workout.name, id: workout.id })}
      />
    </>
  );
}

export default ViewVideoWorkout;