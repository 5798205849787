import request from '../nasm_sdk_v2/request';

const ONE_RM_BASE_ENDPOINT = (apiVersion, userId) => `${apiVersion}/api/users/${userId}/one-rm`;

export default function oneRm(apiVer) {
  function saveResults(userId, jsonData, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error("userId is required to save One Rep Max results."),
      );
    }
    return request.post(`${ONE_RM_BASE_ENDPOINT(apiVersion, userId)}`, jsonData);
  }

  function getCurrentOneRm(userId, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch current One Rep Max.'),
      );
    }
    return request.get(`${ONE_RM_BASE_ENDPOINT(apiVersion, userId)}/current`);
  }

  function getHistory(userId, searchParams, apiVersion = apiVer) {
    if (!userId) {
      return Promise.reject(
        new Error('userId is required to fetch One Rep Max history.'),
      );
    }
    return request.get(`${ONE_RM_BASE_ENDPOINT(apiVersion, userId)}/history`, {
      params: {
        ...searchParams,
      },
    });
  }

  return {
    saveResults,
    getCurrentOneRm,
    getHistory,
  };
}