import request from '../nasm_sdk_v2/request';
import { NASM_OAUTH_CLIENT } from '../nasm_sdk_v2/constants';

const reqConfig = {
  auth: {
    username: NASM_OAUTH_CLIENT.ID,
    password: NASM_OAUTH_CLIENT.SECRET,
  },
};

export default function register(apiVer) {

  function trainer(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/register/trainer`, data);
  }

  function client(data, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/register/client`, data);
  }

  /**
   *
   * @param {string} userId - uuid format
   * @param {string} password
   * @param {string} apiVersion
   * @returns {Promise<*>}
   */
  function resetPassword(userId, password, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/register/${userId}/reset-password`, { password });
  }

  /**
   *
   * @param {string} email
   * @param {string} apiVersion
   * @returns {Promise<*>}
   */
  function checkEmail(email, apiVersion = apiVer) {
    return request.post(`${apiVersion}/api/register/check-email`, { email });
  }

  function UATrainer(email, password, apiVersion = apiVer) {
    const reqBody = { email, password, accepted_terms: true };
    return request.post(`${apiVersion}/api/register/trainer/ua`, reqBody);
  }

  function migrateEdgeAccountToUA(email, password, apiVersion = apiVer) {
    const reqBody = { email, password };
    return request.post(`${apiVersion}/api/register/migrate-account-ua`, reqBody, reqConfig);
  }

  function verifyCredentials(email, password, apiVersion = apiVer) {
    const reqBody = { email, password };
    return request.post(`${apiVersion}/api/register/verify-credentials`, reqBody, reqConfig);
  }

  function linkAccountUA(email, password, apiVersion = apiVer) {
    const reqBody = { email, password };
    return request.post(`${apiVersion}/api/register/link-account-ua`, reqBody);
  }

  return {
    trainer,
    client,
    resetPassword,
    checkEmail,
    UATrainer,
    migrateEdgeAccountToUA,
    verifyCredentials,
    linkAccountUA,
  };
}

