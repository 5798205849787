import React from 'react';
import PropTypes from 'prop-types';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';

import { colors } from '../../../styles';
import {styled} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import ClientMeasurementController from '../ClientMeasurement/ClientMeasurementController';

const HeaderText = styled(Typography)({
  color: colors.white,
  fontWeight: 500,
  fontSize: 20,
  lineHeight: 1.95,
});

const CenterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: 'inherit',
  marginLeft: 20,
});

function ClientMeasurementsCard (props) {
  const  { loading, measurementsData = [], unitPreferences = {} } = props;

  return (
    <CardContainer
      width={420}
      height={300}
    >
      <CardHeader backgroundColor={colors.red} height={55}>
        <CenterContainer>
          <HeaderText>
            Measurements
          </HeaderText>
        </CenterContainer>
      </CardHeader>
      <ClientMeasurementController
        loading={loading}
        measurementsData={measurementsData}
        unitPreferences={unitPreferences}
      />
    </CardContainer>
  );
}

ClientMeasurementsCard.propTypes = {
  loading: PropTypes.bool,
  measurementsData: PropTypes.arrayOf(PropTypes.shape({
    /***
     * String format: YYYY-MM-DD
     */
    date: PropTypes.string,
    /***
     * Could be in either lbs or kg
     */
    weight: PropTypes.number,
    /***
     * number between 0 and 100
     */
    body_fat_percentage: PropTypes.number,
    /***
     * number between 0 and 100
     */
    lean_body_mass_percentage: PropTypes.number,
  })),
  unitPreferences: PropTypes.shape({
    /***
     * Unit Height can be set as in (inches) or cm (centimeters) for example
     */
    unit_height: PropTypes.string,
    /***
     * Unit Weight can be set as lb (pounds) or kg (kilograms) for example
     */
    unit_weight: PropTypes.string,
  }),
};

export default ClientMeasurementsCard;