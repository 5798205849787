import { useEffect, useState } from 'react';
import nasmApi from '../../api/endpoints';

export default function useFetchExerciseDetails (exerciseId) {
  const [exerciseDetails, setExerciseDetails] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (exerciseId) {
      // Reset Exercise Details if new exerciseId is set
      setExerciseDetails({});
      setIsDataLoaded(false);
      setError(false);

      nasmApi.nasmExercises
        .getNasmExercise(exerciseId)
        .then(data => {
          if (isMounted) {
            setExerciseDetails(data.result);
            setIsDataLoaded(true);
            setError(false);
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {
          // console.error(error);
          setError(true);
          setExerciseDetails({});
          setIsDataLoaded(false);
        });
    }

    return () => { isMounted = false; };
  }, [exerciseId]);

  return { isDataLoaded, exerciseDetails, error };
}
