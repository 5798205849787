import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DialogCircleButton from './DialogCircleButton';
import ScheduleBanner from './ScheduleBanner';
import WorkoutsList from './WorkoutsList';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import ScheduleDialog from '../SchedulePopover/ScheduleDialog';
import SectionDivider from './SectionDivider';

import { colors } from '../../styles';

import useFetchProgramScheduleById from '../../hooks/workoutSchedule/FetchProgramScheduleById';
import {
  clearProgram,
  deleteScheduledProgram,
  scheduleProgram,
  scheduleProgramToday,
  selectScheduledProgram,
} from '../../reducers/selectedProgramReducer';
import { selectWorkout, clearWorkout } from '../../reducers/selectedWorkoutReducer';
import { rescheduleProgramContext } from '../../reducers/programContextReducer';
import { updateQuery } from '../../util/utilFunctions';
import moment from 'moment';

const useStyles = makeStyles({
  dialogContainer: {
    minWidth: 554,
    minHeight: 645,
    border: `1px solid ${colors.selected_highlight_copy_6}`,
    borderRadius: 17,
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
    overflowY: 'hidden',
    overflowX: 'hidden',
    marginBottom: 0,
  },
});

export default function ViewScheduleDialog (props) {
  const {
    open,
    setOpen,
    setCanFetchData,
    selectedScheduleId,
    selectedWorkoutId,
    selectedClient,
    eventDate,
  } = props;

  const classes = useStyles();

  const onClose = () => setOpen(false);
  const refreshCalendarMonthData = () => {
    setCanFetchData(false);
    setCanFetchData(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogContainer }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <DialogContent
        selectedScheduleId={selectedScheduleId}
        selectedWorkoutId={selectedWorkoutId}
        selectedClient={selectedClient}
        eventDate={eventDate}
        onClose={onClose}
        refreshCalendarMonthData={refreshCalendarMonthData}
      />
    </Dialog>
  );
}

function DialogContent (props) {
  const {
    selectedScheduleId,
    selectedWorkoutId,
    selectedClient,
    eventDate,
    onClose,
    refreshCalendarMonthData,
  } = props;

  const selectedProgram = useSelector(state => state.selectedProgram);

  const [scheduledWorkout, setScheduledWorkout] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [videoWorkoutImage, setVideoWorkoutImage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  // TODO: use confirmation dialog box component
  const onDeleteSchedule = scheduleId => {
    // Depends on a client being selected in redux before this function
    // can be safely called
    dispatch(deleteScheduledProgram(scheduleId))
      .then(() => {
        onClose();
        refreshCalendarMonthData();
      }).catch(e => {
        window.alert('Failed to delete schedule(s).');
      });
  };

  const {
    isDataLoaded,
    error,
    // startDate,
    // endDate,
    // scheduleStatus,
    nasmProgramId,
    programCategory,
    workouts,
    programName,
    programStartDate,
    programEndDate,
  } = useFetchProgramScheduleById({
    userId: selectedClient.id,
    scheduleId: selectedScheduleId,
    eventDate: moment(eventDate).format(),
  });

  // Determine which workout to display from scheduled program
  useEffect(() => {
    if (workouts.length > 0) {
      const workoutToFind = workouts.find(w =>
        w.id === selectedWorkoutId &&
        w.days_of_week instanceof Array &&
        w.days_of_week.includes(eventDate.day()));

      setScheduledWorkout(workoutToFind ?? {});
      if(workoutToFind &&
        workoutToFind.uploaded_media &&
        workoutToFind.uploaded_media.thumbnail_url) {
        setVideoWorkoutImage(workoutToFind.uploaded_media.thumbnail_url);
      }
    }
  }, [workouts, selectedWorkoutId, eventDate]);

  const getCustomizeProgramLink = () => {
    const query = updateQuery(
      history.location.search,
      {
        view: 'programDetails',
        programId: nasmProgramId,
        scheduleId: selectedScheduleId,
        programStartDate,
        programEndDate,
      },
    );
    return {
      pathname: `/clients/my-clients/${selectedClient.first_name.toLowerCase()}/dashboard/calendar/customize-program`,
      search: query,
    };
  };

  const getCustomizeWorkoutLink = () => {
    const newQueryParams = {
      view: 'workoutDetails',
      programId: nasmProgramId,
      scheduleId: selectedScheduleId,
      programStartDate,
      programEndDate,
    };

    const query = updateQuery(history.location.search, newQueryParams);
    return {
      pathname: `/clients/my-clients/${selectedClient.first_name.toLowerCase()}/dashboard/calendar/customize-workout`,
      search: query,
    };
  };

  const getCustomizeWorkoutVideoLink = () => {
    const newQueryParams =  {
      view: 'videoWorkout',
      programId: nasmProgramId,
      scheduleId: selectedScheduleId,
      programStartDate,
      programEndDate,
    };

    const query = updateQuery(history.location.search, newQueryParams);
    return {
      pathname: `/clients/my-clients/${selectedClient.first_name.toLowerCase()}/dashboard/calendar/customize-workout`,
      search: query,
    };
  };

  const onEditSchedule = async () => {
    if (programName) {
      // Scheduled Program
      history.push(getCustomizeProgramLink(), { isScheduledProgram: true});
    } else if (scheduledWorkout?.uploaded_media) {
      // Scheduled Video Workout
      history.push(getCustomizeWorkoutVideoLink(), { isScheduledProgram: false });
    } else {
      // Scheduled Workout
      history.push(getCustomizeWorkoutLink(), { isScheduledProgram: false });
    }
  };

  const clearSelectedSchedule = useCallback(() => {
    dispatch(clearProgram());
    dispatch(clearWorkout());
  }, [dispatch]);

  const onCloseScheduleDialog = useCallback(() => {
    clearSelectedSchedule();
    setShowScheduleDialog(false);
  }, [clearSelectedSchedule]);

  const onFail = useCallback((errorMessage) => {
    window.alert(errorMessage);
    onCloseScheduleDialog();
  }, [onCloseScheduleDialog]);

  const onScheduleOnce = async (startDate) => {
    try {
      await dispatch(scheduleProgramToday(startDate));
      // refreshes calendar week strip in Client Dashboard context
      // This is done to ensure scheduled calendar dates are updated
      refreshCalendarMonthData();
    } catch (e) {
      onFail(JSON.stringify(e, null, 2));
    }
  };

  const onScheduleRepeat = async (startDate, endDate) => {
    try {
      await dispatch(scheduleProgram(startDate, endDate));
      // refreshes calendar week strip in Client Dashboard context
      // This is done to ensure scheduled calendar dates are updated
      refreshCalendarMonthData();
    } catch (error) {
      onFail(error.message);
    }
  };

  const onChangeSchedule = () => {
    dispatch(rescheduleProgramContext());
    dispatch(selectScheduledProgram({
      program_id: nasmProgramId,
      schedule_id: selectedScheduleId,
      programStartDate,
      programEndDate,
    })).then((program) => {
      if (!programName) {
        dispatch(selectWorkout(program.workouts[0], true, false));
      }
    });
    setShowScheduleDialog(true);
  };

  if (!isDataLoaded) {
    return <LoadingContainer />;
  }

  if (error) {
    return <ErrorContainer onClose={onClose} />;
  }

  // eslint-disable-next-line camelcase
  const totalDurSeconds = scheduledWorkout?.total_dur_seconds ?? 0;
  const timeDisplay = Math.floor(totalDurSeconds / 60) > 0
    ? `${Math.floor(totalDurSeconds / 60)}+ mins` : `${totalDurSeconds} secs`;

  const confirmDeleteMessage = programName
    // Program
    ? `This workout is a part of a program, "${programName}"` +
    ' Removing this workout will remove the full program from your client’s schedule.'
    // Workout & Exercises only
    : "This will remove all future scheduled workouts of this program from your client's calendar";

  // Remove workout name label if video workout
  // Fallback to workout template name if scheduled workout name not available
  const workoutName = !!scheduledWorkout?.uploaded_media ? '' : (scheduledWorkout?.workout_name ?
      scheduledWorkout?.workout_name : (scheduledWorkout?.name ?? 'Test Workout Schedule'));

  const programCategoryName = scheduledWorkout?.uploaded_media ?
    'Video Workout' : programCategory?.label ?? 'Lifestyle';

  return (
    <>
      <ScheduleBanner
        workoutName={workoutName}
        programCategoryName={programCategoryName}
        timeDisplay={timeDisplay}
        eventDate={eventDate}
        scheduleId={selectedScheduleId}
        videoWorkoutImage={videoWorkoutImage}

        onEditSchedule={onEditSchedule}
        onDeleteSchedule={() => setShowConfirmDialog(true)}
        onClose={onClose}
        onChangeSchedule={onChangeSchedule}
      />
      {/* This list is empty if a Video Workout */}
      <WorkoutsList sections={scheduledWorkout?.sections ?? []} />
      <VideoWorkoutContent
        visible={!!scheduledWorkout?.uploaded_media}
        scheduledWorkout={scheduledWorkout}
      />
      <ConfirmDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        handleConfirmAction={() => onDeleteSchedule(selectedScheduleId)}
        title='Remove Program'
        description={confirmDeleteMessage}
        actionButtonTitle='Remove'
      />
       <ScheduleDialog
        backdropFilterVisible
        open={showScheduleDialog}
        workouts={Object.values(selectedProgram?.entities?.workouts ?? {})}
        onClose={onCloseScheduleDialog}
        onScheduleOnce={onScheduleOnce}
        onScheduleRepeat={onScheduleRepeat}
        onPrepareWorkouts={() => {}}
        disableSaveButton={false}
        onlyRepeat={!!programName}
       />
    </>
  );
}

const useLoadingContainerStyle = makeStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '600px',
  },
});

function LoadingContainer () {
  const classes = useLoadingContainerStyle();
  return (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  );
}

const useErrorContainerStyle = makeStyles({
  errorContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginRight: 20,
  },
  close: {
    fontSize: 18,
  },
  failText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px',
    fontSize: 28,
  },
});

function ErrorContainer (props) {
  const { onClose } = props;
  const classes = useErrorContainerStyle();

  return (
    <Box>
      <Box className={classes.errorContainer}>
        <DialogCircleButton onClick={onClose}>
          <Typography className={classes.close}>
            X
          </Typography>
        </DialogCircleButton>
      </Box>
      <Typography className={classes.failText}>
        Failed to load schedule data.
      </Typography>
    </Box>
  );
}


const useVideoWorkoutContentStyle = makeStyles({
  videoWorkoutDescription: {
    marginTop: 15,
    marginLeft: 23,
    overflowY: 'auto',
  },
});

/**
 * @return {JSX|null}
 */
function VideoWorkoutContent (props) {
  const { visible, scheduledWorkout } = props;

  const classes =useVideoWorkoutContentStyle();

  if (!visible) {
    return null;
  }

  return (
    <Box>
      <SectionDivider
        name={scheduledWorkout.workout_name || scheduledWorkout.name}
        fontColor={colors.black}
      />
      <Typography className={classes.videoWorkoutDescription}>
        {scheduledWorkout.description}
      </Typography>
    </Box>
  );
}
