import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { colors } from '../../styles';

const useStyles = makeStyles({
  updateDateText: props => ({
    color: colors.white,
    fontSize: 14,
    margin: props.margin || 0,
    lineHeight: 2,
  }),
});

/**
 * @return {null|JSX}
 */
function UpdatedAtText(props) {
  const { date, margin } = props;
  const classes = useStyles( { margin });

  if(!date) {
    return null;
  }

  return (
    <Typography className={classes.updateDateText}>
      Updated {moment(date).format('M/D/YYYY')}
    </Typography>
  );
}

UpdatedAtText.propTypes = {
  /***
   *  Date in string format M/DD/YY
   */
  date: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UpdatedAtText;