import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';

import EmptySection from '../EmptyStates/EmptySection';
import {programContexts} from '../../reducers/programContextReducer';
import ftuImgPrograms from '../../resources/ftu-build-program.png';

const EmptySectionContents = {
  [programContexts.LIBRARY]: {
    title: 'Build your own Programs',
    description: 'Create custom workout programs that fit your client’s needs.',
  },
  [programContexts.SCHEDULING]: {
    title: 'You haven’t created any custom programs yet',
    description: 'Create custom programs within your library.',
  },
};

const BoxSpacing = styled(Box)({
  marginTop: 40,
});

/**
 * @return {JSX|null}
 */
function ProgramFTUContent (props) {
  const {
    context,
    onCreateProgram,
    ftuTo = '',
    ftuBtnHidden = false,
    isFirstTimeUserExp,
  } = props;

  if(!isFirstTimeUserExp) {
    return null;
  }

  const { title, description } = EmptySectionContents[context];

  return (
    <BoxSpacing>
      <EmptySection
        ftuImg={ftuImgPrograms}
        emptyTitle={title}
        emptyDescription={description}
        onClickPlus={onCreateProgram}
        to={ftuTo}
        ftuBtnHidden={ftuBtnHidden}
      />
    </BoxSpacing>
  );
}

ProgramFTUContent.propTypes = {
  /***
   * Describes what context this component will be used in:
   * This can either be used in a "LIBRARY" or "SCHEDULING" context
   */
  context: PropTypes.string.isRequired,
  isFirstTimeUserExp: PropTypes.bool.isRequired,
  onCreateProgram: PropTypes.func,
  ftuTo: PropTypes.string,
  ftuBtnHidden: PropTypes.bool,
};

export default ProgramFTUContent;