import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const TextLabel = withStyles({
  root: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 110,
  },
})(Typography);

const useStyles = makeStyles({
  input: {
    borderRadius: 3,
    boxShadow: 'inset 0 0 1px 0 rgba(0, 0, 0, 0.18)',
    border: 'solid 1px #d2d7db',
    width: 240,
    height: 40,
    fontSize: 14,
    cursor: 'pointer',
    '&>input': {
      cursor: 'pointer',
    },
  },
  calendarPreview: {
    marginTop: 25,
  },
});

export default function ScheduleDatePicker (props) {
  const { onChange, onDatePickerError, value, label, disablePast, minDate, minDateMessage } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        autoOk
        disableToolbar
        disablePast={disablePast}
        minDate={minDate}
        allowKeyboardControl={false}
        variant='inline'
        format='MM/DD/yyyy'
        inputVariant='standard'
        onChange={onChange}
        onError={(error, value) => {
          // This callback is responsible for enabling/disabling the Save Button
          if (error) {
            onDatePickerError();
          }
        }}
        value={value}
        minDateMessage={minDateMessage || 'Date should not be before minimal date'}
        PopoverProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          classes: {
            // shifts calendar down relative to anchorOrigin
            paper: classes.calendarPreview,
          },
        }}
        InputProps={{
          className: classes.input,
          disableUnderline: true,
          startAdornment: <TextLabel>{label}</TextLabel>,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
