import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import more from '../../resources/more.png';

const useStyles = makeStyles({
  menuIcon: {
    cursor: 'pointer',
    paddingLeft: 20,
  },
});

export default function FadeMenu (props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { onDuplicatePressed, onDeletePressed, showDeleteButton, showDuplicateButton = true } = props;

  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickDuplicate = () => {
    if (onDuplicatePressed) {
      onDuplicatePressed();
    }
    handleClose();
  };

  const onClickDelete = () => {
    if (onDeletePressed) {
      onDeletePressed();
    }
    handleClose();
  };

  const styles = useStyles(props);

  return (
    !currentUser?.cc_manager ? (
    <div className={styles.menuIcon}>
      <img
        alt=''
        onClick={handleClick}
        onMouseDown={e => e.stopPropagation()}
        src={more}
      />
      <Menu
        style={{ marginLeft: '-45px', marginTop: '42px' }}
        id='fade-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {showDuplicateButton &&
          <MenuItem className='Duplicate' onClick={onClickDuplicate}>
            Duplicate{' '}
          </MenuItem>}
        {showDeleteButton &&
          <MenuItem className='Delete' onClick={onClickDelete}>
            Delete
          </MenuItem>}
      </Menu>
    </div>
    ) : null
  );
}

FadeMenu.propTypes = {
  onDuplicatePressed: PropTypes.func,
  onDeletePressed: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  showDuplicateButton: PropTypes.bool,
};
