import React, {useState} from 'react';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import {PageHeader} from '../../components';
import {ClientBreadcrumbs} from '../../components/ClientDashboard';
import ScheduleCard from '../../components/ClientDashboard/Cards/ScheduleCard';
import OverheadSquatCard from '../../components/ClientDashboard/Cards/OverheadSquatCard';
import NutritionCard from '../../components/ClientDashboard/Cards/NutritionCard';
import AccountCard from '../../components/ClientDashboard/Cards/AccountCard';
import { FloatingButtonDropdown } from '../../components/ClientDashboard';

import useFetchWeeklyCompletion from '../../hooks/dashboard/FetchWeeklyCompletion';
import useFetchCalendarDay from '../../hooks/dashboard/FetchCalendarDay';
import ViewScheduleDialog from '../../components/ViewSchedule/ViewScheduleDialog';
import useFetchOhsaResults from '../../hooks/users/FetchOhsaResults';
import useFetchLatestNutrition from '../../hooks/users/FetchLatestNutrition';
import useFetchPerformanceAssessments from '../../hooks/dashboard/FetchPerformanceAssessments';
import PerformanceAssessmentCard from '../../components/ClientDashboard/Cards/PerformanceAssessmentCard';
import useFetchClientMeasurements from '../../hooks/users/FetchClientMeasurements';
import ClientMeasurementsCard from '../../components/ClientDashboard/Cards/ClientMeasurementsCard';
import useFetchMeasurementUnits from '../../hooks/users/FetchMeasurementUnits';
import GoalsCard from '../../components/ClientDashboard/Cards/GoalsCard';
import { useFetchDashboardMilestones } from '../../hooks/users/GoalsHooks';
import ClientDashboardDetails from '../../components/ClientDashboard/ClientDashboardDetails';

import { CALC_VISIBILITY_STATES } from '../../constants';

const useGridCardStyles = makeStyles({
  gridCardContainer: props => ({
    // https://css-tricks.com/snippets/css/complete-guide-grid/#grid-columngrid-row
    // gridRow: '<start-line> / <end-line>' -->  e.g. 3 / 5 start at row 3 and end at row 5
    // <start-line> / span <value> ---> e.g. auto / span 2 start at the first unoccupied row and take up 2 row spaces
    gridRow: props.gridRow || 'auto',
  }),
});

/**
 * @return {JSX|null}
 */
function GridCard (props) {
  const { children, isVisible, ...rest } = props;
  const classes = useGridCardStyles(rest);

  if (!isVisible) {
    return null;
  }

  return (
    <Box className={classes.gridCardContainer}>
      {children}
    </Box>
  );
}

const useStyles = makeStyles({
  clientDashboard: {
    height: '100%',
    backgroundColor: 'white',
  },
  cardsContainer: {
    padding: '20px 0 50px 0',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    // Support for Grid layout:
    display: 'grid',
    gridAutoFlow: 'row',
    // adds column and row gap for each grid cell item in pixels
    columnGap: 20,
    rowGap: 10,
    // distribute 25px per row (32 rows * 25px = 800px)
    gridTemplateRows: 'repeat(32, 25px)',
    // auto-fill will automatically fill as many columns as it can in a row
    // with column cell sizes constrained between 300px and 420px
    // Note: setting the upper bound to 1fr will cause the grid to have spots that look like it
    // has massive gaps when it shouldn't be in there in the first place.
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    justifyContent: 'center',
  },
});

function ClientDashboard (props) {
  const {
    pathMap = {},
    selectedClient = {},
  } = props;

  const visibilityFlags = {
    visibleGoals: selectedClient.client_user.visible_goals,
    visibleNutrition: selectedClient.client_user.visible_nutrition,
    visibleMeasures: selectedClient.client_user.visible_measures,
    visiblePerfAssess: selectedClient.client_user.visible_perf_assess,
  };

  const classes = useStyles();
  const history = useHistory();

  const [todaysDate] = useState(moment().startOf('day'));
  const [selectedDate, setSelectedDate] = useState(todaysDate);
  const [isViewScheduleDialogOpen, setViewScheduleDialogOpen] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState('');
  const [selectedWorkoutId, setSelectedWorkoutId] = useState('');
  const [canFetchData, setCanFetchData] = useState(true);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const [showClientSettings, setShowClientSettings] = useState(false);
  const [showGoals, setShowGoals] = useState(false);

  const [isCalculatorMenuOpen, setCalculatorMenuOpen] = useState(false);
  const [calcVisibilityState, setCalcVisibilityState] = useState(CALC_VISIBILITY_STATES.NONE);

  const clientFirstName = selectedClient.first_name;
  const clientLastName = selectedClient.last_name;
  const clientUserId = selectedClient.id;

  const navigateToScheduling = () => {
    history.push(`/clients/my-clients/${clientFirstName.toLowerCase()}/dashboard/calendar`);
  };

  const onSelectSchedule = (scheduleId, workoutId) => {
    setViewScheduleDialogOpen(true);
    setSelectedScheduleId(scheduleId);
    setSelectedWorkoutId(workoutId);
  };

  const onClickAccountSettings = () => {
    setShowClientSettings(true);
  };

  const onCloseAccountSettings = () => {
    setShowClientSettings(false);
  };

  const onOpenGoals = () => {
    setShowGoals(true);
  };

  const onCloseGoals = () => {
    setShowGoals(false);
  };

  const onCloseWidget = () => {
    setCalcVisibilityState(CALC_VISIBILITY_STATES.NONE);
  };

  const onOpenBWPWidget = () => {
    setCalcVisibilityState(CALC_VISIBILITY_STATES.BWP);
    setCalculatorMenuOpen(false);
  };
  
  const onOpenOneRmWidget = () => {
    setCalcVisibilityState(CALC_VISIBILITY_STATES.ONE_RM);
    setCalculatorMenuOpen(false);
  };

  const onOpenBMIWidget = () => {
    setCalcVisibilityState(CALC_VISIBILITY_STATES.BMI);
    setCalculatorMenuOpen(false);
  };
  
  const onOpenMHRWidget = () => {
    setCalcVisibilityState(CALC_VISIBILITY_STATES.MHR);
    setCalculatorMenuOpen(false);
  };

  const onOpenBodyFatWidget = () => {
    setCalcVisibilityState(CALC_VISIBILITY_STATES.BodyFat);
    setCalculatorMenuOpen(false);
  };

  const { scheduledWorkoutsByDate } = useFetchWeeklyCompletion(clientUserId, todaysDate.toISOString(), canFetchData);
  const {
    selectedScheduledWorkouts = [],
    isLoading } = useFetchCalendarDay(clientUserId, selectedDate.toISOString(), canFetchData);

  const {
    loading: ohsaResultsLoading,
    overActiveMuscles,
    underActiveMuscles,
    assessmentDate,
  } = useFetchOhsaResults(clientUserId);

  const {
    loading: nutritionResultsLoading,
    nutritionData,
  } = useFetchLatestNutrition(clientUserId);

  const {
    loading: performanceLoading,
    results: performanceResults,
  } = useFetchPerformanceAssessments(clientUserId);
  
  const {
    loading: measurementsLoading,
    measurements,
  } = useFetchClientMeasurements(clientUserId);

  const {
    loading: measurementUnitsLoading,
    measurementUnits,
  } = useFetchMeasurementUnits();

  const {
    loading: goalsLoading,
    milestones,
    focus,
    lastUpdated,
  } = useFetchDashboardMilestones(clientUserId);

  const calculatorNavigationItems = [
    {
      label: 'Body Weight Planner (BWP)',
      onClick: () => onOpenBWPWidget(),
    },
    {
      label: 'One Rep Max (1RM)',
      onClick: () => onOpenOneRmWidget(),
    },
    {
      label: 'Body Mass Index (BMI)',
      onClick: () => onOpenBMIWidget(),
    },
    {
      label: 'Max Heart Rate (MHR)',
      onClick: () => onOpenMHRWidget(),
    },
    {
      label: 'Body Fat',
      onClick: () => onOpenBodyFatWidget(),
    },
  ];

  if (selectedProfile?.ClubId) {
    calculatorNavigationItems.splice(0, 1);
  }

  return (
    <>
      <Box className={classes.clientDashboard}>
        <PageHeader
          title={`${clientFirstName} ${clientLastName}`}
          subtitle='Dashboard'
          height={120}
        />
        <ClientBreadcrumbs
          paddingTop={21}
          paddingLeft={133}
          ariaLabel='client-dashboard-breadcrumbs'
          pathMap={pathMap}
          pathNamesExclusionList={['clients', clientFirstName?.toLowerCase()]}
        />
        <FloatingButtonDropdown
          isMenuOpen={isCalculatorMenuOpen}
          setMenuOpen={setCalculatorMenuOpen}
          dropdownHeader='Calculators'
          navigationItems={calculatorNavigationItems}
        />
        <Box className={classes.cardsContainer}>
          <GridCard gridRow={'auto / span 24'} isVisible>
            <ScheduleCard
              todaysDate={todaysDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              clientUserId={clientUserId}
              scheduledWorkoutsByDate={scheduledWorkoutsByDate}
              selectedScheduledWorkouts={selectedScheduledWorkouts}
              isDashboardDataLoading={isLoading}
              onNavigateToScheduling={navigateToScheduling}
              onSelectSchedule={onSelectSchedule}
            />
          </GridCard>
          <GridCard gridRow={'auto / span 6'} isVisible>
            <AccountCard
              user={selectedClient}
              onOpen={onClickAccountSettings}
            />
          </GridCard>
          <GridCard gridRow={'auto / span 9'} isVisible={visibilityFlags.visibleMeasures}>
            <ClientMeasurementsCard
              loading={measurementsLoading && measurementUnitsLoading}
              measurementsData={measurements}
              unitPreferences={measurementUnits}
            />
          </GridCard>
          <GridCard gridRow={'auto / span 9'} isVisible={visibilityFlags.visibleGoals}>
            <GoalsCard
              loading={goalsLoading}
              milestones={milestones}
              focus={focus}
              lastUpdated={lastUpdated}
              onClick={onOpenGoals}
            />
          </GridCard>
          <GridCard gridRow={'auto / span 11'} isVisible={visibilityFlags.visiblePerfAssess}>
            <PerformanceAssessmentCard
              loading={!!performanceLoading}
              performanceAssessments={performanceResults}
            />
          </GridCard>
          <GridCard gridRow={'auto / span 8'} isVisible={visibilityFlags.visibleNutrition}>
            <NutritionCard
              loading={nutritionResultsLoading}
              nutritionData={nutritionData}
              onClick={onOpenBWPWidget}
            />
          </GridCard>
          <GridCard gridRow={'auto / span 8'} isVisible>
            <OverheadSquatCard
              loading={ohsaResultsLoading}
              overActiveMuscles={overActiveMuscles}
              underActiveMuscles={underActiveMuscles}
              assessmentDate={assessmentDate}
            />
          </GridCard>
        </Box>
      </Box>
      <ViewScheduleDialog
        open={isViewScheduleDialogOpen}
        setOpen={setViewScheduleDialogOpen}
        setCanFetchData={setCanFetchData}
        selectedScheduleId={selectedScheduleId}
        selectedWorkoutId={selectedWorkoutId}
        selectedClient={selectedClient}
        eventDate={moment(selectedDate)}
      />
      {/* Component for housing all the dialog pop ups */}
      <ClientDashboardDetails
        selectedClient={selectedClient}
        showClientSettings={showClientSettings}
        onClickClientSettings={onClickAccountSettings}
        onCloseAccountSettings={onCloseAccountSettings}
        showGoals={showGoals}
        onCloseGoals={onCloseGoals}
        showBWPWidget={calcVisibilityState === CALC_VISIBILITY_STATES.BWP}
        showBMIWidget={calcVisibilityState === CALC_VISIBILITY_STATES.BMI}
        showOneRmWidget={calcVisibilityState === CALC_VISIBILITY_STATES.ONE_RM}
        showMHRWidget={calcVisibilityState === CALC_VISIBILITY_STATES.MHR}
        showBodyFatWidget={calcVisibilityState === CALC_VISIBILITY_STATES.BodyFat}
        onCloseWidget={onCloseWidget}
        // used to hide nutrition setting
        clubId={selectedProfile?.ClubId}
      />
    </>
  );
}

ClientDashboard.propTypes = {
  pathMap: PropTypes.object,
  selectedClient: PropTypes.object,
};

export default ClientDashboard;