import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import nasmApi from '../../api/endpoints';
import ProgramFTUContent from './ProgramFTUContent';
import ProgramListContent from './ProgramListContent';

export default function ProgramList (props) {
  const {
    getProgramUrl,
    selectedProgramIndex,
    setSelectedProgramIndex,
    copyProgramLink,
    onCreateProgram,
    ftuTo,
    ftuBtnHidden,
    showDuplicateDeleteButtons,
  } = props;

  const [isFirstTimeUserExp, setFirstTimeUserExp] = useState(false);
  const location = useLocation();
  const currentTab = location.pathname.includes('my-programs') ? 'my-programs' : 'nasm-programs';
  const context = useSelector(state => state.programContext.context);

  // Used to determine whether or not FTU Panel should be shown
  useEffect(() => {
    const cancel = axios.CancelToken.source();
    const searchParams = {
      page: 1,
      size: 1,
      programSources: 'trainerOwned',
    };

    nasmApi.nasmPrograms
      .findPrograms(searchParams, cancel.token)
      .then(data => {
        if(!data?.isRequestCancelled) {
          setFirstTimeUserExp(data?.result?.programs?.length === 0 && currentTab === 'my-programs');
        }
      });

    return () => cancel.cancel('Program Search Request FTU Cancelled');
  }, [currentTab]);

  return (
    <>
      <ProgramListContent
        selectedProgramIndex={selectedProgramIndex}
        setSelectedProgramIndex={setSelectedProgramIndex}
        copyProgramLink={copyProgramLink}
        showDuplicateDeleteButtons={showDuplicateDeleteButtons}
        getProgramUrl={getProgramUrl}
        isFirstTimeUserExp={isFirstTimeUserExp}
        currentTab={currentTab}
      />
      <ProgramFTUContent
        onCreateProgram={onCreateProgram}
        ftuTo={ftuTo}
        ftuBtnHidden={ftuBtnHidden}
        context={context}
        isFirstTimeUserExp={isFirstTimeUserExp}
      />
    </>
  );
}
