import React from 'react';
import PlaceHolderGraphic from '../../resources/ftu-add-workout.png';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';

const useStyles = makeStyles({
  container: {
    padding: 60,
  },
  placeholderCard: {
    height: 467,
    textAlign: 'center',
    borderRadius: 6,
    boxShadow: '0 -1px 6px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #e4e4e4',
    backgroundColor: colors.white,
    padding: 40,
  },
  title: {
    fontSize: 19,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.05,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: colors.black,
  },
});

export default function Education (props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.placeholderCard}>
        <div>
          <img src={PlaceHolderGraphic} alt='placeholder' />
          <h1 className={classes.title}>Education goes here</h1>
        </div>
      </div>
    </div>
  );
}
