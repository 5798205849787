import { ButtonBase } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { colors } from '../../styles';

const OvalButton = withTheme(withStyles({
  root: props => {
    return {
      width: props.width ? props.width : '68px',
      height: props.height ? props.height : '38px',
      borderRadius: props.borderradius ? props.borderradius : '19px',
      border: props.border ? props.border : 'solid 1px #ced3d7',
      backgroundColor: props.backgroundcolor ? props.backgroundcolor : colors.white,
      color: props.color ? props.color : colors.steel,
      margin: props.margin ? props.margin : 0,
      disabled: props?.disabled ?? false,
      opacity: props?.disabled ? 0.75 : (props?.opacity ?? 1),
      fontSize: props.fontSize ?? 14,
      fontWeight: props.fontWeight ?? 'normal',
      fontFamily: props.theme.typography.fontFamily,
      type: props.type ?? 'button',
      '&:hover': {
        filter: props?.disabled ? 'none' : 'brightness(94%)',
      },
    };
  },
})(ButtonBase));

export default OvalButton;
