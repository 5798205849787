import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Slide } from '@material-ui/core';

import { ReactComponent as Heart } from '../../resources/image-health.svg';
import MacaroniButton from '../Buttons/MacaroniButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '28px',
    height: '534px',
  },
  header: {
    fontSize: '40px',
    fontWeight: 'bold',
  },
  description: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    textAlign: 'center',
  },
});

function HealthWarning (props) {
  const { visible, clientName, onReadHealthWarning } = props;

  const classes = useStyles();

  if (!visible) return null;

  return (
    <Slide
      in={visible}
      direction='left'
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.root}>
        <Heart />
        <Typography className={classes.header}>{`${clientName}'s Health`}</Typography>
        <Typography className={classes.description}>
          Before continuing, make sure your client is healthy enough for physical
          activity and/or has a physician’s permission to workout.
        </Typography>
        <MacaroniButton width='207px' onClick={onReadHealthWarning}>I Understand, Continue</MacaroniButton>
      </Box>
    </Slide>
  );
}

export default HealthWarning;
