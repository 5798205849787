import * as React from "react";
import { Dialog, makeStyles, Typography, Box, CircularProgress } from "@material-ui/core";
import { useFetchTrainerClients } from "../../hooks/trainer/FetchTrainerClients";
import { CheckboxOn } from "../../resources/ChecboxOn";
import { CheckboxOff } from "../../resources/CheckboxOff";
import { MacaroniButton, OvalButton } from "../../components/Buttons";
import { HighlightSearchBar } from "../../components/Inputs";
import { colors } from "../../styles";
import placeholderIcon from "../../resources/user-dashboard-client-profile-with-no-photo.svg";

const useStyle = makeStyles({
  accountPaper: {
    borderRadius: "6px",
    minWidth: "480px",
    height: "700px",
  },
  scrollPaper: {
    alignItems: "flex-start",
    marginTop: "55px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    padding: "20px 27px",
  },
  header: {
    fontFamily: "DMSans-Bold, sans-serif",
    fontSize: "40px",
    color: colors.black,
    textAlign: "center",
    margin: "20px 0px 10px",
  },
  searchBarCot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  selectMemCont: {
    overflow: "scroll",
    scrollbarWidth: "none",
  },
  selectMemberRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: `1px solid ${colors.selected_highlight_copy_6}`,
  },
  avatar: {
    width: "68px",
    height: "68px",
    borderRadius: "68px",
    objectFit: "cover",
    padding: "10px",
  },
  memberName: {
    fontFamily: "DMSans, sans-serif",
    fontSize: "17px",
  },
  checkbox: {
    marginRight: '10px',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function SelectGroupMembers(props) {
  const classes = useStyle();
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [clients, setClients] = React.useState([]);
  const [selectedClients, setSelectedClients] = React.useState(props?.selectedClients ?? []);

  useFetchTrainerClients({ searchText })
    .then(({ clients, loading }) => {
      setClients(clients);
      setLoading(loading);
    })
    .catch((error) => error);

  const clearSearchText = () => {
    setSearchText("");
  };

  function handleSearch(e) {
    setSearchText(e.target.value.toLowerCase());
  }

  const onSelectClient = (client) => {
    const users = [...selectedClients];
    let isNewUser = true;
    let userIndex = 0;
    if (users.length) {
      userIndex = users.findIndex(
        (clientUser) => clientUser.id === client.client_user.id,
      );
      if (userIndex > -1) {
        isNewUser = false;
      }
    }
    if (isNewUser) {
      const clientUser = {
        id: client.client_user.id,
        user: {
          id: client.id,
          avatar_url: client.avatar_url,
          full_name: client.full_name,
        },
      };
      users.push(clientUser);
    } else {
      users.splice(userIndex, 1);
    }
    setSelectedClients(users);
  };

  const renderClients = () => {
    return clients?.map((client, i) => (
      <Box key={i} className={classes.selectMemberRow}>
        <Box className={classes.flex}>
          <img
            src={client.avatar_url ?? placeholderIcon}
            alt="avatar"
            className={classes.avatar}
          />
          <Typography className={classes.memberName}>
            {client.full_name}
          </Typography>
        </Box>
        <Box
          className={classes.checkbox}
          onClick={() => onSelectClient(client)}
        >
          {selectedClients.findIndex((clientUser) => clientUser.id === client.client_user.id) > -1 ? (
            <CheckboxOn />
          ) : (
            <CheckboxOff color={colors.colors_fill_light_gray_2} />
          )}
        </Box>
      </Box>
    ));
  };

  return (
    <Dialog
      maxWidth="sm"
      classes={{
        paper: classes.accountPaper,
        scrollPaper: classes.scrollPaper,
      }}
      open={props.open}
      onClose={props.onClose}
      BackdropProps={{
        style: { backgroundColor: colors.mediumBlue, opacity: 0.35 },
      }}
      disableBackdropClick
    >
      <Box className={classes.content}>
        <Box className={classes.flex}>
          <OvalButton onClick={props.onClose}>Back</OvalButton>
          <MacaroniButton onClick={() => props.onUpdateClients(selectedClients)} width="127px">Save</MacaroniButton>
        </Box>
        <Typography className={classes.header}>Select Members</Typography>
        <Box className={classes.searchBarCot}>
          <HighlightSearchBar
            onClickClear={clearSearchText}
            value={searchText}
            onChange={handleSearch}
            fullWidth={true}
          />
          <OvalButton>Filter</OvalButton>
        </Box>
      </Box>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.selectMemCont}>
          {renderClients()}
        </Box>
      )}
    </Dialog>
  );
}
